import { Spin } from 'antd'
import { RoutePath } from 'constants/routes'
import React from 'react'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ component: Component, isAuthenticated, isLoading, ...rest }: any): any => {
   return isAuthenticated ? (
      <Component {...rest} />
   ) : isLoading ? (
      () => (
         <div
            style={{
               width: '100vw',
               height: '100vh',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
            }}>
            <Spin spinning={isLoading} />
         </div>
      )
   ) : (
      <Navigate to={RoutePath.LOGOUT} />
   )
}

export default PrivateRoute
