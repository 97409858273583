import { CloseCircleOutlined } from '@ant-design/icons'
import { CustomIcon, durationOptions, getIsMobile, Text, useModal, validateRequired } from '@plandok/core'
import { IntlTemplate } from '@plandok/i18n'
import * as api from 'api'
import { Field } from 'components/form'
import React, { memo, useEffect, useState } from 'react'

import { getTimeOptions } from '../config/options'
import * as SC from '../styles'
import ServiceOption from './ServiceOption'

type AppointmentFieldProps = {
   name: string
   value?: any
   employeeId?: string
   removeRow?: (...a: any) => any
   changeValue: (...a: any) => any
   resourcesOptions: any[]
   isLoadingResources: boolean
   locationId?: string
   isMobile?: boolean
   date?: Date
   timeFormat: string
   isServiceFormError: boolean
}

export default memo(function AppointmentField({
   name,
   value,
   removeRow,
   changeValue,
   resourcesOptions,
   isLoadingResources,
   locationId,
   isMobile,
   date,
   timeFormat,
   isServiceFormError,
}: AppointmentFieldProps) {
   const [isBookingAppointment, setIsBookingAppointment] = useState(false)
   const [isWorkingTime, setIsWorkingTime] = useState(true)
   const [employeeName] = useState('')

   const { startTime, employeeId, duration } = value ?? {}

   const durationFieldName = `${name}.duration`
   const setDuration = (_: any, val: any) => {
      changeValue(durationFieldName, (val || {}).duration)
      changeValue(`${name}.price`, (val || {}).price)
   }

   const hasResources = !isLoadingResources && resourcesOptions?.length
   const hasEmployeeId = !!employeeId && employeeId !== 'undefined'
   const hasNotification = !isLoadingResources && employeeName && !isWorkingTime && isWorkingTime !== null
   const options = getTimeOptions(timeFormat)

   const [, { showModal, hideModal }]: any = useModal()

   useEffect(() => {
      ;(async () => {
         if (employeeId) {
            const payload = {
               employeeId,
               locationId,
               date,
               time: startTime,
            }

            const response = await api.fetchIsWorkingStaff('', payload)
            setIsWorkingTime(response?.is_working_time)
         }
      })()
   }, [startTime])

   // useEffect(() => {
   //     (async () => {
   //       const findEmployeeName = staff?.find((employee: any) => employee.id === employeeId)?.firstName || '';
   //
   //       setEmployeeName(findEmployeeName);
   //     })();
   // }, [employeeId])

   useEffect(() => {
      ;(async () => {
         if (employeeId && locationId && date && startTime && duration) {
            const payload = {
               employeeId,
               locationId,
               date,
               startTime,
               duration,
            }

            const { isAppointmentBooked } = await api.checkIsBookedAppointment('', payload)
            setIsBookingAppointment(isAppointmentBooked)
         }
      })()
   }, [employeeId, locationId, date, startTime, duration])

   return (
      <SC.AppointmentFieldWrapper>
         {removeRow && (
            <SC.RemoveIcon>
               <CloseCircleOutlined onClick={removeRow} />
            </SC.RemoveIcon>
         )}

         {isMobile ? (
            <>
               <SC.ServiceFieldWrapper theme={{ isServiceFormError, hasNotification }}>
                  <Field.AsyncSelect
                     name={`${name}.serviceId`}
                     label="input.service.label"
                     placeholder="input.service.placeholder"
                     asyncType="serviceGroups"
                     showSearch={!getIsMobile()}
                     optionRender={ServiceOption}
                     optionLabelProp="title"
                     onCustomChange={setDuration}
                     filterParams={hasEmployeeId ? { 'filter[employee_id]': value.employeeId } : undefined}
                     validate={validateRequired}
                     isCustomSearchOption
                     isFullScreen
                     addBtn="btn.addNewService"
                     modalType="ADD_APPOINTMENT_SERVICE_MODAL"
                     showModal={showModal}
                     hideModal={hideModal}
                  />
               </SC.ServiceFieldWrapper>

               {hasNotification && (
                  <SC.NotifyWrapper>
                     <CustomIcon type="notify" />
                     <Text
                        style={{ whiteSpace: 'pre-line' }}
                        mb="none"
                        size="small"
                        weight="normal"
                        lh="large"
                        color="#61749D"
                        isItalic>
                        <IntlTemplate label="select.notify.title" params={[employeeName]} />
                     </Text>
                  </SC.NotifyWrapper>
               )}

               <SC.FieldsWrapper theme={{ isBookingAppointment }}>
                  <Field.Row className="time-row">
                     <SC.Select
                        name={`${name}.startTime`}
                        label="input.startTime.label"
                        md={6}
                        options={options}
                        theme={{ isBookingAppointment }}
                     />
                     <Field.Select
                        name={durationFieldName}
                        label="input.duration.label"
                        md={6}
                        options={durationOptions}
                        defaultValue={15}
                     />
                  </Field.Row>
               </SC.FieldsWrapper>
            </>
         ) : (
            <>
               <SC.FieldsWrapperForDesktop theme={{ isServiceFormError, isBookingAppointment, hasNotification }}>
                  <SC.Select
                     name={`${name}.startTime`}
                     label="input.startTime.label"
                     md={8}
                     options={options}
                     theme={{ isBookingAppointment }}
                  />

                  <Field.AsyncSelect
                     name={`${name}.serviceId`}
                     label="input.service.label"
                     placeholder="input.service.placeholder"
                     asyncType="serviceGroups"
                     md={16}
                     translate
                     showSearch={!getIsMobile()}
                     optionRender={ServiceOption}
                     optionLabelProp="title"
                     onCustomChange={setDuration}
                     filterParams={hasEmployeeId ? { 'filter[employee_id]': value.employeeId } : undefined}
                     validate={validateRequired}
                     isCustomSearchOption
                     addBtn="btn.addNewService"
                     modalType="ADD_APPOINTMENT_SERVICE_MODAL"
                     showModal={showModal}
                     hideModal={hideModal}
                     isWorkingTime={isWorkingTime}
                     inputKey="serviceId"
                  />
               </SC.FieldsWrapperForDesktop>
               {!isWorkingTime && (
                  <SC.NotifyWrapper>
                     <CustomIcon type="notify" />
                     <Text
                        style={{ whiteSpace: 'pre-line' }}
                        mb="none"
                        size="small"
                        weight="normal"
                        lh="large"
                        color="#61749D"
                        isItalic>
                        <IntlTemplate label="select.notify.title" params={[employeeName]} />
                     </Text>
                  </SC.NotifyWrapper>
               )}
               {isBookingAppointment && (
                  <SC.NotifyBookingAppointmentWrapper
                     label="booking.notify.title"
                     mb={hasNotification ? 'none' : 'xsmall'}
                  />
               )}
               <Field.Select
                  name={durationFieldName}
                  label="input.duration.label"
                  md={8}
                  options={durationOptions}
                  defaultValue={15}
               />
            </>
         )}
         <Field.AsyncSelect
            name={`${name}.employeeId`}
            label="input.staff.label"
            placeholder="input.staff.placeholder"
            asyncType="employee"
            filterParams={locationId ? { 'filter[location_id]': locationId } : undefined}
            md={hasResources ? 8 : 16}
         />
         {!!hasResources && (
            <Field.Select
               name={`${name}.resourceId`}
               label="modal.entity.resource"
               placeholder="modal.entity.resource"
               md={8}
               options={resourcesOptions}
               allowClear
            />
         )}
      </SC.AppointmentFieldWrapper>
   )
})
