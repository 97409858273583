import { IntlLabel } from '@plandok/i18n'
import { Avatar, Badge, Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'

const accessMapping = {
   owner: 'staff.access.owner',
   high: 'staff.access.high',
   medium: 'staff.access.medium',
   low: 'staff.access.low',
   basic: 'staff.access.basic',
   no_access: 'staff.access.no_access',
}

const Photo = styled.img`
   width: 32px;
   height: 32px;
   border-radius: 50%;
   margin-right: 5px;
   :hover {
      cursor: pointer;
   }
`

export const columnsConfig = [
   {
      title: '',
      dataIndex: 'status',
      align: 'center',
      key: 'status',
      render: ({ value: { appointmentColor } }: any) => (
         <Badge
            color={appointmentColor}
            status="processing"
            className="status-badge staff-members-table__status-badge"
         />
      ),
   },
   {
      title: <IntlLabel label="table.staff.name" />,
      dataIndex: 'firstName',
      align: 'left',
      style: { minWidth: '200px' },
      render: ({ value }: any) =>
         value.photo ? (
            <Row align="middle" gutter={[12, 0]}>
               <Col xs={5} lg={4}>
                  <Photo src={value.photo} alt={value.firstName} />
               </Col>
               <Col xs={19} lg={20} className="table-row-font text-resp-center">
                  {value.firstName} {value.lastName}
               </Col>
            </Row>
         ) : (
            <Row align="middle" gutter={[12, 0]}>
               <Col xs={5} lg={4}>
                  <Avatar>{(value.firstName || '').substring(0, 2).toUpperCase()}</Avatar>
               </Col>
               <Col xs={19} lg={20} className="table-row-font text-resp-center">
                  {value.firstName} {value.lastName}
               </Col>
            </Row>
         ),
   },
   {
      title: <IntlLabel label="table.staff.mobileNumber" />,
      dataIndex: 'phoneNumber',
      style: { minWidth: '200px' },
      key: 'phoneNumber',
   },
   {
      title: <IntlLabel label="table.staff.email" />,
      dataIndex: 'email',
      key: 'email',
   },
   {
      title: <IntlLabel label="table.staff.userPermission" />,
      dataIndex: 'permissionLevel',
      key: 'permissionLevel',
      style: { minWidth: '150px' },
      render: ({ value }: any) => <IntlLabel label={(accessMapping as any)[value.permissionLevel]} />,
   },
]
