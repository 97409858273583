import { useAppContext } from '@plandok/core'
import { useLocation } from '@plandok/core'
import { IntlLabel, LabelKey, ReactIntl } from '@plandok/i18n'
import { message } from 'antd'
import * as api from 'api'
import { usePwaContext } from 'components'
import { RoutePath } from 'constants/routes'
import { useGetProfile } from 'hooks/info/useGetProfile'
import React, { useContext } from 'react'

export function useOnLogin(msg?: LabelKey) {
   const [, { setAppContext }] = useAppContext()
   const { changeLang } = useContext(ReactIntl)
   const { navigate } = useLocation()
   const getProfile = useGetProfile()
   const pwaContext = usePwaContext()

   return (result: any) => {
      api.authSuccess(result)

      setAppContext({ ...result.context, isAuthenticated: true })

      changeLang(result.context.languageIsoCode)

      message.success(
         <IntlLabel label={msg || 'notification.success.register'} lang={result.context.languageIsoCode} />,
      )

      setTimeout(() => {
         navigate(RoutePath.CALENDAR)
         if (pwaContext?.showDelayed) {
            pwaContext?.showPrompt?.()
         }
      }, 100)

      getProfile()

      // if (form?.employeeEmail) {
      //   try {
      //     Sentry?.configureScope?.(function (scope: any) {
      //       scope?.setUser?.({email: form?.employeeEmail});
      //     });
      //   } catch (e) {
      //     console.error(e, 'Failed to add additional data to Sentry reports');
      //   }
      // }
   }
}
