import { IntlLabel, LabelKey } from '@plandok/i18n'
import React, { ReactNode } from 'react'

import * as SC from './styles'

export const fontSize = {
   xsmall: '12px',
   small: '14px',
   medium: '18px',
   base: '16px',
   large: '20px',
   mlarge: '24px',
   llarge: '28px',
   xlarge: '30px',
   xxlarge: '36px',
   xxxlarge: '40px',
}

export const fontWeight = {
   extraLight: '200',
   light: '300',
   normal: '400',
   medium: '500',
   semiBold: '600',
   bold: '700',
   extraBold: '800',
   initial: 'initial',
}

export const lineHeight: any = {
   base: '16px',
   small: '19px',
   large: '20px',
   mlarge: '21px',
   xlarge: '22px',
   xxlarge: '23px',
   xxxlarge: '33px',
}

const marginBottom: any = {
   negative: '-30px',
   none: '0px',
   xsmall: '8px',
   small: '13px',
   medium: '20px',
   xmedium: '24px',
   large: '30px',
}

const marginLeft: any = {
   none: '0px',
   base: '20px',
   xxsmall: '5px',
   xsmall: '8px',
   small: '13px',
   large: '30px',
}

const colors: any = {
   base: '#13316D',
   secondary: '#6D83B1',
   white: 'white',
   black: 'black',
   green: '#1EAF83',
   red: '#EB588A',
   grey: '#899CC5',
   lightGrey: '#ADB6CC',
   lightBlue: '#62759D',
   link: '#136EF6',
   yellow: '#FFA100',
   violet: '#6563FF',
   lightViolet: '#61749D',
   inherit: 'inherit',
   brown: '#373737',
   darkBrown: '#2D2825',
}

interface TextProps {
   label?: LabelKey
   children?: ReactNode
   mb?: keyof typeof marginBottom
   ml?: keyof typeof marginLeft
   colorType?: keyof typeof colors
   size?: keyof typeof fontSize
   weight?: keyof typeof fontWeight
   lh?: keyof typeof lineHeight
   bold?: boolean
   isCenter?: boolean
   isUnderline?: boolean
   isItalic?: boolean
   [key: string]: any
}

function Text({
   as: Element,
   label,
   children,
   size,
   weight,
   upperCase,
   isUnderline,
   mb,
   ml,
   colorType,
   isItalic,
   isCenter,
   isCursor,
   lh,
   ...props
}: TextProps) {
   return (
      <SC.Text
         as={Element}
         fontSize={size && fontSize[size]}
         fontWeight={weight && fontWeight[weight]}
         marginBottom={marginBottom[mb as any]}
         marginLeft={marginLeft[ml as any]}
         color={colorType && colors[colorType]}
         isCenter={isCenter}
         upperCase={upperCase}
         isUnderline={isUnderline}
         isItalic={isItalic}
         isCursor={isCursor}
         lineHeight={lineHeight[lh as any]}
         {...props}>
         <IntlLabel label={label} />
         {children}
      </SC.Text>
   )
}

Text.defaultProps = {
   as: 'div',
   size: 'large',
   mb: 'base',
}

export default Text
