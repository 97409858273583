import { mediaMobileDown } from '@plandok/core'
import { Pagination as AntPagination } from 'antd'
import styled, { css } from 'styled-components'

type ContainerProps = {
   paginationSpacing?: string
   isCenter?: boolean
}

export const Container = styled.div<ContainerProps>`
   margin: ${({ paginationSpacing }) => paginationSpacing || '30px 0'};
   justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'space-between')};
   display: flex;
   align-items: center;

   .ant-pagination-item-active {
      border: none !important;
   }
`

export const TotalNumbers = styled.div`
   display: flex;
   div {
      margin-left: 0.5rem;
   }
`

export const Pagination = styled(AntPagination)`
   &.ant-pagination {
      .ant-pagination-item.ant-pagination-item-active {
         border-color: transparent;
      }
   }

   .ant-pagination-item-active {
      color: white;

      &:hover {
         background: none !important;
      }
   }

   ${mediaMobileDown(css`
      margin: auto;
   `)}
`
