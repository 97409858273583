import { withCurrencySign } from '@plandok/i18n'
import get from 'lodash/get'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'

import { IoC } from '../../../../di.config'
import { extractOptions, filterOptions } from '../../../../helpers/form/helpers'
import CustomFilterSelectInput from '../../input/CustomFilterSelectInput'
import SelectInput from '../../input/SelectInput'

export interface AsyncSelectInputProps extends Partial<any> {
   input: Partial<FieldRenderProps<any, any>['input']>
   asyncType: string
   selectComponent?: React.ComponentType<any>
   selectFirstAsDefault?: boolean
   onInitialLoad?: (onChange: any, options: any[], value: any) => void
   showSearch?: boolean
   predefinedOptions?: any[]
   filterParams?: any
   className?: string
   hideForOne?: boolean
   sign: string
   isCustomSearchOption?: boolean
   addBtn?: string
   modalType?: string
   isWorkingTime?: boolean
   inputKey?: string
}

const AsyncSelectInput: React.FC<AsyncSelectInputProps> = props => {
   const {
      input,
      asyncType,
      selectComponent: SelectComponent = SelectInput,
      selectFirstAsDefault,
      onInitialLoad,
      showSearch,
      predefinedOptions,
      hideForOne,
      sign,
      isCustomSearchOption,
      addBtn,
      modalType,
      isWorkingTime,
      filterParams,
      inputKey,
      ...rest
   } = props

   const [options, setOptions] = useState<any[]>([])
   const [loading, setLoading] = useState(true)
   const [filter, setFilter] = useState('')

   // @ts-ignore
   //TODO: Look out for any fix to this error
   const { fetchData, mapping, groupValueExtractor, isGroup } = useCallback(
      IoC.getService().getAsyncSelectConfig(asyncType) as any,
      [asyncType],
   )

   const getSupportInfo = () => ({ sign })

   const getOptions = useCallback(
      (selectFirst?: boolean) => {
         setLoading(true)
         fetchData(filterParams).then((data: any) => {
            const newOptions = isGroup ? groupValueExtractor(data, getSupportInfo()) : extractOptions(data, mapping)
            setOptions(newOptions)
            setLoading(false)

            if (selectFirst && input && !input.value) {
               const initValue = get(newOptions, '[0].code')
               input.onChange?.(props.mode === 'multi' ? [initValue] : initValue)
            }

            if (onInitialLoad) {
               onInitialLoad(input.onChange, newOptions, input.value)
            }
         })
      },
      [fetchData, filterParams, groupValueExtractor, input, isGroup, mapping, onInitialLoad, props.mode],
   )

   useEffect(() => {
      getOptions(selectFirstAsDefault)
   }, [])

   const onSearch = (filter: string) => {
      if (showSearch) {
         setFilter(filter)
      }
   }

   const onFilterMenu = (filter: string) => {
      setFilter(filter)
   }

   const onGetOptions = () => {
      getOptions()
   }

   const filteredOptions = filterOptions(options, isGroup, filter, predefinedOptions)

   if (hideForOne && !loading && (!options?.length || options?.length < 2)) {
      return null
   }

   return (
      <>
         {!addBtn && !isCustomSearchOption ? (
            <SelectComponent
               {...(input as any)}
               {...(rest as any)}
               onSearch={onSearch}
               value={loading ? '' : input.value}
               options={!isGroup ? filteredOptions : []}
               optionGroups={isGroup && filteredOptions}
               loading={loading}
               filterOption={() => true} // Prevents default filtering
            />
         ) : (
            <CustomFilterSelectInput
               {...(input as any)}
               {...(rest as any)}
               inputKey={inputKey ?? ''}
               filterTxt={filter}
               onSearch={onSearch}
               onSubFilter={onFilterMenu}
               value={loading ? '' : input.value}
               options={!isGroup ? filteredOptions : []}
               optionGroups={isGroup && filteredOptions}
               loading={loading}
               filterOption={() => true}
               modalType={modalType}
               addBtn={addBtn}
               isCustomSearchOption={isCustomSearchOption}
               onGetOptions={onGetOptions}
               isWorkingTime={isWorkingTime}
            />
         )}
      </>
   )
}

export default withCurrencySign(AsyncSelectInput)
