import { Pie } from '@ant-design/plots'
import { getIsMobile, Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import React from 'react'
import { useEffect, useState } from 'react'

import { App_ClientsAnalytics } from '../../../../../../graphql/generatedTypes'
import { NoData } from '../NoData/NoData'
import * as SC from './styles'
import { ChartsData, CustomTooltip } from './types'

type ReferencePieChartProps = {
   data: App_ClientsAnalytics
}

export const ReferencePieChart = ({ data }: ReferencePieChartProps) => {
   const [mappedSourceData, setMappedSourceData] = useState<ChartsData[]>([])

   const dataLength = data?.byReferralSource?.length ?? 0
   const colors = ['#D0DAF1', '#1877F2', '#5E2FF5']

   useEffect(() => {
      const genderDataBySource: ChartsData[] = []
      data?.byReferralSource?.map((item: any) => {
         return genderDataBySource.push({
            type: item.name || 'Unknown',
            value: item.percent || 0,
         })
      })
      setMappedSourceData(genderDataBySource)
   }, [data])

   const tooltipContent = (title: string, data: any[]): React.ReactNode => (
      <SC.Tooltip>
         {title}
         <div>
            ({data[0]?.value}% <IntlLabel label="analytics.clients.title" />)
         </div>
      </SC.Tooltip>
   )

   const referencePieChartConfig = {
      data: mappedSourceData,
      angleField: 'value',
      colorField: 'type',
      color: colors,
      radius: 0.9,
      legend: false,
      label: {
         position: 'inside',
         offset: '-50%',
         content: (_ref: any) => {
            const percent = _ref?.percent ?? 0
            return `${(percent * 100).toFixed(0)}%`
         },
         style: {
            fontSize: 16,
            fontWeight: 500,
            textAlign: 'center',
         },
      },
      tooltip: {
         customContent: (title: string, data: any) => tooltipContent(title, data),
      } as CustomTooltip,
      interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
   }

   return (
      <SC.ColumnGraph>
         <Text size="medium" weight={getIsMobile() ? 'semiBold' : 'normal'} colorType="base" mb="none">
            <IntlLabel label="analytics.clientsBySource.title" />
         </Text>
         {dataLength >= 2 && (
            <SC.PieChart>
               <Pie {...referencePieChartConfig} />
               <SC.LegendContainer>
                  {mappedSourceData?.map((item, index) => (
                     <div key={item.type} className="align-center">
                        <SC.LegendDot color={colors[index]} />
                        <SC.LegendValue>{item.type}</SC.LegendValue>
                     </div>
                  ))}
               </SC.LegendContainer>
            </SC.PieChart>
         )}
         {dataLength < 2 && <NoData />}
      </SC.ColumnGraph>
   )
}
