import { extractFieldError } from '@plandok/core'
import { IntlLabel, LabelKey, withIntlLabel } from '@plandok/i18n'
import { Col, Form } from 'antd'
import { ColProps } from 'antd/lib/grid'
import cn from 'classnames'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import React from 'react'
import { FieldMetaState } from 'react-final-form'

import FormTooltip from './components/FormTooltip'

export interface FormBlockProps extends Omit<ColProps, 'translate'> {
   meta: FieldMetaState<any>
   submitting?: boolean
   formClassName?: string
   label?: LabelKey | true
   labelComponent?: React.ReactNode
   tooltip?: LabelKey | { key: LabelKey; style?: any }[]
   component?: any
   noLabelHolder?: boolean
   itemProps?: { className: string }
   placeholder?: LabelKey
   explanation?: string
   inputError?: string
}

const colProps = ['span', 'order', 'offset', 'push', 'pull', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'noLabelHolder']

const FormBlock = <T extends {}>({
   submitting,
   meta,
   label,
   labelComponent,
   tooltip,
   itemProps,
   formClassName,
   component: Component,
   noLabelHolder,
   explanation,
   inputError,
   ...props
}: FormBlockProps & T) => {
   const error = extractFieldError(meta, submitting) || inputError

   if (noLabelHolder) {
      return (
         <div className="ant-col ant-col-24">
            <div className="ant-row ant-form-item ant-form-item-with-help">
               <Component {...props} />
            </div>
         </div>
      )
   }

   return (
      <Col {...pick(props, colProps)}>
         <Form.Item
            label={
               labelComponent || (
                  <>
                     {label === true ? <span> </span> : <IntlLabel label={label} />}
                     <FormTooltip tooltip={tooltip} />
                  </>
               )
            }
            validateStatus={error ? 'error' : undefined}
            help={<IntlLabel label={error} />}
            colon={false}
            className={cn(formClassName || '', itemProps && itemProps.className)}>
            <Component {...omit(props, colProps)} />
         </Form.Item>
         <IntlLabel label={explanation} />
      </Col>
   )
}

FormBlock.defaultProps = {
   span: 24,
}

export default withIntlLabel({ propKey: 'placeholder' })(FormBlock)
