import { useNavigation } from '@plandok/core'
import { message } from 'antd'
import get from 'lodash/get'
import React, { useCallback, useEffect, useState } from 'react'

import { RoutePath } from '../../constants/routes'

interface FormApiProps {
   createAction?: (props: any) => any
   editAction?: (id: string, props: any) => any
   detailsAction?: any
   backPath?: RoutePath | string
   successMessage?: string
   isModal?: boolean
   detailsSelect?: (
      response: any,
      rescheduleItemDate?: string | Date,
      rescheduleItemTime?: number,
      rescheduleItemStaff?: string,
   ) => any
   emptyId?: boolean
   rescheduleItemDate?: string | Date
   rescheduleItemTime?: number
   rescheduleItemStaff?: string
   setRescheduleItemDate?: (rescheduleItemDate: string | Date | undefined) => void
   setRescheduleItemTime?: (rescheduleItemTime: number | undefined) => void
   setRescheduleItemStaff?: (rescheduleItemStaff: string) => void
}
export default function withFormApi({
   createAction,
   editAction,
   detailsAction,
   backPath,
   successMessage,
   isModal,
   detailsSelect,
   emptyId,
   rescheduleItemDate,
   rescheduleItemTime,
   rescheduleItemStaff,
   setRescheduleItemDate,
   setRescheduleItemTime,
   setRescheduleItemStaff,
}: FormApiProps) {
   return (WrappedComponent: any) => {
      return (props: any) => {
         const [initialValues, setInitialValues] = useState(props.initialValues || {})
         const [isLoading, setIsLoading] = useState(false)
         const { modalProps } = props

         const { params, navigate } = useNavigation()

         const getId = useCallback(() => {
            if (props.customId) {
               return props.customId
            }
            return isModal ? props.modalProps?.id : get(props, 'match.params.id', params.id)
         }, [props, isModal, params])

         const getIsEdit = useCallback(() => !!getId(), [getId])

         const goBack = useCallback(() => {
            if (props.onBack) {
               props.onBack()
            } else if (backPath) {
               navigate(backPath)
            }
         }, [props.onBack, backPath])

         const getDetails = useCallback(async () => {
            if (!detailsAction || (!getIsEdit() && !emptyId)) {
               return
            }

            setIsLoading(true)
            const response = await detailsAction(getId())

            if (response) {
               const selectedDetails = detailsSelect
                  ? detailsSelect(response, rescheduleItemDate, rescheduleItemTime, rescheduleItemStaff)
                  : response
               setInitialValues(selectedDetails)
            }

            setIsLoading(false)
         }, [
            detailsAction,
            getId,
            getIsEdit,
            detailsSelect,
            rescheduleItemDate,
            rescheduleItemTime,
            rescheduleItemStaff,
            emptyId,
         ])

         const onSubmit = useCallback(
            async (form: any) => {
               if (getIsEdit() && editAction) {
                  const res = await editAction(getId(), form)
                  if (!res?.errors && props.onSuccess) {
                     props.onSuccess(res)
                  }
                  if (res?.errors) {
                     Object.values(res?.errors).map((err: any) => message.error(err))
                  }

                  setRescheduleItemDate?.(undefined)
                  setRescheduleItemTime?.(undefined)
                  setRescheduleItemStaff?.('')

                  return res
               }

               const res = await createAction!(form)
               modalProps?.getAddedItem && modalProps?.getAddedItem(res)

               if (!res?.errors && props.onSuccess) {
                  props.onSuccess(res, undefined, form)
               }
               if (res?.errors) {
                  Object.values(res?.errors).map((err: any) => message.error(err))
               }

               return res
            },
            [
               getIsEdit,
               editAction,
               createAction,
               getId,
               props.onSuccess,
               setRescheduleItemDate,
               setRescheduleItemTime,
               setRescheduleItemStaff,
            ],
         )

         const onSuccess = useCallback(() => {
            if (successMessage) {
               message.success(successMessage)
            }

            goBack()
         }, [successMessage, goBack])

         const onCancel = useCallback(() => goBack(), [goBack])

         useEffect(() => {
            if (!props.skipDetails && (emptyId || getIsEdit())) {
               getDetails()
            }
         }, [props.skipDetails, emptyId])

         return (
            <WrappedComponent
               onSubmit={onSubmit}
               onSuccess={onSuccess}
               onCancel={onCancel}
               entityId={getId()}
               isEdit={getIsEdit()}
               {...props}
               initialValues={initialValues}
               isLoading={isLoading}
            />
         )
      }
   }
}
