import { getIsMobile } from '@plandok/core'
import * as api from 'api'
import { RestTable } from 'components'
import React from 'react'

import { getMobileColumn, invoiceColumns } from './appTableConfig'
import { WrapperInvoice } from './styled'

// export const invoiceConfig = [
//    {
//       title: <IntlLabel label="invoice.table.date" />,
//       dataIndex: 'name',
//       key: 'name',
//       render: (_: undefined, value: PaymentListItem) => value.createdAt ?? '',
//    },
//    {
//       title: <IntlLabel label="invoice.table.invoice" />,
//       dataIndex: 'formattedInvoiceNumber',
//       key: 'formattedInvoiceNumber',
//    },
//    {
//       title: <IntlLabel label="invoice.table.priceTaxIncl" />,
//       dataIndex: 'priceTaxIncl.amount',
//       key: 'priceTaxIncl.amount',
//       render: (_: undefined, value: PaymentListItem) => {
//          const amount = value?.priceTaxIncl?.amount
//          const currency = value?.priceTaxIncl?.currency
//
//          return amount ? (
//             <div>
//                {amount} {currency}
//             </div>
//          ) : null
//       },
//    },
//    {
//       title: '',
//       width: '300px',
//       render: (_: undefined, value: PaymentListItem) => (
//          <Button
//             ghost
//             shape="round"
//             icon="download"
//             upperCase={false}
//             onClick={() => value?.invoiceNumber && downloadInvoicePDF(value.invoiceNumber)}>
//             <span className="ml-1">
//                <IntlLabel label="invoice.table.download" />
//             </span>
//          </Button>
//       ),
//    },
// ]

export default function InvoicesPage() {
   const isMobile = getIsMobile()
   return (
      <WrapperInvoice>
         <RestTable
            columns={invoiceColumns}
            mobileClassName="app_clients-list--mobile"
            mobileRowComponent={getMobileColumn(isMobile)}
            apiConfig={{
               fetchData: api.fetchInvoices,
               magicMinus: true,
               actualOffset: true,
               mapping: {
                  pageKey: 'page[offset]',
                  limitKey: 'page[limit]',
                  dataKey: 'data',
                  totalCountKey: 'meta.pagination.total',
                  searchKey: 'query',
               },
            }}
         />
      </WrapperInvoice>
   )
}
