import { Column } from '@ant-design/plots'
import { getIsMobile, Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { arrayMoveImmutable } from 'array-move'
import React from 'react'
import { useEffect, useState } from 'react'

import { App_ClientsAnalytics } from '../../../../../../graphql/generatedTypes'
import { NoData } from '../NoData/NoData'
import * as SC from './styles'
import { ChartsData } from './types'

type AgeColumnProps = {
   data: App_ClientsAnalytics
}

export const AgeColumnChart = ({ data }: AgeColumnProps) => {
   const [mappedAgeData, setMappedAgeData] = useState<ChartsData[]>([])

   const dataLength = data?.byAge?.length

   useEffect(() => {
      const genderDataByAge: ChartsData[] = []
      const clientsByAge = data?.byAge

      const findIndexForUnknownItem = clientsByAge?.findIndex((item: any) => item.name === 'unknown') || 0
      const arrayMoveClientsByAge = arrayMoveImmutable(clientsByAge || [], findIndexForUnknownItem, 0) || []
      const sortedClientsByAge = arrayMoveClientsByAge.sort((a: any, b: any) => parseInt(a.name) - parseInt(b.name))

      sortedClientsByAge?.map(item => {
         return genderDataByAge.push({
            type: item?.count ?? '',
            value: item?.name ?? 0,
         })
      })
      setMappedAgeData(genderDataByAge)
   }, [data])

   const ageColumnChartConfig = {
      data: mappedAgeData,
      xField: 'value',
      yField: 'type',
      color: '#61749D',
      height: 180,
      autoFit: true,
      maxColumnWidth: 25,
      label: {
         position: 'inside',
         offset: '-50%',
         style: {
            fill: '#FFFFFF',
            opacity: 0.6,
         },
      },
      meta: {
         type: { alias: 'count' },
      },
   } as any

   return (
      <SC.ColumnGraph>
         <Text
            size="medium"
            weight={getIsMobile() ? 'semiBold' : 'normal'}
            colorType="base"
            mb={!dataLength ? 'none' : 'medium'}>
            <IntlLabel label="analytics.clientsByAge.title" />
         </Text>
         <SC.BarChart>
            {!!dataLength && <Column {...ageColumnChartConfig} />}
            {!dataLength && <NoData />}
         </SC.BarChart>
      </SC.ColumnGraph>
   )
}

export default AgeColumnChart
