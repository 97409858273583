import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { IntlMoney } from '@plandok/i18n'
import { formatDuration } from 'helpers/date'
import React from 'react'

import * as SC from '../style'
import SmallDragIcon from './SmallDragIcon'

function ServiceItem({ item = {}, editService, groupId }: any) {
   const editItem = () => {
      editService(item.id, groupId)
   }

   const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id })

   const style = {
      transform: CSS.Transform.toString(transform),
      transition,
   }

   return (
      <SC.ServiceItem onClick={editItem} data-row-key={item.id} ref={setNodeRef} style={style}>
         <div data-service-item-drag {...attributes} {...listeners}>
            <SmallDragIcon data-service-item-drag />
         </div>
         <div data-service-item-content>
            <div>{item.name}</div>
            <div>{formatDuration(item.duration)}</div>
            <div>
               <IntlMoney value={item.price} />
            </div>
         </div>
      </SC.ServiceItem>
   )
}

export default ServiceItem
