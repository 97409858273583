import { useModal, useNavigation } from '@plandok/core'
import { useLocation } from '@plandok/core'
import * as api from 'api'
import { AppLayout, NoInternetConnection, Placeholder } from 'components'
import { RoutePath } from 'constants/routes'
import { onReschedule } from 'helpers/app/reschedule'
import React, { useEffect, useState } from 'react'
import { isFirefox } from 'react-device-detect'

import withApiData from '../../../hocs/form/withApiData'
import MultipleAddButton from './components/MultipleAddButton'
import ServiceGroupList from './components/ServiceGroupList'
import ServicesMobileHeader from './components/ServicesMobileHeader'
import * as helpers from './helpers'

function ServicesPage(props: any) {
   const { urlQuery } = useNavigation()
   const [data, setItem] = useState(props.data)
   const { navigate } = useLocation()
   const [, { showModal }] = useModal()

   const query = urlQuery()

   const createService = (serviceGroupId?: string) =>
      navigate(RoutePath.SERVICE_CREATE + (serviceGroupId ? `?serviceGroupId=${serviceGroupId}` : ''))
   const editService = (id: string) => navigate(RoutePath.SERVICE_EDIT, id)
   const createCategory = () =>
      showModal({
         type: 'CREATE_CATEGORY',
         modalProps: { onSuccess: props.fetchData, initialValues: { color: '#6cd5cb' } },
      })
   const editCategory = (id: string) =>
      showModal({ type: 'CREATE_CATEGORY', modalProps: { id, onSuccess: props.fetchData } })
   const onItemDrop = (id: any) => (params: any) => setItem(helpers.moveElementItem(data, id, params))

   const scrollToCurrentService = (id: string) => {
      // Firefox does not support center scroll
      document.getElementById(id)?.scrollIntoView?.({ block: isFirefox ? 'start' : 'center', behavior: 'smooth' })
   }

   useEffect(() => {
      scrollToCurrentService(query.serviceGroupId)
   }, [query.serviceGroupId])

   return (
      <NoInternetConnection>
         <AppLayout
            mobileHeader={ServicesMobileHeader}
            headerTitle="sidebar.service"
            customButton={
               !props.showPlaceholder ? (
                  <MultipleAddButton
                     actions={[
                        {
                           icon: 'newServiceGroup',
                           title: 'btn.newServiceGroup',
                           action: createCategory,
                        },
                        {
                           icon: 'newService',
                           title: 'service.new.title',
                           action: createService,
                        },
                     ]}
                  />
               ) : null
            }>
            {props.showPlaceholder ? (
               <Placeholder
                  imgName="services"
                  btnLabel="state.services.button"
                  descriptionLabel="state.services.description"
                  titleLabel="state.services.title"
                  onClick={createCategory}
                  position="relative"
               />
            ) : (
               <ServiceGroupList
                  isLoading={props.isLoading}
                  fetchServiceGroups={props.fetchData}
                  data={props.data ?? []}
                  onSortEnd={onReschedule({
                     data: props.data,
                     changeData: props.changeData,
                     onPositionUpdate: async (id: string, newPosition: number) =>
                        await api.changeServiceGroupPosition('', { id, newPosition }),
                  })}
                  changeData={props.changeData}
                  onItemDrop={onItemDrop}
                  createService={createService}
                  createCategory={createCategory}
                  editCategory={editCategory}
                  editService={editService}
               />
            )}
         </AppLayout>
      </NoInternetConnection>
   )
}

export default withApiData(api.fetchCategories, { isSortable: true })(ServicesPage)
