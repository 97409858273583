import { CloseCircleOutlined, DownOutlined, PlusCircleFilled, UserOutlined } from '@ant-design/icons'
import { AuthBlock, getIsMobile, getScreenHeight, Portal, SearchInput, Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import * as api from 'api'
import { PermissionType } from 'constants/auth'
import React, { memo, useEffect, useState } from 'react'

import { ModifyLayout } from '../../../../../components'
import { ClientFormWithApi } from '../../../ModifyClientPage'
import ClientDetails from './components/ClientDetails'
import ClientSearchList from './components/ClientSearchList'
import EmptyListHolder from './components/EmptyListHolder'
import * as SC from './styles'

export default memo(function ClientSelectMenu({ input, timeFormat }: any) {
   const [version, setVersion] = useState(1)
   const [value, setValue] = useState('')
   const [isOpen, setOpen] = useState(false)
   const [isOpenClient, setIsOpenClient] = useState(false)
   const [clients, setClients] = useState([])
   const [isMobileOpen, setMobileOpen] = useState(false)
   const isMobile = getIsMobile()
   const [isLoading, setIsLoading] = useState(false)

   useEffect(() => {
      if (!isOpenClient) {
         ;(async () => {
            setIsLoading(true)
            await api
               .fetchClientOptions(value ? { query: value } : value)
               .then(e => setClients((e || {}).clients || []))
            setIsLoading(false)
         })()
      }
   }, [value, isOpenClient])

   const openCreateClient = () => setIsOpenClient(true)
   const closeClientCreate = () => setIsOpenClient(false)

   const scrollHeight = getScreenHeight()

   const toggleMobile = () => setMobileOpen(!isMobileOpen)

   const clientEdit = (
      <Portal>
         {isOpenClient && (
            <SC.ClientContainer style={{ minHeight: scrollHeight || '100vh' }}>
               <ModifyLayout
                  style={{ background: 'white' }}
                  title={input.value ? 'title.edit.client' : 'client.create.title'}
                  onClose={closeClientCreate}>
                  <ClientFormWithApi
                     customId={input.value}
                     skipDetails={!input.value}
                     onBack={closeClientCreate}
                     initialValues={{ notificationChannel: 'sms_and_email', gender: 'unknown' }}
                     onSuccess={(res: any) => {
                        closeClientCreate()
                        setVersion(p => p + 1)
                        res.clientId && input.onChange(res.clientId)
                     }}
                  />
               </ModifyLayout>
            </SC.ClientContainer>
         )}
      </Portal>
   )

   if (input.value) {
      const removeClient = () => {
         input.onChange(null)
         setValue('')
      }

      return (
         <>
            <ClientDetails
               version={version}
               clientId={input.value}
               timeFormat={timeFormat}
               removeClient={removeClient}
               openCreateClient={openCreateClient}
            />
            {clientEdit}
         </>
      )
   }

   const onOpen = !isOpen ? () => setOpen(true) : undefined

   const setMobileClient = (clientId: string) => {
      input.onChange(clientId)
      toggleMobile()
   }

   const filteredClients = clients

   return (
      <>
         <SC.Container onClick={onOpen}>
            <SearchInput value={value} setValue={setValue} />
            {!isOpen ? (
               <EmptyListHolder />
            ) : (
               <ClientSearchList
                  isLoading={isLoading}
                  clients={filteredClients}
                  selectClient={input.onChange}
                  createNewClient={openCreateClient}
               />
            )}
         </SC.Container>
         {!isMobile && (
            <AuthBlock section={PermissionType.CLIENTS}>
               <SC.AddItem onClick={openCreateClient}>
                  <PlusCircleFilled />
                  <Text size="small" colorType="base" weight="semiBold" mb="none" upperCase>
                     <IntlLabel label="client.create.new" />
                  </Text>
               </SC.AddItem>
            </AuthBlock>
         )}
         <SC.SelectClientMobile
            onClick={(e: any) => {
               e.preventDefault()
               e.stopPropagation()
               toggleMobile()
            }}>
            <UserOutlined /> <IntlLabel label="client.choose" /> <DownOutlined />
         </SC.SelectClientMobile>
         {isMobileOpen ? (
            <SC.MobileContainer>
               <SC.CloseIcon onClick={toggleMobile}>
                  <CloseCircleOutlined />
               </SC.CloseIcon>
               <SearchInput value={value} setValue={setValue} />
               <ClientSearchList
                  isLoading={isLoading}
                  clients={filteredClients}
                  selectClient={setMobileClient}
                  createNewClient={openCreateClient}
               />
            </SC.MobileContainer>
         ) : null}
         {clientEdit}
      </>
   )
})
