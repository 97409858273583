import { message } from 'antd'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { queryCall } from '../../../../graphql'

interface GraphStoreInitializeProps {
   gqlQuery?: any
   fetchData?: (...args: any[]) => Promise<any>
   mapping: {
      pageKey: string
      limitKey?: string
      dataKey: string
      totalCountKey: string
      searchKey?: string
      maxCountKey?: string
      emptyStateKey?: string
   }
}

interface GraphStoreState {
   page: number
   totalCount: number
   maxCount: number
   isLoading: boolean
   initialized: boolean
   error: string
   debouncedQuery: string
   data: { [key: string]: any[] }
   showPlaceholder: boolean
   gqlParams: {
      after?: string
      before?: string
      [key: string]: any
   }
}

export const useGraphStore = (initProps: GraphStoreInitializeProps, searchStr?: string) => {
   const [state, setState] = useState<GraphStoreState>({
      page: 1,
      totalCount: 0,
      maxCount: 10,
      isLoading: false,
      initialized: false,
      error: '',
      debouncedQuery: '',
      data: {},
      showPlaceholder: false,
      gqlParams: {},
   })

   const getCacheKey = useCallback(
      (page: number) => {
         const searchStrPart = searchStr ? `:query${searchStr}` : ''
         return `${page}${searchStrPart}`
      },
      [searchStr],
   )

   const extractGqlParams = useCallback((resp: any) => {
      if (resp.pageInfo) {
         setState(prev => ({
            ...prev,
            gqlParams: {
               ...prev.gqlParams,
               after: resp.pageInfo.endCursor,
            },
         }))
      }
   }, [])

   const currentPageKey = useMemo(() => getCacheKey(state.page), [getCacheKey, state.page])

   // Memoized computed values
   const currentData = useMemo(() => {
      const pageData = state.data[getCacheKey(state.page)]
      console.log(state.page, state.data, pageData, pageData ? pageData.map((v, index) => ({ ...v, key: index })) : [])
      return pageData ? pageData.map((v, index) => ({ ...v, key: index })) : []
   }, [state.data, state.page])

   const allData = useMemo(() => {
      return Object.values(state.data).reduce<any[]>((acc, arr) => {
         if (!arr) return acc
         return [...acc, ...arr]
      }, [])
   }, [state.data])

   const totalDataCount = useMemo(() => {
      return Object.values(state.data).reduce((acc, arr) => acc + (arr?.length || 0), 0)
   }, [state.data])

   const hasMore = useMemo(() => {
      return state.totalCount > totalDataCount
   }, [state.totalCount, totalDataCount])

   const count = useMemo(() => {
      return state.data[currentPageKey]?.length ?? 0
   }, [state.data, currentPageKey])

   const extractData = useCallback(
      (resp: any) => {
         const getResData = get(resp, initProps.mapping.dataKey)
         if (Boolean(getResData?.edges)) {
            extractGqlParams(getResData)
            return getResData.edges?.map(({ node }: any) => {
               if (Boolean(node.firstName)) {
                  return { ...node, user: `${node.firstName} ${node.lastName || ''}` }
               }
               if (
                  Boolean(node.clientFirstName) ||
                  Boolean(node.cancelledByFirstName) ||
                  Boolean(node.employeeFirstName)
               ) {
                  return {
                     ...node,
                     client: `${node.clientFirstName || ''} ${node.clientLastName || ''}`,
                     employee: `${node.employeeFirstName || ''} ${node.employeeLastName ?? ''}`,
                     cancelledBy: `${node.cancelledByFirstName || ''} ${node.cancelledByLastName ?? ''}`,
                  }
               }
               return node
            })
         }
         return getResData
      },
      [initProps.mapping.dataKey, extractGqlParams],
   )

   const filterGqlDataBySearchParams = useCallback(
      (result: any, query?: string) => {
         if (query) {
            const filteredResult =
               result?.[initProps.mapping.dataKey]?.edges?.filter((item: any) =>
                  Boolean(
                     item?.node?.clientFirstName?.toLowerCase().includes(query.toLowerCase()) ||
                        item?.node?.clientLastName?.toLowerCase().includes(query.toLowerCase()),
                  ),
               ) ?? []

            return {
               ...result,
               [initProps.mapping.dataKey]: { edges: filteredResult, totalCount: filteredResult.length },
            }
         }
         return result
      },
      [initProps.mapping.dataKey],
   )

   const extractTotalCount = useCallback(
      (resp: any) => {
         return get(resp, initProps.mapping.totalCountKey) ?? 0
      },
      [initProps.mapping.totalCountKey],
   )

   const fetchData = useCallback(
      async (page: number = 1, freshData?: any) => {
         if (state.isLoading) return

         if (page !== 1 && state.data[getCacheKey(page)]) {
            setState(prev => ({ ...prev, page }))
            return
         }

         setState(prev => ({ ...prev, isLoading: true }))

         try {
            let resp = await (initProps.fetchData && typeof freshData !== 'number'
               ? initProps.fetchData(filterGqlDataBySearchParams(freshData, searchStr))
               : queryCall(initProps.gqlQuery, page === 1 ? {} : { ...state.gqlParams })())

            const data = extractData(resp)
            const totalCount = extractTotalCount(resp)

            let showPlaceholder = false
            if (initProps.mapping.emptyStateKey) {
               showPlaceholder = !!get(resp, initProps.mapping.emptyStateKey)
            } else {
               showPlaceholder = !totalCount && !data?.length
            }

            setState(prev => ({
               ...prev,
               data: { ...prev.data, [getCacheKey(page)]: data },
               page,
               totalCount,
               showPlaceholder,
               isLoading: false,
               initialized: true,
            }))
         } catch (e) {
            message.error('Failed to load data, please contact support')
            setState(prev => ({ ...prev, isLoading: false }))
         }
      },
      [
         state.isLoading,
         state.data,
         state.gqlParams,
         state.debouncedQuery,
         getCacheKey,
         filterGqlDataBySearchParams,
         extractData,
         extractTotalCount,
         initProps.gqlQuery,
         initProps.mapping.emptyStateKey,
      ],
   )

   const fetchDataDebounced = useCallback(
      debounce(async (query?: string) => {
         setState(prev => ({
            ...prev,
            page: 1,
            searchStr: query || '',
         }))

         clearCache()
         await fetchData(1)
      }, 50),
      [fetchData],
   )

   const fetchFreshData = useCallback(
      async (data?: any) => {
         clearCache()
         setState(prev => ({
            ...prev,
            gqlParams: { ...prev.gqlParams },
         }))
         await fetchData(state.page, data)
      },
      [fetchData, state.page],
   )

   const pageChange = async (page: number) => {
      await fetchData(page)
   }

   const clearCache = () => setState(prev => ({ ...prev, data: {} }))

   const resetFilter = () => {
      setState(prev => ({
         ...prev,
         searchStr: '',
         page: 0,
         totalCount: 0,
         maxCount: 10,
      }))
   }

   useEffect(() => {
      return () => {
         setState(prev => ({
            ...prev,
            showPlaceholder: false,
            initialized: false,
            data: {},
            searchStr: '',
            page: 0,
            totalCount: 0,
            maxCount: 10,
            gqlParams: {},
         }))
      }
   }, [])

   return {
      ...state,
      fetchData,
      fetchDataDebounced,
      fetchFreshData,
      pageChange,
      clearCache,
      resetFilter,
      currentData,
      allData,
      hasMore,
      count,
   }
}
