import React from 'react'

import * as SC from '../style'
import DragIcon from './DragIcon'

function DragHandle({ listeners, attributes }: any) {
   return (
      <SC.Drag {...listeners} {...attributes} style={{ cursor: 'grab' }}>
         <DragIcon />
      </SC.Drag>
   )
}

export default DragHandle
