import { useDispatch, useSelector } from 'react-redux'

import { hideAllModals, hideModal, showModal } from './store'

interface ModalActions {
   showModal: (props: { type: string; modalProps?: any }) => void
   hideModal: (type: string) => void
   hideAllModals: () => void
}

export const useModal = (): [modals: any[], ModalActions] => {
   const dispatch = useDispatch()
   const modals = useSelector((state: any) => state.modalContext.modals)

   return [
      modals,
      {
         showModal: (props: { type: string; modalProps?: any }) => {
            dispatch(showModal(props))
         },
         hideModal: (type: string) => {
            dispatch(hideModal(type))
            setTimeout(() => {
               dispatch(hideModal(type)) // Clean up after animation delay
            }, 300) // Same as the animation delay
         },
         hideAllModals: () => {
            dispatch(hideAllModals())
            setTimeout(() => {
               dispatch(hideAllModals()) // Clean up after animation delay
            }, 300) // Same as the animation delay
         },
      }, // The actions (second element)
   ]
}
