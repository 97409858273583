import * as React from 'react'
import { Field } from 'react-final-form'

import { FormBlockProps } from '../field/FormBlock'

export const withField =
   <T extends { name: string }>(Component: React.ComponentType<any>, defaultProps?: any) =>
   (props: T & Omit<FormBlockProps, 'meta'> & { [key: string]: any }) => (
      <Field component={Component} {...defaultProps} {...props} />
   )
