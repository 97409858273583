import { useQuery } from '@tanstack/react-query'
import { Spin } from 'antd'
import * as api from 'api'
import { QueryType } from 'api/query'
import React, { ReactElement, useState } from 'react'

type NotificationFormApiProps = {
   children(value: {
      initialValues: any
      onSubmit: (form: any) => void
      setLoading: (loading: boolean) => void
   }): ReactElement
   notificationId: string
}

export default function NotificationFormApi({ children, notificationId }: NotificationFormApiProps) {
   const [loading, setLoading] = useState<boolean>(false)
   const { data, isFetching: isLoading } = useQuery({
      queryKey: [QueryType.NOTIFICATIONS, notificationId],
      queryFn: () => api.fetchNotificationConfig(notificationId),
   })

   return (
      <Spin spinning={loading || isLoading}>
         {children({
            initialValues: data,
            onSubmit: async (form: any) => {
               const timestampRegex = /^\d{10,13}$/
               if (form.hasOwnProperty('id')) {
                  setLoading(true)
                  const { id, ...newTemplate } = form
                  if (timestampRegex.test(form.id)) {
                     let response = await api.createNotificationConfig(notificationId, newTemplate)
                     setLoading(false)
                     return response
                  } else {
                     let response = await api.updateNotificationConfig(
                        { templateId: id, notificationType: notificationId },
                        newTemplate,
                     )
                     setLoading(false)
                     return response
                  }
               } else {
                  return api.changeNotificationStatus(notificationId, form)
               }
            },
            setLoading: setLoading,
         })}
      </Spin>
   )
}
