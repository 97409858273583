import {
   AuthBlock,
   Card,
   CustomIcon,
   formatTime,
   getIsMobile,
   Text,
   useAppContext,
   useModal,
   useNavigation,
} from '@plandok/core'
import { IntlDate, IntlLabel, IntlMoney, IntlTemplate } from '@plandok/i18n'
import { useQuery } from '@tanstack/react-query'
import { Col, Row, Spin } from 'antd'
import * as api from 'api'
import { QueryType } from 'api/query'
import { AppointmentDate, ClientItem, ModifyLayout, NoInternetConnection, TextLong } from 'components'
import { PermissionType } from 'constants/auth'
import { repeatFrequencyIntervals } from 'constants/data'
import { RoutePath } from 'constants/routes'
import { formatDuration } from 'helpers/date'
import { parseDateField } from 'helpers/date/field'
import { getUrlParams } from 'helpers/router'
import sumBy from 'lodash/sumBy'
import React from 'react'
import { useStore } from 'store/store'
import { Client, EmployeeForSelect } from 'types'

import AppointmentFooterTotal from '../ModifyAppointmentPage/components/AppointmentFooterTotal'
import CancelBtn from './components/CancelBtn'
import EditBtn from './components/EditBtn'
import HistoryLog from './components/HistoryLog'
import NotificationLog from './components/NotificationLog'
import RescheduleBtn from './components/RescheduleBtn'
import StatusBtn from './components/StatusBtn'
import { Status } from './helpers'
import * as SC from './styles'
import { Log } from './types'

type AppointmentDetailsData = {
   bookings: {
      id: string
      duration: number
      employee: EmployeeForSelect
      price: string
      resource: null | { name: string }
      serviceId: string
      serviceName: string
      startTime: number
   }[]
   client: Client | null
   date: string
   id: string
   locationId: string
   logs: Log[]
   notes: string | null
   notificationLogs: Log[]
   options: {
      repeating_value: string
      frequency: string
      last_appointment_date: string
   }
   recurrenceId: string | null
   reminderAt: string | null
   reminderSendAt: string | null
   status: string
   paymentType?: string
   paymentStatus?: string
}

export default function AppointmentDetailsPage() {
   const [context] = useAppContext()

   const timeFormat = context.timeFormat
   const [, { showModal }] = useModal()
   const { params, navigate } = useNavigation()

   const backPath = getUrlParams()?.backPath
   const isMobile = getIsMobile()

   // @ts-ignore
   const { data, isLoading }: { data: AppointmentDetailsData; isLoading: boolean } = useQuery({
      queryKey: [QueryType.APPOINTMENT_DETAILS, params.id],
      queryFn: () => api.fetchAppointmentDetails(params.id),
   })

   const { repeating_value, frequency } = data?.options ?? {}
   const findFrequency = repeatFrequencyIntervals.find(interval => interval.code === frequency)?.name

   // Means that we come from page that we want to come back after create
   const onCancelAppointment = () =>
      backPath ? navigate(backPath, { id: params.id, status: data?.status }) : navigate(RoutePath.CALENDAR)

   const setRescheduleItemId = useStore(state => state.setRescheduleItemId)

   const openAppointmentCancel = () =>
      showModal({
         type: 'CANCEL_APPOINTMENT',
         modalProps: {
            id: params.id,
            recurrenceId: data?.recurrenceId ?? '',
            onSuccess: onCancelAppointment,
         },
      })

   const editItem = () =>
      params.id
         ? navigate(
              `${RoutePath.APPOINTMENT_UPDATE}/${params.id}`,
              {},
              {
                 state: {
                    ...data?.options,
                    recurrenceId: data?.recurrenceId,
                 },
              },
           )
         : undefined

   const rescheduleItem = () => {
      setRescheduleItemId(params.id ?? '')

      navigate(RoutePath.CALENDAR)
   }

   const getLogLabel = (log: Log) => ` ${log?.employee?.firstName || ''} ${log?.employee?.lastName || ''}, `
   const until = new Date(data?.options?.last_appointment_date || 0)
   const dateFormat = timeFormat === '12h' ? 'EEE, dd MMM, yyyy h:mm a' : 'EEE, dd MMM, yyyy HH:mm'

   return (
      <NoInternetConnection>
         <ModifyLayout title="appointment.view.title">
            <Card noPadding withShadow>
               <Spin spinning={isLoading}>
                  <Row>
                     <Col span={24} xl={16}>
                        <Row>
                           <SC.Header>
                              <AppointmentDate hideArrow input={{ value: parseDateField(data?.date) }} disabled />
                              {data?.recurrenceId && !!data?.options?.last_appointment_date && (
                                 <Text size="small" colorType="base" weight="normal" mb="none">
                                    <IntlLabel label="rp.repeats.title" />
                                    &nbsp;
                                    <IntlLabel label={findFrequency} />
                                    &nbsp;
                                    <IntlLabel label="rp.repeatsUntil.title" />
                                    &nbsp;
                                    <IntlDate date={until} dateFormat="EEE, dd MMM yyyy" />
                                    &nbsp;
                                    {parseInt(repeating_value) <= 30 && (
                                       <IntlTemplate label="rp.repeatingTimes.title" params={[repeating_value]} />
                                    )}
                                 </Text>
                              )}
                              {isMobile ? (
                                 <>
                                    <SC.StatusTitle>
                                       <IntlLabel label="appointment.status.btn" />:
                                    </SC.StatusTitle>
                                    <SC.StatusButton>
                                       <StatusBtn status={data?.status} id={params.id ?? ''} />
                                    </SC.StatusButton>
                                 </>
                              ) : (
                                 <StatusBtn status={data?.status} id={params.id ?? ''} />
                              )}
                           </SC.Header>
                           <SC.MobileBlock>
                              <SC.ClientHeader>
                                 <div data-client-info-header>
                                    <ClientItem client={data?.client} redirectOnClick />
                                 </div>
                                 <TextLong text={data?.client?.notes} />
                              </SC.ClientHeader>
                           </SC.MobileBlock>
                           <SC.Body>
                              {data?.bookings?.map(
                                 ({ id, employee, price, startTime, resource, serviceName, duration }) => {
                                    const displayName = employee?.firstName?.concat(` ${employee?.lastName || ''}`)

                                    return (
                                       <SC.AppointmentItem key={id}>
                                          <div data-appointment-item-date>
                                             {timeFormat && formatTime(startTime, timeFormat)}
                                          </div>
                                          <div data-appointment-item-info>
                                             <div>
                                                {serviceName} <Status status={data?.status} />
                                             </div>
                                             <div>
                                                {formatDuration(duration)} {displayName ? ` with ${displayName},` : ''}
                                                {resource?.name}
                                             </div>
                                          </div>
                                          <div data-appointment-item-amount>
                                             <IntlMoney value={price} />
                                          </div>
                                       </SC.AppointmentItem>
                                    )
                                 },
                              )}
                              <SC.AppointmentItem summary>
                                 <div data-appointment-item-date />
                                 <div data-appointment-item-info>
                                    <div />
                                    <div>{formatDuration(sumBy(data?.bookings, 'duration'))}</div>
                                 </div>
                                 <div data-appointment-item-amount>
                                    <IntlMoney
                                       value={data?.bookings?.reduce(
                                          (acc, booking) => acc + parseFloat(booking.price),
                                          0,
                                       )}
                                    />
                                 </div>
                              </SC.AppointmentItem>
                              {data?.notes && (
                                 <SC.History>
                                    <div>
                                       <IntlLabel label="appointment.notes.title" />
                                    </div>
                                    <div>{data?.notes}</div>
                                 </SC.History>
                              )}
                              <br />
                              {data?.logs && (
                                 <SC.History>
                                    <div>
                                       <IntlLabel label="appointment.history.title" />
                                    </div>
                                    <div>
                                       {data?.logs?.map(log => (
                                          <HistoryLog
                                             getLogLabel={getLogLabel}
                                             log={log}
                                             key={log.id}
                                             dateFormat={dateFormat}
                                          />
                                       ))}
                                       {data?.notificationLogs?.map(log => (
                                          <NotificationLog
                                             getLogLabel={getLogLabel}
                                             log={log}
                                             key={log.id}
                                             dateFormat={dateFormat}
                                          />
                                       ))}
                                    </div>
                                 </SC.History>
                              )}
                           </SC.Body>
                        </Row>
                     </Col>
                     <SC.ClientMenu>
                        <SC.ClientHeader>
                           <div data-client-info-header>
                              <ClientItem client={data?.client} redirectOnClick />
                           </div>
                           <TextLong text={data?.client?.notes} />
                        </SC.ClientHeader>
                        <SC.StatusTitle>
                           <IntlLabel label="appointment.status.btn" />:
                        </SC.StatusTitle>
                        <SC.StatusButton>
                           <StatusBtn status={data?.status} id={params.id ?? ''} />
                        </SC.StatusButton>
                        {data?.paymentType === 'stripe_payment' && (
                           <SC.PaymentMethodSection>
                              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                 <CustomIcon type="paidByCard" />
                                 <Text size="base" weight="medium" colorType="base" ml="xsmall" mb="none">
                                    <IntlLabel label="paid.by.card" />
                                 </Text>
                              </div>
                           </SC.PaymentMethodSection>
                        )}
                     </SC.ClientMenu>
                  </Row>
               </Spin>
            </Card>
            <ModifyLayout.Footer info={<AppointmentFooterTotal value={data} style={{ display: 'block' }} />}>
               <AuthBlock section={PermissionType.CAN_BOOK_APPOINTMENTS}>
                  <SC.ClientFooter>
                     <CancelBtn openAppointmentCancel={openAppointmentCancel} />
                     <RescheduleBtn rescheduleItem={rescheduleItem} />
                     <EditBtn editItem={editItem} />
                  </SC.ClientFooter>
                  <SC.MobileFooter>
                     <SC.ButtonContainer>
                        <RescheduleBtn rescheduleItem={rescheduleItem} />
                        <EditBtn editItem={editItem} />
                     </SC.ButtonContainer>
                     <CancelBtn openAppointmentCancel={openAppointmentCancel} />
                  </SC.MobileFooter>
               </AuthBlock>
            </ModifyLayout.Footer>
         </ModifyLayout>
      </NoInternetConnection>
   )
}
