import { InitialStateType } from '@plandok/core/src/hooks/context/appStore'

export const onSupportOpen = (context: InitialStateType) => {
   ;(window as any)?.$chatwoot.setUser(context.userId, {
      name: `${context.firstName} - ${context.company}`,
      email: context.email,
      phone_number: context?.phoneNumber ?? '--',
      description: `Permission: ${context.access.permissionLevel} (Plan: ${context.subscription.type}, staffs: ${context.subscription.employeeCount})`,
      country_code: context.country,
      company_name: context.company,
      avatar_url: context.photo ? `https://assets.plandok.com/staff-photos/${context.photo}` : undefined,
   })
   ;(window as any)?.$chatwoot.setLocale(context.languageIsoCode)
   ;(window as any)?.$chatwoot.setCustomAttributes({
      timezone: context.timeZone,
      currencyisocode: context.currencyIsoCode,
      subscription: context.subscription.type,
      permissionlevel: context.access.permissionLevel,
      marketlink: `https://book.plandok.com/partner/${context.slug}`,
   })
   ;(window as any)?.$chatwoot.toggle()
}
