import styled, { css } from 'styled-components'

import { mediaSmDown } from '../../../../helpers/styles'

type DropdownContainerProps = {
   isGroups?: boolean
}

type SelectContainerProps = {
   isWorkingTime?: boolean
}

export const DropdownContainer = styled.div<DropdownContainerProps>`
   // div.ant-select-dropdown-menu-item-group-title {
   //    margin: 0;
   // }
   //
   // .ant-select-dropdown-menu-item {
   //    padding: ${({ isGroups }) => (isGroups ? '0 12px 0 0' : '5px 12px')};
   // }
   //
   // li.ant-select-dropdown-menu-item:not(.ant-select-dropdown-menu-item-disabled) {
   //    border-radius: 0;
   // }

   ${mediaSmDown(css`
      padding: ${(props: any) => (props.isGroups ? '50px 5px  5px 0' : 'inherit')};
      position: ${(props: any) => (props.isGroups ? 'fixed' : 'inherit')};
      background: ${(props: any) => (props.isGroups ? '#fff' : 'inherit')};
      top: ${(props: any) => (props.isGroups ? '0' : 'inherit')};
      left: ${(props: any) => (props.isGroups ? '0' : 'inherit')};
      right: ${(props: any) => (props.isGroups ? '0' : 'inherit')};
      bottom: ${(props: any) => (props.isGroups ? '0' : 'inherit')};

      // .ant-select-dropdown-menu {
      //    height: ${(props: any) => (props.isGroups ? 'calc(100vh - 11rem)' : 'inherit')};
      //    margin-bottom: ${(props: any) => (props.isGroups ? '1.5rem' : 'inherit')};
      //    max-height: inherit;
      // }
      // .ant-select-dropdown-menu-item-group-title {
      //    font-size: 20px;
      //    border-bottom: none;
      // }
      // .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
      //    padding-left: ${(props: any) => (props.isGroups ? '0' : 'inherit')};
      // }
      // li.ant-select-dropdown-menu-item:not(.ant-select-dropdown-menu-item-disabled) {
      //    padding: ${(props: any) => (props.isGroups ? '0' : '5px 12px')};
      // }
      //
      // li.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active {
      //    background-color: #fff;
      // }
   `)}
`

export const CloseIcon = styled.div`
   font-size: 20px;
   color: #adb6cc;
   position: absolute;
   right: 20px;
   top: 16px;
   cursor: pointer;
`

export const SearchDiv = styled.div`
   margin-block: 10px;
   margin-left: 16px;

   .ant-input-group-wrapper {
      .ant-input-wrapper {
         height: 35px !important;
      }
   }
   .ant-input {
      border: 1px solid whitesmoke;
      background-color: whitesmoke;
      height: 35px;
   }
   .ant-input-group-addon:last-child {
      button {
         height: 35px;
      }
   }

   ${mediaSmDown(css`
      .ant-input {
         border-bottom: 1px solid #adb6cc;
         background-color: white;
         &:hover {
            border-bottom: 1px solid #6de1f7 !important;
         }
      }
   `)}
`

export const AddBtnItem = styled.div`
   color: #14316d;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   padding: 10px 0;
   cursor: pointer;
   &:hover {
      background-color: #f2f2f2;
   }
   svg {
      margin-right: 10px;
   }
`

export const SelectContainer = styled.div<SelectContainerProps>`
   display: ${({ isWorkingTime }: SelectContainerProps) => (isWorkingTime ? 'flex' : 'inherit')};
   flex-direction: ${({ isWorkingTime }: SelectContainerProps) => (isWorkingTime ? 'column' : 'inherit')};
`
