import { Button, Field, Form, useModal, withModal } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Col, Row, Spin } from 'antd'
import * as api from 'api'
import React, { useState } from 'react'

import { CancellationReason } from '../../../../constants/data'
import * as SC from '../styles'

type ConfirmUnsubscribeModalProps = {
   modalProps: {
      onSuccess: () => void
   }
   onClose: () => void
   hideAllModals: () => void
}

function ConfirmUnsubscribeModal({ modalProps, onClose, hideAllModals }: ConfirmUnsubscribeModalProps) {
   const [, { showModal }] = useModal()
   const [loading, setLoading] = useState(false)

   const unsubscribe = async (values: any) => {
      setLoading(true)
      const cancelSubscription = await api.cancelPlan(values)
      setLoading(false)

      if (cancelSubscription.errors) {
         onClose()
         showModal({ type: 'CANCEL_SUBSCRIPTION_ERROR' })
      } else {
         modalProps?.onSuccess()
         hideAllModals()
      }
   }

   return (
      <Spin spinning={loading}>
         <SC.UnsubscribeContainer>
            <div className="description-container">
               <h2>
                  <IntlLabel label="modal.confirmCancel.subscription" />
               </h2>
               <span className="subscription-description">
                  <IntlLabel label="modal.confirmCancel.subscription.description" />
               </span>
            </div>
            <div className="form-container">
               <Form onSubmit={unsubscribe}>
                  {({ formError, submitting }: any) => (
                     <Row>
                        <Form.Alert text={formError} />
                        <Field.Select
                           name="cancellationType"
                           label="input.cancellation.type.label"
                           options={Object.values(CancellationReason).map(item => ({
                              code: item,
                              name: `dropdown.${item}.description`,
                           }))}
                           translate
                        />
                        <Field.TextArea
                           name="cancellationDescription"
                           label="input.cancellation.description.label"
                           placeholder="input.cancellation.description.placeholder"
                           rows="5"
                        />
                        <Col md={24} xs={24}>
                           <Button
                              loading={submitting}
                              label="btn.cancelSubscription.confirm"
                              danger
                              htmlType="submit"
                              style={{ width: '100%' }}
                              data-confirm-unsubscribe
                           />
                        </Col>
                     </Row>
                  )}
               </Form>
            </div>
         </SC.UnsubscribeContainer>
      </Spin>
   )
}

export default withModal(null)(ConfirmUnsubscribeModal)
