import { Button, CustomIcon, Text } from '@plandok/core'
import { useGoogleLogin } from '@react-oauth/google'
import { message } from 'antd'
import * as api from 'api'
import { LoginFormProps } from 'pages/auth/LoginPage/components/LoginForm'
import React, { useState } from 'react'

function GoogleLoginButton(props: LoginFormProps) {
   let [loading, setLoading] = useState(false)

   const googleSignIn = useGoogleLogin({
      onSuccess: async (codeResponse: any) => {
         setLoading(true)
         let response = await api.socialAuthLogin('google')({
            accessToken: codeResponse.access_token,
         })
         setLoading(false)

         if (response?.token) {
            props.onSuccess(response)
            return
         }

         message.error(response?.errors?.message)
      },
      onError: () => message.error('Login failed'),
      flow: 'implicit',
   })

   return (
      <Button
         whiteBtn
         className="justify-align-center w-100 mt-4"
         upperCase={false}
         onClick={() => googleSignIn()}
         loading={loading}>
         <CustomIcon type="googleLogin" />
         <Text
            label="btn.continueWithGoogle.title"
            mb="none"
            colorType="black"
            size="base"
            weight="medium"
            ml="xsmall"
         />
      </Button>
   )
}

export default GoogleLoginButton
