import * as Sentry from '@sentry/browser'
import { Button, Result } from 'antd'
import React, { Component } from 'react'

class ErrorBoundary extends Component<any, any> {
   state: any = { eventId: null }

   static getDerivedStateFromError() {
      return { hasError: true }
   }

   componentDidCatch(error: any, errorInfo: any) {
      Sentry.withScope(scope => {
         scope.setFingerprint([window.location.pathname])
         scope.setExtras(errorInfo)
         const eventId = Sentry.captureException(error)
         this.setState({ eventId })
      })
   }

   render() {
      let env = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      if (this.state.hasError && !env) {
         return (
            <div
               style={{
                  width: '100%',
                  height: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
               }}>
               <div style={{ position: 'absolute' }}>
                  <Button
                     key="console"
                     style={{ opacity: 0 }}
                     onClick={() => {
                        Sentry.showReportDialog({ eventId: this.state.eventId })
                     }}>
                     Report Dialog
                  </Button>
               </div>
               <Result
                  status="warning"
                  title="There are some problems with your operation. We already investigating it."
                  extra={
                     <>
                        <Button
                           type="primary"
                           onClick={() => {
                              window.location.replace('/logout')
                           }}>
                           {' Back Home '}
                        </Button>
                     </>
                  }
               />
            </div>
         )
      }

      const { children } = this.props
      return children ?? null
   }
}

export default ErrorBoundary
