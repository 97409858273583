import { DndContext } from '@dnd-kit/core'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ButtonAddAnother, Placeholder } from 'components'
import React from 'react'

import DragHandle from './components/DragHandle'
import ServiceControl from './components/ServiceControl'
import ServiceItem from './components/ServiceItem'
import * as SC from './style'

function ServiceGroup({
   editCategory,
   editService,
   fetchServiceGroups,
   createService,
   handleServiceSortEnd,
   ...props
}: any) {
   const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: props.id,
   })

   const style = {
      transform: CSS.Transform.toString(transform),
      transition,
   }

   const onCreate = () => createService(props.id)

   return (
      <SC.Container
         {...props}
         data-service-group-list={props.name}
         data-row-key={props.id}
         ref={setNodeRef}
         style={style}>
         <DragHandle listeners={listeners} attributes={attributes} />
         <SC.Item>
            <ServiceControl editCategory={editCategory} id={props.id} fetchServiceGroups={fetchServiceGroups} />
            <SC.Prefix color={props.color} />
            <SC.Title>{props.name}</SC.Title>

            <DndContext
               onDragEnd={event => {
                  const { active, over } = event

                  if (active.id !== over?.id) {
                     const oldIndex = (props.services || []).findIndex((item: any) => item.id === active.id)
                     const newIndex = (props.services || []).findIndex((item: any) => item.id === over?.id)
                     return handleServiceSortEnd({ oldIndex, newIndex })
                  }
               }}>
               <SortableContext items={props.services.map((s: any) => s.id)} strategy={verticalListSortingStrategy}>
                  {(props.services || []).map((e: any, i: number) => (
                     <ServiceItem
                        key={`service-item-${e.id}`}
                        item={e}
                        index={i}
                        groupId={props.id}
                        editService={editService}
                     />
                  ))}
               </SortableContext>
            </DndContext>

            {(props.services || []).length === 0 && (
               <Placeholder
                  imgName="serviceGroup"
                  descriptionLabel="state.serviceGroup.description"
                  btnLabel="state.serviceGroup.btn"
                  onClick={onCreate}
               />
            )}
            {(props.services || []).length > 0 && <ButtonAddAnother onClick={onCreate} label="service.new.title" />}
         </SC.Item>
      </SC.Container>
   )
}

export default ServiceGroup
