import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import React from 'react'

import TableRow from './TableRow'

function TableBody(props: any) {
   const sensors = useSensors(useSensor(PointerSensor))

   const onDragEnd = (event: any) => {
      const { active, over } = event

      if (active.id !== over?.id) {
         const oldIndex = props.data.findIndex((item: any) => item.id === active.id)
         const newIndex = props.data.findIndex((item: any) => item.id === over.id)

         return props.onSortEnd({ oldIndex, newIndex })
      }
   }

   return (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
         <SortableContext items={props.data?.map((item: any) => item.id)} strategy={verticalListSortingStrategy}>
            <tbody className="ant-table-tbody">
               {props.data?.map((value: any, i: number) => {
                  const onItemClick = () => (props.onItemClick || console.info)(value.id)
                  return (
                     <TableRow
                        index={i}
                        value={value}
                        onClick={onItemClick}
                        columns={props.columns}
                        key={`${value.id}-${i}`}
                     />
                  )
               })}
            </tbody>
         </SortableContext>
      </DndContext>
   )
}

export default TableBody
