import { Button, Field, mediaMdDown, mediaSmDown, SelectInput, Text } from '@plandok/core'
import { Checkbox, Table } from 'antd'
import styled, { css } from 'styled-components'

type PropsColor = {
   background: boolean
}

type FlexProps = {
   direction?: 'row' | 'column'
   alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch'
   justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around'
   padding?: string
   margin?: string
}

type LinkContainerProps = {
   spaceBellow?: boolean
}

type CardImageProps = {
   imgUrl: string
}

export const ModalFooter = styled.div`
   display: flex;
   flex-direction: row;
   margin-top: 20px;
   justify-content: center;

   button {
      width: 100%;

      &[data-shift-time-minor-btn] {
         max-width: 120px;
         margin-right: auto;
      }
      &[data-shift-time-btn] {
         max-width: 310px;
      }
      max-width: 200px;
      &:not(:first-of-type) {
         margin-left: 20px;
      }
   }
`

export const ModalFooterResponsive = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-top: 20px;

   button {
      width: 100%;
      &:not(:first-of-type) {
         margin-top: 20px;
      }
   }

   @media screen and (min-width: 768px) {
      flex-direction: row;

      button {
         width: 100%;
         max-width: 200px;

         &[data-shift-time-minor-btn] {
            max-width: 120px;
            margin-right: auto;
         }
         &[data-shift-time-btn] {
            max-width: 310px;
         }
         &:not(:first-of-type) {
            margin-top: 0;
            margin-left: 20px;
         }
      }
   }
`

export const DeleteClientContainer = styled.div`
   display: flex;
   flex-direction: column;
   text-align: center;
   div {
      color: ${({ theme }) => theme.primaryColor};
   }
   i[data-delete-icon] {
      font-size: 60px;
      color: #adb6cc;
      margin-bottom: 10px;
   }
`

export const UnsubscribeContainer = styled.div`
   display: flex;
   flex-direction: column;

   .description-container {
      h2,
      span {
         text-align: center !important;
         color: ${({ theme }) => theme.primaryColor};
      }
      h2 {
         font-size: 20px;
      }
      span {
         font-size: 16px;
      }
   }

   button[data-confirm-unsubscribe] {
      height: 50px;
      white-space: normal;
   }

   .form-container {
      margin: 5% 0 0 0;
   }
`

export const AddItem = styled.span`
   cursor: pointer;
   color: #adb6cc;
   i {
      color: ${({ theme }) => theme.infoColor};
   }
`

export const RemoveRow = styled.div`
   position: absolute;
   right: 10px;
   top: 33px;
   background: white;
   width: 20px;
   text-align: center;
   cursor: pointer;
`

export const BreakText = styled.div`
   text-align: center;
   color: #adb6cc;
   margin-bottom: 25px;
`

export const TitleDescription = styled.div`
   font-size: 16px;
   color: #adb6cc;
   margin-top: 8px;
`

export const DeleteShiftBtnBlock = styled.div`
   display: flex;
   flex-direction: row;
   button {
      &:not(:first-of-type) {
         width: 250px;
      }

      &:first-of-type {
         width: 100px;
         margin-right: auto;
      }

      &:last-of-type {
         margin-left: 20px;
      }
   }
`

export const MenuItem = styled.div<any>`
   padding: 15px 25px;
   border-bottom: 1px solid #e9ecf3;
   color: ${({ theme }) => theme.primaryColor};
   cursor: pointer;
   display: flex;
   align-items: center;
   div {
      font-size: 14px;
   }
   div:first-of-type {
      color: #adb6cc;
      margin-right: 15px;
   }

   :first-of-type {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
   }
   transition: background-color 0.3s;

   &:hover {
      background-color: #e9ecf3;
   }

   ${({ isRemove }) =>
      isRemove &&
      css`
         color: #ff5e5e;
         i {
            color: #ff5e5e;
         }
      `}
`

export const ClientDetailsMenuContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin: -15px 0;
`

export const BookingLinksContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin: -15px 0;
   align-items: center;

   h2,
   h3,
   span {
      color: ${({ theme }) => theme.primaryColor};
   }
   h2 {
      font-size: 1.5rem;
      font-weight: bold;

      ${mediaSmDown(css`
         font-size: 1rem;
      `)};
   }
   h3 {
      font-size: 0.875rem;
      font-weight: bold;
      margin-top: 80px;
      text-align: center;

      ${mediaSmDown(css`
         margin-top: 16px;
      `)};
   }
   h1 {
      color: #4bb543;
      font-size: 1rem;
   }
   .after-description {
      font-size: 14px;
      margin: 70px 0 80px;
      text-align: center;

      ${mediaSmDown(css`
         margin: 30px 0 20px;
      `)};
   }

   .link-box {
      color: ${({ theme }) => theme.primaryColor};
      margin-top: 16px;
      border: 1px dashed #13316d;
      padding: 16px 64px;
      border-radius: 3px;
      background: #e4e4e440;

      ${mediaSmDown(css`
         max-width: 288px;
         padding: 16px 8px;
      `)};
   }
   .link-steps {
      color: ${({ theme }) => theme.primaryColor};
      margin: 48px 0;

      ${mediaSmDown(css`
         max-width: 288px;
         padding: 16px 8px;
      `)}
   }

   ${mediaSmDown(css`
      margin: 25px 15px;
   `)};
`

export const ClientDetailsFooter = styled.div`
   margin: 20px;
`

export const AddReason = styled.div`
   font-weight: 600;
   color: #14316d;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   padding: 10px 0;
   margin-left: 10px;
   cursor: pointer;
   i {
      margin-right: 10px;
   }
   ${mediaSmDown(css`
      i {
         margin-right: 5px;
      }
   `)};
`

export const StyledContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 0 100px;
   button {
      min-width: 180px;
   }
   ${mediaSmDown(css`
      padding: 0 60px;
      .ant-col-24 {
         width: 50%;
      }
   `)};
`

export const StyledAgeContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   button {
      min-width: 180px;
   }
`

export const Container = styled.div<PropsColor>`
   background-color: ${({ background }) => `${background ? '#F0F3F9' : '#fff'}`};
   display: flex;
   flex-direction: row;
   align-items: center;
   width: 100%;
   padding: 15px 40px;
   color: #13316d;
   .ant-col-24 {
      width: 100px;
   }
   span {
      color: #13316d;
   }
   .ant-radio-inner {
      width: 20px;
      height: 20px;
      background-color: #fff;
      margin-right: 5px;
      &::after {
         top: 4px;
         left: 4px;
         width: 10px;
         height: 10px;
         ${mediaSmDown(css`
            top: 4px;
         `)};
      }
   }
   .ant-radio-checked {
      &::after {
         animation: none !important;
         border: none !important;
      }
   }
   &:nth-child(3) {
      > div:last-child {
         ${mediaSmDown(css`
            margin-left: 20px;
            color: red !important;
         `)};
      }
   }
   ${mediaSmDown(css`
      padding-left: 18px;
      flex-wrap: wrap;
   `)};
`

export const DateContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   .ant-form-item-label {
      display: none;
   }
   .ant-form-explain {
      display: none;
   }
`

export const Divider = styled.div`
   margin-bottom: 20px;
   width: 90%;
   min-width: 90%;
`

export const ToContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   ${mediaSmDown(css`
      font-size: 16px;
   `)};
`

export const ClientSelectionContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding: 0 40px 0 40px;
   .ant-checkbox-inner {
      background: #fff;
      border-color: #13316d;
   }
   ${mediaSmDown(css`
      padding: 0 10px 0 10px;
   `)};
`

export const ClientSelectionAll = styled.div`
   border-bottom: 1px solid #dfe6f1;
   padding: 20px 0;
   .ant-checkbox-indeterminate .ant-checkbox-inner {
      &::after {
         width: 14px;
         height: 14px;
      }
   }
   .ant-checkbox-checked {
      &::after {
         animation: none !important;
         border: none !important;
      }
   }
`

export const ClientSelectionCheckList = styled.div`
   border-bottom: 1px solid #dfe6f1;
   padding: 10px 0;
   margin-left: 20px;
   .ant-checkbox-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
   }
   .ant-checkbox-checked {
      &::after {
         animation: none !important;
         border: none !important;
      }
   }
`

export const ServiceSelectionHeader = styled.div`
   ${mediaSmDown(css`
      padding: 0 10px;
   `)};
`

export const ServiceSelectionContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding: 0 40px 0 40px;
   .ant-checkbox-inner {
      background: #fff;
      border-color: #13316d;
   }
   ${mediaSmDown(css`
      padding: 0;
   `)};
`

export const ServiceSelectionAll = styled.div`
   font-weight: 500;
   color: #14316d !important;
   border-bottom: 1px solid #dfe6f1;
   padding: 20px 0;
   .ant-checkbox-indeterminate .ant-checkbox-inner {
      &::after {
         width: 14px;
         height: 14px;
      }
   }
   .ant-checkbox-wrapper {
      color: #14316d !important;
   }
   .ant-checkbox-checked {
      &::after {
         animation: none !important;
         border: none !important;
      }
   }
   ${mediaSmDown(css`
      margin-left: 15px;
   `)};
`

export const ServiceSelectionListWrapper = styled.div`
   overflow-y: auto;
   max-height: 53vh;

   &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      margin-top: 16px;
      margin-bottom: 16px;
   }
   &::-webkit-scrollbar-track-piece {
      background-color: #e9ecf3;
   }
   &::-webkit-scrollbar-thumb {
      background: #adb6cc;
      border-radius: 10px;
   }
   ${mediaSmDown(css`
      max-height: 60vh;
      padding: 0 0 20px;
   `)};
`

export const ServiceSelectionCheckListGroup = styled.div`
   font-weight: 500;
   border-bottom: 1px solid #dfe6f1;
   padding: 25px 0;
   margin: 0 20px;
   .ant-checkbox-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #14316d !important;
   }
   .ant-checkbox-indeterminate .ant-checkbox-inner {
      &::after {
         width: 14px;
         height: 14px;
      }
   }
   .ant-checkbox-checked {
      &::after {
         animation: none !important;
         border: none !important;
      }
   }
`

export const ServiceSelectionCheckList = styled.div`
   border-bottom: 1px solid #dfe6f1;
   padding: 10px 0 10px 20px;
   margin: 0 20px;
   .ant-checkbox-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
   }
   .ant-checkbox + span {
      width: 100%;
   }
   .ant-checkbox-checked {
      &::after {
         animation: none !important;
         border: none !important;
      }
   }
`

export const ServiceSelectionItem = styled.div`
   font-weight: 500;
   color: #14316d;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   padding-left: 10px;
`

export const ServiceSelectionName = styled.div`
   margin-left: 10px;
`

export const ServiceSelectionBtn = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-top: 25px;
   button {
      min-width: 130px;
      ${mediaSmDown(css`
         border: none;
         background-color: #fff;
         color: #14316d;
         border-radius: 0;
         min-width: 80px;
         box-shadow: none;
         &:hover .active {
            background: #fff;
            color: #14316d;
         }
         &:focus {
            background: #fff;
            color: #14316d;
         }
      `)};
   }
`

export const ClientSelectionItem = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   padding-left: 10px;
`

export const ClientSelectionName = styled.div`
   margin-left: 10px;
`

export const SearchClient = styled.div`
   position: relative;
   input {
      border-bottom: none;
      font-size: 18px;
      background: #f4f5f9;
      border-radius: 20px;
      padding: 5px 15px;
   }
   div {
      > div {
         height: 0;
      }
   }
   i {
      position: absolute;
      right: 10px;
      top: 10px;
   }
`

export const ClientSelectionBtn = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-top: 25px;
   button {
      min-width: 130px;
      ${mediaSmDown(css`
         border: none;
         background-color: #fff;
         color: #14316d;
         border-radius: 0;
         min-width: 80px;
         box-shadow: none;
         &:hover .active {
            background: #fff;
            color: #14316d;
         }
         &:focus {
            background: #fff;
            color: #14316d;
         }
      `)};
   }
`

export const ClientSelectionFooterBtn = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   margin-top: 20px;
   padding: 20px 0;
   background: #ffffff;
   box-shadow: 0 2px 10px rgba(38, 60, 122, 0.2);
   border-radius: 0 0 20px 20px;
   button {
      min-width: 180px;
   }
   ${mediaSmDown(css`
      position: absolute;
      bottom: 0;
      box-shadow: none;
      border-radius: 0;
      margin-top: 0;
   `)};
`

export const PaymentContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 30px;
   button {
      min-width: 180px;
   }
`

export const TextContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-bottom: 40px;
   ${mediaSmDown(css`
      flex-direction: column;
      margin-bottom: 20px;
   `)};
`

export const CampaignModals = styled.div`
   text-align: center;
   div:first-child {
      padding: 20px 95px;
      ${mediaSmDown(css`
         padding: 10px;
      `)};
   }
`

export const PricingModal = styled.div`
   display: flex;
   flex-direction: row;

   svg {
      fill: #13316d;
   }
`

export const PricingModalContainer = styled.div`
   padding-left: 22px;

   ${mediaMdDown(css`
      padding-left: 13px;
   `)};
`

export const PricingModalText = styled.div`
   border-top: 1px solid #e9ecf3;
   border-bottom: 1px solid #e9ecf3;
   padding: 14px 0px 16px;
   div {
      font-size: 16px;
      a {
         color: #14316d;
         font-weight: 500;
         text-decoration-color: #14316d;
      }
   }
   div:not(:last-child) {
      margin-bottom: 14px;
   }

   ${mediaMdDown(css`
      div {
         font-size: 14px;
      }
      div:nth-child(1) {
         margin-bottom: 10px;
      }
   `)};
`

export const PricingModalFooter = styled.div`
   padding-top: 20px;
   div.ant-select-selection-selected-value {
      color: #13316d;
   }
   div.ant-form-explain {
      display: none;
   }

   ${mediaMdDown(css`
      padding-top: 15px;
   `)};
`

export const WrapperInfoFooter = styled.div`
   display: flex;
   flex-direction: row;
   div.ant-form-item-label {
      padding-bottom: 5px;
   }

   ${mediaMdDown(css`
      flex-direction: column;
      div.ant-form-item-control-wrapper {
         margin-bottom: 19px;
      }
   `)};
`

export const ContainerInfoFooter = styled.div`
   margin-left: 60px;
   min-width: 153px;

   ${mediaMdDown(css`
      margin-left: 0px;
   `)};
`

export const PricingText = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-top: 5px;

   ${mediaMdDown(css`
      justify-content: start;
      div:first-child {
         margin-right: 5px;
      }
   `)};
`

export const CardImage = styled.div<CardImageProps>`
   background: url('${({ imgUrl }) => imgUrl}');
   background-repeat: no-repeat;
   height: 554px;
   background-size: 100% 100%;
   margin-bottom: 30px;
   & .ant-form-explain {
      display: none;
   }
   ${mediaSmDown(css`
      margin: 0 auto 20px auto;
   `)}
`

export const ActionContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   button {
      min-width: 250px;
   }
`

export const MessagePreviewContainer = styled.div`
   border: 1px solid #adb6cc;
   box-sizing: border-box;
   border-radius: 10px;
   width: 100%;
   min-height: 504px;
   padding: 70px 70px 0;
   margin-bottom: 30px;
   display: flex;
   flex-direction: column;
   align-items: center;

   svg:first-child {
      margin-bottom: 40px;
   }
   .ant-form-explain,
   .ant-form-item-label {
      display: none;
   }
   ${mediaSmDown(css`
      min-height: 380px;
      padding: 50px 10px 30px 10px;
   `)}
`

export const CampaignActivationContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 30px;
   button {
      min-width: 180px;
   }
`

export const UpgradeBlock = styled.div`
   background: #f5f5f5;
   border-radius: 5px;
   padding: 10px 20px;
   margin-bottom: 27px;
   display: grid;
   grid-template-columns: 48px 1fr;
   align-items: center;
`

export const RepeatContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding: 20px 16px 16px 20px;

   button {
      width: 45%;
   }
`

export const FieldRow = styled(Field.Row)`
   display: flex;
   justify-content: space-between;
   width: inherit;
`

export const UpdateContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding: 20px 32px 16px;

   button {
      width: 45%;
   }
`

export const WrappedText = styled(Text)`
   word-break: break-word;

   span {
      text-decoration: underline;
      &:hover {
         cursor: pointer;
      }
   }
`

export const CampaignUpgradeContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   margin-top: 30px;
   button {
      min-width: 180px;
      :last-child {
         margin-top: 20px;
         border-color: white;
         border: none;
         background-color: transparent;
         color: #14316d;
      }
   }
`

export const Select = styled(SelectInput)`
   margin-bottom: 20px;
`

export const ImportClientsModalFooter = styled.div`
   display: flex;
   justify-content: center;
   margin-top: 20px;
`

export const PremiumModalContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;

   svg {
      margin-top: 15px;
      height: 35px;
      width: 43px;
   }

   button {
      width: 184px;
      border: none;
   }
`

export const PremiumModalFooter = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`

export const ClientMergeButtonsContainer = styled.div`
   margin: 0 27px 30px;
   display: flex;
   justify-content: space-between;

   ${mediaSmDown(css`
      margin: 0 16px 30px;
   `)}
`

export const StyledCheckboxWrapper = styled.div`
   margin: 0 27px 22px;

   ${mediaSmDown(css`
      margin: 0 16px 16px;
   `)}
`

export const StyledCheckbox = styled(Checkbox)`
   text-align: start;
   display: flex;
   align-items: center;
   margin-bottom: 20px;

   span.ant-checkbox-inner {
      background: #ffffff;
      border: 2px solid #13316d;
      border-radius: 3px;
      width: 24px;
      height: 24px;

      &::after {
         left: 17%;
         top: 48%;
         width: 10px;
         height: 15px;
      }
   }

   span.ant-checkbox.ant-checkbox-indeterminate {
      span.ant-checkbox-inner {
         &::after {
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
         }
      }
   }

   .ant-checkbox + span {
      padding: 0;
      margin-left: 29px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #13316d;

      ${mediaSmDown(css`
         margin-left: 19px;
         font-size: 16px;
         line-height: 19px;
      `)}
   }
`

export const StyledCheckboxConfirm = styled(Checkbox)`
   margin-top: 24px;
   margin-bottom: 40px;

   span.ant-checkbox-inner {
      background: #ffffff;
      border: 2px solid #13316d;
      border-radius: 3px;
      width: 24px;
      height: 24px;

      &::after {
         left: 17%;
         top: 48%;
         width: 10px;
         height: 15px;
      }
   }

   .ant-checkbox-checked::after {
      border: none;
   }

   .ant-checkbox + span {
      padding: 0;
      margin-left: 18px;
      font-size: 18px;
      color: #13316d;
   }

   ${mediaSmDown(css`
      margin-bottom: 22px;
      display: flex;
   `)}
`

export const ModalFooterDuplicatedClients = styled.div`
   padding: 30px 0;
   text-align: center;
   box-shadow: 0 2px 10px rgba(38, 60, 122, 0.2);

   button {
      max-height: 36px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      padding: 15px 46px;
   }

   ${mediaSmDown(css`
      padding: 21px 0;

      button {
         max-height: 48px;
         font-size: 16px;
         line-height: 19px;
         padding: 8px 41px;
      }
   `)}
`

export const ModalFooterConfirmDuplicatesClients = styled.div`
   text-align: center;

   button {
      max-height: 36px;
      padding: 15px 36px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      &:nth-child(1) {
         border: 1px solid #adb6cc;
         color: #13316d;
         background: #ffffff !important;
         margin-right: 16px;
      }
   }

   ${mediaSmDown(css`
      button {
         max-height: 40px;
         padding: 12px 16px;
         font-size: 14px;
         line-height: 16px;
         width: 47%;
      }
   `)}
`

export const DuplicatedClientsCardWrapper = styled.div`
   margin: 0 27px;
   max-height: 670px;
   overflow: auto;

   &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      margin-top: 16px;
      margin-bottom: 16px;
   }
   &::-webkit-scrollbar-track-piece {
      background-color: #e9ecf3;
   }
   &::-webkit-scrollbar-thumb {
      background: #adb6cc;
      border-radius: 10px;
   }

   & > div {
      border: 1px solid #dfe6f1;
      border-radius: 10px;
      padding: 6px 30px 10px;
      display: flex;
      align-items: center;
      margin-right: 20px;

      & > div {
         margin-right: 29px;
      }
   }

   & > div:not(:last-child) {
      margin-bottom: 15px;
   }

   ${mediaSmDown(css`
      height: 533px;
      margin: 0 16px;

      & > div {
         padding: 3px 25px 1px 20px;
         margin-right: 7px;

         & > div {
            margin-right: 20px;
         }
      }

      & > div:not(:last-child) {
         margin-bottom: 10px;
      }
   `)}
`

export const DuplicatedClientsNotFoundWrapper = styled.div`
   height: 326px;
   display: flex;
   align-items: center;
   justify-content: center;

   div {
      text-align: center;

      svg {
         margin-bottom: 16px;
      }
      div:nth-child(2) {
         line-height: 28px;
      }
      div:nth-child(3) {
         line-height: 19px;
      }
   }
`

export const DuplicatedClientsContainer = styled.div`
   width: 100%;

   div {
      span.ant-avatar {
         margin-right: 10px;
      }
      div {
         color: #13316d;

         & div:nth-child(1) {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
         }

         & div:nth-child(n + 2) {
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
         }
      }
   }

   .client-container {
      display: flex;
      padding: 13px 0 15px;
      &:not(:last-child) {
         border-bottom: 1px solid #dfe6f1;
      }
   }
`

export const Flex = styled.div<FlexProps>`
   display: flex;
   flex-direction: ${({ direction }) => direction || 'row'};
   align-items: ${({ alignItems }) => alignItems || 'flex-start'};
   justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
   padding: ${({ padding }) => padding || '0'};
   margin: ${({ margin }) => margin || '0'};
`

export const ButtonContainer = styled.div`
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 16px;
   align-self: stretch;

   button {
      box-shadow: none;
   }
`

export const DeleteRMTitle = styled(Text)`
   font-size: 18px;
   line-height: 21px;
   margin-bottom: 30px;

   ${mediaMdDown(css`
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 18px;
   `)}
`

export const DeleteRMButtonsContainer = styled.div`
   button {
      max-height: 36px;
      padding: 8px 33px;
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;

      :nth-child(1) {
         border: 1px solid #adb6cc;
         color: #13316d;
         background: #ffffff !important;
         margin-right: 21px;
      }
   }
`

export const AnalyticsImg = styled.img`
   width: 460px;
   height: 340px;
`

export const BookingAnalyticsInfo = styled.div`
   width: 472px;
   line-height: 26px;

   .ant-form-item-label {
      font-weight: 600;
      line-height: inherit;
      margin: 5px 0;
      font-size: 14px;
   }

   button {
      margin-bottom: 24px;
      background: #136ef6;
      border: none;
   }

   a {
      text-decoration: none;
      margin-left: 5px;
      div {
         color: #136ef6;
      }
   }
`

export const ContainerSendTestMessage = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;

   .ant-form-item-label {
      display: none;
   }

   button {
      padding: 9px 56px;
      font-weight: 500;
      max-height: 36px;
      line-height: 19px;
   }

   ${mediaMdDown(css`
      div.ant-form-item-control-wrapper {
         max-width: 284px;
         margin: 0 auto;
      }
      button {
         padding: 7px 56px;
         font-weight: 600;
         line-height: 20px;
      }
   `)}
`

export const InfoApplySendTestMessage = styled.div`
   display: flex;
   margin-bottom: 18px;
   align-items: center;

   svg {
      fill: #13316d;
      margin-right: 11px;
   }
`

export const DependingDescription = styled(Text)`
   font-size: 18px;
   line-height: 25px;
   max-width: 421px;
   margin: 0 auto 16px;
   ${mediaMdDown(css`
      font-size: 16px;
      margin-bottom: 10px;
   `)};
`

export const PriceCalculatedDescription = styled(Text)`
   font-size: 18px;
   line-height: 25px;
   ${mediaMdDown(css`
      font-size: 16px;
   `)};
`

export const CustomTable = styled(Table)`
   .ant-table {
      max-width: 472px;
      margin: 0 auto 32px;
   }
   .ant-table-thead > tr > th {
      background: #e9eef5;
      line-height: 25px;
      border: 1px solid #dfe6f1;
   }
   .ant-table-thead > tr > th:nth-child(1) {
      border-right: none;
      border-top-left-radius: 10px;
      padding: 7px 27px 8px 34px;
   }
   .ant-table-thead > tr > th:nth-child(2) {
      border-top-right-radius: 10px;
      padding: 7px 49px 8px 30px;
   }
   .ant-table-tbody > tr > td {
      border: 1px solid #dfe6f1;
      border-top: none;
   }
   .ant-table-tbody > tr > td:nth-child(1) {
      border-right: none;
      padding: 6px 124px 6px 34px;
   }
   .ant-table-tbody > tr > td:nth-child(2) {
      padding: 6px 68px 7px 30px;
   }
   .ant-table-tbody > tr:last-child > td:nth-child(1) {
      border-bottom-left-radius: 10px;
   }
   .ant-table-tbody > tr:last-child > td:nth-child(2) {
      border-bottom-right-radius: 10px;
   }
   ${mediaMdDown(css`
      .ant-table-thead > tr > th:nth-child(1) {
         border-top-left-radius: 5px;
         padding: 8px 17px 9px 15px;
      }
      .ant-table-thead > tr > th:nth-child(2) {
         border-top-right-radius: 5px;
         padding: 8px 59px 9px 15px;
      }
      .ant-table-tbody > tr > td:nth-child(1) {
         padding: 7px 67px 12px 15px;
      }
      .ant-table-tbody > tr > td:nth-child(2) {
         padding: 7px 21px 12px 15px;
      }
      .ant-table-tbody > tr:last-child > td:nth-child(1) {
         border-bottom-left-radius: 5px;
      }
      .ant-table-tbody > tr:last-child > td:nth-child(2) {
         border-bottom-right-radius: 5px;
      }
   `)};
`

export const PriceCalculator = styled(Text)`
   line-height: 28px;
   margin-bottom: 19px;
   ${mediaMdDown(css`
      margin-bottom: 12px;
   `)};
`

export const ContainerCounter = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 15px;
   svg {
      cursor: pointer;
   }
`

export const InputCalc = styled.input`
   font-size: 20px;
   border: 1px solid #adb6cc;
   border-radius: 5px;
   margin: 0 8px;
   width: 64px;
   height: 36px;
   text-align: center;
   padding: 0;
   color: #13316d;
`

export const WrapperPrice = styled.div`
   display: flex;
   justify-content: center;
   div:nth-child(1) {
      font-size: 34px;
      line-height: 40px;
   }
   div:nth-child(2) {
      line-height: 16px;
      margin-top: 20px;
   }
`

export const CancelSubscriptionErrorTitle = styled.div`
   display: flex;
   margin-bottom: 24px;
   align-items: center;
   justify-content: center;
   text-align: center;
   svg {
      margin-right: 10px;
   }
   div {
      line-height: 33px;
   }
   ${mediaMdDown(css`
      flex-direction: column;
      svg {
         margin-right: 0;
         margin-bottom: 9px;
         width: 36px;
         height: 36px;
      }
   `)};
`

export const LatestPaymentFailedTitle = styled.div`
   display: flex;
   margin-bottom: 24px;
   align-items: center;
   justify-content: center;
   text-align: center;
   svg {
      margin-right: 12px;
   }
   div {
      line-height: 33px;
   }
   ${mediaMdDown(css`
      flex-direction: column;
      svg {
         margin-right: 0;
         margin-bottom: 8px;
      }
   `)};
`

export const ErrorDescription = styled.div`
   display: flex;
   margin-bottom: 42px;
   text-align: center;
   div {
      line-height: 25px;
   }
   ${mediaMdDown(css`
      margin-bottom: 24px;
   `)};
`

export const Link = styled.span`
   color: #2472e8;
   cursor: pointer;
   text-decoration: underline;
   text-transform: lowercase;
`

export const BtnsContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
   max-width: 494px;
   justify-content: space-between;
   margin: 0 auto;
   ${mediaSmDown(css`
      max-width: 302px;
   `)};
`

export const BtnLink = styled(Button)`
   border: 1px solid #adb6cc !important;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
   min-width: 154px;
   box-shadow: none !important;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: fill 0.3s;
   svg {
      margin-left: 8px;
   }
   &:hover {
      svg path {
         fill: white;
      }
   }
   ${mediaMdDown(css`
      font-weight: 600;
      min-width: 142px;
      svg {
         margin-left: 7px;
      }
   `)};
`

export const BtnUpgrade = styled(Button)`
   min-width: 154px;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
   background: #2472e8;
   ${mediaSmDown(css`
      width: 100%;
      margin-top: 16px;
   `)};
`

export const ExtraCostsForNewMemberDescription = styled(Text)`
   line-height: 25px;
   max-width: 465px;
   margin: 0 auto 24px;
   ${mediaSmDown(css`
      margin-bottom: 27px;
      max-width: 280px;
   `)};
`

export const CostsItemPrice = styled(Text)`
   font-size: 20px;
   line-height: 23px;
   ${mediaSmDown(css`
      font-size: 18px;
      line-height: 21px;
   `)};
`

export const ExtraCostsForNewMemberBtnsContainer = styled.div`
   text-align: center;
   margin-top: 15px;

   button {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      min-width: 172px;
      :nth-child(1) {
         border: 1px solid #adb6cc !important;
         box-shadow: none !important;
      }
      :nth-child(2) {
         background: #349447;
         margin-left: 20px;
      }
   }

   ${mediaSmDown(css`
      button {
         font-weight: 600;
         min-width: 133px;
      }
   `)};
`

export const UpgradeRequiredTitle = styled(Text)`
   line-height: 25px;
   margin-bottom: 32px;
`

export const BtnUpgradeSubscription = styled(Button)`
   padding: 11px 53px;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
   background: #2472e8;
   ${mediaSmDown(css`
      padding: 10px 61px;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
   `)};
`

export const LiveChatContainer = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 28px;
   div:first-of-type {
      display: flex;
      align-items: center;
      span {
         margin-left: 17px;
         max-width: 324px;
         div:first-of-type {
            color: #26385b;
            line-height: 21px;
            margin-bottom: 2px;
         }
         div:last-of-type {
            color: #61749d;
            line-height: 17px;
         }
      }
   }
   :hover {
      cursor: pointer;
   }
`

export const LinkContainer = styled.a<LinkContainerProps>`
   display: flex;
   justify-content: space-between;
   align-items: center;
   div:first-of-type {
      display: flex;
      align-items: center;
      span {
         margin-left: 17px;
         max-width: 324px;
         div:first-of-type {
            color: #26385b;
            line-height: 21px;
            margin-bottom: 2px;
         }
         div:last-of-type {
            color: #61749d;
            line-height: 17px;
         }
      }
   }
   :hover {
      text-decoration: none;
   }

   ${({ spaceBellow }) =>
      spaceBellow &&
      css`
         margin-bottom: 27px;
      `}
`

export const DeleteAccountContainer = styled.div`
   text-align: center;
   div {
      :first-of-type {
         font-size: 1.8rem;
         line-height: 33px;
         margin: 24px 0 16px;
      }
      :last-of-type {
         line-height: 21px;
         margin-bottom: 24px;
      }
   }
`

export const DeleteAccountButton = styled(Button)`
   min-width: 145px;
   :first-of-type {
      border: 1px solid ${({ theme }) => theme.minorColor};
      box-shadow: none;
      margin-right: 16px;
   }
`

export const DeletePaymentMethodContainer = styled.div`
   text-align: center;
   button {
      min-width: 140px;
      :first-of-type {
         border: 1px solid #adb6cc;
         box-shadow: none;
         margin-right: 21px;
         ${mediaSmDown(css`
            margin-right: 16px;
         `)};
      }
   }
`

export const StyledActionRow = styled(Field.Row)`
   justify-content: center;
   button {
      width: 274px;
   }
`

export const ChangeStripeBtnContainer = styled.div`
   display: flex;
   justify-content: center;
   button {
      line-height: 19px;
      :first-of-type {
         border: 1px solid #adb6cc;
         box-shadow: none;
         padding: 10px 42px;
      }
      :last-of-type {
         margin-left: 16px;
         padding: 10px 24px;
      }
   }
   ${mediaSmDown(css`
      flex-direction: column-reverse;
      max-width: 220px;
      margin: 0 auto;
      button:last-of-type {
         margin: 0 0 16px;
      }
   `)};
`

export const BtnDismiss = styled(Button)`
   border: 1px solid #adb6cc !important;
   box-shadow: none !important;
   margin-right: 20px;

   ${mediaMdDown(css`
      border: none !important;
      margin: 5px 0 0;
   `)};
`

export const BtnsWrapper = styled.div`
   display: flex;
   justify-content: center;

   ${mediaMdDown(css`
      flex-direction: column-reverse;
      max-width: calc(100% - 53px);
      margin: 0 auto;
   `)};
`
