import { PlusCircleOutlined } from '@ant-design/icons'
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Button, Text } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Dropdown, Spin } from 'antd'
import * as api from 'api'
import { onReschedule } from 'helpers/app/reschedule'
import React from 'react'

import * as SC from '../styles'
import CreateServiceMenu from './CreateServiceMenu'
import ServiceGroup from './ServiceGroup'

function ServiceGroupList({
   data,
   onItemDrop,
   createService,
   editService,
   createCategory,
   editCategory,
   isLoading,
   fetchServiceGroups,
   changeData,
   onSortEnd,
}: any) {
   const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor))

   const onDragEnd = (event: any) => {
      const { active, over } = event

      if (active.id !== over?.id) {
         const oldIndex = (data || []).findIndex((item: any) => item.id === active.id)
         const newIndex = (data || []).findIndex((item: any) => item.id === over.id)
         return onSortEnd({ oldIndex, newIndex })
      }
   }

   return (
      <SC.Container>
         <SC.Header>
            <Dropdown
               overlay={<CreateServiceMenu createService={createService} createCategory={createCategory} />}
               trigger={['click']}
               placement="bottomRight">
               <Button label="btn.add.new" type="primary" />
            </Dropdown>
         </SC.Header>
         <Spin spinning={isLoading}>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
               <SortableContext
                  items={(data || [])?.map((item: any) => item.id)}
                  strategy={verticalListSortingStrategy}>
                  {(data || []).map((e: any, i: number) => (
                     <ServiceGroup
                        key={`group-${e.id}`}
                        fetchServiceGroups={fetchServiceGroups}
                        services={e.services}
                        name={e.name}
                        color={e.color}
                        id={e.id}
                        index={i}
                        editService={editService}
                        onServiceItemDrop={onItemDrop(e.id)}
                        editCategory={editCategory}
                        createService={createService}
                        handleServiceSortEnd={onReschedule({
                           data: e?.services,
                           changeData: (nextServices: any) => {
                              const nextData = [...data]
                              nextData[i].services = nextServices
                              changeData(nextData)
                           },
                           onPositionUpdate: async (id: string, newPosition: number) => {
                              await api.changeServicePosition('', { id, newPosition })
                           },
                        })}
                     />
                  ))}
               </SortableContext>
            </DndContext>
         </Spin>
         <SC.AddCategoryWrapper>
            <Text size="base" weight="normal" colorType="base">
               <IntlLabel label="serviceGroup.addCategory.description" />
            </Text>
            <SC.AddCategory onClick={createCategory}>
               <PlusCircleOutlined /> <IntlLabel label="serviceGroup.addCategory.title" />
            </SC.AddCategory>
         </SC.AddCategoryWrapper>
      </SC.Container>
   )
}

export default ServiceGroupList
