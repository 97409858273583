import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { AuthBlock, getIsMobile, SearchInput, Text, useHistory } from '@plandok/core'
import { IntlLabel } from '@plandok/i18n'
import { Input, Select } from 'antd'
import cn from 'classnames'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { FieldInputProps } from 'react-final-form'

import { PermissionType } from '../../../../constants/auth'
import { OptionGroupType, OptionType } from '../../../../constants/select'
import { useModal } from '../../../../hooks/modal'
import * as helpers from '../SelectInput/helpers'
import * as SC from './styles'

interface SelectInputProps extends Partial<FieldInputProps<any, any>> {
   submitting?: boolean
   filter?: boolean
   filterTxt?: string
   options?: OptionType[]
   optionGroups?: OptionGroupType[]
   className?: string
   optionRender?: React.ComponentType<any>
   onChange: (...arg: any) => void
   onCustomChange?: (...arg: any) => any
   dropdownClassName?: string
   addBtn?: string
   modalType?: string
   onGetOptions?: () => any
   onSubFilter: (val: string) => any
   showModal?: (...arg: any) => any
   hideModal?: (modalType: string | undefined) => any
   isCustomSearchOption?: boolean
   isFullScreen?: boolean
   isWorkingTime?: boolean
   children?: ReactNode
}

const SelectInput = ({
   // submitting,
   options,
   filter,
   filterTxt,
   optionGroups,
   optionRender: OptionRender,
   onChange: onInputChange,
   onSubFilter,
   addBtn,
   section,
   modalType,
   onGetOptions,
   isCustomSearchOption,
   isFullScreen,
   isWorkingTime,
   children,
   ...rest
}: SelectInputProps) => {
   const menuRef = useRef<HTMLDivElement>(null)
   const containerRef = useRef<HTMLDivElement>(null)
   const [isOpen, setIsOpen] = useState(false)
   const isMobile = getIsMobile()
   const [, { showModal, hideModal }] = useModal()
   const history = useHistory()

   const handleClickOutside = (event: MouseEvent) => {
      if (containerRef && containerRef.current && containerRef.current.contains(event.target as Node)) {
         setIsOpen(!isOpen)
         return
      }

      if (menuRef && menuRef.current && !menuRef.current.contains(event.target as Node) && isOpen) {
         setIsOpen(false)
      }
   }

   const onChange = (value: React.FocusEvent<string | number> | undefined, ...arg: any) => {
      onInputChange(value, ...arg)

      if (rest.onCustomChange) {
         rest.onCustomChange(value, helpers.extractValue(value, options, optionGroups))
      }
   }

   const onFilter = (e: React.ChangeEvent<HTMLInputElement>) => onSubFilter(e.target.value)

   const onAddedSuccess = () => {
      setIsOpen(true)
      hideModal && hideModal(modalType ?? '')
      onGetOptions && onGetOptions()
   }

   const onCloseModal = () => {
      setIsOpen(true)
      hideModal && hideModal(modalType ?? '')
   }

   const onAddItem = () => {
      setIsOpen(false)
      history.go(1)
      showModal &&
         showModal({
            type: modalType ?? '',
            modalProps: {
               onSuccess: onAddedSuccess,
               onExit: onCloseModal,
            },
         })
   }

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside, false)

      return () => document.removeEventListener('mousedown', handleClickOutside, false)
   })

   return (
      <SC.SelectContainer ref={containerRef} isWorkingTime={isWorkingTime}>
         <Select
            {...rest}
            onChange={onChange}
            listHeight={isMobile ? 750 : 230}
            value={rest.value === null || rest.value === '' ? undefined : rest.value}
            className={cn(
               { 'ant-select__filter': filter, 'ant-select-fullscreen': isOpen && isFullScreen },
               'br-small-select',
               rest.className,
            )}
            data-field-name={rest.name}
            popupClassName={cn(`dropdown_field-${rest.name?.replace(/([[\].])/g, '_')}`, rest.dropdownClassName)}
            open={isOpen}
            onSelect={() => setIsOpen(false)}
            dropdownRender={(menu: React.ReactNode) => (
               <div ref={menuRef}>
                  <SC.DropdownContainer isGroups={isCustomSearchOption}>
                     {isMobile && isCustomSearchOption && (
                        <SC.CloseIcon onClick={() => setIsOpen(false)}>
                           <CloseCircleOutlined />
                        </SC.CloseIcon>
                     )}
                     {isCustomSearchOption && !isMobile && (
                        <SC.SearchDiv>
                           <Input.Search
                              className="ant-input-filter"
                              value={filterTxt}
                              onChange={onFilter}
                              placeholder="Select Service"
                           />
                        </SC.SearchDiv>
                     )}
                     {isCustomSearchOption && isMobile && (
                        <SearchInput onChange={onFilter} spaced placeholder="input.searchService.placeholder" />
                     )}
                     {menu}
                     <AuthBlock section={section || PermissionType.SERVICES}>
                        <SC.AddBtnItem onClick={onAddItem}>
                           <PlusCircleOutlined />
                           <Text size="small" upperCase weigh="semiBold" mb="none" bold>
                              <IntlLabel label={addBtn} />
                           </Text>
                        </SC.AddBtnItem>
                     </AuthBlock>
                  </SC.DropdownContainer>
               </div>
            )}
            virtual={false}>
            {(options || []).map((val, i) => (
               <Select.Option value={val.code} title={val.name} key={`${val.code}-${i}`}>
                  {OptionRender ? <OptionRender value={val.value} /> : val.name}
               </Select.Option>
            ))}
            {(optionGroups || []).map(val => {
               return (val.options || []).map((v: any, idx: number) => (
                  <Select.Option value={v.code} title={`${v.name}`} key={`${v.code}-${idx}`}>
                     {OptionRender ? (
                        <OptionRender
                           value={v.value}
                           title={isCustomSearchOption && val.options[0].code === v.code ? val.label : ''}
                        />
                     ) : (
                        v.name
                     )}
                  </Select.Option>
               ))
            })}
         </Select>
         {!isWorkingTime && children}
      </SC.SelectContainer>
   )
}

export default SelectInput
