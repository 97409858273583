import './api'
import './app.d'
import './styles/app/index.less'
import '@plandok/core/styles/index.less'

import { ApolloProvider } from '@apollo/client'
import { appContextSlice, IoC as CoreIoC, modalSlice, theme } from '@plandok/core'
import { getInitialLanguage, IntlProvider, IoC as IntlIoC } from '@plandok/i18n'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import LogRocket from 'logrocket'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { getTheme } from 'theme'

import App from './App'
import ErrorBoundary from './ErrorBoundary'
import { graphqlClient } from './graphql'
import { getAsyncSelectConfig } from './helpers/app/lookups'
import { useCountry, useCurrencyCode } from './hooks/info/useCurrencyCode'
import * as serviceWorkers from './serviceWorkers'

LogRocket.init('zbckxa/plandok')

// Registration of service that will be called in
// Components to get currencyCode in i18n library
IntlIoC.registerService({ useCurrencyCode })
// Components to get asyncSelect components
CoreIoC.registerService({ getAsyncSelectConfig, useCountry })

if (process.env.NODE_ENV === 'production') {
   const version = process.env.REACT_APP_SENTRY_RELEASE || 'unknown-version'

   console.log(`Hi there 👋, you are using version ${version}`)

   // Initialize Datadog RUM if required environment variables are set
   // const initializeDatadog = () => {
   //    const appId = process.env.REACT_APP_DATADOG_APP_ID
   //    const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN
   //
   //    if (appId && clientToken) {
   //       datadogRum.init({
   //          applicationId: appId,
   //          clientToken: clientToken,
   //          site: 'datadoghq.eu',
   //          sessionSampleRate: 100,
   //          trackUserInteractions: true,
   //          version,
   //       })
   //       console.log('Datadog RUM initialized')
   //    } else {
   //       console.warn('Datadog RUM not initialized: Missing APP_ID or CLIENT_TOKEN')
   //    }
   // }

   // Initialize Sentry if DSN is provided
   const initializeSentry = () => {
      const sentryDsn = process.env.REACT_APP_SENTRY_API_URL

      if (sentryDsn) {
         Sentry.init({
            dsn: sentryDsn,
            release: version,
            autoSessionTracking: true,
            ignoreErrors: ['Non-Error promise rejection captured'],
         })
         console.log('Sentry initialized')
      } else {
         console.warn('Sentry not initialized: Missing API_URL')
      }
   }

   // Execute initializations
   // initializeDatadog()
   initializeSentry()
}

// Configure the store
const store = configureStore({
   reducer: {
      appContext: appContextSlice.reducer,
      modalContext: modalSlice.reducer,
   },
})

const queryClient = new QueryClient()

const root = createRoot(document.getElementById('app') as HTMLElement)

root.render(
   <ErrorBoundary>
      <BrowserRouter>
         <ReduxProvider store={store}>
            <ConfigProvider theme={getTheme()}>
               <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
                  <ApolloProvider client={graphqlClient}>
                     <QueryClientProvider client={queryClient}>
                        <ThemeProvider theme={theme}>
                           <IntlProvider getInitialLanguage={getInitialLanguage}>
                              <App />
                           </IntlProvider>
                        </ThemeProvider>
                     </QueryClientProvider>
                  </ApolloProvider>
               </GoogleOAuthProvider>
            </ConfigProvider>
         </ReduxProvider>
      </BrowserRouter>
   </ErrorBoundary>,
)

// @ts-ignore
if (module.hot) {
   // @ts-ignore
   module.hot.accept()
}

serviceWorkers.register()
