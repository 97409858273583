import { useAppContext, useNavigation } from '@plandok/core'
import { Spin } from 'antd'
import * as api from 'api'
import React, { useEffect } from 'react'

import { RoutePath } from '../../constants/routes'
import { graphqlClient } from '../../graphql'

export default function LogoutPage() {
   const [, { resetAppContext }] = useAppContext()
   const { navigate } = useNavigation()

   useEffect(() => {
      graphqlClient?.resetStore()
      graphqlClient?.clearStore()
      ;(async () => await api.logout())()
      resetAppContext()

      if ('caches' in window) {
         caches.keys().then(names => names.forEach(name => caches.delete(name)))
      }

      navigate(RoutePath.LOGIN)
      // eslint-disable-next-line
   }, [])
   return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <Spin spinning={true} />
      </div>
   )
}
