/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
  "login.title": "Увійдіть у свій акаунт",
  "label.email": "Електронна адреса",
  "placeholder.email": "наприклад, johndoe@gmail.com",
  "input.password.label": "Пароль",
  "input.password.placeholder": "Введіть пароль",
  "login.btn": "Увійдіть до Plandok",
  "link.sign.in": "Увійти",
  "label.password": "Введіть пароль        ",
  "login.forgot.password": "Забули пароль?",
  "login.no.account": "Не маєте акаунту?",
  "login.description": "Якщо у вас виникли проблеми з входом, спробуйте скинути пароль",
  "register.title": "Створити акаунт\t",
  "register.description": "Створіть безкоштовний акаунт у програмі планування та бронювання зустрічей у Plandok",
  "register.btn": "Створити мій акаунт",
  "register.agreement.agree": "Я згоден з",
  "register.agreement.terms": "Умови веб-сайту",
  "register.agreement.policy": "Політика конфіденційності\t",
  "register.agreement.and": "і\t",
  "register.agreement.partner": "Партнерські умови бізнесу",
  "register.have.account": "Вже є аккаунт ?",
  "register.validation.confirm": "Перш ніж продовжити, перегляньте та прийміть наведені вище умови.",
  "link.sign.up": "Зареєструйтесь",
  "text.alreadyHaveAccount": "Вже є аккаунт ?",
  "input.company.label": "Компанія",
  "input.company.placeholder": "Компанія",
  "input.country.label": "Країна",
  "input.country.placeholder": "Назва країни",
  "input.timeZone.label": "Часовий пояс\t",
  "input.timeZone.placeholder": "GMT +02.00",
  "input.currency.label": "Валюта",
  "input.language.label": "Мова (Language)",
  "input.language.placeholder": "Англійська",
  "input.currency.placeholder": "Євро - EUR",
  "reset.title": "Скинути пароль",
  "reset.btn.text": "Надіслати скидання пароля",
  "reset.pwd.btn": "Скинути пароль",
  "reset.no.password": "Не хочете скидати пароль?",
  "reset.description": "Введіть свою адресу електронної пошти, і ми надішлемо вам вказівки щодо зміни пароля.\t",
  "sidebar.calendar": "Календар",
  "sidebar.client": "Клієнти",
  "sidebar.staff": "Персонал",
  "sidebar.service": "Послуги",
  "sidebar.notification": "Повідомлення",
  "sidebar.settings": "Налаштування",
  "sidebar.menu": "Меню",
  "sidebar.analytics": "Аналітика",
  "sidebar.language": "Мова (Language)",
  "sidebar.logout": "Вийти",
  "user.menu.settings": "Мої налаштування",
  "user.menu.language": "Змінити мову",
  "user.menu.logout": "Вийти",
  "input.globalSearch.placeholder": "Що Ви шукаєте ?",
  "input.globalSearch.description": "Шукайте за іменем клієнта, мобільним телефоном, електронною поштою або посиланням на бронювання",
  "title.upcomingAppointments": "Найближчі зустрічі",
  "calendar.day": "День",
  "calendar.week": "Тиждень",
  "calendar.monday": "Понеділок",
  "calendar.tuesday": "Вівторок",
  "calendar.wednesday": "Середа",
  "calendar.thursday": "Четвер",
  "calendar.friday": "П’ятниця",
  "calendar.saturday": "Субота",
  "calendar.sunday": "Неділя",
  "calendar.mon": "Пн",
  "calendar.tue": "Вт",
  "calendar.wed": "Ср",
  "calendar.thu": "Чт",
  "calendar.fri": "Пт",
  "calendar.sat": "Сб",
  "calendar.sun": "Сонце",
  "calendar.today": "Сьогодні",
  "calendar.january": "Січень",
  "calendar.february": "Лютий",
  "calendar.march": "Березень",
  "calendar.april": "Квітень",
  "calendar.may": "Травень",
  "calendar.june": "Червень",
  "calendar.july": "Липень",
  "calendar.august": "Серпень",
  "calendar.september": "Вересень",
  "calendar.october": "Жовтень",
  "calendar.november": "Листопад",
  "calendar.december": "Грудень",
  "calendar.jan": "Січ",
  "calendar.feb": "Лют",
  "calendar.mar": "Бер",
  "calendar.apr": "Квіт",
  "calendar.may.short": "Трав",
  "calendar.jun": "Черв",
  "calendar.jul": "Лип",
  "calendar.aug": "Серп",
  "calendar.sept": "Верес",
  "calendar.oct": "Жовт",
  "calendar.nov": "Листоп",
  "calendar.dec": "Груд",
  "calendar.allStaff": "Всі",
  "calendar.workingStaff": "Працюючі",
  "calendar.tooltip.time": "Час",
  "calendar.tooltip.category": "Категорія",
  "calendar.tooltip.service": "Послуга",
  "calendar.tooltip.staff": "Персонал",
  "calendar.tooltip.price": "Ціна",
  "calendar.tooltip.repeating": "Повторення",
  "btn.viewDetails": "Переглянути деталі",
  "calendar.tooltip.emptyCustomer": "З вул",
  "calendar.tooltip.bookedOnline": "Онлайн-бронювання",
  "calendar.print.description": "Щоб скористатися функцією друку, потрібно оновити план. Щоб оновити відвідайте",
  "calendar.print.appointmentsBetween.title": "Призначення між",
  "calendar.print.appointmentsAnd.title": "і",
  "calendar.billing.title": "План і виставлення рахунків",
  "calendar.btn.print.title": "Роздрукувати",
  "datepicker.next.week": "Наступного тижня",
  "datepicker.in.two.weeks": "Через 2 тижні",
  "datepicker.in.three.weeks": "Через 3 тижні",
  "datepicker.in.four.weeks": "Через 4 тижні",
  "datepicker.in.five.weeks": "Через 5 тижнів",
  "datepicker.in.six.weeks": "Через 6 тижнів",
  "modal.splitAppointment.title": "Спліт зустріч",
  "modal.splitAppointment.text": "Ця зустріч є частиною групи, якщо перенести її на інший день, вона поділиться на окреме бронювання. Ви впевнені?",
  "blocktime.create.modal.title": "Час блокування",
  "blocktime.edit.modal.title": "Редагувати час блокування",
  "appointment.create.menu": "Зустріч",
  "blocktime.create.menu": "Час блокування",
  "appointment.new.title": "Нова зустріч",
  "input.startTime.label": "Час початку",
  "input.endTime.label": "Час закінчення",
  "input.startDate.label": "Дата початку",
  "input.endDate.label": "Кінцева дата",
  "input.startDate.description.label": "Вибір запуску Дата",
  "input.endDate.description.label": "Виберіть дату закінчення",
  "input.closedDatedDescription.placeholder": "наприклад канікули",
  "input.service.label": "Обслуговування",
  "input.services.label": "послуги",
  "input.service.placeholder": "Виберіть послугу",
  "input.staff.label": "Персонал",
  "input.staff.placeholder": "Підберіть персонал",
  "input.pickDateAndTime.title": "Оберіть дату та час",
  "input.pickDate.title": "Виберіть дату",
  "service.add.another": "Додайте ще одну послугу",
  "input.appointmentNotes.label": "Примітки про зустріч",
  "input.emailSubject.placeholder": "Зверніть увагу на вашу зустріч",
  "input.appointmentNotes.placeholder": "Додати примітку про зустріч (бачить лише персонал)",
  "input.searchClients.placeholder": "Пошук клієнта",
  "input.searchService.placeholder": "Пошук",
  "appointment.empty.list": "Використовуйте пошук, щоб додати клієнта, або залиште порожнім, щоб зберегти як вхід.",
  "appointment.clients.empty.list": "Список клієнтів порожній.",
  "appointment.clients.addClient": "Створити клієнта",
  "client.create.new": "Створити нового клієнта",
  "client.choose": "Виберіть клієнта",
  "client.menu.edit": "Редагувати деталі клієнта",
  "client.menu.remove": "Зняти з зустрічі",
  "appointment.btn.save": "зберегти",
  "appointment.vat": "ПДВ ({0}%)",
  "appointment.total": "Всього",
  "appointment.view.title": "Переглянути зустріч",
  "appointment.status.btn": "Статус зустрічі",
  "appointment.btn.cancel": "Видалити",
  "btn.cancelAppointment": "Видаляти",
  "modal.cancelAppointment.title": "Видаляти",
  "title.clients": "Клієнти",
  "client.create.title": "Новий клієнт",
  "client.options.export.title": "Експорт клієнтів",
  "client.options.export.description": "Завантажте CSV із даними клієнта",
  "client.options.import.title": "Імпорт клієнтів",
  "client.export.premium.description": "Щоб скористатися функцією експорту, потрібно оновити план.",
  "client.export.premium.visit": "Щоб оновити відвідайте ",
  "client.import.instructions.description": "Дотримуйтеся наведених нижче інструкцій, щоб імпортувати клієнтів до свого облікового запису Plandok",
  "client.import.instructions.download": "Завантажте файл імпорту за допомогою кнопки нижче",
  "client.import.instructions.copy": "Скопіюйте та вставте дані клієнта у файл",
  "client.import.instructions.email": "Надішліть його нам на адресу info@plandok.com",
  "client.import.instructions.account": "і ми імпортуємо до вашого облікового запису",
  "table.clients.name": "Ім'я",
  "table.clients.phone": "Телефон",
  "table.clients.mobile": "Телефон",
  "table.clients.email": "Електронна пошта",
  "table.clients.gender": "Стать",
  "client.page.title": "Профіль клієнта",
  "btn.more.options": "Більше варіантів",
  "btn.showAll": "Показати все",
  "btn.collapse": "Згорнути",
  "btn.reschedule": "Перепланувати",
  "title.edit.client": "Редагувати клієнта",
  "menu.edit.client": "Редагувати клієнта",
  "menu.delete.client": "Видалити клієнта",
  "title.mobilePhone": "Мобільний телефон",
  "title.telephoneNumber": "Номер телефону",
  "title.email": "Електронна пошта",
  "title.address": "Адреса",
  "title.dateOfBirth": "Дата народження",
  "title.gender": "Стать",
  "client.statistics": "Статистика",
  "client.totalSales": "Загальний обсяг продажів",
  "client.totalBookings": "Всього бронювань",
  "client.outstanding": "Незавершено",
  "client.allBooking": "Всі бронювання",
  "client.completed": "Завершено",
  "client.cancelled": "Скасовано",
  "client.noShow": "Немає до показу",
  "appointment.list.title": "Зустріч",
  "upcoming.list.title": "Майбутні",
  "past.list.title": "Минулі",
  "modal.confirmClientDelete.text.0": "Ви впевнені, що хочете назавжди видалити клієнта",
  "modal.confirmClientDelete.text.1": "з бази даних?",
  "input.date.label": "Конкретна дата",
  "input.date.placeholder": "Виберіть Дату",
  "input.firstName.label": "Ім'я",
  "input.firstName.placeholder": "напр.Олександр",
  "input.lastName.label": "Прізвище",
  "input.lastName.placeholder": "наприклад Петренко",
  "input.mobile.label": "Номер мобільного",
  "input.mobile.placeholder": "Введіть номер",
  "input.email.label": "Електронна адреса",
  "input.email.placeholder": "наприклад, johndoe@gmail.com",
  "input.title.label": "Назва",
  "input.title.placeholder": "напр. Стоматолог",
  "input.notificationType.label": "Надіслати повідомлення до",
  "tab.personalInfo": "Особиста інформація",
  "input.gender.label": "Стать",
  "input.referal.label": "Реферальне джерело",
  "input.referal.placeholder": "Виберіть Джерело",
  "input.birthDay.label": "День народження",
  "input.month.placeholder": "Місяць",
  "input.day.placeholder": "День",
  "input.year.placeholder": "Рік",
  "input.clientsNotes.label": "Примітки клієнта",
  "input.clientsNotes.placeholder": "Введіть нотатки клієнта",
  "input.bookingDisplay.label": "Відображати на всіх замовленнях",
  "input.serviceDescription.label": "Опис сервісу",
  "input.serviceDescription.placeholder": "Додати короткий опис, яке буде видно для клієнтів в онлайн-замовлень",
  "input.isBookableOnline.label": "Доступно для замовлення онлайн",
  "service.onlineBookings.title": "Онлайн бронювання",
  "tab.address": "Адреса",
  "input.address.label": "Адреса",
  "input.address.placeholder": "Введіть свою адресу",
  "input.city.label": "Місто",
  "input.city.placeholder": "Введіть місто",
  "input.zip.label": "Поштовий індекс",
  "input.zip.placeholder": "Введіть поштовий індекс",
  "btn.edit": "Редагувати",
  "btn.cancel": "Скасувати",
  "btn.save": "Зберегти",
  "btn.save.changes": "Зберегти",
  "tabs.staffWorking.title": "Робочі години",
  "tabs.staffMembers.title": "Співробітники",
  "tabs.userPermissions.title": "Дозволи користувача",
  "tabs.closedDates.title": "Закриті дати",
  "btn.new.staff": "Новий персонал",
  "table.staff.name": "Ім'я",
  "table.staff.mobileNumber": "Телефон",
  "table.staff.email": "Електронна пошта",
  "table.staff.userPermission": "Дозвіл користувача",
  "table.staff.dataRange": "Проміжок часу",
  "table.staff.numberOfDays": "Кількість днів",
  "table.staff.location": "Розташування",
  "table.staff.description": "Опис",
  "table.appointmentsList.appointment": "Призначення",
  "table.appointmentsList.client": "Клієнт",
  "table.appointmentsList.service": "Сервіс",
  "table.appointmentsList.date": "Дата",
  "table.appointmentsList.time": "час",
  "table.appointmentsList.duration": "Тривалість",
  "table.appointmentsList.location": "Розташування",
  "table.appointmentsList.staff": "Персонал",
  "table.appointmentsList.price": "Ціна",
  "table.appointmentsList.status": "Статус",
  "table.appointmentsCancellations.ref": "посилання",
  "table.appointmentsCancellations.client": "Клієнт",
  "table.appointmentsCancellations.service": "Сервіс",
  "table.appointmentsCancellations.scheduledDate": "Запланована дата",
  "table.appointmentsCancellations.cancelledDate": "Дата скасування",
  "table.appointmentsCancellations.cancelledBy": "Скасовано",
  "table.appointmentsCancellations.reason": "Причина",
  "table.appointmentsCancellations.price": "Ціна",
  "table.appointmentsCancellations.totalCount": "Загальна кількість",
  "table.clients.appointments": "Призначення",
  "table.clients.noShows": "Без шоу",
  "table.clients.totalSales": "Загальний обсяг продажів",
  "table.clients.added": "Додано",
  "table.clients.daysAbsent": "Дні відсутності",
  "table.clients.lastAppointment": "Остання зустріч",
  "table.clients.lastLocation": "Остання локація",
  "table.appointmentsByService.serviceName": "Назва служби",
  "table.appointmentsByService.appointments": "Призначення",
  "table.appointmentsByService.totalValue": "Загальна вартість",
  "table.appointmentsByStaff.staffMember": "Штатний співробітник",
  "table.appointmentsByStaff.appointments": "Призначення",
  "table.appointmentsByStaff.totalValue": "Загальна вартість",
  "table.pagination.showing.title": "Показ",
  "table.pagination.results.title": "результати",
  "clients.duplicate.title": "Знайдено дублікатів клієнтів.",
  "clients.duplicate.description": "Є схожі клієнти, які можна об’єднати.",
  "clients.merge.btn.title": "Об’єднати",
  "clients.merge.modal.title": "Об’єднати клієнтів",
  "clients.merge.selectAll.title": "Виберіть усі дублікати",
  "clients.merge.duplicatesNotFound.title": "Дублікатів не знайдено",
  "clients.merge.duplicatesNotFound.description": "Немає клієнтів для об'єднання",
  "clients.mergeConfirm.modal.title": "Підтвердити об’єднання",
  "clients.mergeConfirm.modal.description": "Ви впевнені, що бажаєте об’єднати {0} дублікатів? Ця дія є постійною та не може бути скасована.",
  "clients.mergeConfirm.checkbox.title": "Я розумію, що об’єднання клієнтів не можна скасувати",
  "clients.mergeConfirm.btn.title": "Підтвердити та об’єднати",
  "clients.mergeConfirm.cancel.btn.title": "Скасувати",
  "clients.byNumber.btn.title": "Телефоном {0}",
  "clients.byEmail.btn.title": "Електронною поштою {0}",
  "clients.byName.btn.title": "За назвою {0}",
  "title.newStaff": "Новий персонал",
  "title.editStaff": "Редагувати персонал",
  "description.extraCharge": "При додаванні нових співробітників до Вашої щомісячної абонплати буде додана додаткова плата",
  "tabs.details": "Деталі",
  "input.staffPicture.label": "малюнок",
  "input.cropImage.title": "Обрізати зображення",
  "input.cropImage.btn.apply.title": "Застосувати",
  "input.stafffirstName.label": "Ім'я",
  "input.stafffirstName.placeholder": "напр. Олександр",
  "input.stafflastName.label": "Прізвище",
  "input.stafflastName.placeholder": "наприклад Петренко",
  "input.stafmobile.label": "Телефон",
  "input.stafemail.label": "Електронна пошта",
  "input.stafemail.placeholder": "наприклад, johndoe@plandok.com",
  "input.permission.label": "Дозволи користувача",
  "input.uploadProfile.label": "Завантажити зображення профілю",
  "input.changeProfile.label": "Змінити зображення профілю",
  "input.notes.label": "Примітки",
  "input.staffNotes.placeholder": "Додайте приватні нотатки, які можна переглянути лише в налаштуваннях персоналу (необов’язково)",
  "input.blockTime.placeholder": "наприклад, обідня зустріч",
  "tabs.locations": "Місцезнаходження",
  "tabs.integrations": "Інтеграції",
  "input.staffLocations.description": "Призначте місця, де цього співробітника можна забронювати.",
  "input.multiLocation.description": "Призначте місця, де цього співробітника можна забронювати.",
  "tabs.services": "Послуги",
  "input.staffServices.description": "Призначте послуги, які може виконувати цей співробітник.",
  "input.multiServices.description": "Призначте послуги, які може виконувати цей співробітник.",
  "input.shiftStart.label": "Початок зміни",
  "input.shiftEnd.label": "Кінець зміни",
  "edit.staff.title.resendPassword": "Пароль повторно",
  "edit.staff.info.resendPassword": "Співробітник не отримав листа з паролем? Натисніть кнопку нижче, щоб повторно надіслати електронний лист із інструкціями щодо встановлення пароля.",
  "edit.staff.button.resendPassword": "Повторно надіслати пароль",
  "btn.add.shift": "Додайте ще одну зміну",
  "break.time": "{0} час перерви",
  "input.repeat.label": "Повторити",
  "input.repeatWeekly.label": "Щотижня",
  "input.repeatDoNot.label": "Не повторювати",
  "input.endRepeat.label": "Повтор закінчується",
  "input.endRepeatOngoing.label": "Триває",
  "input.endRepeatSpecificDate.label": "Конкретна дата",
  "input.endRepeatDate.placeholder": "Виберіть Дату",
  "btn.deleteUpcomingShift": "Видалити майбутні зміни",
  "btn.deleteThisShift": "Видалити лише цю зміну",
  "btn.updateUpcomingShift": "Оновіть майбутні зміни",
  "btn.updateThisShift": "Оновити лише цю зміну",
  "modal.repeatShift.title": "Повторення зміни",
  "form.error.uniqShifts": "Зрушення мають бути унікальними",
  "btn.add.new": "Додати новий",
  "btn.addNewService": "Додати нову послугу",
  "btn.newCategory": "Нова категорія",
  "btn.newServiceGroup": "Нова категорія",
  "btn.editCategory": "Редагувати категорію",
  "btn.deleteCategory": "Видалити категорію",
  "btn.downloadImportFile": "Завантажте файл імпорту",
  "serviceGroup.delete.success": "Категорію успішно видалено!",
  "service.new.title": "Нова послуга",
  "service.edit.title": "Редагувати послугу",
  "input.serviceName.label": "Назва послуги",
  "input.serviceName.placeholder": "наприклад Масаж",
  "input.serviceCategory.label": "Категорія послуг",
  "input.serviceCategory.placeholder": "Виберіть категорію послуг",
  "input.price.label": "Ціна",
  "input.price.placeholder": "0",
  "input.duration.label": "Тривалість",
  "input.duration.placeholder": "Тривалість",
  "input.staffSelect.description": "Виберіть персонал, який виконує цю послугу",
  "input.newCategoryName.label": "Назва категорії",
  "input.newCategoryDescription.label": "Опис категорії",
  "input.newCategoryDescription.placeholder": "Опишіть цю категорію, вона буде показана в онлайн-бронюванні",
  "modal.newCategory.title": "Нова категорія",
  "modal.editCategory.title": "Редагувати категорію",
  "input.chooseCategory.label": "Виберіть колір категорії:",
  "client.messages.title": "Повідомлення клієнта",
  "tabs.messagesLog": "Журнал повідомлень",
  "tabs.messagesLog.description": "Список надісланих повідомлень вашим клієнтам",
  "tabs.messagesSettings": "Налаштування",
  "tabs.messagesSettings.description": "Налаштуйте налаштування повідомлень відповідно до потреб вашого бізнесу",
  "table.header.timeSent": "Надіслано",
  "table.header.client": "Клієнт",
  "table.header.appointment": "Зустріч",
  "table.header.destination": "Адреса",
  "table.header.type": "Тип",
  "table.header.message": "Повідомлення",
  "table.header.status": "Статус",
  "table.header.cost": "Ціна",
  "modal.message.title": "Переглянути повідомлення",
  "setup.page.title": "Налаштування",
  "title.accountSettings": "Налаштування аккаунта",
  "title.accountSetup": "Налаштування облікового запису",
  "title.accountSetup.description": "Керуйте налаштуваннями такими, як назва вашої компанії та часовий пояс",
  "resources.list.title": "Ресурси",
  "title.resources": "Ресурси",
  "title.resources.description": "Створіть резервні ресурси, такі як кімнати, стільці чи обладнання",
  "title.locations": "Місцезнаходження",
  "title.locations.description": "Керуйте кількома торговими точками для вашого бізнесу",
  "calendar.settings.title": "Налаштування календаря",
  "calendar.btn.selectFromCalendar": "Виберіть з календаря",
  "title.calendarSettings": "Налаштування календаря",
  "title.calendarSettings.description": "Налаштуйте колірну схему та макет календаря",
  "title.clientSettings": "Налаштування клієнта",
  "title.onlineBookingSettings": "Налаштування онлайн-бронювань",
  "title.salesSettings": "Налаштування продажів",
  "title.receiptSequencing": "Послідовність чеків",
  "title.receiptSequencing.description": "Налаштуйте деталі, які відображатимуться на чеках, що видаються вашим клієнтам",
  "title.receiptTemplate": "Шаблон чеку",
  "title.taxes.description": "Керуйте податковими ставками, які застосовуються до товарів, що продаються на касі",
  "title.paymentTypes": "Типи оплати",
  "title.paymentTypes.description": "Налаштуйте типи оплати вручну для використання на касі",
  "title.discounts.description": "Налаштування знижок",
  "title.receiptPrefix": "Квитанція №. Префікс",
  "title.receiptNumber": "Наступний номер квитанції",
  "title.change": "Змінити",
  "title.saleReceiptTemplate": "Шаблон товарного чека",
  "description.saleReceiptTemplate": "Налаштуйте вміст, який відображатиметься на чеках, що видаються вашим клієнтам",
  "setting.automaticallyPrint": "Автоматичний друк чеків після завершення продажу",
  "setting.showMobile": "Показувати мобільний та email клієнта на чеку",
  "setting.showAddress": "Показувати адресу клієнта на чеку",
  "title.receiptTitle": "Заголовок чеку",
  "title.referrals": "Реферальні джерела",
  "title.referrals.description": "Налаштуйте власні джерела, щоб відстежувати, як клієнт знайшов вашу компанію",
  "title.clientNotifications": "Налаштування сповіщень клієнта",
  "title.clientNotifications.description": "Керуйте повідомленнями, які надсилають клієнтам про їхні зустрічі",
  "cancelReason.list.title": "Причини скасування",
  "title.cancellationReasons": "Причини скасування",
  "title.cancellationReasons.description": "Відстежуйте, чому клієнти не приїхали на зустрічі",
  "title.onlineBooking.description": "Керуйте доступністю та налаштуваннями онлайн-бронювань",
  "title.pointOfSale": "Касовий термінал",
  "discount.list.title": "Знижки",
  "title.discountTypes": "Типи знижок",
  "title.discountTypes.description": "Налаштуйте типи знижок вручну для використання під час оформлення замовлення",
  "title.premiumFeature": "Преміальна функція",
  "company.details.title": "Інформація про компанію",
  "input.businessName.label": "Назва фірми",
  "input.businessName.placeholder": "напр. Eaglewings",
  "input.description.label": "Опис",
  "input.description.placeholder": "Введіть опис",
  "input.businessAddress.label": "Адреса",
  "input.businessAddress.placeholder": "Введіть адресу",
  "input.website.label": "Веб-сайт",
  "input.website.placeholder": "Введіть назву веб-сайту",
  "input.contactNumber.label": "Контактний номер",
  "input.contactNumber.placeholder": "Введіть номер",
  "input.businessTimeZone.label": "Часовий пояс",
  "input.businessTimeZone.placeholder": "GMT +02.00",
  "input.businessCountry.label": "Країна",
  "input.businessCountry.placeholder": "Виберіть країну",
  "input.timeFormat.label": "Формат часу",
  "input.timeFormat.placeholder": "24 години",
  "table.header.resourceName": "Ресурс",
  "btn.new.resource": "Новий ресурс",
  "input.resourceName.label": "Назва ресурсу",
  "input.resourceName.placeholder": "Введіть ім'я",
  "input.resourceDescription.label": "Опис",
  "input.resourceDescription.placeholder": "Введіть опис",
  "input.resourceLocation.placeholder": "Назва місця",
  "resourcesTooltip.location": "Підказка про розташування ресурсу",
  "modal.newResource.title": "Створити новий ресурс",
  "modal.editResource.title": "Редагувати ресурс",
  "table.header.locationName": "Місцезнаходження",
  "table.header.locatonAddress": "Адреса",
  "table.header.location.Phone": "Телефон",
  "modal.newLocation.title": "Нове місцезнаходження",
  "modal.editLocation.title": "Редагувати місцезнаходження",
  "btn.new.location": "Нове місцезнаходження",
  "btn.addExtraLocation": "Додати додаткову локацію",
  "input.locationName.label": "Назва місцезнаходження",
  "input.locationName.placeholder": "Введіть назву місцезнаходження",
  "input.profile.locationName.placeholder": "наприклад перукарня салон",
  "input.slug.label": "Slug",
  "input.locationTips.label": "Поради щодо розташування",
  "input.slug.explanation": "Буде видно в вашому регіоні URL",
  "input.contactEmail.label": "Контактна електронна адреса",
  "input.locationAddress.label": "Адреса",
  "input.locationAddress.placeholder": "Введіть адресу місцезнаходження",
  "tooltip.contactEmail": "Відповіді на прийом клієнта надходитимуть на цю електронну пошту.",
  "input.appointmentColors.label": "Колір зустрічі",
  "input.colorBy.service": "За сервісною групою",
  "input.colorBy.employee": "За працівником",
  "input.colorBy.appointment": "За статусом зустрічі",
  "input.timeSlot.label": "Проміжок часового інтервалу",
  "input.defaultViewType.label": "Вид за замовчуванням",
  "input.calendarTimeRange.label": "Діапазон часу календаря",
  "input.calendarTimeRange.allTimes.option": "Весь час (00:00 - 24:00)",
  "input.calendarTimeRange.onlyWorkingHours.option": "Тільки робочий час",
  "input.calendarTimeRange.customRange.option": "Нестандартний діапазон",
  "input.weekStart.label": "День початку тижня",
  "table.header.refSourcetitle": "Реферальне джерело",
  "table.header.refSourceAddDate": "Дату додано",
  "btn.new.referral": "Нове реферальне джерело",
  "input.nameRefSource.label": "Назва реферального джерела ",
  "input.nameRefSource.placeholder": "наприклад, реклама у Facebook",
  "modal.newRefSource.title": "Нове реферальне джерело",
  "modal.editRefSource.title": "Редагувати реферальне джерело",
  "input.sendBy.label": "Надіслано",
  "input.emailSubject.label": "Тема електронного листа",
  "input.emailTemplate.label": "Шаблон електронної листа",
  "input.emailTemplate.placeholder": "Введіть шаблон електронного листа",
  "input.subject.label": "Тема електронного листа",
  "input.subject.placeholder": "Введіть тему електронного листа",
  "title.messagingSettings.label": "Налаштування обміну повідомленнями",
  "title.messagingSettings.description": "Налаштування типів повідомлень тут буде автоматично надіслано клієнтам. До усіх створені повідомленнь можна легко отримати доступ на сторінці повідомлень (у головному меню). Налаштуйте параметри, як і коли надсилати повідомлення, та редагуйте шаблони, щоб персоналізувати текст. Використовуйте наведені нижче теги, щоб включити деталі зустрічі до повідомлень:",
  "client.first.name": "CLIENT_FIRST_NAME",
  "client.last.name": "CLIENT_LAST_NAME",
  "staff.first.name": "STAFF_FIRST_NAME",
  "staff.last.name": "STAFF_LAST_NAME",
  "booking.date.time": "BOOKING_DATE_TIME",
  "booking.date": "BOOKING_DATE",
  "booking.time": "BOOKING_TIME",
  "service.name": "SERVICE_NAME",
  "business.name": "BUSINESS_NAME",
  "business.phone": "BUSINESS_PHONE",
  "business.address": "BUSINESS_ADDRESS",
  "business.email": "BUSINESS_EMAIL",
  "location.name": "LOCATION_NAME",
  "location.phone": "LOCATION_PHONE",
  "location.address": "LOCATION_ADDRESS",
  "location.email": "LOCATION_EMAIL",
  "tabs.reminders.title": "Нагадування",
  "input.enableReminder.label": "Увімкнути повідомлення про нагадування",
  "input.enableReminder.description": "Автоматично надсилати клієнтам перед їх майбутньою зустріччю",
  "input.reminder.title": "Нагадування {0}",
  "input.message.title": "Повідомлення {0}",
  "input.reminder.description": "Канали та час відправлення",
  "input.reminder.channels": "Виберіть канали, на які буде надіслано це повідомлення",
  "input.reminderBefore.label": "Нагадування будуть надіслані раніше",
  "input.smsSender.label": "Ідентифікатор відправника SMS",
  "input.messageSender.label": "Ідентифікатор відправника повідомлення",
  "input.smsSender.placeholder": "Введіть ідентифікатор відправника SMS",
  "input.smsTemplate.label": "SMS-шаблон",
  "input.messageTemplate.label": "Шаблон повідомлення",
  "input.smsTemplate.placeholder": "Введіть шаблон SMS",
  "input.whatsAppSender.placeholder": "Plandok",
  "reminder.whatsApp.notificate": "Шаблон повідомлення WhatsApp наразі не можна редагувати через певні обмеження. Однак ми активно працюємо над тим, щоб зробити його повністю редагованим.",
  "reminder.charactersCount": "Кількість символів:",
  "reminder.pricing": "Ціни",
  "modal.pricing.description": "У різних країнах діють різні ціни. Ми надаємо найкращу можливу ціну від наших партнерів. Він може відрізнятися.",
  "modal.pricing.subDescription": "Тарифи будуть застосовані після відправлення повідомлень і додані до майбутнього рахунку-фактури.",
  "modal.pricing.counterDescription": "Стандартне текстове SMS обмежене 160 символами і 70 символами для повідомлень зі спеціальними символами.",
  "modal.pricing.learnMore": "Дізнатися більше",
  "modal.pricing.priceRates": "Тарифи",
  "modal.pricing.messagePrice": "Вартість 1 повідомлення:",
  "modal.sendTestMessageByNumber.title": "Надішліть тестове повідомлення на цей номер:",
  "modal.sendTestMessageByNumber.description": "Тарифи застосовуються відповідно до звичайного повідомлення.",
  "modal.sendTestMessageByEmail.title": "Надішліть тестове повідомлення на цей адрес електронної пошти:",
  "modal.sendTestMessageByEmail.placeholder": "Будь ласка, введіть адресу електронної пошти",
  "btn.send": "Надіслати",
  "reminder.sendTestMessage": "Надіслати тестове повідомлення",
  "button.reminder.addTag": "Додати тег",
  "button.reminder.addAnotherReminder": "ДОДАТИ ЩЕ НАГАДУВАННЯ",
  "button.message.addAnotherMessage": "ДОДАТИ ЩЕ ПОВІДОМЛЕННЯ",
  "button.reminder.upgrade": "Оновіть для доступу",
  "label.emailSettings": "Налаштування Email",
  "label.SMSSettings": "Налаштування SMS",
  "label.whatsAppSettings": "Налаштування WhatsApp",
  "tooltip.sender.0": "Відображатиметься як ім'я відправника SMS",
  "tooltip.sender.1": "Максимум 11 символів",
  "tooltip.sender.2": "У деяких країнах може не працювати",
  "tooltip.sender.3": "Латинські літери або цифри",
  "tabs.confirmations.title": "Підтвердження",
  "btn.appBookings": "Бронювання в додатку",
  "btn.onlineBookings": "Онлайн бронювання",
  "input.enableAppConfirmation.label": "Увімкнути підтвердження бронювання в додатку",
  "input.enableOnlineConfirmation.label": "Увімкнути підтвердження онлайн бронювань",
  "input.enableConfirmationApp.description": "Автоматично відправляється клієнтам, коли для них заброньовано зустріч",
  "input.enableConfirmationOnline.description": "Автоматично відправляється клієнтам, коли вони бронюють зустріч",
  "messageSetup.header": "Налаштування повідомлень",
  "channels.subHeader": "Канали",
  "tabs.reschedules.title": "Перенести",
  "input.enableReschedule.label": "Увімкнути перенесення повідомлень",
  "input.enableReschedule.description": "Автоматично надсилає клієнтам, коли час початку зустрічі змінюється",
  "tabs.cancellations.title": "Скасування",
  "input.enableCancellation.label": "Увімкнути повідомлення про скасування",
  "input.enableCancellations.description": "Автоматично надсилає клієнтам, коли їх зустріч скасовано",
  "tabs.thanks.title": "Дякую",
  "input.enableThank.label": "Увімкніть повідомлення подяки",
  "input.enableThankyou.description": "Повідомлення надсилається клієнту, коли статус зустрічі позначений як завершений",
  "tabs.lastVisit.title": "Останній візит",
  "input.enableLastVisit.label": "Увімкнути повідомлення про останній візит",
  "input.enableLastVisit.description": "Автоматично надсилає клієнтам, коли вони не відвідували вашу компанію протягом зазначеної кількості днів",
  "table.cancelReason.name": "Причини скасування",
  "table.cancelReason.addDate": "Дату додано",
  "btn.new.cancelReason": "Нова причина скасування",
  "modal.newCancelReason.title": "Нова причина скасування",
  "modal.editCancelReason.title": "Редагувати причину скасування",
  "input.cancelReason.title": "Назва причини скасування",
  "input.cancelReason.placeholder": "наприклад, Клієнт недоступний",
  "table.discounts.name": "Знижки",
  "table.discounts.value": "Вартість",
  "table.discounts.addDate": "Дата створення",
  "input.discountName.label": "Назва знижки",
  "input.discountName.placeholder": "наприклад, Громадяни похилого віку",
  "input.discountValue.label": "Вартість знижки",
  "btn.new.discount": "Нова знижка",
  "input.discountPercentage.label": "% Процент",
  "input.discountSum.label": "Сума",
  "modal.newDiscount.title": "Новий тип знижки",
  "modal.editDiscount.title": "Редагувати знижку",
  "modal.deleteAccount.title": "Видалити обліковий запис",
  "modal.deleteAccount.description": "Видалення облікового запису є незворотнім процесом, і всі ваші дані будуть видалені назавжди.",
  "modal.deleteAccount.successMessage": "Ваш обліковий запис успішно видалено.",
  "title.mySettings": "Мої налаштування",
  "title.personalDetails": "Особиста інформація",
  "title.personalDetails.description": "Вкажіть своє ім’я та контактну інформацію, введена тут електронна адреса використовується для вашого доступу до входу",
  "btn.continueWithGoogle.title": "Продовжити з Google",
  "btn.continueWithFacebook.title": "Продовжити з Facebook",
  "notification.googleAccountUnlinked": "Обліковий запис Google від'єднано",
  "notification.googleAccountLinked": "Прив'язано обліковий запис Google",
  "notification.facebookAccountUnlinked": "Обліковий запис Facebook від'єднано",
  "notification.facebookAccountLinked": "Прив'язано обліковий запис Facebook",
  "btn.linkAccount": "Прив'язати акаунт",
  "btn.unlinkAccount": "Відв'язати акаунт",
  "btn.deleteYourAccount": "Видалити обліковий запис",
  "input.myName.label": "Ім'я",
  "input.myName.placeholder": "напр. Олександр",
  "input.mylastName.label": "Прізвище",
  "input.mylastName.placeholder": "наприклад Петренко",
  "title.changePassword": "Змінити пароль",
  "title.changePassword.description": "Щоб оновити, введіть свій існуючий пароль, а потім новий. Якщо ви не знаєте свого існуючого пароля, ви можете вийти і скористатися посиланням Скинути пароль на сторінці Вхід.",
  "input.currentPassword.label": "Поточний пароль",
  "label.currentPassword.placeholder": "Введіть поточний пароль",
  "input.newPassword.label": "Новий пароль",
  "input.newPassword.placeholder": "Введіть новий пароль",
  "input.verifyPassword.label": "Повторіть пароль",
  "input.verifyPassword.placeholder": "Повторіть новий пароль",
  "btn.confirmDelete": "Так, видалити зараз",
  "btn.confirm": "Підтвердити",
  "btn.confirmBook": "Підтвердити та забронювати",
  "btn.confirmPay": "Підтвердити та оплатити",
  "btn.delete": "Видалити",
  "btn.close": "Закрити",
  "validation.required": "Обов’язкове поле",
  "validation.email.format": "Неправильний формат електронної пошти",
  "validation.url.format": "Неправильний формат url, має починатися з https:// або http://",
  "validation.password.length": "Пароль повинен містити щонайменше 7 символів",
  "validation.password.letters": "Пароль повинен містити цифру та букву",
  "validation.startTime": "Час початку не може бути пізніше або збігатися з часом закінчення",
  "validation.endTime": "Час закінчення не може бути раніше або збігатися з часом початку",
  "tooltip.password.title": "Пароль повинен:",
  "tooltip.password.characters": "Містити щонайменше 7 символів",
  "tooltip.password.number": "Містити номер",
  "tooltip.password.letter": "Містити букву",
  "tooltip.location": "Підказка про місце розташування",
  "footer.support.center": "Потрібна допомога? Зв'яжіться з нами",
  "not.found": "Не знайдено",
  "input.cancelReason.label": "Причина скасування",
  "input.location.label": "Місцезнаходження",
  "input.location.placeholder": "Назва місця",
  "input.name.label": "Ім'я",
  "title.recentlyAddedClients": "Клієнти (нещодавно додані)",
  "title.calendarView": "Перегляд календаря",
  "text.manageProfile": "Керуйте своїм профілем користувача",
  "modal.language.title": "Виберіть мову",
  "modal.language.placeholder": "Пошук мови",
  "dropdown.access.medium": "Середній",
  "dropdown.access.high": "Високий",
  "dropdown.access.low": "Низький",
  "dropdown.access.basic": "Основні",
  "dropdown.access.no": "Немає доступу",
  "dropdown.access.owner": "Власник",
  "button.select.all": "Вибрати все",
  "button.selected": "Вибрані",
  "dropdown.gender.unknown": "Невідомо",
  "dropdown.gender.male": "Чоловіча",
  "dropdown.gender.female": "Жіноча",
  "dropdown.notificationBy.none": "Не надсилайте",
  "dropdown.notificationBy.sms": "СМС",
  "dropdown.notificationBy.email": "Електронна пошта",
  "dropdown.notificationBy.combined": "СМС та електронна пошта",
  "notification.success.login": "Ви успішно ввійшли в систему!",
  "notification.success.register": "Успішно зареєстровано!",
  "dropdown.time.minutes": "хвилин",
  "dropdown.repeat.no": "Не повторюйте",
  "dropdown.repeat.weekly": "Щотижня",
  "dropdown.endRepeat.ongoing": "Триває",
  "dropdown.endRepeat.weekly": "Конкретна дата",
  "dropdown.time.appointment.immediately": "Негайно",
  "dropdown.time.appointment.upTo1Hour": "До 1 години",
  "dropdown.time.appointment.upTo2Hours": "До 2 годин",
  "dropdown.time.appointment.upTo3Hours": "До 3 годин",
  "dropdown.time.appointment.upTo4Hours": "До 4 годин",
  "dropdown.time.appointment.upTo5Hours": "До 5 годин",
  "dropdown.time.appointment.upTo6Hours": "До 6 годин",
  "dropdown.time.appointment.upTo7Hours": "До 7 годин",
  "dropdown.time.appointment.upTo8Hours": "До 8 годин",
  "dropdown.time.appointment.upTo9Hours": "До 9 годин",
  "dropdown.time.appointment.upTo10Hours": "До 10 годин",
  "dropdown.time.appointment.upTo11Hours": "До 11 годин",
  "dropdown.time.appointment.upTo12Hours": "До 12 години",
  "dropdown.time.appointment.upTo13Hours": "До 13 годин",
  "dropdown.time.appointment.upTo14Hours": "До 14 годин",
  "dropdown.time.appointment.upTo15Hours": "До 15 годин",
  "dropdown.time.appointment.upTo16Hours": "До 16 годин",
  "dropdown.time.appointment.upTo17Hours": "До 17 годин",
  "dropdown.time.appointment.upTo18Hours": "До 18 годин",
  "dropdown.time.appointment.upTo19Hours": "До 19 годин",
  "dropdown.time.appointment.upTo20Hours": "До 20 годин",
  "dropdown.time.appointment.upTo21Hours": "До 21 годин",
  "dropdown.time.appointment.upTo22Hours": "До 22 годин",
  "dropdown.time.appointment.upTo23Hours": "До 23 годин",
  "dropdown.time.appointment.upTo24Hours": "До 1 дня",
  "dropdown.time.appointment.upTo48Hours": "До 2 днів",
  "dropdown.time.appointment.upTo3days": "До 3 днів",
  "dropdown.time.appointment.upTo4days": "До 4 днів",
  "dropdown.time.appointment.upTo5days": "До 5 днів",
  "dropdown.time.appointment.upTo6days": "До 6 днів",
  "dropdown.time.appointment.upTo1week": "До 1 тижня",
  "dropdown.time.appointment.upTo2weeks": "До 2 тижнів",
  "dropdown.time.appointment.upTo15Min": "До 15 хвилин",
  "dropdown.time.appointment.upTo30Min": "До 30 хвилин",
  "dropdown.time.appointment.upTo1Month": "До 1 місяця",
  "dropdown.time.appointment.upTo2Months": "До 2 місяців",
  "dropdown.time.appointment.upTo3Months": "До 3 місяців",
  "dropdown.time.appointment.upTo4Months": "До 4 місяців",
  "dropdown.time.appointment.upTo5Months": "До 5 місяців",
  "dropdown.time.appointment.upTo6Months": "До 6 місяців",
  "dropdown.time.appointment.interval5Min": "5 хвилин",
  "dropdown.time.appointment.interval10Min": "10 хвилин",
  "dropdown.time.appointment.interval15Min": "15 хвилин",
  "dropdown.time.appointment.interval20Min": "20 хвилин",
  "dropdown.time.appointment.interval30Min": "30 хвилин",
  "dropdown.time.appointment.interval40Min": "40 хвилин",
  "dropdown.time.appointment.interval45Min": "45 хвилин",
  "dropdown.time.appointment.interval50Min": "50 хвилин",
  "dropdown.time.appointment.interval60Min": "60 хвилин",
  "dropdown.time.appointment.interval75Min": "75 хвилин",
  "dropdown.time.appointment.interval90Min": "90 хвилин",
  "dropdown.time.appointment.interval120Min": "120 хвилин",
  "dropdown.time.appointment.interval150Min": "150 хвилин",
  "dropdown.time.appointment.interval180Min": "180 хвилин",
  "dropdown.time.appointment.interval240Min": "240 хвилин",
  "dropdown.time.appointment.anyTime": "У будь-який час",
  "dropdown.time.appointment.disabled": "Вимкнено",
  "dropdown.time.appointment.doesntRepeat": "Не повторюється",
  "dropdown.time.appointment.daily": "Щодня",
  "dropdown.time.appointment.every2Days": "Кожні 2 дні",
  "dropdown.time.appointment.every3Days": "Кожні 3 дні",
  "dropdown.time.appointment.every4Days": "Кожні 4 дні",
  "dropdown.time.appointment.every5Days": "Кожні 5 днів",
  "dropdown.time.appointment.every6Days": "Кожні 6 днів",
  "dropdown.time.appointment.every7Days": "Кожні 7 днів",
  "dropdown.time.appointment.weekly": "Щотижня",
  "dropdown.time.appointment.every2weeks": "Кожні 2 тижні",
  "dropdown.time.appointment.every3weeks": "Кожні 3 тижні",
  "dropdown.time.appointment.every4weeks": "Кожні 4 тижні",
  "dropdown.time.appointment.every5weeks": "Кожні 5 тижнів",
  "dropdown.time.appointment.every6weeks": "Кожні 6 тижнів",
  "dropdown.time.appointment.every7weeks": "Кожні 7 тижнів",
  "dropdown.time.appointment.every8weeks": "Кожні 8 тижнів",
  "dropdown.time.appointment.every9weeks": "Кожні 9 тижнів",
  "dropdown.time.appointment.every10weeks": "Кожні 10 тижнів",
  "dropdown.time.appointment.monthly": "Щомісяця",
  "dropdown.time.appointment.every2months": "Кожні 2 місяці",
  "dropdown.time.appointment.every3months": "Кожні 3 місяці",
  "dropdown.time.appointment.every4months": "Кожні 4 місяці",
  "dropdown.time.appointment.every5months": "Кожні 5 місяців",
  "dropdown.time.appointment.every6months": "Кожні 6 місяців",
  "dropdown.time.appointment.every7months": "Кожні 7 місяців",
  "dropdown.time.appointment.every8months": "Кожні 8 місяців",
  "dropdown.time.appointment.every9months": "Кожні 9 місяців",
  "dropdown.time.appointment.every10months": "Кожні 10 місяців",
  "dropdown.time.appointment.every11months": "Кожні 11 місяців",
  "dropdown.time.appointment.after2times": "Через 2 рази",
  "dropdown.time.appointment.after3times": "Після 3 разів",
  "dropdown.time.appointment.after4times": "Через 4 рази",
  "dropdown.time.appointment.after5times": "Після 5 разів",
  "dropdown.time.appointment.after6times": "Після 6 разів",
  "dropdown.time.appointment.after7times": "Після 7 разів",
  "dropdown.time.appointment.after8times": "Після 8 разів",
  "dropdown.time.appointment.after9times": "Після 9 разів",
  "dropdown.time.appointment.after10times": "Після 10 разів",
  "dropdown.time.appointment.after11times": "Після 11 разів",
  "dropdown.time.appointment.after12times": "Після 12 разів",
  "dropdown.time.appointment.after13times": "Після 13 разів",
  "dropdown.time.appointment.after14times": "Після 14 разів",
  "dropdown.time.appointment.after15times": "Після 15 разів",
  "dropdown.time.appointment.after20times": "Після 20 разів",
  "dropdown.time.appointment.after25times": "Після 25 разів",
  "dropdown.time.appointment.after30times": "Після 30 разів",
  "dropdown.time.appointment.specificDate": "Конкретна дата",
  "modal.createHours.title": "Створіть {0} годин",
  "modal.editHours.title": "Редагувати {0} годин",
  "modal.deleteItem.confirm.0": "Ви впевнені, що хочете назавжди",
  "modal.deleteItem.confirm.1": "видалити",
  "modal.deleteItem.confirm.2": "з бази даних?",
  "modal.entity.category": "Категорія",
  "modal.entity.referal": "Реферальне джерело",
  "modal.entity.resource": "Ресурс",
  "modal.entity.location": "Місцезнаходження",
  "modal.deleteReminder.title": "Видалити нагадування",
  "modal.deleteMessage.title": "Видалити повідомлення",
  "modal.deleteReminder.description": "Ви дійсно хочете видалити це нагадування ?",
  "modal.deleteMessage.description": "Ви дійсно хочете видалити це повідомлення ?",
  "modal.deleteReminder.confirm": "Так, видалити",
  "modal.deletePaymentMethod.title": "Видалити спосіб оплати",
  "modal.deletePaymentMethod.description": "Цей спосіб оплати буде безповоротно видалено",
  "closedDate.create.modal.title": "Додати Дата закриття",
  "closedDate.edit.modal.title": "Редагувати Дата закриття",
  "staff.access.owner": "Власник",
  "staff.access.high": "Високий",
  "staff.access.medium": "Середній",
  "staff.access.low": "Низький",
  "staff.access.basic": "Основні",
  "staff.access.no_access": "Немає доступу",
  "staff.create.title": "Додати Дата закриття",
  "staff.emptyState.title": "Немає запланованих співробітників.",
  "staff.emptyState.description": "Щоб додати робочі години, дивіться розділ співробітників.\n",
  "staff.viewStaffMembers.button": "Переглянути співробітників",
  "staff.picture.changePhoto": "Змінити фото",
  "staff.picture.removePhoto": "Видалити фото",
  "input.clientSearch.placeholder": "Шукайте за іменем, електронною поштою або мобільним телефоном",
  "input.blockedDate.label": "Дата",
  "appointment.notes.title": "Примітки про зустріч",
  "appointment.history.title": "Історія зустрічей",
  "appointment.history.by.title": " на {0}",
  "appointment.log.created.label": "Створено",
  "appointment.log.updated.label": "Оновлене",
  "appointment.log.rescheduled.label": "перенесені на",
  "appointment.log.cancelled.label": "скасовано",
  "appointment.log.changed.label": "Змінено статус по",
  "appointment.notificationLog.rescheduling": "Сповіщення про перенесення надіслано",
  "appointment.notificationLog.cancellation": "Повідомлення про скасування надіслано",
  "appointment.notificationLog.confirmation": "Сповіщення про підтвердження надіслано",
  "appointment.notificationLog.reminder": "Сповіщення про нагадування надіслано",
  "appointment.notificationLog.thankYou": "Сповіщення подяки надіслано",
  "appointment.notificationLog.lastVisit": "Сповіщення про останнє відвідування надіслано",
  "state.cancelReason.title": "Встановіть причини скасування",
  "state.cancelReason.description": "Причини скасування допоможуть вам простежити, чому ваші клієнти не з'явились на свої зустрічі.",
  "state.cancelReason.button": "Додайте причину скасування",
  "state.resources.title": "Керуйте своїми ресурсами",
  "state.resources.description": "Бізнес стає успішнішим, коли ви знаєте про наявні ресурси. Відстежуйте кімнати, меблі або будь-яку іншу побутову техніку, щоб забезпечити безперебійне обслуговування та менше нерівностей на шляху.",
  "state.resources.button": "Додати ресурс",
  "state.referralSource.title": "Керуйте реферальним джерелом",
  "state.referralSource.description": "Реферальне джерело вказує, звідки походять клієнти. Відстежуйте їх і використовуйте цю інформацію, щоб можливо покращити видимість вашого бізнесу та коефіцієнт конверсії.",
  "state.referralSource.button": "Додати реферальне джерело ",
  "state.locations.title": "Вкажіть своє місцезнаходження",
  "state.locations.description": "Тут ви можете керувати кількома торговими точками свого бізнесу, встановлювати різні години роботи персоналу для кожного місця та мати індивідуальні календарі для кожного.",
  "state.staff.title": "Керуйте своїм персоналом",
  "state.staff.description": "Створіть профілі для своїх співробітників, щоб ви могли додавати та редагувати їх графіки, а також відстежувати їх зустрічі та робочий час.",
  "state.messageLog.title": "Відстежуйте повідомлення",
  "state.messageLog.description": "Повідомлення нагадують вашим клієнтам про майбутні зустрічі або інформують їх. Тут ви зможете відстежувати повідомлення та їх стан. Щоб налаштувати автоматизовані повідомлення, перейдіть до",
  "state.messageLog.link": "Налаштування сповіщень.",
  "state.clients.title": "Адмініструйте своїх клієнтів",
  "state.clients.description": "Турбота про ваших клієнтів починається з того, що їхні профілі містять всю необхідну інформацію для безперебійної роботи та можливості зв’язку з ними.",
  "state.clients.button": "додати клієнта",
  "state.services.title": "Встановіть групи та послуги",
  "state.services.description": "Групи необхідні, оскільки вони дозволяють групувати подібні послуги, які ви надаєте. Крім того, вони роблять ваш календар чистим та легкодоступним!",
  "state.services.button": "Додати групу послуг",
  "state.calendar.title": "Відсутність запланованого персоналу",
  "state.calendar.description": "Щоб встановити робочий час персоналу, дивіться",
  "state.calendar.button": "Секція персоналу.",
  "state.closedDates.title": "закриті дати",
  "state.closedDates.description": "Перерахуйте дати вашого бізнес закритий для відпочинку, обслуговування або з якоїсь іншої причини. Клієнти не зможуть розміщувати замовлення через Інтернет в ці дні.",
  "state.closedDates.button": "Додати Дата закриття",
  "title.billingPlan": "План і виставлення рахунків",
  "btn.upgrade": "Оновлення",
  "header.usage": "ВИКОРИСТАННЯ",
  "header.dropdown.subscription.plan": "{0} План",
  "header.dropdown.subscription.staff": "({0} Співробітників)",
  "header.dropdown.notifications.description": "{0} повідомленнь",
  "header.paymentDetails": "Переглянути деталі платежу",
  "title.billingPlan.description": "Керуйте своїми обліковими записами. Плануйте та платіжні реквізити, переглядайте транзакції та завантажуйте рахунки-фактури.",
  "plan.free.title": "Початківець",
  "plan.individual.title": "Індивідуальний (1 працівник)",
  "plan.business.title": "Бізнес",
  "btn.upgradeNow": "Оновлення",
  "btn.getStarted": "Початок роботи",
  "btn.currentPlan": "Поточний план",
  "plan.free.option.0": "1 Розташування",
  "plan.free.option.1": "До 5 співробітників",
  "plan.free.option.2": "Необмежена кількість зустрічей",
  "plan.free.option.3": "Необмежена кількість клієнтів",
  "plan.free.option.4": "Необмежена кількість онлайн-бронювань",
  "plan.free.option.5": "Сповіщення електронною поштою",
  "plan.premium.option.0": "Необмежена кількість місць",
  "plan.premium.option.1": "Необмежена кількість зустрічей",
  "plan.premium.option.2": "Необмежена кількість клієнтів",
  "plan.premium.option.3": "Необмежена кількість онлайн-бронювань",
  "plan.premium.option.4": "Повторювані зустрічі",
  "plan.premium.option.5": "Сповіщення електронною поштою",
  "plan.premium.option.6": "СМС сповіщення* ",
  "plan.premium.option.7": "Сповіщення Whatsapp*",
  "plan.premium.option.8": "Необмежена аналітика",
  "plan.premium.option.9": "Необмежена кількість звітів",
  "plan.premium.option.10": "Друк розкладів",
  "plan.premium.option.11": "Експорт клієнта",
  "plan.premium.option.12": "Пріоритетна підтримка",
  "plan.premium.option.13": "Вилучено бренд Plandok з електронних листів (безкоштовні електронні листи на основі Plandok)",
  "plan.premium.costsInfo": "*Вартість SMS/WhatsApp не включена в тарифний план, стягується додаткова плата.",
  "plan.premium.activeUntilDate": "Активний до {0}",
  "period.perMonth": "на місяць (без ПДВ)",
  "plan.premium.description": "Ціна буде збільшуватися при додаванні нових співробітників.",
  "plan.premium.viewPricing": "Переглянути ціни",
  "plan.premium.numberStuff": "Ваша поточна кількість співробітників: {0}",
  "premium.membership": "Ціна преміум-членства може відрізнятися залежно від кількості ваших співробітників.",
  "premium.price.perMonth": "Преміальна ціна за місяць",
  "pricing.calculated": "Ціна розраховується наступним чином:",
  "pricing.calculator": "Калькулятор цін",
  "pricing.numberStuff": "Чисельність персоналу",
  "pricing.extra.member": "{0} за кожного додаткового учасника",
  "modal.title.pricing": "Ціноутворення",
  "plan.foreverFree": "Назавжди безкоштовно",
  "plan.individual.0": "Все для початківців, плюс:",
  "plan.individual.1": "Індивідуальні повідомлення",
  "plan.individual.2": "Видалити брендинг plandok",
  "plan.individual.option.3": "Необмежена кількість SMS-нагадувань",
  "plan.individual.4": "Пріоритетна підтримка",
  "plan.business.0": "Все в індивідуальному порядку, плюс:",
  "plan.business.1": "Необмежена кількість локацій",
  "plan.business.2": "Необмежений персонал",
  "block.billingInfo.title": "Платіжна інформація",
  "block.carInfo.title": "Інформація про кредитну картку",
  "input.cardNumber.label": "Номер картки",
  "input.cardNumber.placeholder": "1234 5678 3456 2456",
  "input.expireDate.label": "Дата закінчення терміну",
  "input.expireDate.placeholder": "05/21",
  "input.cvv.label": "CVV",
  "input.cvv.placeholder": "123",
  "modal.confirmCancel.subscription": "Ви впевнені, що хочете скасувати підписку?",
  "modal.confirmCancel.subscription.description": "Після цього ви повернетесь до використання безкоштовної версії.",
  "btn.cancelSubscription.confirm": "Так, скасувати мою підписку",
  "loading.processingSubscribtion": "Ваш платіж обробляється. Будь ласка, почекайте ...",
  "state.serviceGroup.description": "У групі немає послуг",
  "state.serviceGroup.btn": "Додати послугу",
  "appointment.status.new": "Нова зустріч",
  "appointment.status.confirmed": "Підтверджено",
  "appointment.status.notShow": "Немає до показу",
  "appointment.status.completed": "Завершено",
  "appointment.status.cancelled": "скасований",
  "billing.tab.plan": "Ваш план",
  "billing.tab.costs": "Орієнтовні витрати",
  "billing.tab.billing": "Платіжна інформація",
  "billing.tab.invoice": "Рахунки-фактури",
  "plan.free": "Безкоштовно",
  "plan.deactivated.title": "Преміум був деактивований",
  "plan.active.title": "активувати",
  "payment.step.title": "Платежі",
  "payment.error.title": "Оновіть платіж",
  "payment.payNow.title": "Оплачувати",
  "payment.dismiss.title": "Відхилити",
  "payment.tab.title.sub": "Підписка",
  "payment.tab.subTitle": "Преміальний план",
  "payment.per.members": "на учасника",
  "payment.tab.title.notification": "Сповіщення",
  "payment.card.details": "Реквізити картки",
  "payment.success.message": "Платіж пройшов успішно",
  "payment.payWithCard.title": "Оплатити карткою",
  "payment.cardNumber.title": "Номер картки",
  "payment.expirationDate.title": "Дата закінчення терміну дії",
  "payment.digits.title": "(3 цифри)",
  "payment.amount": "Сума оплати:",
  "payment.btn.pay": "платити",
  "invoice.table.date": "Дата",
  "invoice.table.invoice": "Рахунок-фактура",
  "invoice.table.download": "Завантажити",
  "invoice.table.status": "Статус",
  "costs.billingPeriod.title": "Орієнтовні витрати за цей розрахунковий період",
  "costs.billingPeriod.description": "Це поточні витрати за використання в цьому розрахунковому періоді. Нижче наведено розбивку ваших витрат.",
  "costs.billingPeriod.additionalInfo": "Як тільки ваші витрати на сповіщення досягнуть 50 €, вони будуть списані автоматично. Але не хвилюйтеся, якщо сума менша - вона буде зручно включена в вашу щомісячну підписку.",
  "costs.summary.title": "Підсумок за поточний місяць",
  "costs.summary.description": "Ці витрати враховуються в балансі вашого рахунку",
  "costs.summary.detailedCharges": "Детальні збори",
  "costs.summary.noData": "Немає даних",
  "costs.summary.quantity": "Кількість",
  "costs.summary.subtotal": "Проміжний підсумок",
  "costs.summary.taxes": "Податки",
  "dropdown.time.hours": "годин",
  "message.planUpgrade": "Ви успішно оновили свій план!",
  "costs.subscription": "Підписка",
  "btn.cancelSubscription": "Скасувати підписку",
  "btn.no": "Ні",
  "notification.success.update": "Успішно оновлено",
  "validation.password.match": "Пароль і підтвердження паролю не збігаються",
  "blocked.time": "Час блокування",
  "message.successReset.password": "Готово! Електронне повідомлення про зміну пароля надіслано, перевірте свою поштову скриньку.",
  "title.newPassword": "Введіть новий пароль",
  "title.newPassword.description": "Безпечний доступ до свого облікового запису, створивши новий пароль для входу",
  "input.newPasswordRepeat.label": "Підтвердьте свій новий пароль",
  "btn.change.password": "Змінити мій пароль",
  "text.havingTrouble": "Виникли проблеми?",
  "link.resetPassword.again": "Скиньте пароль ще раз",
  "message.successNew.password": "Пароль успішно змінено.",
  "title.repeatShifts": "Повторення зміни",
  "text.confirmShift.update": "Ви відредагували зміну, яка повторюється щотижня. Видалення майбутніх змін змінить {0} поточний {0} розклад",
  "text.confirmShift.delete": "Ви видаляєте зміну, яка повторюється щотижня. Видалення майбутніх змін змінить {0} поточний {0} розклад",
  "btn.deleteUpcoming.shifts": "Видалити майбутні зміни",
  "btn.deleteThis.shift": "Видалити лише цю зміну",
  "btn.updateUpcoming.shifts": "Оновіть майбутні зміни",
  "btn.updateThis.shift": "Оновити лише цю зміну",
  "text.permisionTab": "Встановіть, які розділи доступні для кожного рівня дозволу користувача. Усі співробітники, що увійшли в систему, можуть отримати доступ до календаря, а облікові записи власників мають повний доступ до системи.",
  "title.permission.locations": "Місцезнаходження",
  "title.permission.booking": "Бронювання та клієнти",
  "title.permission.staff": "Персонал",
  "title.permission.setup": "Налаштування",
  "title.permission.basic": "Основні",
  "title.permission.low": "Низький",
  "title.permission.med": "Середній",
  "title.permission.high": "Високий",
  "title.permission.owner": "Власник",
  "title.allLocations.permission": "Доступ до всіх місць",
  "title.accessCalendar.permission": "Доступ до власного календаря",
  "title.accessOtherCalendar.permission": "Доступ до інших календарів персоналу",
  "title.canBook.permission": "Можна забронювати зустрічі",
  "title.contactInfo.permission": "Може бачити контактну інформацію клієнта",
  "title.clients.permission": "Клієнти",
  "title.messages.permission": "Повідомлення",
  "title.services.permission": "Послуги",
  "title.workingHours.permission": "Робочі години",
  "title.staffMembers.permission": "Співробітники",
  "title.permissionLevels.permission": "Рівні дозволу",
  "title.accountSetup.permission": "Налаштування аккаунта",
  "title.clientSettings.permission": "Налаштування клієнта",
  "title.onlineBookings.permission": "Онлайн бронювання",
  "title.analytics.permission": "Аналітика",
  "title.marketing.permission": "Marketing",
  "notification.type.reminder": "Нагадування",
  "notification.type.confirmation": "Підтвердження",
  "notification.type.online_booking_confirmation": "Підтвердження бронювання онлайн",
  "notification.type.rescheduling": "Перенести",
  "notification.type.cancellation": "Скасування",
  "notification.type.thank_you": "Дякую",
  "notification.type.last_visit": "Останній візит",
  "notification.channel.sms": "SMS",
  "notification.channel.email": "Email",
  "notification.channel.whatsapp": "WhatsApp",
  "notification.status.sent": "Надісланий",
  "notification.status.pending": "Очікується",
  "notification.status.failed": "Помилка",
  "dropdown.time.days": "днів",
  "title.appointment": "зустріч",
  "input.reminderBeforeLastVisit.label": "Повідомлення буде надіслано через зазначену кількість днів",
  "txt.with": "з",
  "short.hours": "год",
  "short.minutes": "хв",
  "input.registrationNumber.label": "Номер компанії",
  "input.vatNumber.label": "ІПН",
  "invoice.table.priceTaxIncl": "Сума",
  "error.wrongSenderId": "Ідентифікатор відправника повинен містити цифри або символи, довжина повинна бути більше 3 і менше 11 символів",
  "sidebar.help": "Допомога",
  "modal.help.title": "Як ми можемо допомогти?",
  "modal.help.liveChat.title": "Живий чат",
  "modal.help.liveChat.description": "Виникли питання? Просто запитайте нас прямо зараз",
  "modal.help.suggestFeature.title": "Запропонуйте функцію",
  "modal.help.suggestFeature.description": "Поділіться своїми пропозиціями, перегляньте пропозиції інших та проголосуйте за потрібні вам функції",
  "modal.help.viewUpdates.title": "Переглянути оновлення",
  "modal.help.viewUpdates.description": "Ознайомтеся з нашими останніми оновленнями та покращеннями",
  "warning.title.paidFeature": "Платна функція",
  "warning.smsFeatureMissing": "Щоб отримати доступ до налаштувань SMS, ваш план потрібно оновити. Для оновлення відвідайте",
  "warning.link.billingAndPricing": "План і виставлення рахунків",
  "warning.newPage.0": "було оновлено!",
  "warning.newPage.1": "Якщо ви використовували стару версію Plandok, ваш пароль більше не працюватиме, будь ласка",
  "warning.newPage.2": "скинути пароль",
  "warning.newPage.3": "насолоджуватися новою системою. Щоб отримати додаткову інформацію, зв’яжіться з нами",
  "warning.newPage.4": "або",
  "title.blockTime.select": "Виберіть час для зустрічі",
  "calendar.allResources": "Усі ресурси",
  "appointment.edit.title": "Редагувати зустріч",
  "appointment.create.title": "Зустріч успішно створено.",
  "appointment.update.title": "Зустріч успішно оновлено.",
  "select.notify.title": "{0} не працює через вибраний проміжок часу, але ви все ще можете замовляти зустрічі.",
  "booking.notify.title": "Вибраний час забронюється, але ви можете створити зустріч.",
  "textArea.characters.left": "залишилось символів",
  "textArea.characters.exceeded": "обмеження перевищено на",
  "validation.characters.exceeded": "Перевищено ліміт символів",
  "staff.menu.members": "Співробітники",
  "staff.menu.dates": "неробочі дні",
  "staff.menu.working": "Графік роботи персоналу",
  "staff.menu.permissions": "Дозволи користувача",
  "staff.menu.members.description": "Додайте або видаліть членів команди та контролюйте рівень їхнього доступу до користувачів",
  "staff.menu.dates.description": "",
  "staff.menu.working.description": "Керуйте доступністю календаря, коригуючи графіки роботи персоналу",
  "staff.menu.permissions.description": "Встановіть пемісії доступу для конфіденційних функцій та інформації",
  "message.successUpdateLocation": "Готово! Профіль місцезнаходження успішно оновлено.",
  "ob.title": "Інтернет-бронювання",
  "ob.overview.tab": "Огляд",
  "ob.massMessages.tab": "Масові повідомлення",
  "ob.campaigns.tab": "Кампанії",
  "ob.profile.tab": "Інтернет-профіль",
  "ob.links.tab": "Посилання",
  "ob.settings.tab": "Налаштування",
  "ob.payments.tab": "Платежі",
  "ob.status.online": "Інтернет",
  "ob.status.offline": "Офлайн",
  "ob.status.title": "Статус",
  "ob.profile.step": "{0} з 4",
  "ob.profile.step.title": "Профіль та місцезнаходження",
  "ob.profile.step.description": "Представте свою філію та ознайомте з послугами, які ви надаєте своїм клієнтам. Частина \"Про нас\" - це один із перших розділів, який будуть переглядати ваші клієнти, якщо цієї інформації недостатньо, не забудьте залишити номер телефону, по якому клієнти можуть зв’язатися з вами для отримання додаткової інформації! Повідомте своїх клієнтів, де ви надаєте свої послуги, надавши їм адресу вашої компанії та навіть відображаючи ваше місцезнаходження на карті!",
  "ob.overview.placheholder.title": "Записати зустріч ще ніколи не було так просто!",
  "ob.overview.placheholder.description": "Створіть свої бізнес профілі в Інтернеті, і нехай люди знаходять <b>YOU</b> на різних платформах соціальних медіа, включаючи ваш власний веб-сайт.",
  "ob.overview.start.now": "Розпочати зараз",
  "ob.links.direct.title": "Пряме посилання",
  "ob.links.facebook.title": "Посилання на Facebook",
  "ob.links.instagram.title": "Посилання на Instagram",
  "ob.links.direct.description": "Зробіть це простішим для вас і зв’яжіть своє онлайн-бронювання!\nВи можете використовувати його на своєму веб-сайті та де вам захочеться.\nЯкщо ви не впевнені, як користуватися посиланням, не хвилюйтеся, ми вас підтримаємо!",
  "ob.links.facebook.description": "Поділіться цим посиланням у Facebook, щоб залучити більше клієнтів та полегшити їм пошук вашої компанії та замовлення зустрічі.",
  "ob.links.instagram.description": "Зробіть це простішим для вас і зв’яжіть посиланням своє онлайн-бронювання!\nВи можете використовувати його на своєму веб-сайті та де вам захочеться.\nЯкщо ви не впевнені, як користуватися посиланням, не хвилюйтеся, ми вас підтримаємо!",
  "ob.analytics.tab.description": "Додайте Google Analytics і Facebook Pixel на сторінку онлайн-бронювання, щоб аналізувати трафік і поведінку користувачів.",
  "ob.analytics.google.title": "Google Analytics",
  "ob.analytics.facebook.title": "Facebook Pixel",
  "ob.analytics.google.description": "За допомогою Google Analytics ви можете відстежувати та аналізувати відвідуваність сайту і поведінку користувачів, щоб отримати інформацію про те, як покращити ваш сайт.",
  "ob.analytics.active": "Активний",
  "ob.analytics.inactive": "Неактивний",
  "ob.analytics.setUp.btn": "Встановити",
  "ob.analytics.google.description.1": "Google Analytics - це потужний інструмент, який допоможе вам відстежувати відвідуваність сайту та поведінку користувачів. За допомогою Google Analytics ви можете відстежувати кількість відвідувачів вашого сайту, звідки вони приходять, які сторінки відвідують і як довго залишаються на сайті.'",
  "ob.analytics.google.description.2": "Крім того, Google Analytics дозволяє встановлювати цілі та відстежувати розмови, що дає вам краще розуміння того, наскільки добре працює ваш сайт і як ви можете його покращити.",
  "ob.analytics.google.trackingId": "Ідентифікатор відстеження",
  "ob.analytics.google.tooltip": "Щоб знайти свій ідентифікатор відстеження, ви повинні спочатку увійти до свого облікового запису Google Analytics, вибрати веб-сайт або додаток, який ви хочете відстежувати, а потім перейти на вкладку \"Адміністрування\". Звідти натисніть на \"Код відстеження\". Ваш ідентифікатор відстеження буде відображено у форматі \"UA-XXXX-Y\".",
  "ob.analytics.google.account": "У вас ще немає облікового запису Google Analytics?",
  "ob.analytics.click": "Натисніть",
  "ob.analytics.here": "тут",
  "ob.analytics.learn": "щоб дізнатися, як його створити.",
  "ob.analytics.facebook.description.1": "Facebook Pixel - це інструмент, який дозволяє відстежувати поведінку користувачів на вашому сайті, включаючи конверсії, перегляди сторінок та інші події.",
  "ob.analytics.facebook.description.2": "Facebook Pixel може надати вам цінну інформацію про вашу аудиторію, таку як демографічні дані, інтереси, тип пристрою, переглянуті сторінки та виконані дії, що допоможе вам краще зрозуміти та оптимізувати взаємодію з користувачами на вашому сайті.",
  "ob.analytics.facebook.pixelId": "Ідентифікатор пікселя",
  "ob.analytics.facebook.tooltip": "Щоб знайти свій Facebook Pixel ID, виконайте наступні кроки: Перейдіть до свого облікового запису Facebook Ads Manager. Натисніть на кнопку 'Меню' у верхньому лівому куті екрана. У розділі 'Менеджер подій' виберіть 'Пікселі'. Тут ви побачите список усіх пікселів, пов'язаних з вашим акаунтом. Натисніть на назву пікселя, для якого вам потрібен ідентифікатор. Ваш ідентифікатор пікселя відобразиться у верхньому лівому кутку екрана, поруч з назвою пікселя.",
  "ob.analytics.facebook.account": "У вас ще немає бізнес-сторінки у Facebook?",
  "ob.analytics.google.account.mobile.1": "Ще не маєте Google Analytics",
  "ob.analytics.google.account.mobile.2": "облікового запису ? Натисніть",
  "ob.analytics.google.account.mobile.3": "щоб дізнатися, як",
  "ob.analytics.google.account.mobile.4": "створити його.",
  "ob.analytics.facebook.account.mobile.1": "У вас ще немає Facebook",
  "ob.analytics.facebook.account.mobile.2": "бізнес-сторінки? Натисніть",
  "ob.analytics.facebook.account.mobile.3": "щоб дізнатися, як",
  "ob.analytics.facebook.account.mobile.4": "її створити.",
  "ob.analytics.successfullySaved.message": "Успішно збережено",
  "ob.settings.booking.title": "Доступність онлайн-бронювання",
  "ob.settings.booking.description": "Встановіть час, коли можна призначити найраніші та найпізніші зустрічі. Керуйте інтервалами часу зустрічей та надайте важливу інформацію клієнтам, поряд із можливістю вибрати співробітника для їх обслуговування.",
  "ob.settings.cancellation.title": "Скасування та перепланування",
  "ob.settings.cancellation.description": "Встановіть часові рамки, коли клієнти можуть скасувати або перенести свій прийом.",
  "ob.settings.locationsUrl.title": "місця URL",
  "ob.settings.locationsUrl.description": "Ваш URL: book.plandok.com/business-name/Location2556",
  "ob.settings.businessName.explanation": "Ваш URL: book.plandok.com/business-name/Location2556",
  "ob.settings.notifications.title": "Електронні сповіщення",
  "ob.settings.notifications.description": "Вкажіть одержувача повідомлень електронною поштою щодо нових зустрічей або внесених до них змін.",
  "ob.settings.required.title": "Обов'язкові та необов'язкові поля",
  "ob.settings.required.description": "Виберіть, які поля клієнт повинен буде заповнити під час онлайн-бронювання.",
  "ob.settings.requiredField.title": "Обов'язковий",
  "ob.settings.optionalField.title": "Додатково",
  "ob.settings.requiredField.notification.title": "Виникла проблема з налаштуваннями",
  "ob.settings.requiredField.notification.description": "Вибачте, але одне поле має бути обов'язковим, окрім 'приміток'.",
  "ob.settings.nameField.title": "Ім'я",
  "ob.settings.lastNameField.title": "Прізвище",
  "ob.settings.phoneField.title": "Номер телефону",
  "ob.settings.emailField.title": "Електронна пошта",
  "ob.settings.notes.label": "Текст-заповнювач для приміток",
  "ob.settings.notes.placeholder": "Наприклад, введіть свої примітки",
  "ob.bookingLimit.title": "Ліміт онлайн-бронювання",
  "ob.bookingLimit.description": "Будь ласка, вкажіть максимальну кількість замовлень послуг, яку ви хочете встановити.",
  "ob.bookingLimit.label": "Обмеження бронювання послуг",
  "ob.bookingLimit.unlimited.title": "Необмежено",
  "ob.bookingLimit.1service.title": "макс. 1 послуга",
  "ob.bookingLimit.2services.title": "макс. 2 послуги",
  "ob.bookingLimit.3services.title": "макс. 3 послуги",
  "ob.menu.edit.profile": "Редагувати профіль",
  "ob.menu.turnOff.profile": "Вимкнути профіль",
  "ob.profile.status": "Статус",
  "ob.overview.tab.description": "Розвивайте свій бізнес, дозволяючи своїм клієнтам бронювання призначень в Інтернеті.",
  "ob.profile.tab.description": "Управління онлайн-профілі для вашого місця розташування. Налаштування зображення, описи, адреси і багато іншого.",
  "ob.links.tab.description": "Тут ви знайдете онлайн посилання бронювань, які ви можете поділитися зі своїми клієнтами.",
  "ob.settings.tab.description": "Налаштування параметрів онлайн-бронювання тут, так що ваші клієнти можуть замовити тільки тоді, коли це зручно для вас.",
  "ob.payments.tab.description": "Виберіть, які способи оплати зможуть використовувати ваші клієнти для оплати послуг.",
  "ob.payments.page.description": "Тут ви можете вибрати, які способи оплати зможуть використовувати ваші клієнти при оплаті послуг.",
  "ob.payOnSite.title": "Оплатити на місці",
  "ob.payOnSite.description": "Ваші клієнти зможуть оплатити послуги готівкою на місці.",
  "ob.payWithCard.title": "Оплатити карткою",
  "ob.payWithCard.description": "Ваші клієнти зможуть оплатити послуги карткою через додаток Stripe.",
  "ob.payWithCard.info.1": "Ви користуєтеся безкоштовним тарифним планом Plandok, тому вам доведеться сплатити",
  "ob.payWithCard.info.2": "комісію Plandok та комісію Stripe при використанні цього способу оплати.",
  "ob.payWithCard.info.3": " щоб мати",
  "ob.payWithCard.info.4": "Комісію Plandok.",
  "ob.payWithCard.info.5": "Преміум-план не звільняє від сплати комісії Stripe. ",
  "ob.payWithCard.info.6": "Ознайомитися з тарифами Stripe можна тут",
  "ob.stripeConnected.info": "(Підключено Stripe)",
  "ob.requiresSetup.info": "(Потрібно налаштувати акаунт Stripe)",
  "ob.connectToStripe.btn": "Підключення до Stripe",
  "ob.connectToStripe.successMessage.title": "Успішно підключено до Stripe",
  "ob.waitingAuthorization.title": "Очікуємо на вашу авторизацію в Stripe",
  "ob.stripeDashboard.title": "Всі журнали платежів та відшкодувань доступні в ",
  "ob.stripeDashboard.url": "Панелі керування Stripe",
  "ob.yourStripeAccount.title": "Ваш акаунт у Stripe",
  "ob.visitStripe.option": "Відвідати Stripe",
  "ob.changeAccount.option": "Змінити акаунт Stripe",
  "input.earliestAppointment.label": "Найраніша зустріч",
  "input.earliestAppointment.placeholder": "Безпосередньо перед призначенням",
  "input.latestAppointment.label": "Найпізніша зустріч",
  "input.latestAppointment.placeholder": "Не більше 6 місяців наперед",
  "input.timeSlots.label": "Проміжок часових інтервалів",
  "input.timeSlots.placeholder": "15 хвилин",
  "checkbox.allowCustomers": "Дозвольте клієнтам вибирати співробітників для їх зустрічі",
  "input.importantInfo.label": "Важлива інформація",
  "input.importantInfo.placeholder": "Інформація, яка буде показана замовнику під час підтвердження зустрічі",
  "input.changeAppointment.label": "Можуть бути внесені зміни до зустрічей",
  "input.changeAppointment.placeholder": "У будь-який час",
  "checkbox.sendToBooked": "Надішліть заброньованому співробітнику",
  "checkbox.sendSpecificEmail": "Надіслати на певну електронну адресу",
  "input.specificEmail.label": "Конкретна електронна адреса",
  "input.specificEmail.placeholder": "Введіть адресу електронної пошти",
  "input.locationsUrl.label": "місця URL",
  "input.locationsUrl.placeholder": "наприклад Джонс-Барбер-магазин",
  "ob.btn.save": "Зберегти",
  "ob.btn.cancel": "Скасувати",
  "ob.btn.showLink": "Показати посилання",
  "ob.btn.active": "Активно",
  "ob.btn.inactive": "Неактивно",
  "ob.linkModal.title": "Пряме посилання для бронювання",
  "ob.linkModal.description": "Ось посилання, де можна безпосередньо дістатись до ваших послуг онлайн-бронювання!",
  "ob.linkModal.afterDescription": "Це посилання спрямовує користувачів безпосередньо до ваших послуг. Лише одним кліком миші клієнти можуть отримати доступ до профілів вашого бізнесу та замовити зустрічі.\nЦе посилання є простим у користуванні та зручним для всіх пристроїв, будь то комп’ютер, телефон або планшет.",
  "ob.fbLinkModal.title": "Facebook сторінка Забронювати",
  "ob.fbLinkModal.description": "Налаштування інтегрованої кнопку Забронювати для бізнес-сторінки Facebook, щоб легко отримати нові замовлення безпосередньо від Facebook.\n",
  "ob.fbLinkModal.step1": "Вхід в бізнес-сторінки на Facebook",
  "ob.fbLinkModal.step2": "Натисніть кнопку «Додати», розташовану біля фото обкладинки",
  "ob.fbLinkModal.step3": "Натисніть на кнопку «Додати кнопку», розташованої поруч з обкладинкою",
  "ob.linkModalCopySuccess.title": "Посилання скопійована!",
  "ob.btn.copyLink": "Копіювати посилання",
  "title.appointmentSlot.select": "Виберіть час для зустрічі",
  "btn.back": "Назад",
  "btn.continue": "Продовжити",
  "ob.hours.open": "Відкрити",
  "ob.hours.close": "Закрити",
  "ob.hours.step.title": "Робочі години",
  "ob.hours.step.description": "Повідомте своїх клієнтів, коли ваш бізнес відкривається і закривається протягом дня.",
  "ob.staffHours.step.title": "Графік роботи персоналу",
  "ob.staffHours.step.description": "Важливий крок, будь ласка, додайте робочий час, щоб система дізналася, коли клієнти можуть забронювати в Інтернеті.",
  "ob.location.step.title": "Інформація про місцезнаходження",
  "ob.photo.step.title": "Фотографії",
  "ob.photo.step.description": "Повідомте своїх клієнтів про те, як виглядає ваше робоче місце.",
  "input.file.dnd.photos": "Перетягніть і відпустіть ваші фотографії",
  "btn.publish": "Увімкніть",
  "text.next": "Далі",
  "input.phone.number.label": "Номер телефону",
  "input.state.label": "Країна",
  "input.state.placeholder": "Введіть населений пункт (муніципалітет)",
  "input.addressDetails.label": "Деталі адреси",
  "input.addressDetails.placeholder": "Введіть деталі адреси, такі як код дверей, інші входи, інформація про паркування тощо.",
  "placeholder.no.photos": "немає фотографій",
  "btn.options": "опції",
  "btn.list.profile": "Увімкнути",
  "btn.preview": "попередній перегляд",
  "btn.next": "наступного",
  "market.ob.pleaseWait.title": "Будь ласка, зачекайте ...",
  "market.ob.location.step.title": "вибір місця",
  "market.ob.services.step.title": "Виберіть послуги",
  "market.ob.employee.step.title": "Виберіть дату та персонал",
  "market.ob.timeSlot.step.title": "Виберіть дату та час",
  "market.ob.personalInfo.step.title": "Оплата",
  "market.ob.appointmentInfo.step.title": "інформація про призначення",
  "market.ob.informationAboutYou.title": "Інформація про вас",
  "market.ob.paymentMethod.title": "Спосіб оплати",
  "market.ob.securePayment.title": "Безпечний платіж",
  "market.ob.payWithCard.title": "Оплатити карткою",
  "market.ob.payOnSite.title": "Оплатити на місці",
  "market.ob.payOnSite.firstDescription": "Розрахуватися потрібно буде в салоні після процедури.",
  "market.ob.payOnSite.secondDescription": "Невелике прохання від нас: підприємства часто є невеликими незалежними підприємствами, тому, якщо ви не можете встигнути на заплановану зустріч, перенесіть або скасуйте її.",
  "market.ob.validThru.title": "Дійсна до (місяць/рік)",
  "market.ob.information.title": "Інформація",
  "market.ob.additionalInformation.title": "Додаткова інформація",
  "market.ob.reschedulingPolicy.title": "Правила скасування/перенесення зустрічей",
  "market.ob.reschedulingPolicy.description": "Зміни в розклад можна внести за {0} до призначеного часу.",
  "market.ob.fieldsAreRequired.title": "* Поля обов'язкові для заповнення",
  "market.ob.step.title": "Крок {0} з 3",
  "market.ob.confirm.step.title": "Переглянути та підтвердити",
  "market.ob.noServices.placeholder": "Ніякі послуги не вибрано",
  "market.ob.input.firstName.label": "{0} Ім'я",
  "market.ob.input.firstName.placeholder": "e.g. Джонс",
  "market.ob.input.lastName.label": "{0} Прізвище",
  "market.ob.input.lastName.placeholder": "e.g. Барбер",
  "market.ob.input.phone.label": "{0} Номер мобільного телефону",
  "market.ob.input.phone.placeholder": "670 70 822",
  "market.ob.input.email.label": "{0} Адреса електронної пошти",
  "market.ob.input.email.placeholder": "johns.barber@gmail.com",
  "market.ob.input.description.label": "Примітки",
  "market.ob.staff.assignedSpecialist.label": "з персоналом",
  "market.ob.staff.noPreference.label": "немає переваги",
  "market.ob.timeSlots.fullyBooked.title": "На жаль, на цей день у нас немає вільного часу.",
  "market.ob.timeSlots.choseAnotherDay.title": "Будь ласка, оберіть інший день.",
  "market.ob.timeSlots.closedSlot.title": "Найближчий доступний час ",
  "market.ob.timeSlots.selectClosest.title": "Виберіть найближчий термін",
  "market.ob.timeSlots.note.title": "Важлива інформація",
  "market.ob.service.duration.title": "хв",
  "market.location.workingHours.title": "Робочі години",
  "market.location.monday.title": "Понеділок",
  "market.location.tuesday.title": "Вівторок",
  "market.location.wednesday.title": "Середа",
  "market.location.thursday.title": "Четвер",
  "market.location.friday.title": "П’ятниця",
  "market.location.saturday.title": "Субота",
  "market.location.sunday.title": "Неділя",
  "market.location.meetOurTeam.title": "Знайомства Наша команда",
  "market.location.title": "Зв'яжіться з нами",
  "market.location.services.title": "Послуги",
  "market.location.about.title": "Про сервіс",
  "market.location.aboutUs.title": "Про нас",
  "market.confirmation.appointmentCanceled.title": "Ця зустріч була скасована",
  "market.confirmation.appointmentConfirmed.title": "Призначення підтверджено",
  "market.confirmation.appointmentConfirmed.description": "Ваша зустріч підтверджена, ось деталі:",
  "market.confirmation.appointmentDate.title": "Дата зустрічі",
  "market.confirmation.scheduledAppointment.title": "Скасувати зустріч онлайн неможливо.",
  "market.confirmation.scheduledAppointment.description": "Якщо ви не можете прийти на заплановану зустріч, будь ласка, перенесіть або скасуйте її.",
  "market.modal.cancelAppointment.title": "Ви дійсно хочете скасувати цю зустріч ?",
  "market.modal.cancelledAppointment.title": "Зустріч було скасовано",
  "market.language.changer.title": "Мова (Language)",
  "market.btn.bookNow": "Резервувати",
  "market.servicesAvailable.title": "послуги",
  "market.poweredByPlandok.title": "Plandok",
  "market.poweredByPlandok.description": "Хочете подібний сайт ? Відвідайте",
  "market.btn.readMore": "Читати більше",
  "market.btn.showLess": "Показати менше",
  "market.btn.reviews": "Відгуки",
  "market.btn.viewMore": "Переглянути більше",
  "market.btn.cancelAppointment": "Скасувати зустріч",
  "market.btn.cancel": "Так, скасувати",
  "market.wh.closed.title": "Зачинено",
  "market.ob.success.message": "Готово! Замовлення було успішно створено",
  "market.ob.cancelAppointment.success.message": "Призначення скасовано успішно",
  "market.ob.cancelAppointment.error.message": "Призначення дезактивація не вдалося",
  "market.ob.cancelAppointment.confirm.title": "Ви впевнені, що хочете скасувати призначення?",
  "market.location.getDirections.title": "Отримати напрямки",
  "market.notFound.title": "Сторінку не знайдено",
  "market.notFound.btn.goBack": "Повернутися назад",
  "market.ob.quantitativeLimit.title": "Кількісне обмеження",
  "market.ob.quantitativeLimit.description": "На жаль, власник наклав обмеження на кількість послуг бронювання, дозволивши вибрати лише {0} послуг{0}",
  "market.ob.quantitativeLimit.three": "три",
  "market.ob.quantitativeLimit.two": "дві",
  "market.ob.quantitativeLimit.one": "одну",
  "market.ob.quantitativeLimit.serviceSingular": "у",
  "market.ob.quantitativeLimit.servicesPlural": "и",
  "validation.characters.minLength": "Це значення є занадто коротким. Він повинен мати 3-х символів або більше.",
  "not.found.btn.label": "Додому",
  "not.found.subTitle.label": "На жаль, сторінка, яку ви відвідали, не існує.",
  "analytics.title": "Аналітика",
  "analytics.dashboard.tab": "Панель приладів",
  "analytics.reports.tab": "Звіти",
  "analytics.premium.title": "премія",
  "analytics.totalAppointments.title": "всього призначення",
  "analytics.cancellationReasons.title": "Кращі скасувати причини",
  "analytics.occupancy.title": "Заповнюваність",
  "analytics.topServices.title": "Кращі скасувати причини",
  "analytics.clients.title": "клієнти",
  "analytics.completed.title": "Завершений",
  "analytics.notCompleted.title": "Чи не завершено",
  "analytics.canceled.title": "Скасований",
  "analytics.noShowAppointments.title": "Немає шоу призначення",
  "analytics.reasonName.title": "ім'я Reason",
  "analytics.reasonCount.title": "Рахувати",
  "analytics.lastPeriod.title": "останній період",
  "analytics.workingHours.title": "Години роботи",
  "analytics.bookedHours.title": "зайняті годинник",
  "analytics.blockedHours.title": "Заблоковані годин",
  "analytics.unbookedHours.title": "неписьменні годинник",
  "analytics.service.title": "Обслуговування",
  "analytics.thisMonth.title": "Цей період",
  "analytics.lastMonth.title": "Останній період",
  "analytics.totalClients.title": "Всього клієнтів",
  "analytics.averageVisits.title": "Середня кількість на одного клієнта",
  "analytics.newClients.title": "Нові клієнти",
  "analytics.returningClients.title": "Повертаючись клієнтів",
  "analytics.returnRate.title": "Швидкість повернення клієнта",
  "analytics.clientsByGender.title": "Клієнти по підлозі",
  "analytics.clientsBySource.title": "Клієнти по посилального джерела",
  "analytics.clientsByAge.title": "Клієнти за віком",
  "analytics.timeRange.custom": "виготовлений на замовлення",
  "analytics.timeRange.today": "Сьогодні",
  "analytics.timeRange.yesterday": "Вчора",
  "analytics.timeRange.last1day": "Останній 1 день",
  "analytics.timeRange.last7days": "Останні 7 днів",
  "analytics.timeRange.last30days": "Останні 30 днів",
  "analytics.timeRange.last90days": "Останні 90 днів",
  "analytics.timeRange.last3months": "Останні 3 місяці",
  "analytics.timeRange.lastMonth": "Останній місяць",
  "analytics.timeRange.last6Months": "Останні 6 місяців",
  "analytics.timeRange.lastYear": "Останній 1 рік",
  "analytics.timeRange.last2year": "Останні 2 роки",
  "analytics.timeRange.thisWeek": "Цього тижня",
  "analytics.timeRange.thisMonth": "Цього місяця",
  "analytics.timeRange.thisYear": "Цього року",
  "analytics.timeRange.tomorrow": "Завтра",
  "analytics.timeRange.next7days": "Наступні 7 днів",
  "analytics.timeRange.next30days": "Наступні 30 днів",
  "analytics.menu.dashboard.description": "Досліджуйте дані своєї компанії в діаграмах та статистиці",
  "analytics.menu.reports.description": "Досліджуйте дані своєї компанії у звітах",
  "analytics.timeRange.allTime": "Весь час",
  "analytics.timeRange.label": "Діапазон часу",
  "analytics.services.lastPeriod.label": "Останній період",
  "analytics.totalAppointments.appointment.label": "Зустріч",
  "analytics.totalAppointments.onlineBook.label": "Онлайн бронювання",
  "analytics.gender.male": "Людина",
  "analytics.gender.female": "Жіночий",
  "analytics.gender.unknown": "Невідомий",
  "analytics.noData": "Немає даних за вибраний період",
  "analytics.premium.info.title": "Оновлення до преміум-версії для перегляду аналітики протягом більше 7 днів.",
  "analytics.premium.upgradeButton.title": "Оновлення",
  "analytics.premium.skipButton.title": "Пропустити",
  "reports.appointments.title": "Призначення",
  "reports.appointments.description": "Переглядайте прогнозовані доходи від майбутніх зустрічей, відстежуйте рівень скасування та причини.",
  "reports.appointmentsList.title": "Список призначень",
  "reports.appointmentsList.description": "Відображає всі зустрічі з відповідною інформацією.",
  "reports.appointmentsSummary.title": "Резюме призначень",
  "reports.appointmentsSummary.description": "Відображає зустрічі за персоналом і обслуговуванням.",
  "reports.appointmentsCancellations.title": "Скасування зустрічей",
  "reports.appointmentCancellations.title": "Скасування записів",
  "reports.appointmentsCancellations.description": "Відображає всі причини та кількість скасованих записів на вибраний період.",
  "reports.summary.title": "Резюме",
  "reports.clients.title": "Клієнти",
  "reports.clients.description": "Отримайте уявлення про те, як клієнти взаємодіють із вашим бізнесом і хто найбільше витрачає",
  "reports.clientsList.title": "Клієнти",
  "reports.clientsList.description": "Список клієнтів з їх даними",
  "reports.exportCsv.title": "Експорт CSV",
  "reports.appointmentsByStaff.title": "Призначення персоналом",
  "reports.appointmentsByService.title": "Запис за службою",
  "reports.clearFilters.button.title": "Очистити фільтри",
  "reports.filterStaff.placeholder": "Весь персонал",
  "reports.filterLocations.placeholder": "Усі локації",
  "reports.filters.title": "Фільтри",
  "reports.locations.mobile.title": "Місця:",
  "reports.staff.mobile.title": "Персонал:",
  "reports.filters.apply.title": "Застосувати",
  "reports.search.placeholder": "Пошук за посиланням або клієнтом",
  "reports.subscription.description": "{0} план (тільки {0} співробітників)",
  "reports.notifications.description": "{0} повідомлень ({0})",
  "serviceGroup.addCategory.description": "Категорії є важливими, оскільки вони дозволяють групувати схожі послуги.",
  "serviceGroup.addCategory.title": "Додати категорію",
  "app.install.title": "Встановити Plandok",
  "app.install.description.title": "Встановлення майже не використовує пам’ять і забезпечує швидкий спосіб повернутися до цієї програми",
  "app.install.button.title": "встановити",
  "app.skip.button.title": "Пропустити",
  "app.actionUndone.button.title": "Дію скасовано",
  "app.appointmentCancelled.button.title": "Зустріч видалена",
  "app.undo.button.title": "Відмінити",
  "app.showAllTimes.button.title": "Показати весь час",
  "app.install.success.title": "Додаток успішно встановлено!",
  "rp.upgradeToPremiumLink.title": "Оновіть до Premium",
  "rp.upgradeToPremium.title": "щоб використовувати цю функцію",
  "rp.repeatAppointment.title": "Повторіть",
  "rp.input.frequency.title": "Частота",
  "rp.input.endsAfter.title": "Закінчення",
  "rp.input.selectDate.title": "Виберіть дату",
  "rp.upgrade.title": "Оновіть свій план",
  "rp.upgrade.description.title": "Преміум версія Plandok не активована. Оновіть свій план, щоб отримати повний доступ до всіх функцій, які надає Plandok.",
  "rp.upgrade.button.title": "Оновіть до преміум-класу",
  "rp.repeating.title": "Повторення",
  "rp.repeats.title": "Повтори",
  "rp.repeatsUntil.title": "поки",
  "rp.repeatingTimes.title": "({0} разів)",
  "rp.cancel.upcoming.title": "Видалити всі майбутні повторні зустрічі",
  "rp.update.upcoming.title": "Оновіть усі майбутні повторні зустрічі",
  "rp.edit.appointment.title": "Оновити призначення",
  "rp.edit.appointment.description.title": "Ви редагуєте повторювану зустріч. Виберіть, які зустрічі потрібно оновити:",
  "rp.edit.thisAppointment.title": "Оновіть лише цю зустріч",
  "rp.edit.allAppointments.title": "Оновіть усі майбутні зустрічі",
  "rp.edit.saveAppointment.title": "зберегти",
  "modal.cancelSubscriptionError.title": "Помилка скасування підписки",
  "modal.cancelSubscriptionError.text.1": "Безкоштовний тарифний план доступний лише для 5",
  "modal.cancelSubscriptionError.text.2": "співробітників",
  "modal.cancelSubscriptionError.text.3": "і 1",
  "modal.cancelSubscriptionError.text.4": "Щоб використовувати безкоштовну версію, видаліть місцезнаходження / співробітників, щоб відповідати вимогам.",
  "modal.latestPaymentFailed.title": "Останній платіж не пройшов",
  "modal.latestPaymentFailed.text.1": "Щоб отримати доступ до всіх функцій, будь ласка, оплатіть або для використання безкоштовної версії видаліть",
  "modal.latestPaymentFailed.text.2": ", щоб відповідати вимогам. Безкоштовний план доступний лише для 5 співробітників та 1 локації.",
  "modal.extraCostsForNewMember.title": "Додаткові витрати для нового учасника",
  "modal.extraCostsForNewMember.description": "При збільшенні кількості співробітників виникають додаткові витрати на щомісячну підписку.",
  "modal.premiumUpgradeRequired.title": "Необхідно перейти на тарифний план Преміум",
  "modal.premiumUpgradeRequired.description": "Для того, щоб додати {0}, ваш тарифний план повинен бути оновлений до Преміум",
  "modal.premiumUpgradeRequired.location": "додаткову локацію",
  "modal.premiumUpgradeRequired.staffMembers": "додаткових співробітників",
  "modal.changeAccount.title": "Змінити акаунт Stripe?",
  "modal.changeAccount.description": "Ви не зможете приймати платежі під час зміни акаунта. Зміна облікового запису займе близько хвилини.",
  "btn.agree": "Погодитись",
  "btn.decline": "Відмовитись",
  "paymentMethod.paymentMethod.title": "Спосіб оплати",
  "paymentMethod.currentPaymentMethod.title": "Поточний спосіб оплати",
  "paymentMethod.expirationDate": "Термін дії {0}",
  "paymentMethod.otherPaymentMethods.title": "Інші способи оплати",
  "paymentMethod.addAnotherPaymentMethod.btn": "Додати інший спосіб оплати",
  "paymentMethod.addPaymentMethod.title": "Додати спосіб оплати",
  "paymentMethod.noPaymentMethodAdded.title": "Спосіб оплати не додано",
  "paymentMethod.noPaymentMethodAdded.description": "Спосіб оплати буде додано при виборі тарифного плану Premium",
  "paymentMethod.goToPlanPage.btn": "Перейти на сторінку тарифного плану",
  "paymentMethod.add.btn": "Додати",
  "paymentMethod.makeDefault.btn": "Зробити за замовчуванням",
  "paymentMethod.cardHasBeenAdded.message": "Картку додано",
  "paymentMethod.cardHasBeenAssign.message": "Картку призначено",
  "page.marketing.overview.messages.description": "Надсилайте кастомні маркетингові повідомлення своїм клієнтам через SMS або електронну пошту кількома простими кліками",
  "page.marketing.overview.btn.sendMessage": "Надіслати повідомлення",
  "page.marketing.overview.campaigns.title": "Кампанії з автоматичною відправкою",
  "page.marketing.overview.campaigns.description": "Створюйте персоналізовані кампанії на основі таких подій, як дні народження клієнтів тощо. \nНадсилайте кампанії електронною поштою та текстовими повідомленнями по декількох каналах",
  "page.marketing.overview.btn.newCampaign": "Нова кампанія",
  "page.marketing.massMessages.title": "Налаштування нового повідомлення",
  "page.marketing.massMessages.messageLog": "Журнал повідомлень",
  "page.marketing.massMessages.sendOn": "Надіслати далі",
  "page.marketing.massMessages.sendTo": "Надіслати на",
  "page.marketing.massMessages.channel": "Канал",
  "page.marketing.campaigns.description": "Надсилайте автоматизовані маркетингові повідомлення своїм клієнтам через SMS або електронну пошту кількома простими кліками",
  "page.marketing.campaigns.birthdaySpecial.title": "Спеціальна пропозиція до дня народження",
  "page.marketing.campaigns.winBackClients.title": "Поверніть клієнтів",
  "page.marketing.campaigns.welcomeNewClients.title": "Ласкаво просимо нових клієнтів",
  "page.marketing.campaigns.rewardRegularClients.title": "Винагороджуйте постійних клієнтів",
  "page.marketing.campaigns.birthdaySpecial.description": "Здивуйте клієнтів в їх особливий день, ця кампанія автоматично надсилає клієнтам повідомлення напередодні їхнього дня народження",
  "page.marketing.campaigns.winBackClients.description": "Спонукайте клієнтів, які не повернулися, повернутися зі спеціальною пропозицією. Ця кампанія відправляється клієнтам, які не повернулися через певний проміжок часу",
  "page.marketing.campaigns.welcomeNewClients.description": "Перетворити новачків на постійних клієнтів, заохочуючи клієнтів, які вперше звернулися, забронювати ще раз за допомогою спеціальної знижки. Ця кампанія автоматично надсилається клієнтам через день після їх першого продажу",
  "page.marketing.campaigns.rewardRegularClients.description": "Здивуйте найкращих клієнтів спеціальною пропозицією, певний спосіб залучити ваших найкращих клієнтів ще більше. Ця кампанія автоматично надсилається клієнтам на основі їхньої нещодавньої активності",
  "page.marketing.campaigns.btn.active": "Активно",
  "page.marketing.campaigns.btn.inactive": "Неактивно",
  "page.marketing.campaigns.btn.startCampaign": "Почати кампанію",
  "page.marketing.campaigns.btn.editCampaign": "Редагувати кампанію",
  "page.marketing.campaigns.btn.resetCampaign": "Скинути кампанію",
  "modal.resetCampaign.description": "Ви дійсно бажаєте скинути цю Кампанію ?",
  "modal.resetCampaign.btn.yesReset": "Так, скинути",
  "page.marketing.smsCreate.step.1": "Налаштування SMS. Крок 1 з 4",
  "page.marketing.smsCreate.step.2": "Налаштування SMS. Крок 2 з 4",
  "page.marketing.smsCreate.step.3": "Налаштування SMS. Крок 3 з 4",
  "page.marketing.smsCreate.step.4": "Налаштування SMS. Крок 4 з 4",
  "page.marketing.emailCreate.step.1": "Налаштування електронної пошти. Крок 1 з 4",
  "page.marketing.emailCreate.step.2": "Налаштування електронної пошти. Крок 2 з 4",
  "page.marketing.emailCreate.step.3": "Налаштування електронної пошти. Крок 3 з 4",
  "page.marketing.emailCreate.step.4": "Налаштування електронної пошти. Крок 4 з 4",
  "page.marketing.campaignCreate.step.1": "Налаштування кампанії. Крок 1 з 3",
  "page.marketing.campaignCreate.step.2": "Налаштування кампанії. Крок 2 з 3",
  "page.marketing.campaignCreate.step.3": "Налаштування кампанії. Крок 3 з 3",
  "page.marketing.smsCreate.step.1.title": "Повідомлення та ідентифікатор відправника",
  "page.marketing.smsCreate.step.1.defaultTitleForSms": "Введіть ID відправника SMS",
  "page.marketing.smsCreate.step.1.defaultMessageForSms": "Введіть ваше повідомлення",
  "page.marketing.emailCreate.step.1.defaultTitleForEmail": "Введіть назву повідомлення",
  "page.marketing.emailCreate.step.1.defaultMessageForSms": "Введіть ваше повідомлення",
  "page.marketing.emailCreate.step.1.title": "Налаштування імейлу",
  "page.marketing.emailCreate.step.1.messageTitle": "Заголовок повідомлення",
  "page.marketing.emailCreate.step.1.replyToEmail": "Відповідь на імейл",
  "page.marketing.emailCreate.step.1.clientReplies": "Відповіді клієнтів будуть відправлятися на цю електронну пошту",
  "page.marketing.emailCreate.step.1.photo.title": "Фото",
  "page.marketing.emailCreate.step.1.enablePhoto": "Увімкнути фото",
  "page.marketing.emailCreate.step.1.changePhoto": "Змінити фото",
  "page.marketing.emailCreate.step.1.button.title": "Кнопка",
  "page.marketing.emailCreate.step.1.enableButton": "Увімкнути кнопку",
  "page.marketing.emailCreate.step.1.buttonName": "Назва кнопки",
  "page.marketing.emailCreate.step.1.buttonURL": "URL кнопки",
  "page.marketing.emailCreate.step.1.socialMedia.title": "Соціальні мережі",
  "page.marketing.emailCreate.step.1.enableSocialMedia": "Увімкнути посилання на соціальні мережі",
  "page.marketing.emailCreate.step.1.facebookPage": "Сторінка в Facebook",
  "page.marketing.emailCreate.step.1.instagramPage": "Сторінка в Instagram",
  "page.marketing.emailCreate.step.1.yourPage": "Ваша сторінка",
  "page.marketing.smsCreate.yourMessage": "Ваше повідомлення",
  "page.marketing.btn.sendMeTestMessage": "Надішліть мені тестове повідомлення",
  "page.marketing.smsCreate.step.1.freeTests": "На сьогодні залишилося {0} безкоштовних тести",
  "page.marketing.smsCreate.messagePreview": "Попередній перегляд повідомлення",
  "page.marketing.smsCreate.btn.clientSelection": "Відбір клієнтів",
  "page.marketing.audience.title": "Аудиторія",
  "page.marketing.smsCreate.step.2.description": "Виберіть, які клієнти отримають ваше повідомлення",
  "page.marketing.smsCreate.step.2.allClient.title": "Всі клієнти",
  "page.marketing.smsCreate.step.2.allClient.description": "Надіслати повідомлення всім вашим клієнтам",
  "page.marketing.smsCreate.step.2.clientGroups.title": "Групи клієнтів",
  "page.marketing.smsCreate.step.2.clientGroups.description": "Надіслати повідомлення обраним групам клієнтів",
  "page.marketing.smsCreate.step.2.totalClients": "Всього клієнтів",
  "page.marketing.smsCreate.step.2.totalPrice": "Загальна вартість",
  "page.marketing.smsCreate.step.3.description": "Виберіть час, коли ви хочете, щоб ваше повідомлення було відправлено",
  "page.marketing.smsCreate.step.3.btn.selectDateTime": "Виберіть дату та час",
  "page.marketing.smsCreate.step.4.title": "Оплата масової розсилки",
  "page.marketing.smsCreate.step.4.description": "Введіть дані вашої кредитної картки та оплатіть",
  "page.marketing.smsCreate.step.4.paymentAmount": "Сума платежу:",
  "btn.scheduleSend": "Запланувати відправку",
  "btn.previous": "Попередня",
  "btn.payment": "Оплата",
  "btn.payAndFinish": "Сплатити і закінчити",
  "btn.backToDashboard": "Повернутися до головної",
  "btn.saveAndClose": "Зберегти та закрити",
  "modal.editClientSelection.title": "Відредагувати вибір клієнта",
  "modal.newClient.description": "Клієнти, додані останніми:",
  "modal.recentClient.title": "Останні клієнти",
  "modal.recentClient.description": "Клієнти, які відвідували останнім часом:",
  "modal.clientByAge.title": "Клієнти за віком",
  "modal.clientsByGender.title": "Клієнти за статтю",
  "modal.paymentConfirmation.title": "Платіж успішно здійснено !",
  "modal.paymentConfirmation.messageSend": "Ваше повідомлення буде відправлено",
  "modal.previewMessage.title": "Попередній перегляд повідомлення",
  "modal.previewSms.tab": "Попередній перегляд SMS",
  "modal.previewEmail.tab": "Попередній перегляд електронного листа",
  "modal.editServiceSelection.title": "Відредагувати вибір послуги",
  "modal.editServiceSelection.allServices": "Всі послуги",
  "modal.campaignActivated.title": "Кампанія активована !",
  "modal.campaignActivated.description": "Ви можете призупинити або відредагувати цю кампанію в будь-який час",
  "btn.all": "Всі {0}",
  "btn.included": "Включено {0}",
  "btn.excluded": "Виключено {0}",
  "checkbox.allClients": "Всі клієнти {0}",
  "scheduleSend.time": "час",
  "scheduleSend.timeSetTo": "Встановлений час:",
  "scheduleSend.setTime": "Встановити час",
  "campaignSetup.step.1.header.title": "Налаштування кампанії",
  "campaignSetup.step.2.header.title": "Редагувати повідомлення",
  "campaignSetup.step.3.header.title": "Відгук",
  "campaignSetup.channel.description": "Виберіть, на які канали буде відправлятися розсилка",
  "campaignSetup.smsSetup.tab": "Налаштування SMS",
  "campaignSetup.emailSetup.tab": "Налаштування електронної пошти",
  "campaignSetup.almostDone.title": "Майже готово !",
  "campaignSetup.almostDone.description": "Ваша розумна розсилка готова до роботи, після активації вона буде автоматично відправляти повідомлення клієнтам на постійній основі. Ви можете легко змінити або призупинити розсилку в будь-який момент",
  "campaignSetup.approximatePrice.header": "Орієнтовна вартість",
  "campaignSetup.approximatePrice.description": "На найближчі 30 днів",
  "campaignSetup.approximatePrice.approx": "Приблизно",
  "campaign.birthdaySpecial.description": "Ця розсилка автоматично відправляється клієнтам напередодні їхнього дня народження",
  "campaign.birthdaySpecial.sendToClient": "Надіслати клієнту:",
  "campaign.birthdaySpecial.selectOption.1": "У день народження",
  "campaign.birthdaySpecial.selectOption.2": "За три дні до дня народження",
  "campaign.birthdaySpecial.selectOption.3": "За тиждень до дня народження",
  "campaign.birthdaySpecial.selectOption.4": "За два тижні до дня народження",
  "campaign.winBackClients.description": "Ця кампанія відправляється клієнтам, які не повернулися через певний проміжок часу",
  "campaign.winBackClients.clientsWhoDidntReturn": "Клієнти, які не повернулися за останній",
  "campaign.winBackClients.appliedTo": "Застосовується до",
  "campaign.winBackClients.all": " всіх ",
  "campaign.winBackClients.services": "послуг",
  "campaign.winBackClients.editSelection": "Редагувати вибірку",
  "campaign.welcomeNewClients.description": "Перетворіть новачків на постійних клієнтів, заохочуючи їх до повторного бронювання спеціальною знижкою",
  "campaign.welcomeNewClients.smartCampaignNewClients": "Ця розумна кампанія автоматично надсилається новим клієнтам на наступний день після їхнього першого бронювання",
  "campaign.rewardRegularClients.description": "Ця кампанія автоматично надсилається клієнтам на основі їхньої нещодавньої активності",
  "campaign.rewardRegularClients.clientsWithAtLeast": "Клієнти, які мали щонайменше",
  "campaign.rewardRegularClients.appointmentsInThe": "зустрічей за",
  "campaign.rewardRegularClients.last": "останні",
  "campaign.rewardRegularClients.appointments": "зустрічей",
  "campaign.rewardRegularClients.inTheLast": "за останні",
  "campaign.rewardRegularClients.months": "місяці",
  "subscriptionPaymentFailed.title": "Будь ласка, оновіть метод оплати до {0}, інакше всі преміальні функції будуть припинені, дякую.",
  "subscriptionCancelled.title": "Ми приносимо свої вибачення, але ваш Premium було деактивовано через несплату. Якщо ви бажаєте й надалі використовувати преміум-функції, активуйте преміум-план, дякую.",
  "notificationPaymentFailed.title": "Ваш платіж за повідомлення не виконано. Дякуємо, завершіть платіж до {0}, інакше сповіщення буде вимкнено.",
  "notificationCancelled.title": "Приносимо вибачення, але ваші сповіщення деактивовано через несплату. Якщо ви хочете продовжувати надсилати сповіщення, будь ласка, здійсніть оплату, дякую.",
  "btn.hideOptions": "Приховати опції",
  "btn.showOptions": "Показати опції",
  "notification.googleDisconnected": "Календар Google відключено",
  "notification.googleConnected": "Календар Google підключено",
  "notification.appleDisconnected": "Календар Apple відключено",
  "notification.appleConnected": "Календар Apple підключено",
  "notification.microsoft365Disconnected": "Календар Microsoft365 відключено",
  "notification.microsoft365Connected": "Календар Microsoft365 підключено",
  "notification.linkCopied": "Посилання скопійовано",
  "calendarSync.title": "Синхронізація календаря",
  "calendarSync.description": "Синхронізуйте зустрічі Plandok з вашим улюбленим календарем, щоб легко організовувати та ніколи не пропускати важливі події.",
  "googleCalendar.title": "Календар Google",
  "appleCalendar.title": "Календар Apple",
  "microsoft365Calendar.title": "Календар Microsoft365",
  "linkYourCalendar.title": "Зв'яжіть ваші календарі",
  "connect.btn": "Підключити",
  "disconnect.rtn": "Відключити",
  "connected.label": "Підключено",
  "modal.workingHours.standartMethod.btn": "Стандартний метод",
  "modal.workingHours.customMethod.btn": "Індивідуальний метод",
  "modal.workingHours.workingDates.input": "Робочі дати",
  "modal.workingHours.selectDates.placeholder": "Виберіть дати",
  "noInternetConnection.notification.title": "Немає підключення до Інтернету",
  "app.update.title": "Доступна нова версія",
  "app.update.description.title": "Ми додали нові функції та виправили деякі помилки, щоб зробити вашу роботу з Plandok максимально комфортною.",
  "app.update.button.title": "Оновити",
  "app.notNow.button.title": "Не зараз",
  "app.updateAvailable.button.title": "Доступна нова версія",
  "upgradePlan.success.message": "Підписку успішно оновлено",
  "upgradePlan.error.message": "Помилка оновлення підписки, будь ласка, зверніться до служби підтримки, якщо з вашої картки списано кошти, а підписку не оновлено",
  "sidebar.inventory": "Інвентар",
  "products.tab": "Продукція",
  "suppliers.tab": "Постачальники",
  "stockOrders.tab": "Замовлення на склад",
  "stockTracking.tab": "Відстеження запасів",
  "input.productsSearch.placeholder": "Пошук за назвою товару або штрих-кодом",
  "product.create.btn.title": "Додати новий товар",
  "table.products.product": "Товар",
  "table.products.barcode": "Штрих-код",
  "table.products.brand": "Бренд",
  "table.products.sale": "Розпродаж",
  "table.products.saleOpt": "Опц. розпродажу",
  "saleOptions.tooltip.title": "Опції розпродажу",
  "saleOptions.tooltip.description": "Ви можете увімкнути/вимкнути продаж під час оформлення замовлення.",
  "seeMore.title": "побачити більше",
  "option.products.downloadCSV": "Завантажити CSV",
  "option.products.downloadExcel": "Завантажити Excel",
  "option.products.manageBrands": "Керування брендами",
  "option.products.manageCategories": "Керування категоріями",
  "option.products.manageStock": "Керувати запасами",
  "option.products.importProducts": "Імпорт товарів",
  "modal.filter.categories.title": "Категорії",
  "modal.filter.brands.title": "Бренди",
  "modal.filter.stock.title": "Акції",
  "modal.filter.allCategories.selectItem": "Всі категорії",
  "modal.filter.allBrands.selectItem": "Всі бренди",
  "modal.filter.allSuppliers.selectItem": "Всі постачальники",
  "modal.filter.allProducts.selectItem": "Всі товари",
  "input.suppliersSearch.placeholder": "Пошук за назвою постачальника",
  "supplier.create.btn.title": "Додати нового постачальника",
  "table.suppliers.supplierName": "Ім'я постачальника",
  "table.suppliers.phoneNumber": "Номер телефону",
  "table.suppliers.physicalAddress": "Фізична адреса",
  "validation.notification.reminderTime": "Неможливо надіслати сповіщення з тим самим часом нагадування.",
  "validation.paymentType.switches": "Потрібно дозволити принаймні один спосіб оплати",
  "plan.premium.option.14": "0% комісія Plandok для зустрічей, заброньованих через Онлайн бронювання за допомогою кредитної картки.",
  "title.dragAndExtend.appointment.permission": "Може перетягувати та подовжувати зустрічі",
  "button.lastVisit.addAnotherLastvisit": "ДОДАТИ ЩЕ ОДИН ОСТАННІЙ ВІЗИТ",
  "notifications.template.badge.validate": "Перевірити",
  "notifications.template.badge.refused": "Відмовлено",
  "paid.by.card": "Оплачено карткою",
  "costs.manual.paid.link": "Або ви можете оплатити сповіщення вручну за допомогою картки, натиснувши це посилання",
  "modal.remove.account.title": "Видаліть обліковий запис Stripe",
  "modal.remove.account.description": "Ваш обліковий запис буде видалено, і ви не зможете приймати онлайн-платежі. ",
  "ob.remove.account.option": "Видаліть обліковий запис Stripe",
  "btn.remove.account": "видалити",
  "calendar.tooltip.selected": "Вибране",
  "calendar.tooltip.no.preference": "Без переваг",
  "ob.gtag.manager.title": "Менеджер тегів Google",
  "ob.analytics.gtag.description.1": "Менеджер тегів Google дає змогу вимірювати рентабельність інвестицій у рекламу, а також відстежувати ваші Flash, відео та сайти та програми соціальних мереж.",
  "ob.gtag.manager.description.1": "Менеджер тегів Google — це безкоштовна система керування тегами, яка дає змогу керувати маркетинговими тегами (фрагментами коду чи пікселями відстеження) на вашому веб-сайті чи в мобільній програмі та розгортати їх без необхідності безпосередньо змінювати код.",
  "ob.gtag.manager.description.2": "По суті, GTM спрощує процес керування маркетинговими тегами, спрощуючи відстеження ефективності веб-сайту, вимірювання маркетингових кампаній і оптимізацію взаємодії з користувачами.",
  "text.gender.male": "Чоловік",
  "text.gender.female": "Жінка",
  "text.gender.unknown": "Невідомий",
  "ob.stripeConnected.context": "(Смужка підключена)",
  "ob.requiresSetup.context": "(Потрібно налаштувати обліковий запис Stripe)",
  "ob.payWithCard.context.1": "Ви використовуєте безкоштовний план Plandok, тому вам доведеться платити",
  "ob.payWithCard.context.2": "Комісія Plandok і комісія Stripe за допомогою цього способу оплати",
  "ob.payWithCard.context.3": "мати",
  "ob.payWithCard.context.4": "Комісійні збори Plandok.",
  "ob.payWithCard.context.5": "Преміальний план не звільняє від сплати комісійних зборів Stripe",
  "ob.payWithCard.context.6": ""
};

export default labels;
