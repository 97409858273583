/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
  "login.title": "Prisijunkite prie savo paskyros",
  "label.email": "El.paštas",
  "placeholder.email": "pvz.: jonas@plandok.com",
  "input.password.label": "Slaptažodis",
  "input.password.placeholder": "Įveskite slaptažodį",
  "login.btn": "Prisijungti",
  "link.sign.in": "Prisijungti",
  "label.password": "Įveskite slaptažodį",
  "login.forgot.password": "Pamiršote slaptažodį?",
  "login.no.account": "Dar neturite paskyros?",
  "login.description": "Jeigu kyla problemų prisijungiant, galite iš naujo atstatyti slaptažodį",
  "register.title": "Sukurti paskyrą",
  "register.description": "Sukurkite nemokamą paskyrą ir naudokitės rezervacijų planavimo sistema Plandok",
  "register.btn": "Registruotis",
  "register.agreement.agree": "Sutinku su",
  "register.agreement.terms": "svetainės sąlygomis",
  "register.agreement.policy": "privatumo politika",
  "register.agreement.and": "ir",
  "register.agreement.partner": "partnerių verslo sąlygomis",
  "register.have.account": "Jau turite paskyrą?",
  "register.validation.confirm": "Prieš tęsdami peržiūrėkite ir sutikite su pateiktomis sąlygomis.",
  "link.sign.up": "Registruotis",
  "text.alreadyHaveAccount": "Jau turite paskyrą?",
  "input.company.label": "Įmonė",
  "input.company.placeholder": "Įmonė",
  "input.country.label": "Šalis",
  "input.country.placeholder": "Pasirinkite šalį",
  "input.timeZone.label": "Laiko zona",
  "input.timeZone.placeholder": "GMT +02.00",
  "input.currency.label": "Valiuta",
  "input.language.label": "Kalba (Language)",
  "input.language.placeholder": "English",
  "input.currency.placeholder": "Euro – EUR",
  "reset.title": "Atstatyti slaptažodį",
  "reset.btn.text": "Siųsti slaptažodžio atstatymą",
  "reset.pwd.btn": "Atstatyti slaptažodį",
  "reset.no.password": "Prisiminei slaptažodį?",
  "reset.description": "Įveskite savo registruotą prisijungimo el. pašto adresą, kad gautumėte saugią nuorodą ir nustatytumėte naują slaptažodį",
  "sidebar.calendar": "Kalendorius",
  "sidebar.client": "Klientai",
  "sidebar.staff": "Darbuotojai",
  "sidebar.service": "Paslaugos",
  "sidebar.notification": "Pranešimai",
  "sidebar.settings": "Nustatymai",
  "sidebar.menu": "Meniu",
  "sidebar.analytics": "Analitika",
  "sidebar.language": "Kalba (Language)",
  "sidebar.logout": "Atsijungti",
  "user.menu.settings": "Mano nustatymai",
  "user.menu.language": "Keisti kalbą",
  "user.menu.logout": "Atsijungti",
  "input.globalSearch.placeholder": "Ko ieškote?",
  "input.globalSearch.description": "Ieškokite pagal kliento vardą, mobilųjį telefoną, el. pašto adresą arba užsakymo numerį",
  "title.upcomingAppointments": "Ateinančios rezervacijos",
  "calendar.day": "Diena",
  "calendar.week": "Savaitė",
  "calendar.monday": "Pirmadienis",
  "calendar.tuesday": "Antradienis",
  "calendar.wednesday": "Trečiadienis",
  "calendar.thursday": "Ketvirtadienis",
  "calendar.friday": "Penktadienis",
  "calendar.saturday": "Šeštadienis",
  "calendar.sunday": "Sekmadienis",
  "calendar.mon": "Pir",
  "calendar.tue": "Ant",
  "calendar.wed": "Tre",
  "calendar.thu": "Ket",
  "calendar.fri": "Pen",
  "calendar.sat": "Šeš",
  "calendar.sun": "Sek",
  "calendar.today": "Šiandien",
  "calendar.january": "Sausis",
  "calendar.february": "Vasaris",
  "calendar.march": "Kovas",
  "calendar.april": "Balandis",
  "calendar.may": "Gegužė",
  "calendar.june": "Birželis",
  "calendar.july": "Liepa",
  "calendar.august": "Rugpjūtis",
  "calendar.september": "Rugsėjis",
  "calendar.october": "Spalis",
  "calendar.november": "Lapkritis",
  "calendar.december": "Gruodis",
  "calendar.jan": "Sau",
  "calendar.feb": "Vas",
  "calendar.mar": "Kov",
  "calendar.apr": "Bal",
  "calendar.may.short": "Geg",
  "calendar.jun": "Bir",
  "calendar.jul": "Lie",
  "calendar.aug": "Rugp",
  "calendar.sept": "Rugs",
  "calendar.oct": "Spa",
  "calendar.nov": "Lap",
  "calendar.dec": "Gruo",
  "calendar.allStaff": "Visi",
  "calendar.workingStaff": "Dirbantys",
  "calendar.tooltip.time": "Laikas",
  "calendar.tooltip.category": "Kategorija",
  "calendar.tooltip.service": "Paslauga",
  "calendar.tooltip.staff": "Darbuotojas",
  "calendar.tooltip.price": "Kaina",
  "calendar.tooltip.repeating": "Kartojimas",
  "btn.viewDetails": "Peržiūrėti išsamią informaciją",
  "calendar.tooltip.emptyCustomer": "Nuo gatvės",
  "calendar.tooltip.bookedOnline": "Online registracija",
  "calendar.print.description": "Norint naudoti spausdinimo funkciją, jūsų planas turi būti aktyvuotas. Aktyvuokite čia",
  "calendar.print.appointmentsBetween.title": "Rezervacijos tarp",
  "calendar.print.appointmentsAnd.title": "ir",
  "calendar.billing.title": "Planas ir atsiskaitymai",
  "calendar.btn.print.title": "Spausdinti",
  "datepicker.next.week": "Kita savaitė",
  "datepicker.in.two.weeks": "Po 2 savaičių",
  "datepicker.in.three.weeks": "Po 3 savaičių",
  "datepicker.in.four.weeks": "Po 4 savaičių",
  "datepicker.in.five.weeks": "Po 5 savaičių",
  "datepicker.in.six.weeks": "Po 6 savaičių",
  "modal.splitAppointment.title": "Suskaidyti rezervaciją",
  "modal.splitAppointment.text": "Ši rezervacija yra grupės dalis, perkėlimas į kitą dieną suskaidys ją į atskiras rezervacijas. Ar tikrai to norite? ",
  "blocktime.create.modal.title": "Laiko blokavimas",
  "blocktime.edit.modal.title": "Redaguoti blokavimą",
  "appointment.create.menu": "Rezervaciją",
  "blocktime.create.menu": "Laiko blokavimą",
  "appointment.new.title": "Nauja Rezervacija",
  "input.startTime.label": "Pradžia",
  "input.endTime.label": "Pabaiga",
  "input.startDate.label": "Pradinė data",
  "input.endDate.label": "Galutinė data",
  "input.startDate.description.label": "Pasirinkti pradinę datą",
  "input.endDate.description.label": "Pasirinkti galutinę datą",
  "input.closedDatedDescription.placeholder": "pvz. šventės",
  "input.service.label": "Paslauga",
  "input.services.label": "paslaugos",
  "input.service.placeholder": "Pasirinkite paslaugą",
  "input.staff.label": "Darbuotojas",
  "input.staff.placeholder": "Pasirinkite darbuotoją",
  "input.pickDateAndTime.title": "Pasirinkimo data ir laikas",
  "input.pickDate.title": "Pasirinkite datą",
  "service.add.another": "Pridėkite dar vieną paslaugą",
  "input.appointmentNotes.label": "Pastabos",
  "input.emailSubject.placeholder": "Rezervacijos pastabos",
  "input.appointmentNotes.placeholder": "Pridėti pastabas (matomos tik darbuotojui)",
  "input.searchClients.placeholder": "Kliento paieška",
  "input.searchService.placeholder": "Paieška",
  "appointment.empty.list": "Norėdami pridėti klientą, naudokitės paieška arba palikite tuščią, jei norite išsaugoti kaip nuo gatvės",
  "appointment.clients.empty.list": "Klientų sąrašas tuščias.",
  "appointment.clients.addClient": "Pridėti klientą",
  "client.create.new": "Pridėti naują klientą",
  "client.choose": "Pasirinkite klientą",
  "client.menu.edit": "Redaguoti kliento informaciją",
  "client.menu.remove": "Pašalinti iš rezervacijos",
  "appointment.btn.save": "Saugoti",
  "appointment.vat": "PVM ({0}%)",
  "appointment.total": "Viso",
  "appointment.view.title": "Rezervacijos informacija",
  "appointment.status.btn": "Rezervacijos būsena",
  "appointment.btn.cancel": "Ištrinti",
  "btn.cancelAppointment": "Ištrinti",
  "modal.cancelAppointment.title": "Ištrinti",
  "title.clients": "Klientai",
  "client.create.title": "Naujas klientas",
  "client.options.export.title": "Eksportuoti klientus",
  "client.options.export.description": "Atsisiųskite CSV su kliento duomenimis",
  "client.options.import.title": "Importuoti klientus",
  "client.export.premium.description": "Norint naudoti eksporto funkciją, reikalingas Premium planas.",
  "client.export.premium.visit": "Užsisakyti Premium",
  "client.import.instructions.description": "Jei norite importuoti klientus į \"Plandok\" paskyrą, atlikite toliau nurodytus nurodymus",
  "client.import.instructions.download": "Atsisiųskite importo failą naudodami toliau pateiktą mygtuką",
  "client.import.instructions.copy": "Nukopijuokite ir įklijuokite savo kliento duomenis į failą",
  "client.import.instructions.email": "Atsiūskite mums į info@plandok.com ir mes pridėsime klientus į jūsų \"Plandok\" paskyrą",
  "client.import.instructions.account": "",
  "table.clients.name": "Vardas",
  "table.clients.phone": "Tel. numeris",
  "table.clients.mobile": "Tel. numeris",
  "table.clients.email": "El.paštas",
  "table.clients.gender": "Lytis",
  "client.page.title": "Kliento informacija",
  "btn.more.options": "Daugiau",
  "btn.showAll": "Rodyti viską",
  "btn.collapse": "Sumažinti",
  "btn.reschedule": "Perplanuoti",
  "title.edit.client": "Redaguoti klientą",
  "menu.edit.client": "Redaguoti klientą",
  "menu.delete.client": "Ištrinti klientą",
  "title.mobilePhone": "Mobilusis telefonas",
  "title.telephoneNumber": "Telefono numeris",
  "title.email": "El. paštas",
  "title.address": "Adresas",
  "title.dateOfBirth": "Gimimo data",
  "title.gender": "Lytis",
  "client.statistics": "Statistika",
  "client.totalSales": "Viso pardavimų",
  "client.totalBookings": "Viso rezervacijų",
  "client.outstanding": "Skola",
  "client.allBooking": "Rezervacijų",
  "client.completed": "Atlikta",
  "client.cancelled": "Atšaukta",
  "client.noShow": "Neatvyko",
  "appointment.list.title": "Rezervacijos",
  "upcoming.list.title": "Ateinančios",
  "past.list.title": "Praėjusios",
  "modal.confirmClientDelete.text.0": "Ar tikrai norite visam laikui ištrinti klientą",
  "modal.confirmClientDelete.text.1": "iš duomenų bazės?",
  "input.date.label": "Iki tam tikros datos",
  "input.date.placeholder": "Pasirinkite datą",
  "input.firstName.label": "Vardas",
  "input.firstName.placeholder": "pvz.: Jonas",
  "input.lastName.label": "Pavardė",
  "input.lastName.placeholder": "pvz.: Jonaitis",
  "input.mobile.label": "Mobilus numeris",
  "input.mobile.placeholder": "Įveskite numerį",
  "input.email.label": "El. paštas",
  "input.email.placeholder": "pvz.: jonas@plandok.com",
  "input.title.label": "Pareigos",
  "input.title.placeholder": "pvz. Odontologas",
  "input.notificationType.label": "Siųsti pranešimus per",
  "tab.personalInfo": "Asmeninė informacija",
  "input.gender.label": "Lytis",
  "input.referal.label": "Srauto šaltinis",
  "input.referal.placeholder": "Pasirinkti šaltinį",
  "input.birthDay.label": "Gimimo data",
  "input.month.placeholder": "Mėnuo",
  "input.day.placeholder": "Diena",
  "input.year.placeholder": "Metai",
  "input.clientsNotes.label": "Pastabos",
  "input.clientsNotes.placeholder": "Įveskite pastabas apie klientą",
  "input.bookingDisplay.label": "Rodyti ant rezervacijų",
  "input.serviceDescription.label": "Paslaugos aprašymas",
  "input.serviceDescription.placeholder": "Pridėkite trumpą aprašymą, kuris bus matomas klientams rezervuojant paslaugą internetu",
  "input.isBookableOnline.label": "Galima užsisakyti internetu",
  "service.onlineBookings.title": "Online rezervacijos",
  "tab.address": "Adresas",
  "input.address.label": "Adresas",
  "input.address.placeholder": "Įveskite adresą",
  "input.city.label": "Miestas",
  "input.city.placeholder": "Įveskite miestą, pvz.: Vilnius",
  "input.zip.label": "Pašto kodas",
  "input.zip.placeholder": "Įveskite pašto kodą",
  "btn.edit": "Redaguoti",
  "btn.cancel": "Atšaukti",
  "btn.save": "Saugoti",
  "btn.save.changes": "Saugoti",
  "tabs.staffWorking.title": "Darbo valandos",
  "tabs.staffMembers.title": "Darbuotojai",
  "tabs.userPermissions.title": "Darbuotojų teisės",
  "tabs.closedDates.title": "Nedarbo dienos",
  "btn.new.staff": "Pridėti darbuotoją",
  "table.staff.name": "Vardas",
  "table.staff.mobileNumber": "Tel.numeris",
  "table.staff.email": "El.paštas",
  "table.staff.userPermission": "Darbuotojo teisės",
  "table.staff.dataRange": "Laikotarpis",
  "table.staff.numberOfDays": "Dienų skaičius",
  "table.staff.location": "Padalinys",
  "table.staff.description": "Aprašymas",
  "table.appointmentsList.appointment": "Rezervacija",
  "table.appointmentsList.client": "Klientas",
  "table.appointmentsList.service": "Paslauga",
  "table.appointmentsList.date": "Data",
  "table.appointmentsList.time": "Laikas",
  "table.appointmentsList.duration": "Trukmė",
  "table.appointmentsList.location": "Lokacija",
  "table.appointmentsList.staff": "Darbuotojas",
  "table.appointmentsList.price": "Kaina",
  "table.appointmentsList.status": "Būsena",
  "table.appointmentsCancellations.ref": "Ref",
  "table.appointmentsCancellations.client": "Klientas",
  "table.appointmentsCancellations.service": "Paslauga",
  "table.appointmentsCancellations.scheduledDate": "Sukūrimo data",
  "table.appointmentsCancellations.cancelledDate": "Atšaukimo data",
  "table.appointmentsCancellations.cancelledBy": "Atšaukė",
  "table.appointmentsCancellations.reason": "Priežastis",
  "table.appointmentsCancellations.price": "Kaina",
  "table.appointmentsCancellations.totalCount": "Viso",
  "table.clients.appointments": "Rezervacijos",
  "table.clients.noShows": "Neatvyko",
  "table.clients.totalSales": "Pardavimai",
  "table.clients.added": "Pridėta",
  "table.clients.daysAbsent": "Neatvyko dienų",
  "table.clients.lastAppointment": "Paskutinis vizitas",
  "table.clients.lastLocation": "Paskutinė lokacija",
  "table.appointmentsByService.serviceName": "Paslaugos pavadinimas",
  "table.appointmentsByService.appointments": "Rezervacijos",
  "table.appointmentsByService.totalValue": "Vertė",
  "table.appointmentsByStaff.staffMember": "Darbuotojas",
  "table.appointmentsByStaff.appointments": "Rezervacijos",
  "table.appointmentsByStaff.totalValue": "Vertė",
  "table.pagination.showing.title": "Rodoma",
  "table.pagination.results.title": "rezultatų",
  "clients.duplicate.title": "Rasta pasikartojančių klientų.",
  "clients.duplicate.description": "Yra panašių klientų, kuriuos galima sujungti.",
  "clients.merge.btn.title": "Sujungti",
  "clients.merge.modal.title": "Sujungti klientus",
  "clients.merge.selectAll.title": "Pasirinkite visus dublikatus",
  "clients.merge.duplicatesNotFound.title": "Dublikatų nerasta",
  "clients.merge.duplicatesNotFound.description": "Nėra klientų, kuriuos būtų galima sujungti",
  "clients.mergeConfirm.modal.title": "Patvirtinkite sujungimą",
  "clients.mergeConfirm.modal.description": "Ar tikrai norite sujungti {0} dublikatus? Šis veiksmas yra nuolatinis ir jo negalima anuliuoti.",
  "clients.mergeConfirm.checkbox.title": "Suprantu, kad klientų sujungimo anuliuoti negalima",
  "clients.mergeConfirm.btn.title": "Patvirtinti ir sujungti",
  "clients.mergeConfirm.cancel.btn.title": "Atšaukti",
  "clients.byNumber.btn.title": "Telefonu {0}",
  "clients.byEmail.btn.title": "El. paštu {0}",
  "clients.byName.btn.title": "Pagal vardą {0}",
  "title.newStaff": "Naujas darbuotojas",
  "title.editStaff": "Redaguoti darbuotoją",
  "description.extraCharge": "Pridedant naujus darbuotojus, prie mėnesinės prenumeratos bus pridėtas papildomas mokestis",
  "tabs.details": "Informacija",
  "input.staffPicture.label": "Nuotrauka",
  "input.cropImage.title": "Apkarpyti vaizdą",
  "input.cropImage.btn.apply.title": "Saugoti",
  "input.stafffirstName.label": "Vardas",
  "input.stafffirstName.placeholder": "pvz.: Jonas",
  "input.stafflastName.label": "Pavardė",
  "input.stafflastName.placeholder": "pvz.: Jonaitis",
  "input.stafmobile.label": "Mobilus numeris",
  "input.stafemail.label": "El. paštas",
  "input.stafemail.placeholder": "pvz.: jonas@plandok.com",
  "input.permission.label": "Darbuotojo teisės",
  "input.uploadProfile.label": "Įkelkite profilio nuotrauką",
  "input.changeProfile.label": "Keisti profilio nuotrauką",
  "input.notes.label": "Pastabos",
  "input.staffNotes.placeholder": "Pridėkite pastabas, matomas tik personalo nustatymuose",
  "input.blockTime.placeholder": "pvz.: susitikimas",
  "tabs.locations": "Filialai",
  "tabs.integrations": "Integracijos",
  "input.staffLocations.description": "Priskirkite filialą, kur šį darbuotoją bus galima registruoti.",
  "input.multiLocation.description": "Priskirkite filialą, kur šį darbuotoją galima registruoti.",
  "tabs.services": "Paslaugos",
  "input.staffServices.description": "Priskirkite paslaugas, kurias gali atlikti šis darbuotojas.",
  "input.multiServices.description": "Priskirkite paslaugas, kurias gali atlikti šis darbuotojas.",
  "input.shiftStart.label": "Pamainos pradžia",
  "input.shiftEnd.label": "Pamainos pabaiga",
  "edit.staff.title.resendPassword": "Slaptažodžio priminimas",
  "edit.staff.info.resendPassword": "Darbuotojas negavo el. laiško su slaptažodžiu? Spustelėkite mygtuką, ir sistema išsiųs naują el. laišką su slaptažodžių instrukcijomis.",
  "edit.staff.button.resendPassword": "Išsiųsti slaptažodį el. paštu",
  "btn.add.shift": "Pridėkite dar vieną pamainą",
  "break.time": "{0} pertrauka",
  "input.repeat.label": "Kartoti",
  "input.repeatWeekly.label": "Kiekvieną savaitę",
  "input.repeatDoNot.label": "Nekartoti",
  "input.endRepeat.label": "Kartojimo pabaiga",
  "input.endRepeatOngoing.label": "Visada",
  "input.endRepeatSpecificDate.label": "Iki tam tikros datos",
  "input.endRepeatDate.placeholder": "Pasirinkite datą",
  "btn.deleteUpcomingShift": "Ištrinkite artėjančias pamainas",
  "btn.deleteThisShift": "Ištrinti tik šią pamainą",
  "btn.updateUpcomingShift": "Atnaujinti artėjančias pamainas",
  "btn.updateThisShift": "Atnaujinti tik šią pamainą",
  "modal.repeatShift.title": "Pasikartojanti pamaina",
  "form.error.uniqShifts": "Pamainos turi būti unikalios",
  "btn.add.new": "Pridėti",
  "btn.addNewService": "Pridėti paslaugą",
  "btn.newCategory": "Pridėti kategoriją",
  "btn.newServiceGroup": "Pridėti kategoriją",
  "btn.editCategory": "Redaguoti kategoriją",
  "btn.deleteCategory": "Ištrinti kategoriją",
  "btn.downloadImportFile": "Atsisiųsti importo failą",
  "serviceGroup.delete.success": "Kategorija sėkmingai ištrinta!",
  "service.new.title": "Nauja paslauga",
  "service.edit.title": "Redaguoti paslaugą",
  "input.serviceName.label": "Paslaugos pavadinimas",
  "input.serviceName.placeholder": "Įveskite pavadinimą, pvz.: Masažas",
  "input.serviceCategory.label": "Paslaugos kategorija",
  "input.serviceCategory.placeholder": "Pasirinkite paslaugos kategoriją",
  "input.price.label": "Kaina",
  "input.price.placeholder": "0",
  "input.duration.label": "Trukmė",
  "input.duration.placeholder": "Trukmė",
  "input.staffSelect.description": "Pasirinkite šią paslaugą atliekantį darbuotoją",
  "input.newCategoryName.label": "Kategorijos pavadinimas",
  "input.newCategoryDescription.label": "Kategorijos aprašymas",
  "input.newCategoryDescription.placeholder": "Apibūdinkite šią kategoriją, ji bus rodoma užsakymuose internetu",
  "modal.newCategory.title": "Nauja kategorija",
  "modal.editCategory.title": "Redaguoti kategoriją",
  "input.chooseCategory.label": "Pasirinkite kategorijos spalvą:",
  "client.messages.title": "Pranešimai klientams",
  "tabs.messagesLog": "Pranešimų istorija",
  "tabs.messagesLog.description": "Jūsų klientams išsiųstų pranešimų sąrašas",
  "tabs.messagesSettings": "Pranešimų nustatymai",
  "tabs.messagesSettings.description": "Koreguokite pranešimų nustatymus, kad jie atitiktų jūsų verslo poreikius",
  "table.header.timeSent": "Išsiųsta",
  "table.header.client": "Klientas",
  "table.header.appointment": "Rezervacija",
  "table.header.destination": "Adresas",
  "table.header.type": "Tipas",
  "table.header.message": "Pranešimas",
  "table.header.status": "Būsena",
  "table.header.cost": "Kaina",
  "modal.message.title": "Peržiūrėti pranešimą",
  "setup.page.title": "Nustatymai",
  "title.accountSettings": "Paskyros nustatymai",
  "title.accountSetup": "Įmonės nustatymai",
  "title.accountSetup.description": "Nustatykite įmonės pavadinimą, laiko juostą, valiutą, kontaktus ir kita.",
  "resources.list.title": "Ištekliai",
  "title.resources": "Ištekliai",
  "title.resources.description": "Nustatykite rezervuojamus išteklius, tokius kaip kambariai, kėdės ar įranga",
  "title.locations": "Filialai",
  "title.locations.description": "Tvarkykite keletą savo verslo filialų.",
  "calendar.settings.title": "Kalendoriaus nustatymai",
  "calendar.btn.selectFromCalendar": "Pasirinkite iš kalendoriaus",
  "title.calendarSettings": "Kalendoriaus nustatymai",
  "title.calendarSettings.description": "Koreguokite kalendoriaus spalvų spektrą ir išdėstymą",
  "title.clientSettings": "Kliento nustatymai",
  "title.onlineBookingSettings": "Užsakymo internetu nustatymai",
  "title.salesSettings": "Pardavimo nustatymai",
  "title.receiptSequencing": "Kvitų gavimo eiliškumas",
  "title.receiptSequencing.description": "Konfigūruokite klientams išduodamuose pardavimo kvituose rodomą informaciją",
  "title.receiptTemplate": "Kvito šablonas",
  "title.taxes.description": "Tvarkykite mokesčių tarifus, taikomus kasoje parduodamoms prekėms",
  "title.paymentTypes": "Mokėjimų tipai",
  "title.paymentTypes.description": "Nustatykite rankinių mokėjimų tipus, naudotinus kasos metu",
  "title.discounts.description": "Nustatykite nuolaidas",
  "title.receiptPrefix": "Kvito Nr. Prefiksas",
  "title.receiptNumber": "Kitas kvito numeris",
  "title.change": "Pakeisti",
  "title.saleReceiptTemplate": "Pardavimo kvito šablonas",
  "description.saleReceiptTemplate": "Pritaikykite klientams išduodamuose pardavimo kvituose rodomą turinį",
  "setting.automaticallyPrint": "Automatiškai spausdinti kvitus užbaigus pardavimą",
  "setting.showMobile": "Pardavimo kvite rodyti kliento mobilųjį telefoną ir el. paštą",
  "setting.showAddress": "Pardavimo kvite rodyti kliento adresą",
  "title.receiptTitle": "Kvito pavadinimas",
  "title.referrals": "Srauto šaltiniai",
  "title.referrals.description": "Nustatykite šaltinius, kad galėtumėte sekti, kaip klientas rado jūsų verslą",
  "title.clientNotifications": "Kliento pranešimų nustatymai",
  "title.clientNotifications.description": "Tvarkykite pranešimus, siunčiamus klientams apie jų rezervacijas",
  "cancelReason.list.title": "Atšaukimo priežastys",
  "title.cancellationReasons": "Atšaukimo priežastys",
  "title.cancellationReasons.description": "Stebėkite, kodėl klientai neatvyko į susitikimus",
  "title.onlineBooking.description": "Tvarkykite kelionių užsakymo internetu prieinamumą ir nustatymus",
  "title.pointOfSale": "Pardavimai",
  "discount.list.title": "Nuolaidos",
  "title.discountTypes": "Nuolaidų tipai",
  "title.discountTypes.description": "Nustatykite rankinius nuolaidų tipus, kuriuos galima naudoti atsiskaitymo metu",
  "title.premiumFeature": "Aukščiausios kokybės funkcija",
  "company.details.title": "Įmonės informacija",
  "input.businessName.label": "Įmonės pavadinimas",
  "input.businessName.placeholder": "Įveskite įmonės pavadinimą, pvz.: Plandok",
  "input.description.label": "Aprašymas",
  "input.description.placeholder": "Įveskite įmonės aprašymą",
  "input.businessAddress.label": "Adresas",
  "input.businessAddress.placeholder": "Įveskite įmonės adresą",
  "input.website.label": "Įmonės tinklalapis",
  "input.website.placeholder": "Įveskite nuorodą",
  "input.contactNumber.label": "Kontaktinis numeris",
  "input.contactNumber.placeholder": "Įveskite numerį",
  "input.businessTimeZone.label": "Laiko zona",
  "input.businessTimeZone.placeholder": "GMT +02.00",
  "input.businessCountry.label": "Šalis",
  "input.businessCountry.placeholder": "Pasirinkite šalį",
  "input.timeFormat.label": "Laiko formatas",
  "input.timeFormat.placeholder": "24 valandos",
  "table.header.resourceName": "Išteklius",
  "btn.new.resource": "Naujas Išteklius",
  "input.resourceName.label": "Išteklių pavadinimas",
  "input.resourceName.placeholder": "Įveskite pavadinimą",
  "input.resourceDescription.label": "Aprašymas",
  "input.resourceDescription.placeholder": "Įveskite aprašymą",
  "input.resourceLocation.placeholder": "Filialo pavadinimas",
  "resourcesTooltip.location": "Location tooltip for resource",
  "modal.newResource.title": "Pridėti naują Išteklių",
  "modal.editResource.title": "Redaguoti Išteklių",
  "table.header.locationName": "Filialas",
  "table.header.locatonAddress": "Adresas",
  "table.header.location.Phone": "Tel.",
  "modal.newLocation.title": "Naujas filialas",
  "modal.editLocation.title": "Redaguoti filialą",
  "btn.new.location": "Naujas filialas",
  "btn.addExtraLocation": "Pridėti papildomą vietą",
  "input.locationName.label": "Filialo pavadinimas",
  "input.locationName.placeholder": "Įveskite filialo pavadinimą",
  "input.profile.locationName.placeholder": "Pvz.: Ingridos Salonas",
  "input.slug.label": "Nuorodos galūnė",
  "input.locationTips.label": "Patarimai dėl vietos",
  "input.slug.explanation": "Bus matoma jūsų URL nuorodoje",
  "input.contactEmail.label": "Kontaktinis el. paštas",
  "input.locationAddress.label": "Gatvė",
  "input.locationAddress.placeholder": "Įveskite gatvę",
  "tooltip.contactEmail": "Atsakymai į kliento rezervacijos n pranešimus bus siunčiami šiuo el. paštu.",
  "input.appointmentColors.label": "Rezervacijos spalva",
  "input.colorBy.service": "Pagal paslaugos kategorija",
  "input.colorBy.employee": "Pagal darbuotojo spalvą",
  "input.colorBy.appointment": "Pagal rezervacijos būseną",
  "input.timeSlot.label": "Laiko tarpo intervalas",
  "input.defaultViewType.label": "Numatytasis vaizdas",
  "input.calendarTimeRange.label": "Kalendoriaus laiko intervalas",
  "input.calendarTimeRange.allTimes.option": "Visi laikai (00:00 - 24:00)",
  "input.calendarTimeRange.onlyWorkingHours.option": "Tik darbo valandos",
  "input.calendarTimeRange.customRange.option": "Pasirinktinis diapazonas",
  "input.weekStart.label": "Savaitės pradžios diena",
  "table.header.refSourcetitle": "Srauto šaltinis",
  "table.header.refSourceAddDate": "Sukūrimo data",
  "btn.new.referral": "Naujas srauto šaltinis",
  "input.nameRefSource.label": "Šaltinio pavadinimas",
  "input.nameRefSource.placeholder": "pvz.: Facebook reklama",
  "modal.newRefSource.title": "Naujas srauto šaltinis",
  "modal.editRefSource.title": "Redaguoti srauto šaltinį",
  "input.sendBy.label": "Siųsti",
  "input.emailSubject.label": "El. laiško tema",
  "input.emailTemplate.label": "El. laiško šablonas",
  "input.emailTemplate.placeholder": "Įveskite laiško šabloną",
  "input.subject.label": "Laiško tema",
  "input.subject.placeholder": "Įveskite laiško temą",
  "title.messagingSettings.label": "Pranešimų nustatymai",
  "title.messagingSettings.description": "Pasirinkti pranešimai bus siunčiami automatiškai klientui. Galite koreguoti, kaip ir kada bus siunčiami pranešimai klientams. Redaguoti šablonus. Naudokite apačioje pateiktas žymas įtraukti apsilankymo informaciją į išraišką:",
  "client.first.name": "CLIENT_FIRST_NAME",
  "client.last.name": "CLIENT_LAST_NAME",
  "staff.first.name": "STAFF_FIRST_NAME",
  "staff.last.name": "STAFF_LAST_NAME",
  "booking.date.time": "BOOKING_DATE_TIME",
  "booking.date": "BOOKING_DATE",
  "booking.time": "BOOKING_TIME",
  "service.name": "SERVICE_NAME",
  "business.name": "BUSINESS_NAME",
  "business.phone": "BUSINESS_PHONE",
  "business.address": "BUSINESS_ADDRESS",
  "business.email": "BUSINESS_EMAIL",
  "location.name": "LOCATION_NAME",
  "location.phone": "LOCATION_PHONE",
  "location.address": "LOCATION_ADDRESS",
  "location.email": "LOCATION_EMAIL",
  "tabs.reminders.title": "Priminimas",
  "input.enableReminder.label": "Įjungti priminimo pranešimus",
  "input.enableReminder.description": "Automatiškai siunčia klientams prieš artėjantį jų apsilankymą",
  "input.reminder.title": "Priminimas {0}",
  "input.message.title": "Žinutė {0}",
  "input.reminder.description": "Kanalai ir siuntimo laika",
  "input.reminder.channels": "Pasirinkite kanalus, kuriais šis pranešimas bus siunčiamas",
  "input.reminderBefore.label": "Priminimai siunčiami likus",
  "input.smsSender.label": "SMS siuntėjo ID",
  "input.messageSender.label": "Pranešimo siuntėjo ID",
  "input.smsSender.placeholder": "Įveskite sms ID",
  "input.smsTemplate.label": "SMS šablonas",
  "input.messageTemplate.label": "Pranešimo šablonas",
  "input.smsTemplate.placeholder": "Įveskite SMS šabloną",
  "input.whatsAppSender.placeholder": "Plandok",
  "reminder.whatsApp.notificate": "\"WhatsApp\" žinutės šablonas šiuo metu neredaguojamas dėl apribojimų. Tačiau aktyviai dirbame, kad jis būtų visiškai redaguojamas.",
  "reminder.charactersCount": "Simbolių skaičius:",
  "reminder.pricing": "Kainodara",
  "modal.pricing.description": "Skirtingose šalyse taikomos skirtingos kainos. Pateikiame geriausią įmanomą mūsų partnerių tarifą. Jis gali skirtis.",
  "modal.pricing.subDescription": "Mokesčiai bus taikomi išsiuntus pranešimus ir įtraukti į būsimą sąskaitą faktūrą.",
  "modal.pricing.counterDescription": "Standartinėje SMS žinutėje gali būti tik 160 simbolių, o žinutėse su specialiais ženklais - 70 simbolių.",
  "modal.pricing.learnMore": "Sužinokite daugiau",
  "modal.pricing.priceRates": "Kainų tarifai",
  "modal.pricing.messagePrice": "1 žinutės kaina:",
  "modal.sendTestMessageByNumber.title": "Siųsti bandomąją žinutę šiuo numeriu:",
  "modal.sendTestMessageByNumber.description": "Mokesčiai taikomi kaip už įprastą žinutę.",
  "modal.sendTestMessageByEmail.title": "Siųsti bandomąją žinutę į šį el. pašto adresą:",
  "modal.sendTestMessageByEmail.placeholder": "Įveskite el. pašto adresą",
  "btn.send": "Siųsti",
  "reminder.sendTestMessage": "Siųsti bandomąjį pranešimą",
  "button.reminder.addTag": "Pridėti žymą",
  "button.reminder.addAnotherReminder": "PRIDĖK KITĄ PRIMINIMĄ",
  "button.message.addAnotherMessage": "PRIDĖTI KITĄ ŽINUTĘ",
  "button.reminder.upgrade": "Atnaujinkite, kad galėtumėte pasiekti",
  "label.emailSettings": "Email nustatymai",
  "label.SMSSettings": "SMS nustatymai",
  "label.whatsAppSettings": "WhatsApp nustatymai",
  "tooltip.sender.0": "Bus rodoma kaip SMS siuntėjo vardas",
  "tooltip.sender.1": "daugiausia 11 simbolių",
  "tooltip.sender.2": "Gali neveikti kai kuriose šalyse",
  "tooltip.sender.3": "Tik lotyniškos raidės ar skaičiai",
  "tabs.confirmations.title": "Patvirtinimas",
  "btn.appBookings": "Programėlėje esantys užsakymai",
  "btn.onlineBookings": "Užsakymai internetu",
  "input.enableAppConfirmation.label": "Įgalinti programėlėje esančius užsakymų patvirtinimus",
  "input.enableOnlineConfirmation.label": "Įjungti užsakymų internetu patvirtinimus",
  "input.enableConfirmationApp.description": "Automatiškai siunčiama klientams, kai jiems rezervuojamas vizitas",
  "input.enableConfirmationOnline.description": "Automatiškai siunčiama klientams, kai jiems rezervuojamas vizitas",
  "messageSetup.header": "Pranešimo nustatymas",
  "channels.subHeader": "Kanalai",
  "tabs.reschedules.title": "Perplanavimas",
  "input.enableReschedule.label": "Įjungti perplanavimo pranešimus",
  "input.enableReschedule.description": "Automatiškai išsiunčiama klientui, kai pasikeičia jų rezervacijos laikas",
  "tabs.cancellations.title": "Atšaukimas",
  "input.enableCancellation.label": "Įjungti atšaukimo pranešimus",
  "input.enableCancellations.description": "Automatiškai išsiunčiama klientams, kai atšaukiama jų rezervacija",
  "tabs.thanks.title": "Padėka",
  "input.enableThank.label": "Įjungti padėkos pranešimus",
  "input.enableThankyou.description": "Siunčiama klientui, kai rezervacijos būklė pasikeičia į „Atlikta“",
  "tabs.lastVisit.title": "Paskutinis vizitas",
  "input.enableLastVisit.label": "Įjungti paskutinio vizito pranešimus",
  "input.enableLastVisit.description": "Automatiškai išsiunčiama klientams, kai jie nesilankė jūsų įmonėje nurodytą dienų skaičių",
  "table.cancelReason.name": "Atšaukimo priežastys",
  "table.cancelReason.addDate": "Sukūrimo data",
  "btn.new.cancelReason": "Nauja atšaukimo priežastis",
  "modal.newCancelReason.title": "Nauja atšaukimo priežastis",
  "modal.editCancelReason.title": "Redaguoti atšaukimo priežastį",
  "input.cancelReason.title": "Atšaukimo pavadinimas",
  "input.cancelReason.placeholder": "pvz.: Klientas nepasiekiamas",
  "table.discounts.name": "Nuolaidos",
  "table.discounts.value": "Vertė",
  "table.discounts.addDate": "Sukūrimo data",
  "input.discountName.label": "Nuolaidos pavadinimas",
  "input.discountName.placeholder": "pvz.: Pensiniko nuolaida",
  "input.discountValue.label": "Nuolaidos vertė",
  "btn.new.discount": "Nauja nuolaida",
  "input.discountPercentage.label": "% Procentas",
  "input.discountSum.label": "Suma",
  "modal.newDiscount.title": "Nauja nuolaida",
  "modal.editDiscount.title": "Redaguoti nuolaidą",
  "modal.deleteAccount.title": "Ištrinti paskyrą",
  "modal.deleteAccount.description": "Paskyros ištrynimas yra negrįžtamas procesas, o visi jūsų duomenys bus pašalinti visiems laikams.",
  "modal.deleteAccount.successMessage": "Jūsų paskyra sėkmingai ištrinta.",
  "title.mySettings": "Mano nustatymai",
  "title.personalDetails": "Asmeniniai duomenys",
  "title.personalDetails.description": "Įveskite savo vardą ir kontaktinę informaciją, čia įvestas el. pašto adresas yra naudojamas prisijungimui",
  "btn.continueWithGoogle.title": "Tęsti su 'Google'",
  "btn.continueWithFacebook.title": "Tęsti su 'Facebook'",
  "notification.googleAccountUnlinked": "Google' paskyra nesusieta",
  "notification.googleAccountLinked": "Susieta 'Google' paskyra",
  "notification.facebookAccountUnlinked": "Facebook' paskyra nesusieta",
  "notification.facebookAccountLinked": "Susieta 'Facebook' paskyra",
  "btn.linkAccount": "Susieti paskyrą",
  "btn.unlinkAccount": "Atsieti paskyrą",
  "btn.deleteYourAccount": "Ištrinkite savo paskyrą",
  "input.myName.label": "Vardas",
  "input.myName.placeholder": "pvz.: Jonas",
  "input.mylastName.label": "Pavardė",
  "input.mylastName.placeholder": "pvz.: Jonaitis",
  "title.changePassword": "Keisti slaptažodį",
  "title.changePassword.description": "Norėdami atnaujinti, įveskite esamą slaptažodį, o paskui naują. Jei nežinote savo esamo slaptažodžio, galite atsijungti ir naudoti nuorodą „Priminti slaptažodį“, esančią prisijungimo puslapyje.",
  "input.currentPassword.label": "Dabartinis slaptažodis",
  "label.currentPassword.placeholder": "Įveskite dabartinį slaptažodį",
  "input.newPassword.label": "Naujas slaptažodis",
  "input.newPassword.placeholder": "Įveskite naują slaptažodį",
  "input.verifyPassword.label": "Pakartokite slaptažodį",
  "input.verifyPassword.placeholder": "Pakartokite naują slaptažodį",
  "btn.confirmDelete": "Taip, ištrinti dabar",
  "btn.confirm": "Patvirtinti",
  "btn.confirmBook": "Patvirtinkite ir rezervuokite",
  "btn.confirmPay": "Patvirtinkite ir mokėkite",
  "btn.delete": "Ištrinti",
  "btn.close": "Uždaryti",
  "validation.required": "Prašome užpildyti",
  "validation.email.format": "Neteisingas el. pašto formatas",
  "validation.url.format": "Neteisingas url formatas, turi prasidėti https:// arba http://",
  "validation.password.length": "Slaptažodis turi būti bent 7 simbolių",
  "validation.password.letters": "Slaptažodyje turi būti skaičiai ir raidės",
  "validation.startTime": "Pradžios laikas negali būti vėlesnis arba sutapti su pabaigos laiku",
  "validation.endTime": "Pabaigos laikas negali būti ankstesnis arba sutapti su pradžios laiku",
  "tooltip.password.title": "Slaptažodis turi:",
  "tooltip.password.characters": "Turėti bent 7 simbolius",
  "tooltip.password.number": "Turėti skaičių",
  "tooltip.password.letter": "Turėti raidę",
  "tooltip.location": "Location tooltip",
  "footer.support.center": "Reikia pagalbos? Susisiekite ",
  "not.found": "Nerasta",
  "input.cancelReason.label": "Atšaukimo priežastis",
  "input.location.label": "Filialas",
  "input.location.placeholder": "Filialo pavadinimas",
  "input.name.label": "Pavadinimas",
  "title.recentlyAddedClients": "Klientai (neseniai pridėti)",
  "title.calendarView": "Kalendoriaus vaizdas",
  "text.manageProfile": "Tvarkykite savo vartotojo profilį",
  "modal.language.title": "Pasirinkite kalbą",
  "modal.language.placeholder": "Paieškos kalba",
  "dropdown.access.medium": "Vidutinis",
  "dropdown.access.high": "Aukštas",
  "dropdown.access.low": "Žemas",
  "dropdown.access.basic": "Bazinis",
  "dropdown.access.no": "Nėra teisių",
  "dropdown.access.owner": "Savininkas",
  "button.select.all": "Pasirinkti visus",
  "button.selected": "Pasirinkta",
  "dropdown.gender.unknown": "Nežinoma",
  "dropdown.gender.male": "Vyras",
  "dropdown.gender.female": "Moteris",
  "dropdown.notificationBy.none": "Nesiųsti",
  "dropdown.notificationBy.sms": "SMS",
  "dropdown.notificationBy.email": "El.paštu",
  "dropdown.notificationBy.combined": "SMS ir el. paštu",
  "notification.success.login": "Sėkmingai prisijungėte!",
  "notification.success.register": "Sėkmingai užsiregistravote!",
  "dropdown.time.minutes": "minutės",
  "dropdown.repeat.no": "Nekartoti",
  "dropdown.repeat.weekly": "Kas savaitę",
  "dropdown.endRepeat.ongoing": "Visada",
  "dropdown.endRepeat.weekly": "Iki tam tikros datos",
  "dropdown.time.appointment.immediately": "Nedelsiant",
  "dropdown.time.appointment.upTo1Hour": "Iki 1 valandos",
  "dropdown.time.appointment.upTo2Hours": "Iki 2 valandų",
  "dropdown.time.appointment.upTo3Hours": "Iki 3 valandų",
  "dropdown.time.appointment.upTo4Hours": "Iki 4 valandų",
  "dropdown.time.appointment.upTo5Hours": "Iki 5 valandų",
  "dropdown.time.appointment.upTo6Hours": "Iki 6 valandų",
  "dropdown.time.appointment.upTo7Hours": "Iki 7 valandų",
  "dropdown.time.appointment.upTo8Hours": "Iki 8 valandų",
  "dropdown.time.appointment.upTo9Hours": "Iki 9 valandų",
  "dropdown.time.appointment.upTo10Hours": "Iki 10 valandų",
  "dropdown.time.appointment.upTo11Hours": "Iki 11 valandų",
  "dropdown.time.appointment.upTo12Hours": "Iki 12 valandų",
  "dropdown.time.appointment.upTo13Hours": "Iki 13 valandų",
  "dropdown.time.appointment.upTo14Hours": "Iki 14 valandų",
  "dropdown.time.appointment.upTo15Hours": "Iki 15 valandų",
  "dropdown.time.appointment.upTo16Hours": "Iki 16 valandų",
  "dropdown.time.appointment.upTo17Hours": "Iki 17 valandų",
  "dropdown.time.appointment.upTo18Hours": "Iki 18 valandų",
  "dropdown.time.appointment.upTo19Hours": "Iki 19 valandų",
  "dropdown.time.appointment.upTo20Hours": "Iki 20 valandų",
  "dropdown.time.appointment.upTo21Hours": "Iki 21 valandos",
  "dropdown.time.appointment.upTo22Hours": "Iki 22 valandų",
  "dropdown.time.appointment.upTo23Hours": "Iki 23 valandų",
  "dropdown.time.appointment.upTo24Hours": "Iki 1 dienos",
  "dropdown.time.appointment.upTo48Hours": "Iki 2 dienų",
  "dropdown.time.appointment.upTo3days": "Iki 3 dienų",
  "dropdown.time.appointment.upTo4days": "Iki 4 dienų",
  "dropdown.time.appointment.upTo5days": "Iki 5 dienų",
  "dropdown.time.appointment.upTo6days": "Iki 6 dienų",
  "dropdown.time.appointment.upTo1week": "Iki 1 savaitės",
  "dropdown.time.appointment.upTo2weeks": "Iki 2 savaičių",
  "dropdown.time.appointment.upTo15Min": "Iki 15 minučių",
  "dropdown.time.appointment.upTo30Min": "Iki 30 minučių",
  "dropdown.time.appointment.upTo1Month": "Iki 1 mėnesio",
  "dropdown.time.appointment.upTo2Months": "Iki 2 mėnesių",
  "dropdown.time.appointment.upTo3Months": "Iki 3 mėnesių",
  "dropdown.time.appointment.upTo4Months": "Iki 4 mėnesių",
  "dropdown.time.appointment.upTo5Months": "Iki 5 mėnesių",
  "dropdown.time.appointment.upTo6Months": "Iki 6 mėnesių",
  "dropdown.time.appointment.interval5Min": "5 minutes",
  "dropdown.time.appointment.interval10Min": "10 minučių",
  "dropdown.time.appointment.interval15Min": "15 minučių",
  "dropdown.time.appointment.interval20Min": "20 minučių",
  "dropdown.time.appointment.interval30Min": "30 minučių",
  "dropdown.time.appointment.interval40Min": "40 minučių",
  "dropdown.time.appointment.interval45Min": "45 minučių",
  "dropdown.time.appointment.interval50Min": "50 minučių",
  "dropdown.time.appointment.interval60Min": "60 minučių",
  "dropdown.time.appointment.interval75Min": "75 minutes",
  "dropdown.time.appointment.interval90Min": "90 minučių",
  "dropdown.time.appointment.interval120Min": "120 minučių",
  "dropdown.time.appointment.interval150Min": "150 minučių",
  "dropdown.time.appointment.interval180Min": "180 minučių",
  "dropdown.time.appointment.interval240Min": "240 minučių",
  "dropdown.time.appointment.anyTime": "Bet kada",
  "dropdown.time.appointment.disabled": "Niekada",
  "dropdown.time.appointment.doesntRepeat": "Nekartoti",
  "dropdown.time.appointment.daily": "Kasdien",
  "dropdown.time.appointment.every2Days": "Kas 2 dienas",
  "dropdown.time.appointment.every3Days": "Kas 3 dienas",
  "dropdown.time.appointment.every4Days": "Kas 4 dienas",
  "dropdown.time.appointment.every5Days": "Kas 5 dienas",
  "dropdown.time.appointment.every6Days": "Kas 6 dienas",
  "dropdown.time.appointment.every7Days": "Kas 7 dienas",
  "dropdown.time.appointment.weekly": "Kas savaitę",
  "dropdown.time.appointment.every2weeks": "Kas 2 savaites",
  "dropdown.time.appointment.every3weeks": "Kas 3 savaites",
  "dropdown.time.appointment.every4weeks": "Kas 4 savaites",
  "dropdown.time.appointment.every5weeks": "Kas 5 savaites",
  "dropdown.time.appointment.every6weeks": "Kas 6 savaites",
  "dropdown.time.appointment.every7weeks": "Kas 7 savaites",
  "dropdown.time.appointment.every8weeks": "Kas 8 savaites",
  "dropdown.time.appointment.every9weeks": "Kas 9 savaites",
  "dropdown.time.appointment.every10weeks": "Kas 10 savaičių",
  "dropdown.time.appointment.monthly": "Kas mėnesį",
  "dropdown.time.appointment.every2months": "Kas 2 mėnesius",
  "dropdown.time.appointment.every3months": "Kas 3 mėnesius",
  "dropdown.time.appointment.every4months": "Kas 4 mėnesius",
  "dropdown.time.appointment.every5months": "Kas 5 mėnesius",
  "dropdown.time.appointment.every6months": "Kas 6 mėnesius",
  "dropdown.time.appointment.every7months": "Kas 7 mėnesius",
  "dropdown.time.appointment.every8months": "Kas 8 mėnesius",
  "dropdown.time.appointment.every9months": "Kas 9 mėnesius",
  "dropdown.time.appointment.every10months": "Kas 10 mėnesių",
  "dropdown.time.appointment.every11months": "Kas 11 mėnesių",
  "dropdown.time.appointment.after2times": "Po 2 kartų",
  "dropdown.time.appointment.after3times": "Po 3 kartų",
  "dropdown.time.appointment.after4times": "Po 4 kartų",
  "dropdown.time.appointment.after5times": "Po 5 kartų",
  "dropdown.time.appointment.after6times": "Po 6 kartų",
  "dropdown.time.appointment.after7times": "Po 7 kartų",
  "dropdown.time.appointment.after8times": "Po 8 kartų",
  "dropdown.time.appointment.after9times": "Po 9 kartų",
  "dropdown.time.appointment.after10times": "Po 10 kartų",
  "dropdown.time.appointment.after11times": "Po 11 kartų",
  "dropdown.time.appointment.after12times": "Po 12 kartų",
  "dropdown.time.appointment.after13times": "Po 13 kartų",
  "dropdown.time.appointment.after14times": "Po 14 kartų",
  "dropdown.time.appointment.after15times": "Po 15 kartų",
  "dropdown.time.appointment.after20times": "Po 20 kartų",
  "dropdown.time.appointment.after25times": "Po 25 kartų",
  "dropdown.time.appointment.after30times": "Po 30 kartų",
  "dropdown.time.appointment.specificDate": "Konkreti datą",
  "modal.createHours.title": "{0} darbo valandos",
  "modal.editHours.title": "Redaguoti {0} darbo valandas",
  "modal.deleteItem.confirm.0": "Ar tikrai norite visam laikui ",
  "modal.deleteItem.confirm.1": "ištrinti",
  "modal.deleteItem.confirm.2": "iš duomenų bazės?",
  "modal.entity.category": "Kategorija",
  "modal.entity.referal": "Srauto šaltinis",
  "modal.entity.resource": "Išteklius",
  "modal.entity.location": "Filialas",
  "modal.deleteReminder.title": "Ištrinti priminimą",
  "modal.deleteMessage.title": "Ištrinti žinutę",
  "modal.deleteReminder.description": "Ar tikrai norite ištrinti šį priminimą ?",
  "modal.deleteMessage.description": "Ar tikrai norite ištrinti šią žinutę ?",
  "modal.deleteReminder.confirm": "Taip, ištrinkite",
  "modal.deletePaymentMethod.title": "Ištrinti mokėjimo būdą",
  "modal.deletePaymentMethod.description": "Šis mokėjimo būdas bus visam laikui ištrintas",
  "closedDate.create.modal.title": "Pridėti nedarbo dieną",
  "closedDate.edit.modal.title": "Redaguoti nedarbo dieną",
  "staff.access.owner": "Savininkas",
  "staff.access.high": "Aukštas",
  "staff.access.medium": "Vidutinis",
  "staff.access.low": "Žemas",
  "staff.access.basic": "Bazinis",
  "staff.access.no_access": "Nėra teisių",
  "staff.create.title": "Pridėti nedarbo dieną",
  "staff.emptyState.title": "Šią dieną nėra dirbančiųjų",
  "staff.emptyState.description": "Norėdami pridėti darbo valandas, tai galite padaryti \"Darbuotojų\" skiltyje",
  "staff.viewStaffMembers.button": "Darbuotojų skiltis",
  "staff.picture.changePhoto": "Keisti nuotrauką",
  "staff.picture.removePhoto": "Pašalinti nuotrauką",
  "input.clientSearch.placeholder": "Ieškokite pagal vardą, el. paštą ar tel. numerį",
  "input.blockedDate.label": "Data",
  "appointment.notes.title": "Rezervacijos pastabos",
  "appointment.history.title": "Rezervacijos istorija",
  "appointment.history.by.title": " pagal {0}",
  "appointment.log.created.label": "Sukurė",
  "appointment.log.updated.label": "Atnaujino",
  "appointment.log.rescheduled.label": "Redagavo",
  "appointment.log.cancelled.label": "Atšaukė",
  "appointment.log.changed.label": "Pakeitė būseną",
  "appointment.notificationLog.rescheduling": "Perplanavimo pranešimas išsiūstas",
  "appointment.notificationLog.cancellation": "Atšaukimo pranešimas išsiūstas",
  "appointment.notificationLog.confirmation": "Patvirtinimo pranešimas išsiūstas",
  "appointment.notificationLog.reminder": "Priminimo pranešimas išsiūstas",
  "appointment.notificationLog.thankYou": "Padėkos pranešimas išsiūstas",
  "appointment.notificationLog.lastVisit": "Paskutinio vizito pranešimas išsiūstas",
  "state.cancelReason.title": "Nustatykite atšaukimo priežastis",
  "state.cancelReason.description": "Atšaukimo priežastys padeda žinoti statistiškai, kodėl jūsų klientai neatvyko į apsilankymus.",
  "state.cancelReason.button": "Pridėti atšaukimo priežastį",
  "state.resources.title": "Tvarkykite savo išteklius",
  "state.resources.description": "Verslas yra sėkmingesnis, kai žinote apie turimus išteklius. Sekite kambarius, įrankius ar bet kokius kitus prietaisus, kad užtikrintumėte sklandų aptarnavimą.",
  "state.resources.button": "Pridėti išteklių",
  "state.referralSource.title": "Tvarkykite atvykimo šaltinius",
  "state.referralSource.description": "Atvykimo šaltinis padeda atsekti kokiais būdais klientai rado jūsų verslą. Kaupkite šią informaciją, kad galėtumėte padidinti savo verslo matomumą investuojant į veikiančius rinkodaros kanalus.",
  "state.referralSource.button": "Pridėti atvykimo šaltinį",
  "state.locations.title": "Nustatykite filialus",
  "state.locations.description": "Čia galite valdyti kelis savo verslo filialus, nustatyti skirtingą darbuotojų darbo laiką kiekviename filiale ir kiekvienas filialas turės atskirus kalendorius.",
  "state.staff.title": "Valdykite personalą",
  "state.staff.description": "Susikurkite savo darbuotojų profilius, kad galėtumėte pridėti ir redaguoti jų tvarkaraščius, taip pat stebėdami jų rezervacijas ir darbo valandas.",
  "state.messageLog.title": "Sekite pranešimus klientams",
  "state.messageLog.description": "Žinutės primena klientams apie artėjančius apsilankymus arba informuoja juos apie pasikeitimus. Čia galėsite sekti pranešimus ir jų būseną. Norėdami sukonfigūruoti automatinius pranešimus, eikite į",
  "state.messageLog.link": "pranešimų nustatymus.",
  "state.clients.title": "Valdykite savo klientus",
  "state.clients.description": "Rūpinimasis savo klientais pirmiausia prasideda įsitikinant, kad jų profiliuose yra visa reikalinga informacija sklandžiam kokybiškų paslaugų suteikimui ir patogiam susisiekimui.",
  "state.clients.button": "pridėti klientą",
  "state.services.title": "Nustatykite paslaugas ir jų kategorijas",
  "state.services.description": "Kategorijos yra būtinos, nes jos leidžia sugrupuoti panašias jūsų teikiamas paslaugas. Be to, jos daro jūsų kalendorių švarų ir lengvai prieinamą!",
  "state.services.button": "Pridėti paslaugų kategoriją",
  "state.calendar.title": "Nėra dirbančio personalo",
  "state.calendar.description": "Norėdami nustatyti darbuotojų darbo valandas, žiūrėkite",
  "state.calendar.button": "darbuotojų skiltį.",
  "state.closedDates.title": "Nedarbo diena",
  "state.closedDates.description": "Pažymėkite datas, kada jūsų verslas nedirba. Pvz.: valstybinė šventė ar kitos priežastys. Šiomis dienomis klientai negalės atlikti online rezervacijų, o kalendoriuje dienos bus rodoms pilkai.",
  "state.closedDates.button": "Pridėti nedarbo dieną",
  "title.billingPlan": "Planas ir atsiskaitymai",
  "btn.upgrade": "Aktyvuok",
  "header.usage": "NAUDOJIMAS",
  "header.dropdown.subscription.plan": "{0} Planas",
  "header.dropdown.subscription.staff": "({0} Darbuotojai)",
  "header.dropdown.notifications.description": "{0} pranešimai",
  "header.paymentDetails": "Peržiūrėti mokėjimo informaciją",
  "title.billingPlan.description": "Tvarkykite savo paskyros planą, atsiskaitymus, matykite mokėjimų operacijas ir atsisiųskite sąskaitas-faktūras.",
  "plan.free.title": "Nemokamas",
  "plan.individual.title": "Individualus (1 darbuotojas)",
  "plan.business.title": "Verslas",
  "btn.upgradeNow": "Aktyvuoti",
  "btn.getStarted": "Pradėkite",
  "btn.currentPlan": "Dabartinis planas",
  "plan.free.option.0": "1 Lokacija",
  "plan.free.option.1": "Iki 5 darbuotojų",
  "plan.free.option.2": "Neribotas rezervacijų k.",
  "plan.free.option.3": "Neribotas klientų kiekis",
  "plan.free.option.4": "Neribotos online rezervacijos",
  "plan.free.option.5": "Pranešimai el.paštu",
  "plan.premium.option.0": "Neribotos lokacijos",
  "plan.premium.option.1": "Neribotas rezervacijų k.",
  "plan.premium.option.2": "Neribotas klientų kiekis",
  "plan.premium.option.3": "Neribotos online rezervacijos",
  "plan.premium.option.4": "Pasikartojančios rezervacijos",
  "plan.premium.option.5": "Pranešimai el.paštu",
  "plan.premium.option.6": "SMS pranešimai* ",
  "plan.premium.option.7": "WhatsApp pranešimai*",
  "plan.premium.option.8": "Neribota Analitika",
  "plan.premium.option.9": "Neribotos Ataskaitos",
  "plan.premium.option.10": "Grafikų spausdinimas",
  "plan.premium.option.11": "Klientų eksportas",
  "plan.premium.option.12": "Prioritetinė pagalba",
  "plan.premium.option.13": "Pašalintas „Plandok“ prekės ženklas iš el.laiškų (nemokami el.laiškai, su „Plandok\")",
  "plan.premium.costsInfo": "*SMS/WhatsApp išlaidos, neįtrauktos į planą, taikomi papildomi mokesčiai.",
  "plan.premium.activeUntilDate": "Aktyvus iki {0}",
  "period.perMonth": "per mėnesį (be PVM)",
  "plan.premium.description": "Kaina padidės pridėjus daugiau darbuotojų.",
  "plan.premium.viewPricing": "Peržiūrėti kainodarą",
  "plan.premium.numberStuff": "Jūsų dabartinis darbuotojų skaičius: {0}",
  "premium.membership": "Premium narystės kaina gali skirtis priklausomai nuo jūsų darbuotojų skaičiaus.",
  "premium.price.perMonth": "Premium mėnesio kaina",
  "pricing.calculated": "Kaina apskaičiuojama taip:",
  "pricing.calculator": "Kainų skaičiuoklė",
  "pricing.numberStuff": "Darbuotojų skaičius",
  "pricing.extra.member": "{0} už kiekvieną papildomą narį",
  "modal.title.pricing": "Kainodara",
  "plan.foreverFree": "Visam laikui nemokamai",
  "plan.individual.0": "Viskas iš Nemokamo, plius:",
  "plan.individual.1": "Individualizuoti pranešimai",
  "plan.individual.2": "Pašalintas „Plandok“ ženklas",
  "plan.individual.option.3": "Neriboti SMS priminimai",
  "plan.individual.4": "Prioritetinė pagalba",
  "plan.business.0": "Viskas iš Individualus, plius:",
  "plan.business.1": "Neriboti filialai",
  "plan.business.2": "Neribotas personalo skaičius",
  "block.billingInfo.title": "Mokėtojo duomenys",
  "block.carInfo.title": "Kortelės duomenys",
  "input.cardNumber.label": "Kortelės numeris",
  "input.cardNumber.placeholder": "1235 5678 3456 2456",
  "input.expireDate.label": "Galiojimo laikas",
  "input.expireDate.placeholder": "05 / 21",
  "input.cvv.label": "CVV",
  "input.cvv.placeholder": "123",
  "modal.confirmCancel.subscription": "Ar tikrai norite atšaukti prenumeratą?",
  "modal.confirmCancel.subscription.description": "Kai tai padarysite, grįšite į nemokamą versiją.",
  "btn.cancelSubscription.confirm": "Taip, atšaukti prenumeratą",
  "loading.processingSubscribtion": "Mokėjimas apdorojamas. Prašome palaukti...",
  "state.serviceGroup.description": "Grupėje nėra paslaugų",
  "state.serviceGroup.btn": "Pridėti paslaugą",
  "appointment.status.new": "Nauja rezervacija",
  "appointment.status.confirmed": "Patvirtinta",
  "appointment.status.notShow": "Neatvyko",
  "appointment.status.completed": "Atlikta",
  "appointment.status.cancelled": "Atšauktas",
  "billing.tab.plan": "Planas",
  "billing.tab.costs": "Numatomos išlaidos",
  "billing.tab.billing": "Rekvizitai",
  "billing.tab.invoice": "Sąskaitos",
  "plan.free": "Nemokamas",
  "plan.deactivated.title": "\"Premium\" buvo deaktyvuota",
  "plan.active.title": "Aktyvuoti",
  "payment.step.title": "Mokėjimai",
  "payment.error.title": "Atnaujinkite mokėjimą",
  "payment.payNow.title": "Apmokėti ",
  "payment.dismiss.title": "Paslėpti ",
  "payment.tab.title.sub": "Prenumerata",
  "payment.tab.subTitle": "Premium planas",
  "payment.per.members": "vienam darbuotojui",
  "payment.tab.title.notification": "Pranešimai",
  "payment.card.details": "Kortelės duomenys",
  "payment.success.message": "Mokėjimas sėkmingas",
  "payment.payWithCard.title": "Mokėti kortele",
  "payment.cardNumber.title": "Kortelės numeris",
  "payment.expirationDate.title": "Galiojimo data",
  "payment.digits.title": "(3 skaitmenys)",
  "payment.amount": "Mokėjimo suma:",
  "payment.btn.pay": "Mokėti",
  "invoice.table.date": "Data",
  "invoice.table.invoice": "Sąskaita",
  "invoice.table.download": "Parsisiųsti",
  "invoice.table.status": "Būsena",
  "costs.billingPeriod.title": "Apskaičiuotos šio atsiskaitymo laikotarpio išlaidos",
  "costs.billingPeriod.description": "Tai yra dabartiniai šio atsiskaitymo laikotarpio naudojimo mokesčiai. Toliau pateikiamas išlaidų suskirstymas.",
  "costs.billingPeriod.additionalInfo": "Kai jūsų išlaidos pranešimams pasieks 50 €, jos bus apmokestintos automatiškai. Tačiau nesijaudinkite, jei suma bus mažesnė; ji bus patogiai įtraukta į jūsų mėnesio prenumeratą.",
  "costs.summary.title": "Mėnesio iki datos suvestinė",
  "costs.summary.description": "Šie mokesčiai įtraukiami į jūsų sąskaitos likutį",
  "costs.summary.detailedCharges": "Detalūs mokesčiai",
  "costs.summary.noData": "Nėra duomenų",
  "costs.summary.quantity": "Kiekis",
  "costs.summary.subtotal": "Tarpinė suma",
  "costs.summary.taxes": "Mokesčiai",
  "dropdown.time.hours": "valandos",
  "message.planUpgrade": "Jūs sėkmingai atnaujinote savo planą!",
  "costs.subscription": "Prenumerata",
  "btn.cancelSubscription": "Atšaukti planą",
  "btn.no": "Ne",
  "notification.success.update": "Sėkmingai atnaujinta",
  "validation.password.match": "Slaptažodžiai nesutampa",
  "blocked.time": "Blokuotas laikas",
  "message.successReset.password": "El. laiškas dėl slaptažodžio atstatymo išsiųstas. Patikrinkite savo el. paštą.",
  "title.newPassword": "Įveskite naują slaptažodį",
  "title.newPassword.description": "Saugiai junkites prie paskyros sukurdami naują prisijungimo slaptažodį",
  "input.newPasswordRepeat.label": "Patvirtinkite savo naują slaptažodį",
  "btn.change.password": "Pakeisti mano slaptažodį",
  "text.havingTrouble": "Turite problemų?",
  "link.resetPassword.again": "Atstatykite savo slaptažodį dar kartą",
  "message.successNew.password": "Slaptažodis sėkmingai pakeistas.",
  "title.repeatShifts": "Pasikartojanti pamaina",
  "text.confirmShift.update": "Redagavote pamainą, kuri kartojasi kas savaitę. Ištrynus būsimas pamainas bus perrašytas {0} esamas {0} tvarkaraštis",
  "text.confirmShift.delete": "Jūs trinate pamainą, kuri kartojasi kas savaitę. Ištrynus būsimas pamainas bus perrašytas {0} esamas {0} tvarkaraštis",
  "btn.deleteUpcoming.shifts": "Ištrinti artėjančias pamainas",
  "btn.deleteThis.shift": "Ištrinti tik šią pamainą",
  "btn.updateUpcoming.shifts": "Atnaujinti artėjančias pamainas",
  "btn.updateThis.shift": "Atnaujinti tik šią pamainą",
  "text.permisionTab": "Nustatykite, kurios skiltys prieinamos kiekvienam vartotojo lygiui. Visi prisijungę darbuotojai gali pasiekti kalendorių, o savininko paskyra turi visišką prieigą prie visos sistemos.",
  "title.permission.locations": "Filialai",
  "title.permission.booking": "Rezervacijos ir klientai",
  "title.permission.staff": "Darbuotojai",
  "title.permission.setup": "Nustatymai",
  "title.permission.basic": "Bazinis",
  "title.permission.low": "Žemas",
  "title.permission.med": "Vidutinis",
  "title.permission.high": "Aukštas",
  "title.permission.owner": "Savininkas",
  "title.allLocations.permission": "Pasiekti visus filialus",
  "title.accessCalendar.permission": "Matyti savo kalendorių",
  "title.accessOtherCalendar.permission": "Matyti kitų darbuotojų kalendorius",
  "title.canBook.permission": "Kurti rezervacijas",
  "title.contactInfo.permission": "Matyti kliento kontaktinę informaciją",
  "title.clients.permission": "Klientai",
  "title.messages.permission": "Pranešimai",
  "title.services.permission": "Paslaugos",
  "title.workingHours.permission": "Darbo valandos",
  "title.staffMembers.permission": "Darbuotojai",
  "title.permissionLevels.permission": "Darbuotojų teisės",
  "title.accountSetup.permission": "Paskyros nustatymai",
  "title.clientSettings.permission": "Kliento nustatymai",
  "title.onlineBookings.permission": "Online registracijos",
  "title.analytics.permission": "Analitika",
  "title.marketing.permission": "Rinkodara",
  "notification.type.reminder": "Priminimas",
  "notification.type.confirmation": "Patvirtinimas",
  "notification.type.online_booking_confirmation": "Užsakymo patvirtinimas internetu",
  "notification.type.rescheduling": "Perplanavimas",
  "notification.type.cancellation": "Atšaukimas",
  "notification.type.thank_you": "Padėka",
  "notification.type.last_visit": "Paskutinis vizitas",
  "notification.channel.sms": "SMS",
  "notification.channel.email": "El. paštas",
  "notification.channel.whatsapp": "WhatsApp",
  "notification.status.sent": "Išsiųsta",
  "notification.status.pending": "In attesa",
  "notification.status.failed": "Nepavyko",
  "dropdown.time.days": "dienos",
  "title.appointment": "rezervacija",
  "input.reminderBeforeLastVisit.label": "Pranešimas bus išsiųstas po nurodyto dienų skaičiaus",
  "txt.with": "su",
  "short.hours": "val",
  "short.minutes": "min",
  "input.registrationNumber.label": "Įmonės kodas",
  "input.vatNumber.label": "PVM kodas",
  "invoice.table.priceTaxIncl": "Suma, su PVM",
  "error.wrongSenderId": "Siuntėjo ID turi sudaryti skaitmenys arba simboliai, ilgis turi būti didesnis nei 3 ir mažesnis nei 11 simbolių",
  "sidebar.help": "Pagalba",
  "modal.help.title": "Kaip galime padėti?",
  "modal.help.liveChat.title": "Pokalbiai gyvai",
  "modal.help.liveChat.description": "Turite klausimų? Klauskite mūsų iš karto",
  "modal.help.suggestFeature.title": "Pasiūlykite funkciją",
  "modal.help.suggestFeature.description": "Dalinkitės pasiūlymais, peržiūrėkite kitų pasiūlymus ir balsuokite už norimas funkcijas",
  "modal.help.viewUpdates.title": "Peržiūrėkite atnaujinimus",
  "modal.help.viewUpdates.description": "Peržiūrėkite naujausius atnaujinimus ir patobulinimus",
  "warning.title.paidFeature": "Mokama funkcija",
  "warning.smsFeatureMissing": "Kad galėtumėte siųsti SMS, Jums reikia užsisakyti atitinkamą planą šioje skiltyje",
  "warning.link.billingAndPricing": "Planas ir mokėjimai.",
  "warning.newPage.0": "atsinaujino!",
  "warning.newPage.1": "Jeigu naudojotės sena Plandok sistema, jūsų slaptažodis nebeveiks, prašome",
  "warning.newPage.2": "pasikeisti slaptažodį",
  "warning.newPage.3": "kad galėtumėte mėgautis nauja sistema. Dėl papildomų klausimų kreipkitės",
  "warning.newPage.4": "arba",
  "title.blockTime.select": "Pasirinkite laiką",
  "calendar.allResources": "Visi ištekliai",
  "appointment.edit.title": "Redaguoti rezervaciją",
  "appointment.create.title": "Paskyrimas sėkmingai sukurtas.",
  "appointment.update.title": "Paskyrimas sėkmingai atnaujintas.",
  "select.notify.title": "{0} nedirba pasirinktu intervalu, tačiau vis tiek galite registruoti.",
  "booking.notify.title": "Pasirinktas laikas yra rezervuotas, norėdami vistiek galite sukurti rezervaciją.",
  "textArea.characters.left": "simbolių liko",
  "textArea.characters.exceeded": "riba viršyta",
  "validation.characters.exceeded": "Simbolių limitas viršytas",
  "staff.menu.members": "Darbuotojai",
  "staff.menu.dates": "Nedarbo dienos",
  "staff.menu.working": "Darbo valandos",
  "staff.menu.permissions": "Darbuotojų teisės",
  "staff.menu.members.description": "Pridėkite arba pašalinkite darbuotojus, valdykite jų prieigos lygį",
  "staff.menu.dates.description": "Žymėkite nedarbo dienas ir valstybines šventes",
  "staff.menu.working.description": "Kontroliuokite darbuotojų darbo grafikus",
  "staff.menu.permissions.description": "Nustatykite, kurios skiltys prieinamos kiekvienam vartotojo lygiui",
  "message.successUpdateLocation": "Atlikta! Vietovės profilis sėkmingai atnaujintas.",
  "ob.title": "Online rezervacijos",
  "ob.overview.tab": "Apžvalga",
  "ob.massMessages.tab": "Masiniai pranešimai",
  "ob.campaigns.tab": "Kampanijos",
  "ob.profile.tab": "Online profilis",
  "ob.links.tab": "Nuorodos",
  "ob.settings.tab": "Nustatymai",
  "ob.payments.tab": "Mokėjimai",
  "ob.status.online": "Įjungta",
  "ob.status.offline": "Išjungta",
  "ob.status.title": "Būsena",
  "ob.profile.step": "{0} iš 4",
  "ob.profile.step.title": "Profilis ir vieta",
  "ob.profile.step.description": "Pristatykite savo veiklą ir teikiamas paslaugas, būtinai palikite telefono numerį, kuriuo klientai gali susisiekti su jumis, kad gautų daugiau informacijos! Įveskite padalinio adresą, kad klientai žinotų, kur jus rasti.",
  "ob.overview.placheholder.title": "Leiskite klientams registruotis internetu!",
  "ob.overview.placheholder.description": "Sukurkite savo verslo profilį ir leiskite klientams registruotis internetu. Nuorodą galima įkelti į savo svetainę ar soc. media paskyrą, pvz „Facebook“, „Instagram“. ",
  "ob.overview.start.now": "Pradėti",
  "ob.links.direct.title": "Nuoroda nukopijuota!",
  "ob.links.facebook.title": "Facebook nuoroda",
  "ob.links.instagram.title": "Instagram nuoroda",
  "ob.links.direct.description": "Viskas labai paprasta, tiesiog naudokite šią nuorodą kur jums patogu. Ar tai būtų jūsų svetainė, ar kokia kita svetainė, per šią nuorodą klientas pasieks jūsų online profilį ir galės užsiregistruoti pats.",
  "ob.links.facebook.description": "Čia rasite instrukciją, kaip įsidėti nuorodą į savo „Facebook“ paskyrą, kad klientai galėtų užsiregistruoti tiesiai iš „Facebook“",
  "ob.links.instagram.description": "Viskas labai paprasta, tiesiog naudokite šią nuorodą kur jums patogu. Ar tai būtų jūsų svetainė, ar kokia kita svetainė, per šią nuorodą klientas pasieks jūsų online profilį ir galės užsiregistruoti pats.",
  "ob.analytics.tab.description": "Pridėkite \"Google Analytics\" ir \"Facebook Pixel\" į savo internetinių užsakymų puslapį, kad galėtumėte analizuoti srautą ir naudotojų elgseną.",
  "ob.analytics.google.title": "Google Analytics",
  "ob.analytics.facebook.title": "Facebook Pixel",
  "ob.analytics.google.description": "Naudodamiesi \"Google Analytics\" galite stebėti ir analizuoti svetainės srautą ir naudotojų elgseną, kad gautumėte įžvalgų, kaip patobulinti svetainę.",
  "ob.analytics.active": "Aktyvus",
  "ob.analytics.inactive": "Neaktyvus",
  "ob.analytics.setUp.btn": "Nustatyti",
  "ob.analytics.google.description.1": "\"Google Analytics\" yra galingas įrankis, kuris gali padėti stebėti svetainės srautą ir naudotojų elgseną. Naudodamiesi \"Google Analytics\" galite stebėti, kiek lankytojų lankosi jūsų svetainėje, iš kur jie atvyksta, kokius puslapius aplanko ir kiek laiko pasilieka jūsų svetainėje.",
  "ob.analytics.google.description.2": "Be to, \"Google Analytics\" leidžia nustatyti tikslus ir stebėti pokalbius, todėl geriau suprasite, kaip gerai veikia jūsų svetainė ir kaip galite ją patobulinti.",
  "ob.analytics.google.trackingId": "Stebėjimo ID",
  "ob.analytics.google.tooltip": "Norėdami rasti savo stebėjimo ID, pirmiausia turite prisijungti prie \"Google Analytics\" paskyros, pasirinkti svetainę arba programą, kurią norite stebėti, ir pereiti į skirtuką \"Administratorius\". Iš ten spustelėkite \"Stebėjimo kodas\". Jūsų sekimo ID bus rodomas formatu \"UA-XXXX-Y\".",
  "ob.analytics.google.account": "Dar neturite \"Google Analytics\" paskyros?",
  "ob.analytics.click": "Spustelėkite",
  "ob.analytics.here": "čia",
  "ob.analytics.learn": "ir sužinosite, kaip ją susikurti.",
  "ob.analytics.facebook.description.1": "Facebook Pixel - tai įrankis, leidžiantis stebėti naudotojų elgesį jūsų svetainėje, įskaitant konversijas, puslapių peržiūras ir kitus įvykius.",
  "ob.analytics.facebook.description.2": "Naudodami \"Facebook Pixel\" galite gauti vertingų įžvalgų apie savo auditoriją, pavyzdžiui, demografinius duomenis, interesus, įrenginio tipą, puslapių peržiūras ir atliktus veiksmus, kurie padeda geriau suprasti ir optimizuoti naudotojų patirtį svetainėje.",
  "ob.analytics.facebook.pixelId": "Pikselio ID",
  "ob.analytics.facebook.tooltip": "Norėdami rasti savo \"Facebook Pixel\" ID, atlikite šiuos veiksmus: Eikite į savo \"Facebook Ads Manager\" paskyrą. Spustelėkite mygtuką \"Meniu\" viršutiniame kairiajame ekrano kampe. Skyriuje \"Events Manager\" (įvykių tvarkytuvas) pasirinkite \"Pixels\" (pikseliai). Čia turėtumėte matyti visų su jūsų paskyra susijusių pikselių sąrašą. Spustelėkite pikselio, kurio ID jums reikia, pavadinimą. Jūsų pikselio ID bus rodomas viršutiniame kairiajame ekrano kampe šalia pikselio pavadinimo.",
  "ob.analytics.facebook.account": "Dar neturite \"Facebook\" verslo puslapio?",
  "ob.analytics.google.account.mobile.1": "Dar neturite \"Google Analytics\"",
  "ob.analytics.google.account.mobile.2": "paskyros? Spustelėkite",
  "ob.analytics.google.account.mobile.3": "norėdami sužinoti, kaip",
  "ob.analytics.google.account.mobile.4": "susikurti tokią paskyrą.",
  "ob.analytics.facebook.account.mobile.1": "Dar neturite \"Facebook\" paskyros",
  "ob.analytics.facebook.account.mobile.2": "verslo puslapio? Spustelėkite",
  "ob.analytics.facebook.account.mobile.3": "ir sužinosite, kaip",
  "ob.analytics.facebook.account.mobile.4": "jį susikurti.",
  "ob.analytics.successfullySaved.message": "Sėkmingai išsaugota",
  "ob.settings.booking.title": "Online rezervacijų prieinamumas",
  "ob.settings.booking.description": "Nustatykite laiką, kada klientai gali registruotis internetu anksčiausiai ir vėliausiai į vizitus. Tvarkykite vizitų laiko intervalus ir pateikite klientams svarbią informaciją, nuspręskite ar leisti pasirinkti darbuotojus. ",
  "ob.settings.cancellation.title": "Atšaukimas ir redagavimas",
  "ob.settings.cancellation.description": "Nustatykite laiko tarpą, kada klientai gali atšaukti arba redaguoti vizitą",
  "ob.settings.locationsUrl.title": "Lokacijos URL",
  "ob.settings.locationsUrl.description": "Bendrinė jūsų verslo nuoroda URL visiems padaliniams. Naudojama tik tų verslų, kurie turi daugiau negu vieną padalinį.",
  "ob.settings.businessName.explanation": "URL: book.plandok.com/business-name/Location2556",
  "ob.settings.notifications.title": "Pranešimai el. paštu ",
  "ob.settings.notifications.description": "Nurodykite gavėjus, kurie bus informuoti el. paštu, kai klientas užsiregistruos į vizitą internetu.",
  "ob.settings.required.title": "Privalomi ir neprivalomi laukai",
  "ob.settings.required.description": "Pasirinkite, kurie laukai bus privalomi or kurie ne užsakant paslaugas internetu",
  "ob.settings.requiredField.title": "Privaloma",
  "ob.settings.optionalField.title": "Neprivaloma",
  "ob.settings.requiredField.notification.title": "Iškilo problema su nustatymais",
  "ob.settings.requiredField.notification.description": "Atsiprašome, bet vienas laukas turi būti privalomas, išskyrus \"postabos\".",
  "ob.settings.nameField.title": "Vardas",
  "ob.settings.lastNameField.title": "Pavardė",
  "ob.settings.phoneField.title": "Telefono numeris",
  "ob.settings.emailField.title": "El. Paštas",
  "ob.settings.notes.label": "Pastabų teksto pakaitinis simbolis",
  "ob.settings.notes.placeholder": "Pvz. Įveskite savo pastabas",
  "ob.bookingLimit.title": "Užsakymo internetu limitas",
  "ob.bookingLimit.description": "Nurodykite didžiausią paslaugų užsakymų skaičių, kurį norite nustatyti.",
  "ob.bookingLimit.label": "Paslaugų užsakymų ribojimas",
  "ob.bookingLimit.unlimited.title": "Neribojamas",
  "ob.bookingLimit.1service.title": "maks. 1 Paslauga",
  "ob.bookingLimit.2services.title": "maks. 2 Paslaugos",
  "ob.bookingLimit.3services.title": "maks. 3 Paslaugos",
  "ob.menu.edit.profile": "Redaguoti profilį",
  "ob.menu.turnOff.profile": "Išjungti",
  "ob.profile.status": "Būsena",
  "ob.overview.tab.description": "Pagerink savo verslą, leisdamas klientams registruotis internetu.",
  "ob.profile.tab.description": "Tvarkykite savo interneto profilį, koreguokite nuotraukas, ​​aprašymą, adresą ir kitą informaciją.",
  "ob.links.tab.description": "Čia rasite savo profilio nuorodą, kuria galite pasidalinti su savo klientais.",
  "ob.settings.tab.description": "internetinės registracijos nustatymus, kad jūsų klientai galėtų registruotis tada, kai jums patogu.",
  "ob.payments.tab.description": "Pasirinkite, kokius mokėjimo būdus klientai galės naudoti mokėdami už paslaugas.",
  "ob.payments.page.description": "Čia galite pasirinkti, kokius mokėjimo būdus jūsų klientai galės naudoti mokėdami už paslaugas.",
  "ob.payOnSite.title": "Mokėti vietoje",
  "ob.payOnSite.description": "Jūsų klientai galės atsiskaityti vietoje grynaisiais pinigais.",
  "ob.payWithCard.title": "Mokėti kortele",
  "ob.payWithCard.description": "Jūsų klientai galės atsiskaityti kreditine kortele per 'Stripe' programėlę.",
  "ob.payWithCard.info.1": "Naudojate nemokamą 'Plandok' planą, todėl turėsite mokėti",
  "ob.payWithCard.info.2": "Plandok' komisinį mokestį ir 'Stripe' komisinį mokestį naudodami šį mokėjimo būdą.",
  "ob.payWithCard.info.3": " turėti",
  "ob.payWithCard.info.4": "Plandok komisinius mokesčius.",
  "ob.payWithCard.info.5": "Premium planas neatleidžia nuo Stripe komisinių mokesčių mokėjimo. ",
  "ob.payWithCard.info.6": "Patikrinkite 'Stripe' kainas čia",
  "ob.stripeConnected.info": "(Stripe prijungtas)",
  "ob.requiresSetup.info": "(Reikia susikurti 'Stripe' paskyrą)",
  "ob.connectToStripe.btn": "Prisijungti prie 'Stripe'",
  "ob.connectToStripe.successMessage.title": "Sėkmingai prisijungta prie 'Stripe'",
  "ob.waitingAuthorization.title": "Laukia jūsų autorizacijos 'Stripe'",
  "ob.stripeDashboard.title": "Visus mokėjimų žurnalus ir grąžinimus galite rasti ",
  "ob.stripeDashboard.url": "Stripe' prietaisų skydelyje",
  "ob.yourStripeAccount.title": "Jūsų 'Stripe' paskyra",
  "ob.visitStripe.option": "Apsilankymas 'Stripe'",
  "ob.changeAccount.option": "Pakeiskite 'Stripe' paskyrą",
  "input.earliestAppointment.label": "Anksčiausia registracija",
  "input.earliestAppointment.placeholder": "Prieš pat vizitą",
  "input.latestAppointment.label": "Vėliausia registracija",
  "input.latestAppointment.placeholder": "Ne daugiau kaip 6 mėnesius į priekį",
  "input.timeSlots.label": "Laiko intervalai",
  "input.timeSlots.placeholder": "15 minučių",
  "checkbox.allowCustomers": "Leiskite klientams pasirinkti darbuotoją",
  "input.importantInfo.label": "Svarbi informacija",
  "input.importantInfo.placeholder": "Informacija, kuri bus rodoma klientui atliekant rezervaciją",
  "input.changeAppointment.label": "Klientas gali redaguoti arba atšaukti rezervaciją",
  "input.changeAppointment.placeholder": "Visada",
  "checkbox.sendToBooked": "Siųsti priskirtam darbuotojui",
  "checkbox.sendSpecificEmail": "Siųsti konkrečiu el. pašto adresu",
  "input.specificEmail.label": "Konkretus pašto adresas",
  "input.specificEmail.placeholder": "Įveskite elektroninio pašto adresą",
  "input.locationsUrl.label": "Lokacijų URL",
  "input.locationsUrl.placeholder": "Pvz. Jurgitos-salonai",
  "ob.btn.save": "Saugoti",
  "ob.btn.cancel": "Atšaukti",
  "ob.btn.showLink": "Rodyti nuorodą",
  "ob.btn.active": "Aktyvi",
  "ob.btn.inactive": "Neaktyvi",
  "ob.linkModal.title": "Tiesioginė nuoroda rezervacijoms",
  "ob.linkModal.description": "Štai nuoroda, kurioje paslaugas galima pasiekti internetu!",
  "ob.linkModal.afterDescription": "Ši nuoroda nukreipia vartotojus tiesiai į Jūsų sukurtą online profilį. Vos keleto mygtukų paspaudimu klientai galės pasirinktį jiems reikiamą paslauga ir užsiregistruoti vizitui. Jūs būsite informuoti el. paštu bei pamatysite kliento registraciją savo kalendoriuje.",
  "ob.fbLinkModal.title": "Facebook puslapio instrukcija",
  "ob.fbLinkModal.description": "Paruoštas registracijos mygtukas, kurį galite integruoti savo „Facebook“ paskyroje ir klientai galės užsiregistruoti tiesiai iš jūsų „Facebook“ paskyros.",
  "ob.fbLinkModal.step1": "Prisijunkite prie savo „Facebook“ puslapio",
  "ob.fbLinkModal.step2": "Spustelėkite „Pridėti mygtuką“ šalia viršelio nuotraukos",
  "ob.fbLinkModal.step3": "Pasirinkite „Registruotis“ ir įdėkite savo nuorodą",
  "ob.linkModalCopySuccess.title": "Nuoroda nukopijuota!",
  "ob.btn.copyLink": "Nukopijuoti nuorodą",
  "title.appointmentSlot.select": "Pasirinkite laiką",
  "btn.back": "Atgal",
  "btn.continue": "Toliau",
  "ob.hours.open": "Dirba",
  "ob.hours.close": "Užsidaro",
  "ob.hours.step.title": "Darbo valandos",
  "ob.hours.step.description": "Darbo valandos, kad klientai žinotų darbo laiką. Tai tik informaciniais tikslais, klientams atliekant rezervaciją internetu, sistema tikrins darbuotojų darbo valandas ir atvaizduos tik ten įvestus laisvus laikus. Įsitikinkite, kad darbuotojai turi įvestas darbo valandas. ",
  "ob.staffHours.step.title": "Darbuotojų darbo laikas",
  "ob.staffHours.step.description": "Svarbus žingsnis, pridėkite darbo valandas sistemai žinoti, kai klientai gali užsisakyti internetu.",
  "ob.location.step.title": "Padalinio informacija",
  "ob.photo.step.title": "Nuotraukos",
  "ob.photo.step.description": "Tegul jūsų klientai žino, kaip jūsų verslas atrodo.",
  "input.file.dnd.photos": "Įkelkite nuotraukas",
  "btn.publish": "Įjungti",
  "text.next": "Tęsti",
  "input.phone.number.label": "Telefono numeris",
  "input.state.label": "Rajonas",
  "input.state.placeholder": "Įveskite rajoną (neprivaloma)",
  "input.addressDetails.label": "Papildoma informacija",
  "input.addressDetails.placeholder": "Įveskite papildomą informaciją, pvz,: durų kodas, informacija apie parkingą ir t.t.",
  "placeholder.no.photos": "Nuotraukų nėra",
  "btn.options": "Pasirinkimai",
  "btn.list.profile": "Įjungti",
  "btn.preview": "Peržiūrėti",
  "btn.next": "Toliau",
  "market.ob.pleaseWait.title": "Palaukite ...",
  "market.ob.location.step.title": "Pasirinkite vietą",
  "market.ob.services.step.title": "Pasirinkite paslaugas",
  "market.ob.employee.step.title": "Data ir darbuotojas",
  "market.ob.timeSlot.step.title": "Pasirinkite datą ir laiką",
  "market.ob.personalInfo.step.title": "Apmokėjimas",
  "market.ob.appointmentInfo.step.title": "Rezervacijos informacija",
  "market.ob.informationAboutYou.title": "Informacija apie tave",
  "market.ob.paymentMethod.title": "Mokėjimo būdas",
  "market.ob.securePayment.title": "Saugus mokėjimas",
  "market.ob.payWithCard.title": "Mokėkite kortele",
  "market.ob.payOnSite.title": "Mokėkite vietoje",
  "market.ob.payOnSite.firstDescription": "Po procedūros turėsite sumokėti salone.",
  "market.ob.payOnSite.secondDescription": "Nedidelis mūsų prašymas: verslai dažnai yra mažos nepriklausomos įmonės, todėl jei negalite atvykti į suplanuotą susitikimą, perkelkite jį arba atšaukite.",
  "market.ob.validThru.title": "Galioja iki (mėnuo/metai)",
  "market.ob.information.title": "Informacija",
  "market.ob.additionalInformation.title": "Papildoma informacija",
  "market.ob.reschedulingPolicy.title": "Atšaukimo/tvarkaraščio keitimo politika",
  "market.ob.reschedulingPolicy.description": "Rezervacija galima keisti, atšaukti likus {0} iki rezervacijos pradžios.",
  "market.ob.fieldsAreRequired.title": "* Pažymėti laukai, privalomi",
  "market.ob.step.title": "Žingsnis {0} iš 3",
  "market.ob.confirm.step.title": "Peržiūrėti ir patvirtinti",
  "market.ob.noServices.placeholder": "Pasirinkite paslaugą",
  "market.ob.input.firstName.label": "{0} Vardas",
  "market.ob.input.firstName.placeholder": "e.g. John",
  "market.ob.input.lastName.label": "{0} Pavardė",
  "market.ob.input.lastName.placeholder": "e.g. Doe",
  "market.ob.input.phone.label": "{0} Tel. numeris",
  "market.ob.input.phone.placeholder": "670 70 822",
  "market.ob.input.email.label": "{0} El.paštas",
  "market.ob.input.email.placeholder": "john.doe@gmail.com",
  "market.ob.input.description.label": "Pastabos",
  "market.ob.staff.assignedSpecialist.label": "Darbuotojas",
  "market.ob.staff.noPreference.label": "Pasirinkti atsitiktinai",
  "market.ob.timeSlots.fullyBooked.title": "Atsiprašome, bet šią dieną neturime laisvų laikų.",
  "market.ob.timeSlots.choseAnotherDay.title": "Prašome pasirinkti kitą dieną.",
  "market.ob.timeSlots.closedSlot.title": "Artimiausias prieinamas laikas yra",
  "market.ob.timeSlots.selectClosest.title": "Pasirinkite artimiausią datą",
  "market.ob.timeSlots.note.title": "Svarbi informacija",
  "market.ob.service.duration.title": "min",
  "market.location.workingHours.title": "Darbo valandos",
  "market.location.monday.title": "Pirmadienis",
  "market.location.tuesday.title": "Antradienis",
  "market.location.wednesday.title": "Trečiadienis",
  "market.location.thursday.title": "Ketvirtadienis",
  "market.location.friday.title": "Penktadienis",
  "market.location.saturday.title": "Šeštadienis",
  "market.location.sunday.title": "Sekmadienis",
  "market.location.meetOurTeam.title": "Susipažinkite su mūsų komanda",
  "market.location.title": "Susisiekite su mumis",
  "market.location.services.title": "Paslaugos",
  "market.location.about.title": "Apie",
  "market.location.aboutUs.title": "Apie mus",
  "market.confirmation.appointmentCanceled.title": "Šis susitikimas buvo atšauktas",
  "market.confirmation.appointmentConfirmed.title": "Patvirtintas paskyrimas",
  "market.confirmation.appointmentConfirmed.description": "Jūsų rezervacija patvirtinta, štai informacija:",
  "market.confirmation.appointmentDate.title": "Paskyrimo data",
  "market.confirmation.scheduledAppointment.title": "Atšaukti vizito internetu negalima.",
  "market.confirmation.scheduledAppointment.description": "Jei negalite atvykti į suplanuotą susitikimą, perkelkite jį į kitą datą arba atšaukite.",
  "market.modal.cancelAppointment.title": "Ar tikrai norite atšaukti šį susitikimą ?",
  "market.modal.cancelledAppointment.title": "Paskyrimas buvo atšauktas",
  "market.language.changer.title": "Kalba (Language)",
  "market.btn.bookNow": "Rezervuoti",
  "market.servicesAvailable.title": "paslaugas",
  "market.poweredByPlandok.title": "Plandok",
  "market.poweredByPlandok.description": "Norite panašios svetainės ? Apsilankykite",
  "market.btn.readMore": "Skaityti daugiau",
  "market.btn.showLess": "Rodyti mažiau",
  "market.btn.reviews": "Atsiliepimai",
  "market.btn.viewMore": "Peržiūrėti daugiau",
  "market.btn.cancelAppointment": "Atšaukti susitikimą",
  "market.btn.cancel": "Taip, atšaukite",
  "market.wh.closed.title": "Uždaryta",
  "market.ob.success.message": "Registracija sėkminga",
  "market.ob.cancelAppointment.success.message": "Registracija atšaukta",
  "market.ob.cancelAppointment.error.message": "Nepavyko, bandykite dar kartą",
  "market.ob.cancelAppointment.confirm.title": "Ar tikrai norite atšaukti registraciją?",
  "market.location.getDirections.title": "Gauti maršrutą",
  "market.notFound.title": "Puslapis nerastas",
  "market.notFound.btn.goBack": "Grįžti atgal",
  "market.ob.quantitativeLimit.title": "Kiekybinė riba",
  "market.ob.quantitativeLimit.description": "Atsiprašome, savininkas nustatė užsakymo paslaugų skaičiaus apribojimą, leidžiantį pasirinkti tik {0} paslaug{0}",
  "market.ob.quantitativeLimit.three": "trys",
  "market.ob.quantitativeLimit.two": "dvi",
  "market.ob.quantitativeLimit.one": "vieną",
  "market.ob.quantitativeLimit.serviceSingular": "a",
  "market.ob.quantitativeLimit.servicesPlural": "os",
  "validation.characters.minLength": "Vertė per trumpa, turėtų būti 3 ar daugiau simbolių.",
  "not.found.btn.label": "Grįžti atgal",
  "not.found.subTitle.label": "Deja, šio puslapio nėra.",
  "analytics.title": "Analitika",
  "analytics.dashboard.tab": "Apžvalga",
  "analytics.reports.tab": "Ataskaitos",
  "analytics.premium.title": "Premium",
  "analytics.totalAppointments.title": "Viso rezervacijų",
  "analytics.cancellationReasons.title": "Top atšaukimo priežastys",
  "analytics.occupancy.title": "Užimtumas",
  "analytics.topServices.title": "Top paslaugos",
  "analytics.clients.title": "Klientai",
  "analytics.completed.title": "Atlikta",
  "analytics.notCompleted.title": "Neatlikta",
  "analytics.canceled.title": "Atšaukta",
  "analytics.noShowAppointments.title": "Neatvyko",
  "analytics.reasonName.title": "Priežastis",
  "analytics.reasonCount.title": "Skaičius",
  "analytics.lastPeriod.title": "Buvęs periodas",
  "analytics.workingHours.title": "Darbo valandos",
  "analytics.bookedHours.title": "Rezervuotos valandos",
  "analytics.blockedHours.title": "Užblokuotos valandos",
  "analytics.unbookedHours.title": "Laisvos valandos",
  "analytics.service.title": "Paslauga",
  "analytics.thisMonth.title": "Šis periodas",
  "analytics.lastMonth.title": "Buvęs periodas",
  "analytics.totalClients.title": "Viso klientų",
  "analytics.averageVisits.title": "Vid. kliento apsilankymų skaičius",
  "analytics.newClients.title": "Nauji klientai",
  "analytics.returningClients.title": "Grįžtantys klientai",
  "analytics.returnRate.title": "Klientų grįžtamumas",
  "analytics.clientsByGender.title": "Klientai pagal lytį",
  "analytics.clientsBySource.title": "Klientai pagal srauto šaltinį",
  "analytics.clientsByAge.title": "Klientai pagal amžių",
  "analytics.timeRange.custom": "Pasirinktinis",
  "analytics.timeRange.today": "Šiandien",
  "analytics.timeRange.yesterday": "Vakar",
  "analytics.timeRange.last1day": "Praėjusią parą",
  "analytics.timeRange.last7days": "Praėjusios 7 dienos",
  "analytics.timeRange.last30days": "Praėjusios 30 dienų",
  "analytics.timeRange.last90days": "Praėjusios 90 dienų",
  "analytics.timeRange.last3months": "Praėję 3 mėn.",
  "analytics.timeRange.lastMonth": "Praėjęs mėnuo",
  "analytics.timeRange.last6Months": "Praėję 6 mėn.",
  "analytics.timeRange.lastYear": "Praėję  1 metai",
  "analytics.timeRange.last2year": "Praėję  2 metai",
  "analytics.timeRange.thisWeek": "Ši savaitė",
  "analytics.timeRange.thisMonth": "Šis mėnuo",
  "analytics.timeRange.thisYear": "Šie metai",
  "analytics.timeRange.tomorrow": "Rytoj",
  "analytics.timeRange.next7days": "Ateinančios 7 dienos",
  "analytics.timeRange.next30days": "Ateinančios 30 dienų",
  "analytics.menu.dashboard.description": "Naršykite savo verslo duomenis diagramose ir statistikoje",
  "analytics.menu.reports.description": "Naršykite savo verslo duomenis ataskaitose",
  "analytics.timeRange.allTime": "Visą laiką",
  "analytics.timeRange.label": "Laiko diapazonas",
  "analytics.services.lastPeriod.label": "Buvęs periodas",
  "analytics.totalAppointments.appointment.label": "Rezervacijos",
  "analytics.totalAppointments.onlineBook.label": "Online rezervacijos",
  "analytics.gender.male": "Vyras",
  "analytics.gender.female": "Moteris",
  "analytics.gender.unknown": "Nežinomas",
  "analytics.noData": "Nėra duomenų pasirinktam laikotarpiui",
  "analytics.premium.info.title": "Norint matyti ilgesnį negu 7 paskutinių dienų intervalą, reikia turėti Premium planą.",
  "analytics.premium.upgradeButton.title": "AKTYVUOTI",
  "analytics.premium.skipButton.title": "Atšaukti",
  "reports.appointments.title": "Rezervacijos",
  "reports.appointments.description": "Peržiūrėkite numatomas pajamas iš būsimų susitikimų, stebėkite atšaukimų rodiklius ir priežastis.",
  "reports.appointmentsList.title": "Rezervacijų sąrašas",
  "reports.appointmentsList.description": "Rodome visą su rezervacijomis susijusią informaciją.",
  "reports.appointmentsSummary.title": "Rezervacijų santrauka",
  "reports.appointmentsSummary.description": "Rodo darbuotojų rodiklius.",
  "reports.appointmentsCancellations.title": "Registracijų atšaukimai",
  "reports.appointmentCancellations.title": "Registracijų atšaukimai",
  "reports.appointmentsCancellations.description": "Rodo visas atšaukimo priežastis ir kiekį už pasirinktą laikotarpį.",
  "reports.summary.title": "Apžvalga",
  "reports.clients.title": "Klientai",
  "reports.clients.description": "Gaukite įžvalgas apie klientus bei sužinokite, kas atneša daugiausiai pajamų",
  "reports.clientsList.title": "Klientai",
  "reports.clientsList.description": "Klientų sąrašas su duomenimis ",
  "reports.exportCsv.title": "Eksportuoti CSV",
  "reports.appointmentsByStaff.title": "Rezervacijos pagal darbuotoją",
  "reports.appointmentsByService.title": "Rezervacijos pagal paslaugą",
  "reports.clearFilters.button.title": "Anuliuoti filtrus",
  "reports.filterStaff.placeholder": "Visi Darbuotojai",
  "reports.filterLocations.placeholder": "Visi Filialai",
  "reports.filters.title": "Filtrai",
  "reports.locations.mobile.title": "Filialai:",
  "reports.staff.mobile.title": "Darbuotojai:",
  "reports.filters.apply.title": "Taikyti",
  "reports.search.placeholder": "Ieškoti pagal klientą",
  "reports.subscription.description": "{0} planas (tik {0} darbuotojai)",
  "reports.notifications.description": "{0} pranešimai ({0})",
  "serviceGroup.addCategory.description": "Grupės yra svarbios, jios padeda sugrupuoti panašias paslaugas. ",
  "serviceGroup.addCategory.title": "Pridėti Kategoriją",
  "app.install.title": "Įdiekite Plandok",
  "app.install.description.title": "Programėlė patogus ir greitas būdas grįžti į savo veiklą",
  "app.install.button.title": "Įdiegti",
  "app.skip.button.title": "Atšaukti",
  "app.actionUndone.button.title": "Neįvykdytas veiksmas",
  "app.appointmentCancelled.button.title": "Išbrauktas paskyrimas",
  "app.undo.button.title": "Atšaukti",
  "app.showAllTimes.button.title": "Rodyti visus laikus",
  "app.install.success.title": "Programėlė sėkmingai įdiegta!",
  "rp.upgradeToPremiumLink.title": "Naujovinkite į Premium",
  "rp.upgradeToPremium.title": "norėdami naudotis šia funkcija",
  "rp.repeatAppointment.title": "Kartoti",
  "rp.input.frequency.title": "Dažnis",
  "rp.input.endsAfter.title": "Pabaiga",
  "rp.input.selectDate.title": "Pasirinkite datą",
  "rp.upgrade.title": "Atnaujinkite savo planą",
  "rp.upgrade.description.title": "Jūs naudojatės nemokama. Norėdami naudotis šia paslauga įsigykite premium planą, tuomet galėtumėsi pasiekti visas Plandok funkcijas.",
  "rp.upgrade.button.title": "Įsigyti",
  "rp.repeating.title": "Pasikartojanti",
  "rp.repeats.title": "Kartojas",
  "rp.repeatsUntil.title": "iki",
  "rp.repeatingTimes.title": "({0} kartus)",
  "rp.cancel.upcoming.title": "Ištrinti visas ateinančias pakartotines rezervacijas",
  "rp.update.upcoming.title": "Atnaujinti visas ateinančias pakartotines rezervacijas",
  "rp.edit.appointment.title": "Atnaujinkite rezervaciją",
  "rp.edit.appointment.description.title": "Redaguojate pasikartojantį susitikimą. Pasirinkite, kuriuos susitikimus reikia atnaujinti:",
  "rp.edit.thisAppointment.title": "Atnaujinkite tik šią rezervaciją",
  "rp.edit.allAppointments.title": "Atnaujinkite visus būsimus susitikimus",
  "rp.edit.saveAppointment.title": "Išsaugoti",
  "modal.cancelSubscriptionError.title": "Atšaukti prenumeratą klaida",
  "modal.cancelSubscriptionError.text.1": "Nemokamas planas galimas tik su ne daugiau kaip 5",
  "modal.cancelSubscriptionError.text.2": "darbuotojų",
  "modal.cancelSubscriptionError.text.3": "ir 1",
  "modal.cancelSubscriptionError.text.4": "Norėdami naudotis nemokama versija, pašalinkite vietas / darbuotojus, kad atitiktų reikalavimus.",
  "modal.latestPaymentFailed.title": "Paskutinis mokėjimas nepavyko",
  "modal.latestPaymentFailed.text.1": "Norėdami gauti prieigą prie visų funkcijų, mokėkite arba naudokite nemokamą versiją pašalinkite",
  "modal.latestPaymentFailed.text.2": ", kad atitiktų reikalavimus. Nemokamas planas galimas tik turint ne daugiau kaip 5 darbuotojus ir 1 vietą.",
  "modal.extraCostsForNewMember.title": "Mokestis už papildomą darbuotą",
  "modal.extraCostsForNewMember.description": "Padidinus darbuotojų skaičių jūsų mėnesinis mokėstis padidės atitinkamai.",
  "modal.premiumUpgradeRequired.title": "Reikalingas 'Premium' atnaujinimas",
  "modal.premiumUpgradeRequired.description": "Norint pridėti papildomą {0}, jūsų planas turi būti atnaujintas į 'Premium'",
  "modal.premiumUpgradeRequired.location": "vietą",
  "modal.premiumUpgradeRequired.staffMembers": "darbuotojų",
  "modal.changeAccount.title": "Keisti 'Stripe' paskyrą?",
  "modal.changeAccount.description": "Kol keisite paskyrą, negalėsite priimti mokėjimų. Pakeitimas užtruks apie minutę.",
  "btn.agree": "Sutinku",
  "btn.decline": "Atšaukti",
  "paymentMethod.paymentMethod.title": "Mokėjimo būdas",
  "paymentMethod.currentPaymentMethod.title": "Dabartinis mokėjimo būdas",
  "paymentMethod.expirationDate": "Galiojimo data {0}",
  "paymentMethod.otherPaymentMethods.title": "Kiti mokėjimo būdai",
  "paymentMethod.addAnotherPaymentMethod.btn": "Pridėti kitą mokėjimo būdą",
  "paymentMethod.addPaymentMethod.title": "Pridėti mokėjimo metodą",
  "paymentMethod.noPaymentMethodAdded.title": "Mokėjimo metodo nepridėta",
  "paymentMethod.noPaymentMethodAdded.description": "Mokėjimo metodas bus pridėtas pasirinkus Premium planą",
  "paymentMethod.goToPlanPage.btn": "Eiti į plano puslapį",
  "paymentMethod.add.btn": "Pridėti",
  "paymentMethod.makeDefault.btn": "Nustatykite numatytąjį",
  "paymentMethod.cardHasBeenAdded.message": "Pridėta kortelė",
  "paymentMethod.cardHasBeenAssign.message": "Kortelė priskirta",
  "page.marketing.overview.messages.description": "Siųskite pasirinktinius rinkodaros pranešimus savo klientams SMS žinutėmis arba el. paštu keliais paprastais paspaudimais",
  "page.marketing.overview.btn.sendMessage": "Siųsti žinutę",
  "page.marketing.overview.campaigns.title": "Automatinio siuntimo kampanijos",
  "page.marketing.overview.campaigns.description": "Kurkite asmenines kampanijas pagal įvykius, pavyzdžiui, klientų gimtadienius ir kt. \nSiųskite keliais kanalais, kad kampanijos būtų pristatomos el. paštu ir SMS žinutėmis",
  "page.marketing.overview.btn.newCampaign": "Nauja kampanija",
  "page.marketing.massMessages.title": "Nustatykite naują pranešimą",
  "page.marketing.massMessages.messageLog": "Žinučių žurnalas",
  "page.marketing.massMessages.sendOn": "Siųsti",
  "page.marketing.massMessages.sendTo": "Siųsti į",
  "page.marketing.massMessages.channel": "Kanalas",
  "page.marketing.campaigns.description": "Siųskite automatizuotus rinkodaros pranešimus savo klientams SMS žinutėmis arba el. paštu keliais paprastais spustelėjimais",
  "page.marketing.campaigns.birthdaySpecial.title": "Specialus gimtadienis",
  "page.marketing.campaigns.winBackClients.title": "Susigrąžinkite klientus",
  "page.marketing.campaigns.welcomeNewClients.title": "Pasveikinkite naujus klientus",
  "page.marketing.campaigns.rewardRegularClients.title": "Apdovanokite nuolatinius klientus",
  "page.marketing.campaigns.birthdaySpecial.description": "Nustebinkite klientus jų ypatingą dieną, ši kampanija automatiškai siunčiama klientams arti jų gimtadienio",
  "page.marketing.campaigns.winBackClients.description": "Tiksliniai nukreipkite nebendraujančius klientus, kad jie sugrįžtų, pateikdami specialų pasiūlymą. Ši kampanija siunčiama klientams, kurie negrįžo po tam tikro laikotarpio",
  "page.marketing.campaigns.welcomeNewClients.description": "Paverskite naujokus nuolatiniais klientais, skatindami pirmą kartą apsilankiusius klientus vėl užsisakyti paslaugas su specialia nuolaida. Ši kampanija automatiškai siunčiama klientams praėjus vienai dienai po pirmojo pardavimo",
  "page.marketing.campaigns.rewardRegularClients.description": "Nustebinkite geriausius klientus specialiu pasiūlymu - tai tam tikras būdas dar labiau sudominti geriausius klientus. Ši kampanija automatiškai siunčiama klientams, atsižvelgiant į naujausią susitikimų aktyvumą",
  "page.marketing.campaigns.btn.active": "Aktyvus",
  "page.marketing.campaigns.btn.inactive": "Neaktyvus",
  "page.marketing.campaigns.btn.startCampaign": "Pradėti kampaniją",
  "page.marketing.campaigns.btn.editCampaign": "Redaguoti kampaniją",
  "page.marketing.campaigns.btn.resetCampaign": "Iš naujo nustatyti kampaniją",
  "modal.resetCampaign.description": "Ar tikrai norite iš naujo nustatyti šią kampaniją ?",
  "modal.resetCampaign.btn.yesReset": "Taip, iš naujo nustatyti",
  "page.marketing.smsCreate.step.1": "SMS nustatymas. 1 žingsnis iš 4",
  "page.marketing.smsCreate.step.2": "SMS nustatymas. 2 žingsnis iš 4",
  "page.marketing.smsCreate.step.3": "SMS nustatymas. 3 žingsnis iš 4",
  "page.marketing.smsCreate.step.4": "SMS nustatymas. 4 žingsnių  iš 4",
  "page.marketing.emailCreate.step.1": "El. pašto sąranka. 1 žingsnis iš 4",
  "page.marketing.emailCreate.step.2": "El. pašto sąranka. 2 žingsnis iš 4",
  "page.marketing.emailCreate.step.3": "El. pašto sąranka. 3 žingsnių iš 4 ",
  "page.marketing.emailCreate.step.4": "El. pašto sąranka. 4 žingsnių iš 4 ",
  "page.marketing.campaignCreate.step.1": "Kampanijos sąranka. 1 iš 3 žingsnių",
  "page.marketing.campaignCreate.step.2": "Kampanijos sąranka. 2 iš 3 žingsnių",
  "page.marketing.campaignCreate.step.3": "Kampanijos sąranka. 3 iš 3 žingsnių",
  "page.marketing.smsCreate.step.1.title": "Pranešimo ir siuntėjo ID",
  "page.marketing.smsCreate.step.1.defaultTitleForSms": "Įveskite SMS siuntėjo ID",
  "page.marketing.smsCreate.step.1.defaultMessageForSms": "Įveskite savo žinutę",
  "page.marketing.emailCreate.step.1.defaultTitleForEmail": "Įveskite žinutės pavadinimą",
  "page.marketing.emailCreate.step.1.defaultMessageForSms": "Įveskite savo žinutę",
  "page.marketing.emailCreate.step.1.title": "El. pašto nustatymai",
  "page.marketing.emailCreate.step.1.messageTitle": "Pranešimo pavadinimas",
  "page.marketing.emailCreate.step.1.replyToEmail": "Atsakyti į el. laišką",
  "page.marketing.emailCreate.step.1.clientReplies": "Kliento atsakymai bus siunčiami į šį el. paštą",
  "page.marketing.emailCreate.step.1.photo.title": "Nuotrauka",
  "page.marketing.emailCreate.step.1.enablePhoto": "Įjungti nuotrauką",
  "page.marketing.emailCreate.step.1.changePhoto": "Pakeisti nuotrauką",
  "page.marketing.emailCreate.step.1.button.title": "Mygtukas",
  "page.marketing.emailCreate.step.1.enableButton": "Įjungti mygtuką",
  "page.marketing.emailCreate.step.1.buttonName": "Mygtuko pavadinimas",
  "page.marketing.emailCreate.step.1.buttonURL": "Mygtuko URL",
  "page.marketing.emailCreate.step.1.socialMedia.title": "Socialinė žiniasklaida",
  "page.marketing.emailCreate.step.1.enableSocialMedia": "Įjungti socialinės žiniasklaidos nuorodas",
  "page.marketing.emailCreate.step.1.facebookPage": "Facebook puslapis",
  "page.marketing.emailCreate.step.1.instagramPage": "Instagram puslapis",
  "page.marketing.emailCreate.step.1.yourPage": "Jūsų puslapis",
  "page.marketing.smsCreate.yourMessage": "Jūsų žinutė",
  "page.marketing.btn.sendMeTestMessage": "Siųskite man bandomąją žinutę",
  "page.marketing.smsCreate.step.1.freeTests": "Šiandien liko {0} nemokami testai",
  "page.marketing.smsCreate.messagePreview": "Žinutės peržiūra",
  "page.marketing.smsCreate.btn.clientSelection": "Kliento pasirinkimas",
  "page.marketing.audience.title": "Auditorija",
  "page.marketing.smsCreate.step.2.description": "Pasirinkite, kurie klientai gaus jūsų pranešimą",
  "page.marketing.smsCreate.step.2.allClient.title": "Visi klientai",
  "page.marketing.smsCreate.step.2.allClient.description": "Siųsti žinutę visiems klientams",
  "page.marketing.smsCreate.step.2.clientGroups.title": "Klientų grupės",
  "page.marketing.smsCreate.step.2.clientGroups.description": "Siųsti pranešimą pasirinktoms klientų grupėms",
  "page.marketing.smsCreate.step.2.totalClients": "Iš viso klientų",
  "page.marketing.smsCreate.step.2.totalPrice": "Bendra kaina",
  "page.marketing.smsCreate.step.3.description": "Pasirinkite laiką, kada norite, kad žinutė būtų išsiųsta",
  "page.marketing.smsCreate.step.3.btn.selectDateTime": "Pasirinkite pasirinktinę datą ir laiką",
  "page.marketing.smsCreate.step.4.title": "Mokėjimas už masinę žinutę",
  "page.marketing.smsCreate.step.4.description": "Įveskite savo kredito kortelės duomenis ir mokėkite",
  "page.marketing.smsCreate.step.4.paymentAmount": "Mokėjimo suma:",
  "btn.scheduleSend": "Siųsti pagal tvarkaraštį",
  "btn.previous": "Ankstesnis",
  "btn.payment": "Mokėjimas",
  "btn.payAndFinish": "Apmokėti ir baigti",
  "btn.backToDashboard": "Grįžti į prietaisų skydelį",
  "btn.saveAndClose": "Išsaugoti ir uždaryti",
  "modal.editClientSelection.title": "Redaguoti kliento pasirinkimą",
  "modal.newClient.description": "Klientai, pridėti per paskutinį:",
  "modal.recentClient.title": "Naujausi klientai",
  "modal.recentClient.description": "Klientai, kurie lankėsi per paskutinį:",
  "modal.clientByAge.title": "Klientas pagal amžių",
  "modal.clientsByGender.title": "Klientai pagal lytį",
  "modal.paymentConfirmation.title": "Mokėjimas sėkmingai atliktas !",
  "modal.paymentConfirmation.messageSend": "Jūsų pranešimas bus išsiųstas",
  "modal.previewMessage.title": "Pranešimo peržiūra",
  "modal.previewSms.tab": "Peržiūrėti SMS žinutę",
  "modal.previewEmail.tab": "Peržiūrėti el. paštą",
  "modal.editServiceSelection.title": "Redaguoti paslaugos pasirinkimą",
  "modal.editServiceSelection.allServices": "Visos paslaugos",
  "modal.campaignActivated.title": "Kampanija aktyvuota !",
  "modal.campaignActivated.description": "Šią kampaniją galite bet kada sustabdyti arba redaguoti",
  "btn.all": "Visi {0}",
  "btn.included": "Įtraukta {0}",
  "btn.excluded": "Neįtraukta {0}",
  "checkbox.allClients": "Visi klientai {0}",
  "scheduleSend.time": "laikas",
  "scheduleSend.timeSetTo": "Nustatytas laikas:",
  "scheduleSend.setTime": "Nustatytas laikas",
  "campaignSetup.step.1.header.title": "Kampanijos nustatymas",
  "campaignSetup.step.2.header.title": "Redaguoti pranešimą",
  "campaignSetup.step.3.header.title": "Peržiūrėti",
  "campaignSetup.channel.description": "Pasirinkite, kokiais kanalais bus siunčiama kampanija",
  "campaignSetup.smsSetup.tab": "SMS nustatymas",
  "campaignSetup.emailSetup.tab": "El. pašto nustatymas",
  "campaignSetup.almostDone.title": "Beveik baigta !",
  "campaignSetup.almostDone.description": "Jūsų išmanioji kampanija paruošta darbui, o ją įjungus ji nuolat automatiškai siųs pranešimus klientams. Šią kampaniją galite bet kada lengvai pakeisti arba sustabdyti",
  "campaignSetup.approximatePrice.header": "Apytikslė kaina",
  "campaignSetup.approximatePrice.description": "Už ateinančias 30 dienų",
  "campaignSetup.approximatePrice.approx": "Apytiksliai",
  "campaign.birthdaySpecial.description": "Ši kampanija automatiškai siunčiama klientams arti jų gimtadienio",
  "campaign.birthdaySpecial.sendToClient": "Siųsti klientui:",
  "campaign.birthdaySpecial.selectOption.1": "Gimtadienio dieną",
  "campaign.birthdaySpecial.selectOption.2": "Likus trims dienoms iki gimtadienio",
  "campaign.birthdaySpecial.selectOption.3": "Likus savaitei iki gimtadienio",
  "campaign.birthdaySpecial.selectOption.4": "Dvi savaitės iki gimtadienio",
  "campaign.winBackClients.description": "Ši kampanija siunčiama klientams, kurie negrįžo po tam tikro laikotarpio",
  "campaign.winBackClients.clientsWhoDidntReturn": "Klientai, kurie negrįžo per paskutinį",
  "campaign.winBackClients.appliedTo": "Taikoma",
  "campaign.winBackClients.all": " visoms ",
  "campaign.winBackClients.services": "paslaugoms",
  "campaign.winBackClients.editSelection": "Redaguoti pasirinkimą",
  "campaign.welcomeNewClients.description": "Paverskite naujokus nuolatiniais klientais, skatindami pirmą kartą apsilankiusius klientus užsisakyti dar kartą su specialia nuolaida",
  "campaign.welcomeNewClients.smartCampaignNewClients": "Ši išmanioji kampanija automatiškai siunčiama naujiems klientams praėjus vienai dienai po jų pirmojo pardavimo",
  "campaign.rewardRegularClients.description": "Ši kampanija automatiškai siunčiama klientams pagal paskutinių susitikimų aktyvumą",
  "campaign.rewardRegularClients.clientsWithAtLeast": "Klientams, turintiems bent",
  "campaign.rewardRegularClients.appointmentsInThe": "susitikimų per",
  "campaign.rewardRegularClients.last": "paskutinius",
  "campaign.rewardRegularClients.appointments": "susitikimų",
  "campaign.rewardRegularClients.inTheLast": "per paskutinius",
  "campaign.rewardRegularClients.months": "mėnesių",
  "subscriptionPaymentFailed.title": "Prašome atnaujinti mokėjimo metodą iki {0}, kad ir toliau galėtumėte naudotis visomis „Premium“ funkcijomis. ",
  "subscriptionCancelled.title": "Atsiprašome, tačiau jūsų Premium planas buvo išjungtas dėl neapmokėjimo. Jei norite toliau naudoti „Premium“ funkcijas, aktyvuokite „Premium“ planą.",
  "notificationPaymentFailed.title": "Mokėjimas už pranešimus nesėkmingas. Atlikite mokėjimą iki {0}, kitaip pranešimai bus išjungti, ačiū.",
  "notificationCancelled.title": "Atsiprašome, bet jūsų pranešimai buvo išjungti dėl neapmokėjimo. Jei norite toliau siųsti pranešimus, prašome apmokėti, ačiū.",
  "btn.hideOptions": "Paslėpti parinktis",
  "btn.showOptions": "Rodyti parinktis",
  "notification.googleDisconnected": "Google' kalendorius atjungtas",
  "notification.googleConnected": "Google' kalendorius prijungtas",
  "notification.appleDisconnected": "Apple' kalendorius atjungtas",
  "notification.appleConnected": "Apple' kalendorius prijungtas",
  "notification.microsoft365Disconnected": "Microsoft365' kalendorius atjungtas",
  "notification.microsoft365Connected": "Microsoft365' kalendorius prijungtas",
  "notification.linkCopied": "Nukopijuota nuoroda",
  "calendarSync.title": "Kalendoriaus sinchronizavimas",
  "calendarSync.description": "Vienu būdu sinchronizuokite 'Plandok' susitikimus su mėgstamu kalendoriumi, kad galėtumėte lengvai organizuoti ir niekada nepraleisti svarbaus įvykio.",
  "googleCalendar.title": "Google' kalendorius",
  "appleCalendar.title": "Apple' kalendorius",
  "microsoft365Calendar.title": "Microsoft365' kalendorius",
  "linkYourCalendar.title": "Susiekite savo kalendorius",
  "connect.btn": "Sujunkite",
  "disconnect.rtn": "Atjungti",
  "connected.label": "Prijungta",
  "modal.workingHours.standartMethod.btn": "Standartinis metodas",
  "modal.workingHours.customMethod.btn": "Pasirinktinis metodas",
  "modal.workingHours.workingDates.input": "Darbo datos",
  "modal.workingHours.selectDates.placeholder": "Pasirinktos datos",
  "noInternetConnection.notification.title": "Nėra interneto ryšio",
  "app.update.title": "Atnaujinimas",
  "app.update.description.title": "Įdėjome naujų funkcijų ir ištaisėme keletą klaidų, kad su \"Plandok\" dirbtumėte kuo sklandžiau.",
  "app.update.button.title": "Atnaujinti ",
  "app.notNow.button.title": "Ne dabar",
  "app.updateAvailable.button.title": "Nauja versija ",
  "upgradePlan.success.message": "Prenumerata sėkmingai atnaujinta",
  "upgradePlan.error.message": "Prenumeratos atnaujinimo klaida, kreipkitės į palaikymo tarnybą, jei jūsų kortelė buvo nurašyta ir prenumerata nebuvo atnaujinta",
  "sidebar.inventory": "Inventorius",
  "products.tab": "Produktai",
  "suppliers.tab": "Tiekėjai",
  "stockOrders.tab": "Atsargų užsakymai",
  "stockTracking.tab": "Atsargų stebėjimas",
  "input.productsSearch.placeholder": "Paieška pagal gaminio pavadinimą arba brūkšninį kodą",
  "product.create.btn.title": "Pridėti naują produktą",
  "table.products.product": "Produktas",
  "table.products.barcode": "Brūkšninis kodas",
  "table.products.brand": "Prekės ženklas",
  "table.products.sale": "Pardavimas",
  "table.products.saleOpt": "Išpardavimo par.",
  "saleOptions.tooltip.title": "Išpardavimo parinktys",
  "saleOptions.tooltip.description": "Galite pasirinkti, ar norite įjungti / išjungti pardavimą kasos metu.",
  "seeMore.title": "Žiūrėti daugiau",
  "option.products.downloadCSV": "Atsisiųsti CSV",
  "option.products.downloadExcel": "Atsisiųsti Excel",
  "option.products.manageBrands": "Tvarkyti prekių ženklus",
  "option.products.manageCategories": "Tvarkyti kategorijas",
  "option.products.manageStock": "Tvarkyti atsargas",
  "option.products.importProducts": "Importuoti produktus",
  "modal.filter.categories.title": "Kategorijos",
  "modal.filter.brands.title": "Prekės ženklai",
  "modal.filter.stock.title": "Atsargos",
  "modal.filter.allCategories.selectItem": "Visos kategorijos",
  "modal.filter.allBrands.selectItem": "Visi prekių ženklai",
  "modal.filter.allSuppliers.selectItem": "Visi tiekėjai",
  "modal.filter.allProducts.selectItem": "Visi produktai",
  "input.suppliersSearch.placeholder": "Paieška pagal tiekėjo pavadinimą",
  "supplier.create.btn.title": "Pridėti naują tiekėją",
  "table.suppliers.supplierName": "Tiekėjo pavadinimas",
  "table.suppliers.phoneNumber": "Telefono numeris",
  "table.suppliers.physicalAddress": "Fizinis adresas",
  "validation.notification.reminderTime": "Impossibile inviare notifiche con lo stesso orario di promemoria.",
  "validation.paymentType.switches": "È necessario consentire almeno un metodo di pagamento",
  "plan.premium.option.14": "Commissione Plandok 0% per gli appuntamenti prenotati tramite Prenotazioni Online con carta di credito.",
  "title.dragAndExtend.appointment.permission": "Gali prailginti ir tampyti rezervacijas",
  "button.lastVisit.addAnotherLastvisit": "AGGIUNGI UN'ALTRA ULTIMA VISITA",
  "notifications.template.badge.validate": "Convalidare",
  "notifications.template.badge.refused": "Rifiutato",
  "paid.by.card": "Apmokėta kortele",
  "costs.manual.paid.link": "Arba galite mokėti už pranešimus rankiniu būdu, spustelėdami šią nuorodą",
  "modal.remove.account.title": "Pašalinkite Stripe paskyrą",
  "modal.remove.account.description": "Jūsų paskyra bus pašalinta ir negalėsite priimti mokėjimų internetu. ",
  "ob.remove.account.option": "Pašalinkite Stripe paskyrą",
  "btn.remove.account": "Pašalinti",
  "calendar.tooltip.selected": "Pasirinkta",
  "calendar.tooltip.no.preference": "Atsitiktinis",
  "ob.gtag.manager.title": "Google Tag Manager",
  "ob.analytics.gtag.description.1": "Naudodami „Google“ žymų tvarkyklę galėsite išmatuoti reklamavimo IG, taip pat stebėti „Flash“, vaizdo įrašų ir socialinių tinklų svetaines bei programas.",
  "ob.gtag.manager.description.1": "„Google“ žymų tvarkyklė yra nemokama žymų valdymo sistema, leidžianti tvarkyti ir įdiegti rinkodaros žymas (kodo fragmentus arba stebėjimo taškus) svetainėje arba programoje mobiliesiems, nekeičiant kodo tiesiogiai.",
  "ob.gtag.manager.description.2": "Iš esmės GTM supaprastina rinkodaros žymų valdymo procesą, todėl lengviau stebėti svetainės našumą, įvertinti rinkodaros kampanijas ir optimizuoti naudotojų patirtį.",
  "text.gender.male": "Vyriška",
  "text.gender.female": "Moteris",
  "text.gender.unknown": "Nežinoma",
  "ob.stripeConnected.context": "(juostelė prijungta)",
  "ob.requiresSetup.context": "(Reikia nustatyti „Stripe“ paskyrą)",
  "ob.payWithCard.context.1": "Naudojate nemokamą Plandok planą, todėl turėsite sumokėti",
  "ob.payWithCard.context.2": "Plandok komisinis mokestis ir Stripe komisinis mokestis naudojant šį mokėjimo būdą",
  "ob.payWithCard.context.3": "turėti",
  "ob.payWithCard.context.4": "Plandok komisiniai mokesčiai.",
  "ob.payWithCard.context.5": "„Premium“ planas neatleidžiamas nuo „Stripe“ komisinių mokesčių mokėjimo",
  "ob.payWithCard.context.6": "Patikrinkite Stripe kainas čia"
};

export default labels;
