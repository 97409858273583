import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CustomIcon, getIsMobile, Text } from '@plandok/core'
import { Spin, Switch } from 'antd'
import * as api from 'api'
import { onReschedule } from 'helpers/app/reschedule'
import PaymentTypesController, {
   IPaymentType,
   PaymentTypeEnum,
} from 'pages/dashboard/BookingPage/BookingPayments/controllers/paymentTypeController'
import { Container } from 'pages/dashboard/CalendarPage/components/PaymentErrorNotification'
import React from 'react'

import DragHandle from '../../ServicesPage/components/ServiceGroup/components/DragHandle'
import PayCardContent from './components/PayCardContent'
import * as SC from './styles'

const SortableElement = (props: {
   paymentType: IPaymentType
   index: number
   isMobile: boolean
   controller: any
   stripePayment: IPaymentType
   onSitePayment: IPaymentType
}) => {
   const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: props.paymentType?.id,
   })

   const style = {
      transform: CSS.Transform.toString(transform),
      transition,
   }

   return (
      <SC.ContainerDraggable
         data-row-key={props.paymentType.paymentType}
         ref={setNodeRef}
         style={style}
         data-payment-type={props.paymentType.paymentType}>
         <DragHandle listeners={listeners} attributes={attributes} />
         {props.paymentType.paymentType === PaymentTypeEnum.STRIPE_PAYMENT ? (
            <StripeCardPayment
               index={props.index}
               isMobile={props.isMobile}
               controller={props.controller}
               stripePayment={props.stripePayment}
               onSitePayment={props.onSitePayment}
            />
         ) : (
            <OnSitePayment
               index={props.index}
               isMobile={props.isMobile}
               controller={props.controller}
               stripePayment={props.stripePayment}
               onSitePayment={props.onSitePayment}
            />
         )}
      </SC.ContainerDraggable>
   )
}

export default function BookingPayments() {
   const controller = PaymentTypesController()

   const isMobile = getIsMobile()

   const onSitePayment = controller.getPaymentType(PaymentTypeEnum.ON_SITE)
   const stripePayment = controller.getPaymentType(PaymentTypeEnum.STRIPE_PAYMENT)

   const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor))

   const refresh = onReschedule({
      data: controller.paymentTypes,
      changeData: (nextPaymentType: any) => controller.setPaymentTypes(nextPaymentType),
      onPositionUpdate: async (id: string, newPosition: number) =>
         await api.changePaymentTypePosition(id, {
            id: id,
            position: newPosition,
         }),
   })

   const onDragEnd = (event: any) => {
      const { active, over } = event

      if (active.id !== over?.id) {
         const oldIndex = (controller.paymentTypes || []).findIndex(item => item.id === active.id)
         const newIndex = (controller.paymentTypes || []).findIndex(item => item.id === over.id)

         return refresh({ oldIndex, newIndex })
      }
   }

   return (
      <SC.Container>
         {!isMobile && (
            <Text label="ob.payments.tab" size="xxxlarge" weight="semiBold" mb="xmedium" lineHeight="49px" />
         )}
         <Text
            label="ob.payments.page.description"
            lh="xxlarge"
            marginBottom={isMobile ? '24px' : '40px'}
            size="medium"
         />
         <Spin spinning={controller.loading}>
            {controller.error && (
               <Container>
                  <div className="align-center">
                     <span className="align-center">
                        <CustomIcon type="clear" />
                        <Text
                           weight="semiBold"
                           size={isMobile ? 'mlarge' : 'base'}
                           mb="none"
                           label="Error"
                           ml="xsmall"
                           lh={isMobile ? 'xxlarge' : 'small'}
                        />
                     </span>

                     <Text
                        size="small"
                        mb={isMobile ? 'small' : 'none'}
                        ml={isMobile ? 'none' : 'base'}
                        lh="large"
                        style={{ maxWidth: '596px' }}>
                        {controller.error}
                     </Text>
                  </div>
               </Container>
            )}
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
               <SortableContext
                  items={controller.paymentTypes?.map((item: any) => item.id)}
                  strategy={verticalListSortingStrategy}>
                  {controller.paymentTypes.map((paymentType, index) => (
                     <SortableElement
                        key={index}
                        index={index}
                        isMobile={isMobile}
                        controller={controller}
                        paymentType={paymentType}
                        stripePayment={stripePayment}
                        onSitePayment={onSitePayment}
                     />
                  ))}
               </SortableContext>
            </DndContext>
         </Spin>
      </SC.Container>
   )
}

export type PaymentTypeProps = {
   index: number
   isMobile: boolean
   stripePayment: IPaymentType
   controller: any
   onSitePayment: IPaymentType
}

const OnSitePayment = (props: PaymentTypeProps) => (
   <SC.PaySiteCard>
      <CustomIcon type="cash" />
      <SC.PaySiteInfo>
         <Text
            label="ob.payOnSite.title"
            mb="small"
            weight="semiBold"
            fontSize="22px"
            lineHeight="26px"
            marginBottom={props.isMobile ? '4px' : '15px'}
         />
         <Text
            label="ob.payOnSite.description"
            size="small"
            lh="xlarge"
            color="#5A6F99"
            marginBottom={props.isMobile ? '16px' : '24px'}
         />
         <SC.SwitchContainer>
            <Switch
               defaultChecked
               checked={props.onSitePayment.isActive}
               onChange={value => props.controller.changeStatus(props.index, PaymentTypeEnum.ON_SITE, value)}
               disabled={!props.stripePayment.isActive}
            />
            <Text
               color={props.onSitePayment.isActive ? '#009329' : '#7183a7'}
               label={props.onSitePayment.isActive ? 'ob.btn.active' : 'ob.btn.inactive'}
               size="medium"
               lh="mlarge"
               mb="none"
               marginLeft="12px"
            />
         </SC.SwitchContainer>
      </SC.PaySiteInfo>
   </SC.PaySiteCard>
)

const StripeCardPayment = (props: PaymentTypeProps) => (
   <SC.PayCardCard>
      <SC.PayCardContainer>
         <CustomIcon type="creditCard" />
         <SC.PayCardWrapper>
            <Text
               label="ob.payWithCard.title"
               mb="small"
               weight="semiBold"
               fontSize="22px"
               lineHeight="26px"
               marginBottom={props.isMobile ? '4px' : '16px'}
            />
            <Text
               label="ob.payWithCard.description"
               size="small"
               lh="xlarge"
               color="#5A6F99"
               marginBottom={props.isMobile ? '9px' : '16px'}
            />

            {!props.isMobile && (
               <PayCardContent
                  stripePayment={props.stripePayment}
                  onSitePayment={props.onSitePayment}
                  controller={props.controller}
                  changeStatus={value =>
                     props.controller.changeStatus(props.index, PaymentTypeEnum.STRIPE_PAYMENT, value)
                  }
               />
            )}
         </SC.PayCardWrapper>
      </SC.PayCardContainer>

      {props.isMobile && (
         <PayCardContent
            stripePayment={props.stripePayment}
            onSitePayment={props.onSitePayment}
            controller={props.controller}
            changeStatus={value => props.controller.changeStatus(props.index, PaymentTypeEnum.STRIPE_PAYMENT, value)}
         />
      )}
   </SC.PayCardCard>
)
