/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
  "login.title": "Přihlašte se ke svému účtu",
  "label.email": "E-mailová adresa",
  "placeholder.email": "např. p.novak@gmail.com",
  "input.password.label": "Heslo",
  "input.password.placeholder": "Zadejte heslo",
  "login.btn": "Vstup do Plandoku",
  "link.sign.in": "Přihlásit se",
  "label.password": "Zadejte heslo",
  "login.forgot.password": "Zapoměli jste heslo?",
  "login.no.account": "Nemáte účet?",
  "login.description": "Pokud máte potíže s přihlášením můžete obnovit své heslo.",
  "register.title": "Vytvořit účet",
  "register.description": "Vytvořte si bezplatný účet pro plánování schůzek a jejich rezervaci v Plandoku",
  "register.btn": "Vytvořit účet",
  "register.agreement.agree": "Souhlasím s",
  "register.agreement.terms": "Podmínky web stránek",
  "register.agreement.policy": "Zásady ochrany osobních údajů",
  "register.agreement.and": "a",
  "register.agreement.partner": "Partnerské obchodní podmínky",
  "register.have.account": "Už máte účet?",
  "register.validation.confirm": "Než budete pokračovat přečtěte a potvrďte/akceptujte výše uvedené podmínky.",
  "link.sign.up": "Registrovat",
  "text.alreadyHaveAccount": "Máte již účet?",
  "input.company.label": "Společnost",
  "input.company.placeholder": "Společnost",
  "input.country.label": "Země",
  "input.country.placeholder": "Název země",
  "input.timeZone.label": "Časové pásmo",
  "input.timeZone.placeholder": "GMT 02,00",
  "input.currency.label": "Měna",
  "input.language.label": "Jazyk (Language)",
  "input.language.placeholder": "Angličtina",
  "input.currency.placeholder": "Euro - EUR",
  "reset.title": "Obnovit heslo",
  "reset.btn.text": "Poslat heslo k obnovení",
  "reset.pwd.btn": "Obnovit heslo",
  "reset.no.password": "Chcete resetovat heslo?",
  "reset.description": "Po zadání vaší e-mailové adresy obdržíte zabezpečený odkaz, kde si nastavíte nové heslo.",
  "sidebar.calendar": "Kalendář",
  "sidebar.client": "klienti",
  "sidebar.staff": "Personál",
  "sidebar.service": "Služby",
  "sidebar.notification": "Zprávy",
  "sidebar.settings": "Nastavení",
  "sidebar.menu": "Hlavní menu",
  "sidebar.analytics": "Analytika",
  "sidebar.language": "Jazyk (Language)",
  "sidebar.logout": "Odhlásit se",
  "user.menu.settings": "Moje nastavení",
  "user.menu.language": "Změnit jazyk",
  "user.menu.logout": "Odhlásit se",
  "input.globalSearch.placeholder": "Co hledáte?",
  "input.globalSearch.description": "Vyhledávat podle názvu klienta, mobilní telefonu, e-mailu nebo rezervace reference",
  "title.upcomingAppointments": "Plánované termíny/nadcházející schůzky",
  "calendar.day": "Den",
  "calendar.week": "Týden",
  "calendar.monday": "Pondělí",
  "calendar.tuesday": "Úterý",
  "calendar.wednesday": "Středa",
  "calendar.thursday": "Čtvrtek",
  "calendar.friday": "Pátek",
  "calendar.saturday": "Sobota",
  "calendar.sunday": "Neděle",
  "calendar.mon": "Pon",
  "calendar.tue": "Úte",
  "calendar.wed": "Stř",
  "calendar.thu": "Čtv",
  "calendar.fri": "Pát",
  "calendar.sat": "Sob",
  "calendar.sun": "Ned",
  "calendar.today": "Dnes",
  "calendar.january": "Leden",
  "calendar.february": "Únor",
  "calendar.march": "Březen",
  "calendar.april": "Duben",
  "calendar.may": "Květen",
  "calendar.june": "Červen",
  "calendar.july": "Červenec",
  "calendar.august": "Srpen",
  "calendar.september": "Září",
  "calendar.october": "Říjen",
  "calendar.november": "Listopad",
  "calendar.december": "Prosinec",
  "calendar.jan": "Led",
  "calendar.feb": "Úno",
  "calendar.mar": "Bře",
  "calendar.apr": "Dub",
  "calendar.may.short": "Kvě",
  "calendar.jun": "Čer",
  "calendar.jul": "Červenec",
  "calendar.aug": "Srp",
  "calendar.sept": "Zář",
  "calendar.oct": "Říj",
  "calendar.nov": "Listopad",
  "calendar.dec": "Prosinec",
  "calendar.allStaff": "Veškerý personál",
  "calendar.workingStaff": "Pracovní tým",
  "calendar.tooltip.time": "Čas",
  "calendar.tooltip.category": "Kategorie",
  "calendar.tooltip.service": "Servis",
  "calendar.tooltip.staff": "Personál",
  "calendar.tooltip.price": "Cena",
  "calendar.tooltip.repeating": "Opakování",
  "btn.viewDetails": "Zobrazit podrobnosti",
  "calendar.tooltip.emptyCustomer": "Vejít",
  "calendar.tooltip.bookedOnline": "Online rezervace",
  "calendar.print.description": "Aby bylo možné používat funkci tisku, musí být váš plán upgradován. Pro upgrade navštivte",
  "calendar.print.appointmentsBetween.title": "Schůzky mezi",
  "calendar.print.appointmentsAnd.title": "a",
  "calendar.billing.title": "Plán a fakturace",
  "calendar.btn.print.title": "Vytisknout",
  "datepicker.next.week": "Příští týden",
  "datepicker.in.two.weeks": "Za 2 týdny",
  "datepicker.in.three.weeks": "Za 3 týdny",
  "datepicker.in.four.weeks": "Za 4 týdny",
  "datepicker.in.five.weeks": "Za 5 týdny",
  "datepicker.in.six.weeks": "Za 6 týdnů",
  "modal.splitAppointment.title": "Rozdělit schůzku",
  "modal.splitAppointment.text": "Tato schůzka je součástí skupiny, přesunutím do jiného dne se oddělí a vznikne zvláštní/nová/samostnatná rezervace",
  "blocktime.create.modal.title": "Blokovat čas",
  "blocktime.edit.modal.title": "Upravit blokací času",
  "appointment.create.menu": "Schůzka",
  "blocktime.create.menu": "Blokovat čas",
  "appointment.new.title": "Nová schůzka",
  "input.startTime.label": "Začátek",
  "input.endTime.label": "Konec",
  "input.startDate.label": "Datum zahájení",
  "input.endDate.label": "Datum ukončení",
  "input.startDate.description.label": "Vyberte datum zahájení",
  "input.endDate.description.label": "Vyberte datum ukončení",
  "input.closedDatedDescription.placeholder": "např. dovolená",
  "input.service.label": "Služba",
  "input.services.label": "služby",
  "input.service.placeholder": "Vyberte službu",
  "input.staff.label": "Personál",
  "input.staff.placeholder": "Vyberte personál",
  "input.pickDateAndTime.title": "Datum a čas výběru",
  "input.pickDate.title": "Výběr data",
  "service.add.another": "Přidal další službu",
  "input.appointmentNotes.label": "Poznámky o schůzkách",
  "input.emailSubject.placeholder": "Poznámka k Vaší schůzce",
  "input.appointmentNotes.placeholder": "Přidejte poznámku ke schůzce (viditelné pouze pro personál)",
  "input.searchClients.placeholder": "Najít klienta",
  "input.searchService.placeholder": "Vyhledávání",
  "appointment.empty.list": "Použijte vyhledávání k přidání klienta nebo nechte list prázdný pro uložení vstupu.",
  "appointment.clients.empty.list": "Seznam klientů je prázdný.",
  "appointment.clients.addClient": "Vytvořit klienta",
  "client.create.new": "Vytvoření nového klienta",
  "client.choose": "Vybrat klienta",
  "client.menu.edit": "Upravit údaje klienta",
  "client.menu.remove": "Odebrat ze schůzky",
  "appointment.btn.save": "Uložit",
  "appointment.vat": "DPH ({0}%)",
  "appointment.total": "Celkem",
  "appointment.view.title": "Zobrazit schůzku",
  "appointment.status.btn": "Stav schůzky",
  "appointment.btn.cancel": "Vymazat",
  "btn.cancelAppointment": "Vymazat",
  "modal.cancelAppointment.title": "Vymazat",
  "title.clients": "Klienti",
  "client.create.title": "Nový klient",
  "client.options.export.title": "Exportovat klienty",
  "client.options.export.description": "Stáhněte si CSV s klientskými daty",
  "client.options.import.title": "Importovat klienty",
  "client.export.premium.description": "Aby bylo možné používat funkci Export, musí být váš plán upgradován.",
  "client.export.premium.visit": "Pro upgrade navštivte ",
  "client.import.instructions.description": "Chcete-li importovat klienty do svého účtu Plandok, postupujte podle pokynů níže",
  "client.import.instructions.download": "Stáhněte si soubor importu pomocí tlačítka níže",
  "client.import.instructions.copy": "Zkopírujte a vložte podrobnosti o klientovi do souboru",
  "client.import.instructions.email": "Pošlete nám jej e-mailem na adresu info@plandok.com",
  "client.import.instructions.account": "a my importujeme do vašeho účtu",
  "table.clients.name": "Jméno",
  "table.clients.phone": "Telefon",
  "table.clients.mobile": "Telefon",
  "table.clients.email": "E-mail",
  "table.clients.gender": "Pohlaví",
  "client.page.title": "Profil klienta",
  "btn.more.options": "Více možností",
  "btn.showAll": "Ukázat vše",
  "btn.collapse": "Kolaps",
  "btn.reschedule": "Přeplánovat",
  "title.edit.client": "Editovat klienta",
  "menu.edit.client": "Editovat klienta",
  "menu.delete.client": "Smazat klienta",
  "title.mobilePhone": "Mobilní telefon",
  "title.telephoneNumber": "Telefonní číslo",
  "title.email": "E-mail",
  "title.address": "Adresa",
  "title.dateOfBirth": "Datum narození",
  "title.gender": "Pohlaví",
  "client.statistics": "Statistika",
  "client.totalSales": "Celkové tržby",
  "client.totalBookings": "Celkové objednávky",
  "client.outstanding": "Nezaplacený",
  "client.allBooking": "Všechny rezervace",
  "client.completed": "Dokončený",
  "client.cancelled": "Zrušený",
  "client.noShow": "No Show",
  "appointment.list.title": "Schůzky",
  "upcoming.list.title": "Nadcházející",
  "past.list.title": "Uplynulé",
  "modal.confirmClientDelete.text.0": "Opravdu chcete odstranit klienta?",
  "modal.confirmClientDelete.text.1": "z databáze?",
  "input.date.label": "Specifické informace",
  "input.date.placeholder": "Vyberte datum",
  "input.firstName.label": "Jméno",
  "input.firstName.placeholder": "např. Pavel",
  "input.lastName.label": "Příjmení",
  "input.lastName.placeholder": "např. Novák",
  "input.mobile.label": "Mobilní číslo",
  "input.mobile.placeholder": "Vložte číslo",
  "input.email.label": "E-mailová adresa",
  "input.email.placeholder": "např. p.novak@gmail.com",
  "input.title.label": "Titul",
  "input.title.placeholder": "např. Zubař",
  "input.notificationType.label": "Odeslat oznámení",
  "tab.personalInfo": "Osobní informace",
  "input.gender.label": "Pohlaví",
  "input.referal.label": "Odkaz k doporučení",
  "input.referal.placeholder": "Vyberte zdroj",
  "input.birthDay.label": "Narozeniny",
  "input.month.placeholder": "Měsíc",
  "input.day.placeholder": "Den",
  "input.year.placeholder": "Rok",
  "input.clientsNotes.label": "Pounámka klienta",
  "input.clientsNotes.placeholder": "Zadejte vzkaz klientovi",
  "input.bookingDisplay.label": "Zobrazení u všech rezervací",
  "input.serviceDescription.label": "Popis služby",
  "input.serviceDescription.placeholder": "Přidejte krátký popis, který uvidí zákazníci v online rezervacích",
  "input.isBookableOnline.label": "Dostupné pro online rezervace",
  "service.onlineBookings.title": "On-line rezervace",
  "tab.address": "Adresa",
  "input.address.label": "Adresa",
  "input.address.placeholder": "Zadejte svou adresu",
  "input.city.label": "Město",
  "input.city.placeholder": "Zadejte město",
  "input.zip.label": "PSČ",
  "input.zip.placeholder": "Zadejte PSČ",
  "btn.edit": "Upravit",
  "btn.cancel": "Zrušit",
  "btn.save": "Uložit",
  "btn.save.changes": "Uložit",
  "tabs.staffWorking.title": "Pracovní doba",
  "tabs.staffMembers.title": "Zaměstnanci",
  "tabs.userPermissions.title": "uživatelské oprávnění",
  "tabs.closedDates.title": "Termíny uzavření",
  "btn.new.staff": "Nový personál",
  "table.staff.name": "Jméno",
  "table.staff.mobileNumber": "Telefon",
  "table.staff.email": "E-mail",
  "table.staff.userPermission": "Oprávnění uživatele",
  "table.staff.dataRange": "Časové období",
  "table.staff.numberOfDays": "Počet dní",
  "table.staff.location": "Umístění",
  "table.staff.description": "Popis",
  "table.appointmentsList.appointment": "Jmenování",
  "table.appointmentsList.client": "Klient",
  "table.appointmentsList.service": "Servis",
  "table.appointmentsList.date": "Datum",
  "table.appointmentsList.time": "Čas",
  "table.appointmentsList.duration": "Trvání",
  "table.appointmentsList.location": "Umístění",
  "table.appointmentsList.staff": "Personál",
  "table.appointmentsList.price": "Cena",
  "table.appointmentsList.status": "Postavení",
  "table.appointmentsCancellations.ref": "Ref",
  "table.appointmentsCancellations.client": "Klient",
  "table.appointmentsCancellations.service": "Servis",
  "table.appointmentsCancellations.scheduledDate": "Plánované datum",
  "table.appointmentsCancellations.cancelledDate": "Datum zrušení",
  "table.appointmentsCancellations.cancelledBy": "Zrušeno uživatelem",
  "table.appointmentsCancellations.reason": "Důvod",
  "table.appointmentsCancellations.price": "Cena",
  "table.appointmentsCancellations.totalCount": "Celkový počet",
  "table.clients.appointments": "Schůzky",
  "table.clients.noShows": "Žádné pořady",
  "table.clients.totalSales": "Celkový prodej",
  "table.clients.added": "Přidáno",
  "table.clients.daysAbsent": "Dny nepřítomnosti",
  "table.clients.lastAppointment": "Poslední schůzka",
  "table.clients.lastLocation": "Poslední umístění",
  "table.appointmentsByService.serviceName": "Název služby",
  "table.appointmentsByService.appointments": "Schůzky",
  "table.appointmentsByService.totalValue": "Celková hodnota",
  "table.appointmentsByStaff.staffMember": "Člen štábu",
  "table.appointmentsByStaff.appointments": "Schůzky",
  "table.appointmentsByStaff.totalValue": "Celková hodnota",
  "table.pagination.showing.title": "Zobrazení",
  "table.pagination.results.title": "výsledky",
  "clients.duplicate.title": "Nalezeni duplicitní klienti.",
  "clients.duplicate.description": "Existují podobní klienti, které lze sloučit.",
  "clients.merge.btn.title": "Spojit",
  "clients.merge.modal.title": "Sloučit klienty",
  "clients.merge.selectAll.title": "Vyberte všechny duplikáty",
  "clients.merge.duplicatesNotFound.title": "Duplikáty nebyly nalezeny",
  "clients.merge.duplicatesNotFound.description": "Nejsou k dispozici žádní klienti ke sloučení",
  "clients.mergeConfirm.modal.title": "Potvrďte sloučení",
  "clients.mergeConfirm.modal.description": "Opravdu chcete sloučit {0} duplikátů? Tato akce je trvalá a nelze ji vrátit zpět.",
  "clients.mergeConfirm.checkbox.title": "Chápu, že sloučení klientů nelze vrátit zpět",
  "clients.mergeConfirm.btn.title": "Potvrdit a sloučit",
  "clients.mergeConfirm.cancel.btn.title": "Zrušit",
  "clients.byNumber.btn.title": "Telefonicky {0}",
  "clients.byEmail.btn.title": "E-mailem {0}",
  "clients.byName.btn.title": "Podle jména {0}",
  "title.newStaff": "Nový personál",
  "title.editStaff": "Editace personálu",
  "description.extraCharge": "Přidáním nového zaměstnance se k vašemu měsíčnímu předplatnému připočte další poplatek",
  "tabs.details": "Podrobnosti",
  "input.staffPicture.label": "Obrázek",
  "input.cropImage.title": "Oříznout obrázek",
  "input.cropImage.btn.apply.title": "Použít",
  "input.stafffirstName.label": "Jméno",
  "input.stafffirstName.placeholder": "např. Pavel",
  "input.stafflastName.label": "Příjmení",
  "input.stafflastName.placeholder": "např. Novák",
  "input.stafmobile.label": "Telefon",
  "input.stafemail.label": "E-mail",
  "input.stafemail.placeholder": "např. p.novak@gmail.com",
  "input.permission.label": "uživatelské oprávnění",
  "input.uploadProfile.label": "Nahrát obrázek profilu",
  "input.changeProfile.label": "Změnit profilový obrázek",
  "input.notes.label": "Poznámky",
  "input.staffNotes.placeholder": "Přidejte soukromé poznámky, které lze zobrazit pouze v nastavení personálu. (volitelné)",
  "input.blockTime.placeholder": "např. pauza na oběd",
  "tabs.locations": "Místa",
  "tabs.integrations": "Integrace",
  "input.staffLocations.description": "Přiřaďte místa, které může tento zaměstnanec rezervovat.",
  "input.multiLocation.description": "Přiřaďte místa, které může tento zaměstnanec rezervovat.",
  "tabs.services": "Služby",
  "input.staffServices.description": "Přiřaďte služby, které může tento zaměstnanec vykonávat.",
  "input.multiServices.description": "Přiřaďte služby, které může tento zaměstnanec vykonávat.",
  "input.shiftStart.label": "Začátek směny",
  "input.shiftEnd.label": "Konec směny",
  "edit.staff.title.resendPassword": "Heslo znovu odeslat",
  "edit.staff.info.resendPassword": "Neobdržel zaměstnanec e-mail s heslem? Kliknutím na tlačítko níže znovu odešlete e-mail s pokyny k nastavení hesla.",
  "edit.staff.button.resendPassword": "Znovu odeslat e-mail s heslem",
  "btn.add.shift": "Přidat další směnu",
  "break.time": "{0} pauza",
  "input.repeat.label": "Opakovat",
  "input.repeatWeekly.label": "Týdně",
  "input.repeatDoNot.label": "Neopakovat",
  "input.endRepeat.label": "Opakujicí se konec",
  "input.endRepeatOngoing.label": "Právě probíhající",
  "input.endRepeatSpecificDate.label": "Konkrétní datum",
  "input.endRepeatDate.placeholder": "Vyberte datum",
  "btn.deleteUpcomingShift": "Odstranit nadcházející směny",
  "btn.deleteThisShift": "Smazat pouze tuto směnu",
  "btn.updateUpcomingShift": "Aktualizovat nadcházející směny",
  "btn.updateThisShift": "Aktualizujte pouze tuto směnu",
  "modal.repeatShift.title": "Opakující se směna",
  "form.error.uniqShifts": "Směny by měly být jedinečné",
  "btn.add.new": "Přidat nový",
  "btn.addNewService": "Přidat novou službu",
  "btn.newCategory": "Nová kategorie",
  "btn.newServiceGroup": "Nová kategorie",
  "btn.editCategory": "Upravit kategorii",
  "btn.deleteCategory": "Odstranit kategorii",
  "btn.downloadImportFile": "Stáhnout soubor importu",
  "serviceGroup.delete.success": "Kategorie byla úspěšně smazána!",
  "service.new.title": "Nová služba",
  "service.edit.title": "Upravit službu",
  "input.serviceName.label": "Název služby",
  "input.serviceName.placeholder": "např. Masáž",
  "input.serviceCategory.label": "Kategorie služeb",
  "input.serviceCategory.placeholder": "Vyberte kategorii služeb",
  "input.price.label": "Cena",
  "input.price.placeholder": "0",
  "input.duration.label": "Doba trvání",
  "input.duration.placeholder": "Doba trvání",
  "input.staffSelect.description": "Vyberte zaměstnance, který bude tuto službu vykonávat.",
  "input.newCategoryName.label": "Název kategorie",
  "input.newCategoryDescription.label": "Popis kategorie",
  "input.newCategoryDescription.placeholder": "Popište tuto kategorii, zobrazí se v Online rezervacích",
  "modal.newCategory.title": "Nová kategorie",
  "modal.editCategory.title": "Upravit kategorii",
  "input.chooseCategory.label": "Vybrat barvu kategorie",
  "client.messages.title": "Klientské zprávy",
  "tabs.messagesLog": "Protokol zpáv",
  "tabs.messagesLog.description": "Seznam odeslaných zpráv vašim klientům",
  "tabs.messagesSettings": "Nastavení",
  "tabs.messagesSettings.description": "Upravte nastavení zpráv tak, aby vyhovovalo potřebám vaší firmy",
  "table.header.timeSent": "Odesláno",
  "table.header.client": "Zákazník/Klient",
  "table.header.appointment": "Schůzka",
  "table.header.destination": "Adresa",
  "table.header.type": "Typ",
  "table.header.message": "Zpráva",
  "table.header.status": "Postavení/důležitost",
  "table.header.cost": "Náklady",
  "modal.message.title": "Zobrazit zprávu",
  "setup.page.title": "Nastavení",
  "title.accountSettings": "Nastavení účtů",
  "title.accountSetup": "Nastavení účtu",
  "title.accountSetup.description": "Správa nastavení jako náýev vaší firmy a časové pásmo",
  "resources.list.title": "Zdroje",
  "title.resources": "Zdroje",
  "title.resources.description": "Nastavte si zdroje které se dají rezerovat jako jsou pokoje, židle nebo vybavení.",
  "title.locations": "Místa",
  "title.locations.description": "Spravujte více prodejen/poboček pro své podnikání",
  "calendar.settings.title": "Nastavení kalendáře",
  "calendar.btn.selectFromCalendar": "Výběr z kalendáře",
  "title.calendarSettings": "Nastavení kalendáře",
  "title.calendarSettings.description": "Upravte barevné schéma/plán a rozložení kalendáře",
  "title.clientSettings": "Nastavení klienta",
  "title.onlineBookingSettings": "Nastavení online rezervací",
  "title.salesSettings": "Nastavení prodeje",
  "title.receiptSequencing": "Řazení účtenek",
  "title.receiptSequencing.description": "Konfigurace podrobností zobrazovaných na účtenkách o prodeji vydaných vašim klientům",
  "title.receiptTemplate": "Šablona účtenky",
  "title.taxes.description": "Správa daňových sazeb, které se vztahují na položky prodávané na pokladně",
  "title.paymentTypes": "Typy plateb",
  "title.paymentTypes.description": "Nastavení ručních typů plateb pro použití při pokladně",
  "title.discounts.description": "Nastavení slev",
  "title.receiptPrefix": "Účtenka č. Předpona",
  "title.receiptNumber": "Další číslo účtenky",
  "title.change": "Změna",
  "title.saleReceiptTemplate": "Šablona prodejní účtenky",
  "description.saleReceiptTemplate": "Přizpůsobte si obsah zobrazovaný na prodejních účtenkách vystavených vašim klientům",
  "setting.automaticallyPrint": "Automatický tisk účtenek po dokončení prodeje",
  "setting.showMobile": "Zobrazení mobilního telefonu a e-mailu klienta na prodejní účtence",
  "setting.showAddress": "Zobrazení adresy klienta na prodejní účtence",
  "title.receiptTitle": "Název účtenky",
  "title.referrals": "Zdroje doporučení",
  "title.referrals.description": "Nastave si vlastní zdroje, abyste evidovali jak klient našel Vaši firmu.",
  "title.clientNotifications": "Nastavení oznámení klienta",
  "title.clientNotifications.description": "Správa zpáv, které jsou odesílány klientů o jejich schůzce",
  "cancelReason.list.title": "Důvody zrušení",
  "title.cancellationReasons": "Důvody zrušení",
  "title.cancellationReasons.description": "Sledujte proč klienti nepřišli na schůzku.",
  "title.onlineBooking.description": "Správa dostupnosti a nastavení online rezervací zájezdů",
  "title.pointOfSale": "Místo prodeje.",
  "discount.list.title": "Slevy",
  "title.discountTypes": "Typ slevy",
  "title.discountTypes.description": "Nastavte si slevy, které použijete při placení",
  "title.premiumFeature": "Prémiová funkce",
  "company.details.title": "Podrobnosti o firmě",
  "input.businessName.label": "Obchodní jméno",
  "input.businessName.placeholder": "např. SkodaCar",
  "input.description.label": "Popis",
  "input.description.placeholder": "Zadejte popis",
  "input.businessAddress.label": "Adresa",
  "input.businessAddress.placeholder": "Zadejte adresu",
  "input.website.label": "Webová stránka",
  "input.website.placeholder": "Zadejte název webové stránky",
  "input.contactNumber.label": "Kontaktní číslo",
  "input.contactNumber.placeholder": "Vložte číslo",
  "input.businessTimeZone.label": "Časové pásmo",
  "input.businessTimeZone.placeholder": "GMT +02,00",
  "input.businessCountry.label": "Země",
  "input.businessCountry.placeholder": "Vyber zemi",
  "input.timeFormat.label": "Formát času",
  "input.timeFormat.placeholder": "24 hodin",
  "table.header.resourceName": "Zdroje",
  "btn.new.resource": "Nové zdroje",
  "input.resourceName.label": "Název zdroje",
  "input.resourceName.placeholder": "Zadejte jméno",
  "input.resourceDescription.label": "Popis",
  "input.resourceDescription.placeholder": "Vložit popis",
  "input.resourceLocation.placeholder": "Název umístění",
  "resourcesTooltip.location": "Popis umístění zdroje",
  "modal.newResource.title": "Vytvořit nový zdroj",
  "modal.editResource.title": "Upravit zdroj",
  "table.header.locationName": "Umístění",
  "table.header.locatonAddress": "Adresa",
  "table.header.location.Phone": "Telefon",
  "modal.newLocation.title": "Nové umístění",
  "modal.editLocation.title": "Upravit umístění",
  "btn.new.location": "Nové umístění",
  "btn.addExtraLocation": "Přidání dalšího umístění",
  "input.locationName.label": "Název umístění",
  "input.locationName.placeholder": "Zadejte název lokality",
  "input.profile.locationName.placeholder": "Např. Holičský salon",
  "input.slug.label": "Slug",
  "input.locationTips.label": "Tipy na umístění",
  "input.slug.explanation": "Zobrazí se v adrese URL vaší polohy",
  "input.contactEmail.label": "Kontaktní e-mail",
  "input.locationAddress.label": "Adresa",
  "input.locationAddress.placeholder": "Zadejte adresu místa",
  "tooltip.contactEmail": "Odpovědi na oznámení o schůzce klienta budou zaslány na tento e-mail.",
  "input.appointmentColors.label": "Barva schůzky",
  "input.colorBy.service": "Podle servisní skupiny",
  "input.colorBy.employee": "Podle personálu",
  "input.colorBy.appointment": "Podle stavu schůzky",
  "input.timeSlot.label": "Vyhrazený čas",
  "input.defaultViewType.label": "Výchozí zobrazení",
  "input.calendarTimeRange.label": "Časový rozsah kalendáře",
  "input.calendarTimeRange.allTimes.option": "Všechny časy (00:00 - 24:00)",
  "input.calendarTimeRange.onlyWorkingHours.option": "Pouze pracovní doba",
  "input.calendarTimeRange.customRange.option": "Vlastní rozsah",
  "input.weekStart.label": "Den začátku týdne",
  "table.header.refSourcetitle": "Zdroj doporučení",
  "table.header.refSourceAddDate": "Datum přidání",
  "btn.new.referral": "Nový zdroj doporučení",
  "input.nameRefSource.label": "Název zdroj doporučení",
  "input.nameRefSource.placeholder": "např. Facebook",
  "modal.newRefSource.title": "Nový zdroj doporučení",
  "modal.editRefSource.title": "Upravit zdroj doporučení",
  "input.sendBy.label": "Posláno",
  "input.emailSubject.label": "Předmět e-mailu",
  "input.emailTemplate.label": "Šablona e-mailu",
  "input.emailTemplate.placeholder": "Zadejte e-mailovou šablonu",
  "input.subject.label": "Předmět e-mailu",
  "input.subject.placeholder": "Zadejte předmět e-mailu",
  "title.messagingSettings.label": "Nastavení zpráv",
  "title.messagingSettings.description": "V nastavení typů zpráv nastavíte co bude automaticky odesláno klientům. Všechny vygenerované zprávy jsou snadno přístupné na stránce zpráv (v hlavní nabídce). Upravte nastavení jak a kdy se zprávy odešlou a upravte šablony tak aby se text přizpůsoboval. Pomocí níže uvedených značek můžete do zpráv zahrnout podrobnosti o schůzce:",
  "client.first.name": "CLIENT_FIRST_NAME",
  "client.last.name": "CLIENT_LAST_NAME",
  "staff.first.name": "STAFF_FIRST_NAME",
  "staff.last.name": "STAFF_LAST_NAME",
  "booking.date.time": "BOOKING_DATE_TIME",
  "booking.date": "BOOKING_DATE",
  "booking.time": "BOOKING_TIME",
  "service.name": "SERVICE_NAME",
  "business.name": "BUSINESS_NAME",
  "business.phone": "BUSINESS_PHONE",
  "business.address": "BUSINESS_ADDRESS",
  "business.email": "BUSINESS_EMAIL",
  "location.name": "LOCATION_NAME",
  "location.phone": "LOCATION_PHONE",
  "location.address": "LOCATION_ADDRESS",
  "location.email": "LOCATION_EMAIL",
  "tabs.reminders.title": "Připomenutí.",
  "input.enableReminder.label": "Povolit připomenutí zpráv",
  "input.enableReminder.description": "Automaticky odešle klientům před jejich nadcházející schůzkou",
  "input.reminder.title": "Připomínka {0}",
  "input.message.title": "Zpráva {0}",
  "input.reminder.description": "Kanály a čas odeslání",
  "input.reminder.channels": "Vyberte kanály, na které bude tato zpráva odeslána",
  "input.reminderBefore.label": "Připomenutí budou zasílány dříve.",
  "input.smsSender.label": "ID odesílatele SMS",
  "input.messageSender.label": "ID odesílatele zprávy",
  "input.smsSender.placeholder": "Zadejte ID odesílatele SMS",
  "input.smsTemplate.label": "Šablona pro SMS",
  "input.messageTemplate.label": "Šablona zprávy",
  "input.smsTemplate.placeholder": "Zadejte šablonu pro SMS",
  "input.whatsAppSender.placeholder": "Plandok",
  "reminder.whatsApp.notificate": "Šablona zprávy WhatsApp je v současné době kvůli omezením neupravitelná. Aktivně však pracujeme na tom, aby byla plně upravitelná.",
  "reminder.charactersCount": "Počet postav:",
  "reminder.pricing": "Ceny",
  "modal.pricing.description": "Různé země mají různé cenové sazby. Poskytujeme nejlepší možnou sazbu od našich partnerů. Může se lišit.",
  "modal.pricing.subDescription": "Poplatky budou účtovány po odeslání zpráv a přičteny k nadcházející faktuře.",
  "modal.pricing.counterDescription": "Standardní textová zpráva SMS je omezena na 160 znaků a 70 znaků pro zprávy se speciálními znaky.",
  "modal.pricing.learnMore": "Zjistěte více",
  "modal.pricing.priceRates": "Cenové sazby",
  "modal.pricing.messagePrice": "Cena za 1 zprávu:",
  "modal.sendTestMessageByNumber.title": "Odeslat testovací zprávu na toto číslo:",
  "modal.sendTestMessageByNumber.description": "Na toto číslo se vztahují poplatky jako na běžnou zprávu.",
  "modal.sendTestMessageByEmail.title": "Odeslat testovací zprávu na tento e-mail:",
  "modal.sendTestMessageByEmail.placeholder": "Zadejte prosím e-mailovou adresu",
  "btn.send": "Odeslat",
  "reminder.sendTestMessage": "Odeslat zkušební zprávu",
  "button.reminder.addTag": "Přidat značku",
  "button.reminder.addAnotherReminder": "PŘIDEJTE DALŠÍ PŘIPOMÍNKU",
  "button.message.addAnotherMessage": "PŘIDAT DALŠÍ ZPRÁVU",
  "button.reminder.upgrade": "Upgradujte pro přístup",
  "label.emailSettings": "Nastavení Email",
  "label.SMSSettings": "Nastavení SMS",
  "label.whatsAppSettings": "Nastavení WhatsApp",
  "tooltip.sender.0": "Zobrazí se jako jméno odesílatele SMS",
  "tooltip.sender.1": "maximum 11 znaků",
  "tooltip.sender.2": "Nemusí fungovat v některých zemích",
  "tooltip.sender.3": "Pouze latinská písmena nebo číslice",
  "tabs.confirmations.title": "Potvrzení",
  "btn.appBookings": "Rezervace v aplikaci",
  "btn.onlineBookings": "Online rezervace",
  "input.enableAppConfirmation.label": "Povolení potvrzení rezervací v aplikaci",
  "input.enableOnlineConfirmation.label": "Povolení potvrzení online rezervací v aplikaci",
  "input.enableConfirmationApp.description": "Automatické odeslání klientům, když je pro ně rezervována schůzka",
  "input.enableConfirmationOnline.description": "Automaticky odešle klientům, když si pro ně rezervují schůzku",
  "messageSetup.header": "Nastavení zprávy",
  "channels.subHeader": "Kanály",
  "tabs.reschedules.title": "Změnit plán",
  "input.enableReschedule.label": "Povolit přesunutí termínu zpráv",
  "input.enableReschedule.description": "Klientům automaticky odešle zprávu, když se jejich čas schůzky změní.",
  "tabs.cancellations.title": "Zrušení",
  "input.enableCancellation.label": "Povolit storno zpráv",
  "input.enableCancellations.description": "Klientům se automaticky odešle zpráva, když bude jejich schůzka zrušena.",
  "tabs.thanks.title": "Děkuji",
  "input.enableThank.label": "Povolit děkovné zprávy",
  "input.enableThankyou.description": "Zpráva je odeslána klientovi, když je stav schůzky označený jako ukončený.",
  "tabs.lastVisit.title": "Poslední návštěva",
  "input.enableLastVisit.label": "Povolit zprávy poslední návštěvy",
  "input.enableLastVisit.description": "Klientům kteří nenavštívili vaší firmu se automaticky odešle zpráva po uvedém počtu dní.",
  "table.cancelReason.name": "Důvody zrušení",
  "table.cancelReason.addDate": "Datum přidání",
  "btn.new.cancelReason": "Nový důvod zrušení",
  "modal.newCancelReason.title": "Nový důvod zrušení",
  "modal.editCancelReason.title": "Upravit důvod zrušení",
  "input.cancelReason.title": "Název důvodu zrušení",
  "input.cancelReason.placeholder": "např. klient není k dispozici",
  "table.discounts.name": "Slevy",
  "table.discounts.value": "Hodnota",
  "table.discounts.addDate": "Datum vytvoření",
  "input.discountName.label": "Název slevy",
  "input.discountName.placeholder": "např. senioři",
  "input.discountValue.label": "Hodnota slevy",
  "btn.new.discount": "Nová sleva",
  "input.discountPercentage.label": "% Procento",
  "input.discountSum.label": "Množství",
  "modal.newDiscount.title": "Nový typ slevy",
  "modal.editDiscount.title": "Upravit slevu",
  "modal.deleteAccount.title": "Odstranit účet",
  "modal.deleteAccount.description": "Odstranění účtu je nevratný proces a všechna vaše data budou navždy odstraněna.",
  "modal.deleteAccount.successMessage": "Váš účet byl úspěšně smazán.",
  "title.mySettings": "Moje nastavení",
  "title.personalDetails": "Osobní údaje",
  "title.personalDetails.description": "Zadejte své jméno a kontaktní údaje. E-mail který je zde uvedený použijte pro přihlášení.",
  "btn.continueWithGoogle.title": "Pokračovat ve službě Google",
  "btn.continueWithFacebook.title": "Pokračovat na Facebooku",
  "notification.googleAccountUnlinked": "Účet Google odpojen",
  "notification.googleAccountLinked": "Propojený účet Google",
  "notification.facebookAccountUnlinked": "Účet Facebook nepropojený",
  "notification.facebookAccountLinked": "Propojený účet na Facebooku",
  "btn.linkAccount": "Propojení účtu",
  "btn.unlinkAccount": "Zrušit propojení účtu",
  "btn.deleteYourAccount": "Smazání účtu",
  "input.myName.label": "Jméno",
  "input.myName.placeholder": "např. Petr",
  "input.mylastName.label": "Příjmení",
  "input.mylastName.placeholder": "např. Novák",
  "title.changePassword": "Změnit heslo",
  "title.changePassword.description": "Chcete-li provést změnu hesla, zadejte své stávající heslo a poté nové. Pokud neznáte své stávající heslo, můžete se odhlásit a použít odkaz Obnovit heslo na stránce Přihlášení.",
  "input.currentPassword.label": "Stávající heslo",
  "label.currentPassword.placeholder": "Vložte stávající heslo",
  "input.newPassword.label": "Nové heslo",
  "input.newPassword.placeholder": "Zadejte nové heslo",
  "input.verifyPassword.label": "Zopakujte heslo",
  "input.verifyPassword.placeholder": "Zopakujte nové heslo",
  "btn.confirmDelete": "Ano, Odstranit.",
  "btn.confirm": "Potvrdit",
  "btn.confirmBook": "Potvrzení a rezervace",
  "btn.confirmPay": "Potvrdit a zaplatit",
  "btn.delete": "Odstranit",
  "btn.close": "Zavřít",
  "validation.required": "Pole je vyžadováno",
  "validation.email.format": "Nesprávný formát e-mailu",
  "validation.url.format": "Nesprávný formát url, musí začínat https:// nebo http://",
  "validation.password.length": "Heslo musí obsahovat alespoň 7 znaků.",
  "validation.password.letters": "Heslo mus obsahovat číslo a písmeno.",
  "validation.startTime": "Čas zahájení nesmí být pozdější nebo stejný jako čas ukončení",
  "validation.endTime": "Čas ukončení nemůže být dřívější nebo stejný jako čas zahájení",
  "tooltip.password.title": "Heslo musí:",
  "tooltip.password.characters": "Musí mít nejméně 7 znaků",
  "tooltip.password.number": "Obsahovat čísla",
  "tooltip.password.letter": "Obsahovat písmena",
  "tooltip.location": "Popis umístění",
  "footer.support.center": "Potřebujete pomoc? Kontaktujte nás.",
  "not.found": "Nenalezeno.",
  "input.cancelReason.label": "Důvod zrušení",
  "input.location.label": "Umístění",
  "input.location.placeholder": "Název umístění",
  "input.name.label": "Název",
  "title.recentlyAddedClients": "Klienti (nedávno přidaní)",
  "title.calendarView": "Zobrazení kalendáře",
  "text.manageProfile": "Spravovat svůj uživatelský profil",
  "modal.language.title": "Výběr jazyka",
  "modal.language.placeholder": "Vyhledávání jazyk",
  "dropdown.access.medium": "Střední",
  "dropdown.access.high": "Vysoký",
  "dropdown.access.low": "Nízký",
  "dropdown.access.basic": "Základní",
  "dropdown.access.no": "Žádný přístup",
  "dropdown.access.owner": "Majitel",
  "button.select.all": "Vybrat vše",
  "button.selected": "Vybraný",
  "dropdown.gender.unknown": "Neznámý",
  "dropdown.gender.male": "Mužský",
  "dropdown.gender.female": "Ženský",
  "dropdown.notificationBy.none": "Neposílejte",
  "dropdown.notificationBy.sms": "SMS",
  "dropdown.notificationBy.email": "E-mailem",
  "dropdown.notificationBy.combined": "SMS a E-mail",
  "notification.success.login": "Úspěšném přihlášení!",
  "notification.success.register": "Úspěšně zaregistrován!",
  "dropdown.time.minutes": "Minuty",
  "dropdown.repeat.no": "Neopakujte",
  "dropdown.repeat.weekly": "Týdně",
  "dropdown.endRepeat.ongoing": "Pokračující/trvající",
  "dropdown.endRepeat.weekly": "Konkrétní datum",
  "dropdown.time.appointment.immediately": "Ihned",
  "dropdown.time.appointment.upTo1Hour": "Do 1 hodiny",
  "dropdown.time.appointment.upTo2Hours": "Až 2 hodiny",
  "dropdown.time.appointment.upTo3Hours": "Až 3 hodiny",
  "dropdown.time.appointment.upTo4Hours": "Až 4 hodiny",
  "dropdown.time.appointment.upTo5Hours": "Až 5 hodin",
  "dropdown.time.appointment.upTo6Hours": "Až 6 hodin",
  "dropdown.time.appointment.upTo7Hours": "Až 7 hodin",
  "dropdown.time.appointment.upTo8Hours": "Až 8 hodin",
  "dropdown.time.appointment.upTo9Hours": "Až 9 hodin",
  "dropdown.time.appointment.upTo10Hours": "Až 10 hodin",
  "dropdown.time.appointment.upTo11Hours": "Až 11 hodin",
  "dropdown.time.appointment.upTo12Hours": "Až 12 hodin",
  "dropdown.time.appointment.upTo13Hours": "Až 13 hodin",
  "dropdown.time.appointment.upTo14Hours": "Až 14 hodin",
  "dropdown.time.appointment.upTo15Hours": "Až 15 hodin",
  "dropdown.time.appointment.upTo16Hours": "Až 16 hodin",
  "dropdown.time.appointment.upTo17Hours": "Až 17 hodin",
  "dropdown.time.appointment.upTo18Hours": "Až 18 hodin",
  "dropdown.time.appointment.upTo19Hours": "Až 19 hodin",
  "dropdown.time.appointment.upTo20Hours": "Až 20 hodin",
  "dropdown.time.appointment.upTo21Hours": "Až 21 hodin",
  "dropdown.time.appointment.upTo22Hours": "Až 22 hodin",
  "dropdown.time.appointment.upTo23Hours": "Až 23 hodin",
  "dropdown.time.appointment.upTo24Hours": "Až 1 den",
  "dropdown.time.appointment.upTo48Hours": "Až 2 dny",
  "dropdown.time.appointment.upTo3days": "Až 3 dny",
  "dropdown.time.appointment.upTo4days": "Až 4 dny",
  "dropdown.time.appointment.upTo5days": "Až 5 dní",
  "dropdown.time.appointment.upTo6days": "Až 6 dní",
  "dropdown.time.appointment.upTo1week": "Až 1 týden",
  "dropdown.time.appointment.upTo2weeks": "Až 1 týden",
  "dropdown.time.appointment.upTo15Min": "Až 15 minut",
  "dropdown.time.appointment.upTo30Min": "Až 30 minut",
  "dropdown.time.appointment.upTo1Month": "Až 1 měsíc",
  "dropdown.time.appointment.upTo2Months": "Až 2 měsíce",
  "dropdown.time.appointment.upTo3Months": "Až 3 měsíce",
  "dropdown.time.appointment.upTo4Months": "Až 4 měsíce",
  "dropdown.time.appointment.upTo5Months": "Až 5 měsíců",
  "dropdown.time.appointment.upTo6Months": "Až 6 měsíců",
  "dropdown.time.appointment.interval5Min": "5 minut",
  "dropdown.time.appointment.interval10Min": "10 minut",
  "dropdown.time.appointment.interval15Min": "15 minut",
  "dropdown.time.appointment.interval20Min": "20 minut",
  "dropdown.time.appointment.interval30Min": "30 minut",
  "dropdown.time.appointment.interval40Min": "40 minut",
  "dropdown.time.appointment.interval45Min": "45 minut",
  "dropdown.time.appointment.interval50Min": "50 minut",
  "dropdown.time.appointment.interval60Min": "60 minut",
  "dropdown.time.appointment.interval75Min": "75 minut",
  "dropdown.time.appointment.interval90Min": "90 minut",
  "dropdown.time.appointment.interval120Min": "120 minut",
  "dropdown.time.appointment.interval150Min": "150 minut",
  "dropdown.time.appointment.interval180Min": "180 minut",
  "dropdown.time.appointment.interval240Min": "240 minut",
  "dropdown.time.appointment.anyTime": "Kdykoli",
  "dropdown.time.appointment.disabled": "Vypnutý",
  "dropdown.time.appointment.doesntRepeat": "Neopakuje se",
  "dropdown.time.appointment.daily": "Denní",
  "dropdown.time.appointment.every2Days": "Každé 2 dny",
  "dropdown.time.appointment.every3Days": "Každé 3 dny",
  "dropdown.time.appointment.every4Days": "Každé 4 dny",
  "dropdown.time.appointment.every5Days": "Každých 5 dní",
  "dropdown.time.appointment.every6Days": "Každých 6 dní",
  "dropdown.time.appointment.every7Days": "Každých 7 dní",
  "dropdown.time.appointment.weekly": "Týdně",
  "dropdown.time.appointment.every2weeks": "Každé 2 týdny",
  "dropdown.time.appointment.every3weeks": "Každé 3 týdny",
  "dropdown.time.appointment.every4weeks": "Každé 4 týdny",
  "dropdown.time.appointment.every5weeks": "Každých 5 týdnů",
  "dropdown.time.appointment.every6weeks": "Každých 6 týdnů",
  "dropdown.time.appointment.every7weeks": "Každých 7 týdnů",
  "dropdown.time.appointment.every8weeks": "Každých 8 týdnů",
  "dropdown.time.appointment.every9weeks": "Každých 9 týdnů",
  "dropdown.time.appointment.every10weeks": "Každých 10 týdnů",
  "dropdown.time.appointment.monthly": "Měsíční",
  "dropdown.time.appointment.every2months": "Každé 2 měsíce",
  "dropdown.time.appointment.every3months": "Každé 3 měsíce",
  "dropdown.time.appointment.every4months": "Každé 4 měsíce",
  "dropdown.time.appointment.every5months": "Každých 5 měsíců",
  "dropdown.time.appointment.every6months": "Každých 6 měsíců",
  "dropdown.time.appointment.every7months": "Každých 7 měsíců",
  "dropdown.time.appointment.every8months": "Každých 8 měsíců",
  "dropdown.time.appointment.every9months": "Každých 9 měsíců",
  "dropdown.time.appointment.every10months": "Každých 10 měsíců",
  "dropdown.time.appointment.every11months": "Každých 11 měsíců",
  "dropdown.time.appointment.after2times": "Po 2x",
  "dropdown.time.appointment.after3times": "Po 3x",
  "dropdown.time.appointment.after4times": "Po 4x",
  "dropdown.time.appointment.after5times": "Po 5x",
  "dropdown.time.appointment.after6times": "Po 6x",
  "dropdown.time.appointment.after7times": "Po 7x",
  "dropdown.time.appointment.after8times": "Po 8x",
  "dropdown.time.appointment.after9times": "Po 9x",
  "dropdown.time.appointment.after10times": "Po 10x",
  "dropdown.time.appointment.after11times": "Po 11 krát",
  "dropdown.time.appointment.after12times": "Po 12x",
  "dropdown.time.appointment.after13times": "Po 13x",
  "dropdown.time.appointment.after14times": "Po 14x",
  "dropdown.time.appointment.after15times": "Po 15x",
  "dropdown.time.appointment.after20times": "Po 20x",
  "dropdown.time.appointment.after25times": "Po 25 krát",
  "dropdown.time.appointment.after30times": "Po 30x",
  "dropdown.time.appointment.specificDate": "Konkrétní datum",
  "modal.createHours.title": "Vytvoření {0} hodin",
  "modal.editHours.title": "Upravit {0} hodin",
  "modal.deleteItem.confirm.0": "Jste si jisti, že chcete trvale",
  "modal.deleteItem.confirm.1": "vymazat",
  "modal.deleteItem.confirm.2": "z databáze?",
  "modal.entity.category": "Kategorie",
  "modal.entity.referal": "Zdroj doporučení",
  "modal.entity.resource": "Zdroje",
  "modal.entity.location": "Umístění",
  "modal.deleteReminder.title": "Odstranění připomenutí",
  "modal.deleteMessage.title": "Smazat zprávu",
  "modal.deleteReminder.description": "Opravdu chcete tuto připomínku smazat ?",
  "modal.deleteMessage.description": "Opravdu chcete tuto zprávu smazat ?",
  "modal.deleteReminder.confirm": "Ano, vymažte",
  "modal.deletePaymentMethod.title": "Odstranění způsobu platby",
  "modal.deletePaymentMethod.description": "Tento způsob platby bude trvale odstraněn",
  "closedDate.create.modal.title": "Přidat datum uzavření",
  "closedDate.edit.modal.title": "Upravit datum uzavření",
  "staff.access.owner": "Majitel",
  "staff.access.high": "Vysoký",
  "staff.access.medium": "Střední",
  "staff.access.low": "Nízký",
  "staff.access.basic": "Základní",
  "staff.access.no_access": "Žádný přístup",
  "staff.create.title": "Přidat datum uzavření",
  "staff.emptyState.title": "Žádní plánovaní zaměstnanci.",
  "staff.emptyState.description": "Chcete-li přidat pracovní dobu, podívejte se do sekce pro zaměstnance.\n",
  "staff.viewStaffMembers.button": "Zobrazit zaměstnance",
  "staff.picture.changePhoto": "Změnit fotku",
  "staff.picture.removePhoto": "Odebrat fotku",
  "input.clientSearch.placeholder": "Vyhledávat podle jména, e-mailu nebo mobilního telefonu",
  "input.blockedDate.label": "Datum",
  "appointment.notes.title": "Poznámky ke schůzce",
  "appointment.history.title": "Historie schůzek",
  "appointment.history.by.title": " podle {0}",
  "appointment.log.created.label": "Vytvořil",
  "appointment.log.updated.label": "Aktualizováno uživatelem",
  "appointment.log.rescheduled.label": "Přeplánováno uživatelem",
  "appointment.log.cancelled.label": "Zrušeno uživatelem",
  "appointment.log.changed.label": "Stav změnil uživatelem",
  "appointment.notificationLog.rescheduling": "Oznámení o přeplánování odesláno dne",
  "appointment.notificationLog.cancellation": "Oznámení o zrušení odesláno dne",
  "appointment.notificationLog.confirmation": "Oznámení o potvrzení odesláno dne",
  "appointment.notificationLog.reminder": "Oznámení o připomenutí odesláno",
  "appointment.notificationLog.thankYou": "Oznámení s poděkováním bylo odesláno",
  "appointment.notificationLog.lastVisit": "Oznámení o poslední návštěvě odesláno dne",
  "state.cancelReason.title": "Nastavte důvody zrušení",
  "state.cancelReason.description": "Důvody zrušení Vám pomůžou sledovat/určit proč Vaši klienti na jejich schůzku nepřišli.",
  "state.cancelReason.button": "Přidejte důvod zrušení",
  "state.resources.title": "Zpravovat vaše zdroje",
  "state.resources.description": "Podnikání je úspěšnější, když víte o dostupných zdrojích. Mějte přehled o místnostech, nábytku nebo jiných zařízeních, abyste zajistili plynulost služby a bez překážek.",
  "state.resources.button": "Přidejte zdroj",
  "state.referralSource.title": "Zacházejte se zdroji doporučení",
  "state.referralSource.description": "Zdroj doporučení naznačuje, odkud klienti pocházejí. Sledujte je a pomocí těchto informací případně zvyšte viditelnost své firmy a míru konverze.",
  "state.referralSource.button": "Přidejte zdroj doporučení",
  "state.locations.title": "Nastavte si vaše umístění.",
  "state.locations.description": "Zde můžete spravovat více prodejen své firmy, nastavit různou pracovní dobu pro své zaměstnance podle místa a mít pro každého z nich samostatné kalendáře.",
  "state.staff.title": "Spravujte/řiďte své zaměstnance",
  "state.staff.description": "Vytvářejte profily pro své zaměstnance, abyste mohli přidávat a upravovat jejich plány/programy a také sledovat jejich schůzky a pracovní dobu.",
  "state.messageLog.title": "Sledujte zprávy",
  "state.messageLog.description": "Zprávy připomínají vašim klientům nadcházející schůzky nebo je informují. Zde budete moci sledovat zprávy a jejich stav. Chcete-li nakonfigurovat automatické zprávy, přejděte na",
  "state.messageLog.link": "Nastavení upzornění",
  "state.clients.title": "Spravujte své klienty.",
  "state.clients.description": "Péče o klienty začíná ve chvíli kdy profil obsahuje veškeré informace, abyste je byli schopni jednoduše kontaktovat.",
  "state.clients.button": "Přidat klienta",
  "state.services.title": "Nastavit skupiny a služby",
  "state.services.description": "Skupiny jsou nezbytné, protože umožňují seskupovat podobné služby, které poskytujete. Navíc váš kalendář vypadá přehledně a snadno přístupný!",
  "state.services.button": "Přidat servisní skupinu",
  "state.calendar.title": "Žádný plánový personál",
  "state.calendar.description": "Chcete-li personálu nastavit jeho pracovní dobu, podívejte se (viz.)",
  "state.calendar.button": "Personál sekce",
  "state.closedDates.title": "Termíny uzavření",
  "state.closedDates.description": "Uveďte data, kdy je vaše firma uzavřena z důvodu státních svátků, údržby nebo z jakéhokoli jiného důvodu. Klienti v těchto dnech nebudou moci provádět online rezervace.",
  "state.closedDates.button": "Přidat datum uzavření",
  "title.billingPlan": "Plán a fakturace",
  "btn.upgrade": "Aktualizace",
  "header.usage": "Aktualizace",
  "header.dropdown.subscription.plan": "{0} Plán",
  "header.dropdown.subscription.staff": "({0} Zaměstnanci)",
  "header.dropdown.notifications.description": "{0} zprávy",
  "header.paymentDetails": "Zobrazit detaily platby",
  "title.billingPlan.description": "Spravujte své účty. Plánujte a fakturujte, zobrazujte transakce a stahujte faktury.",
  "plan.free.title": "Pro začátek",
  "plan.individual.title": "Individuální (pouze1 zaměstnanec)",
  "plan.business.title": "Obchodní",
  "btn.upgradeNow": "Aktualizace",
  "btn.getStarted": "Začněte",
  "btn.currentPlan": "Součastný plán",
  "plan.free.option.0": "1 Umístění",
  "plan.free.option.1": "Až 5 zaměstnanců",
  "plan.free.option.2": "Neomezené schůzky",
  "plan.free.option.3": "Neomezený počet klientů",
  "plan.free.option.4": "Neomezené online rezervace",
  "plan.free.option.5": "E-mailová upozornění",
  "plan.premium.option.0": "Neomezený počet míst",
  "plan.premium.option.1": "Neomezené schůzky",
  "plan.premium.option.2": "Neomezený počet klientů",
  "plan.premium.option.3": "Neomezené online rezervace",
  "plan.premium.option.4": "Opakující se schůzky",
  "plan.premium.option.5": "E-mailová upozornění",
  "plan.premium.option.6": "SMS upozornění* ",
  "plan.premium.option.7": "Oznámení Whatsapp*",
  "plan.premium.option.8": "Neomezená analytika",
  "plan.premium.option.9": "Neomezené přehledy",
  "plan.premium.option.10": "Tisk rozvrhů",
  "plan.premium.option.11": "Export klienta",
  "plan.premium.option.12": "Přednostní podpora",
  "plan.premium.option.13": "Odstraněna značka Plandok z e-mailů (bezplatné e-maily Powered by Plandok)",
  "plan.premium.costsInfo": "*Poplatky za SMS/WhatsApp nejsou zahrnuty v plánu, účtují se dodatečné poplatky.",
  "plan.premium.activeUntilDate": "Aktivní do {0}",
  "period.perMonth": "měsíčně (bez DPH)",
  "plan.premium.description": "Cena se zvýší přidáním dalších zaměstnanců.",
  "plan.premium.viewPricing": "Zobrazit ceny",
  "plan.premium.numberStuff": "Váš aktuální počet zaměstnanců: {0}",
  "premium.membership": "Cena prémiového členství se může lišit v závislosti na počtu vašich zaměstnanců.",
  "premium.price.perMonth": "Prémiová cena za měsíc",
  "pricing.calculated": "Cena se vypočítá takto:",
  "pricing.calculator": "Cenová kalkulačka",
  "pricing.numberStuff": "Počet zaměstnanců",
  "pricing.extra.member": "{0} za každého dalšího člena",
  "modal.title.pricing": "Ceny",
  "plan.foreverFree": "Navždy zdarma",
  "plan.individual.0": "Vše je v základu, plus:",
  "plan.individual.1": "Vlastní oznámení",
  "plan.individual.2": "Odebrat plandok značku",
  "plan.individual.option.3": "Neomezená SMS připomenutí",
  "plan.individual.4": "Prioritní podpora",
  "plan.business.0": "Vče je idividuální, plus:",
  "plan.business.1": "Neomezené místo",
  "plan.business.2": "Neomezený počet zaměstnanců",
  "block.billingInfo.title": "Fakturační údaje",
  "block.carInfo.title": "Informace o kreditní kartě",
  "input.cardNumber.label": "Číslo karty",
  "input.cardNumber.placeholder": "1234 5678 3456 2456",
  "input.expireDate.label": "Datum konce platnosti karty",
  "input.expireDate.placeholder": "05/21",
  "input.cvv.label": "CVV",
  "input.cvv.placeholder": "123",
  "modal.confirmCancel.subscription": "Jste si jisti, že chcete ukončit předplatné?",
  "modal.confirmCancel.subscription.description": "Jakmile to jednou uděláte budete opět používat bezplatnou verzi",
  "btn.cancelSubscription.confirm": "Ano, zrušit předplatné.",
  "loading.processingSubscribtion": "Vaše platba se zpracovává. Prosím čekejte…",
  "state.serviceGroup.description": "Žádné služby ve skupině.",
  "state.serviceGroup.btn": "Přidat službu",
  "appointment.status.new": "Nová schůzka",
  "appointment.status.confirmed": "Potvrzena.",
  "appointment.status.notShow": "No show",
  "appointment.status.completed": "Dokončeno",
  "appointment.status.cancelled": "Zrušeno",
  "billing.tab.plan": "Váš plán",
  "billing.tab.costs": "Odhadované náklady",
  "billing.tab.billing": "Fakturační údaje",
  "billing.tab.invoice": "Faktury",
  "plan.free": "Zdarma",
  "plan.deactivated.title": "Služba Premium byla deaktivována",
  "plan.active.title": "Aktivovat",
  "payment.step.title": "Platby",
  "payment.error.title": "Aktualizovat platbu",
  "payment.payNow.title": "Platit",
  "payment.dismiss.title": "Propustit",
  "payment.tab.title.sub": "Předplatné",
  "payment.tab.subTitle": "Prémiový plán",
  "payment.per.members": "na člena",
  "payment.tab.title.notification": "Oznámení",
  "payment.card.details": "Podrobnosti o kartě",
  "payment.success.message": "Platba proběhla úspěšně",
  "payment.payWithCard.title": "Platba kartou",
  "payment.cardNumber.title": "Číslo karty",
  "payment.expirationDate.title": "Datum vypršení platnosti karty",
  "payment.digits.title": "(3 číslice)",
  "payment.amount": "Částka k úhradě:",
  "payment.btn.pay": "Platit",
  "invoice.table.date": "Datum",
  "invoice.table.invoice": "Faktura",
  "invoice.table.download": "Stahnout",
  "invoice.table.status": "Stav",
  "costs.billingPeriod.title": "Odhadované náklady pro toto zúčtovací období",
  "costs.billingPeriod.description": "Toto jsou aktuální náklady na vaše používání v tomto fakturačním období. Níže je k dispozici rozpis vašich nákladů.",
  "costs.billingPeriod.additionalInfo": "Jakmile vaše výdaje za oznámení dosáhnou 50 €, budou automaticky účtovány. Nemusíte se však obávat, pokud je částka nižší; bude pohodlně zahrnuta do vašeho měsíčního předplatného.",
  "costs.summary.title": "Souhrn od měsíce do dnešního dne",
  "costs.summary.description": "Tyto poplatky jsou započítány do zůstatku vašeho účtu",
  "costs.summary.detailedCharges": "Podrobné poplatky",
  "costs.summary.noData": "Žádná data",
  "costs.summary.quantity": "Množství",
  "costs.summary.subtotal": "Mezisoučet",
  "costs.summary.taxes": "Daně",
  "dropdown.time.hours": "hodiny",
  "message.planUpgrade": "Úspěšně jste aktualizovali plán!",
  "costs.subscription": "Předplatné",
  "btn.cancelSubscription": "Zrušit předplatné",
  "btn.no": "Ne",
  "notification.success.update": "Úspěčně aktualizováno.",
  "validation.password.match": "Heslo a potvrzovací heslo se neshodují",
  "blocked.time": "Blokovaný čas",
  "message.successReset.password": "Hotovo! E-mail o restartu byl odeslán, zkontrolujte vaší e-mailovou schránku.",
  "title.newPassword": "Zadejte nové heslo",
  "title.newPassword.description": "Bezpečný přístup k účtu prostřednictvím vytvoření nového přihlašovacího hesla",
  "input.newPasswordRepeat.label": "Potvrďte nové heslo",
  "btn.change.password": "Změnit heslo.",
  "text.havingTrouble": "Máte potíže?",
  "link.resetPassword.again": "Obnovit heslo znovu.",
  "message.successNew.password": "Úspěsná změna hesla.",
  "title.repeatShifts": "Opakujte směnu",
  "text.confirmShift.update": "Upravili jste směnu, která se opakuje každý týden. Odstranění nadcházejících směn se přepíše {0} na probíhající {0} plán",
  "text.confirmShift.delete": "Upravili jste směnu, která se opakuje každý týden. Odstranění nadcházejících směn se přepíše {0} na probíhající {0} plán",
  "btn.deleteUpcoming.shifts": "Odstranění nadcházejících směn",
  "btn.deleteThis.shift": "Smazat pouze tuto směnu",
  "btn.updateUpcoming.shifts": "Aktualizovat nadcházející směny",
  "btn.updateThis.shift": "Aktualizovat pouze tuto směnu",
  "text.permisionTab": "Nastavení sekcí, které jsou přístupné pro každou úroveň uživatelských oprávnění.\nVšichni přihlášení zaměstnanci mají přístup do kalendáře a vlastník účtů ma plný přístup do systému.",
  "title.permission.locations": "Místa",
  "title.permission.booking": "Rezervace & Klienti",
  "title.permission.staff": "Personál",
  "title.permission.setup": "Založit/Nastavit",
  "title.permission.basic": "Základní",
  "title.permission.low": "Nízký",
  "title.permission.med": "Průměrný",
  "title.permission.high": "Vysoký",
  "title.permission.owner": "Vlastník",
  "title.allLocations.permission": "Přístup ke všem místům",
  "title.accessCalendar.permission": "Přístup do vlastního kalendáře",
  "title.accessOtherCalendar.permission": "Přístup ke kalendáři ostatních zaměstnanců",
  "title.canBook.permission": "Možnost rezervovat schůzky",
  "title.contactInfo.permission": "Možnost vidět kontaktní informace klienta",
  "title.clients.permission": "Klienti",
  "title.messages.permission": "Zrávy",
  "title.services.permission": "Služby",
  "title.workingHours.permission": "Pracovní doba",
  "title.staffMembers.permission": "Zaměstnanci",
  "title.permissionLevels.permission": "Úrovně oprávnění",
  "title.accountSetup.permission": "Nastavení účtu",
  "title.clientSettings.permission": "Nastavení klienta",
  "title.onlineBookings.permission": "Online rezervace",
  "title.analytics.permission": "Analytika",
  "title.marketing.permission": "Marketing",
  "notification.type.reminder": "Připomínka",
  "notification.type.confirmation": "Potvrzení",
  "notification.type.online_booking_confirmation": "Potvrzení rezervace online",
  "notification.type.rescheduling": "Změnit plán",
  "notification.type.cancellation": "Zrušení",
  "notification.type.thank_you": "Děkuji",
  "notification.type.last_visit": "Poslední návštěva",
  "notification.channel.sms": "SMS",
  "notification.channel.email": "E-mail",
  "notification.channel.whatsapp": "WhatsApp",
  "notification.status.sent": "Odesláno",
  "notification.status.pending": "Čeká se na",
  "notification.status.failed": "Neúspěšně",
  "dropdown.time.days": "dny",
  "title.appointment": "schůzka",
  "input.reminderBeforeLastVisit.label": "Oznámení bude odesláno po uvedeném počtu dní.",
  "txt.with": "s.",
  "short.hours": "hod.",
  "short.minutes": "min.",
  "input.registrationNumber.label": "IČO",
  "input.vatNumber.label": "DIČ",
  "invoice.table.priceTaxIncl": "Množství.",
  "error.wrongSenderId": "ID odesílatele musí obsahovat číslice nebo znaky, délka musí být více jak 3 a nižší než 11 symbolů.",
  "sidebar.help": "Nápověda",
  "modal.help.title": "Jak můžeme pomoci?",
  "modal.help.liveChat.title": "Živý chat",
  "modal.help.liveChat.description": "Máte dotazy? Zeptejte se nás hned",
  "modal.help.suggestFeature.title": "Navrhněte funkci",
  "modal.help.suggestFeature.description": "Sdílejte návrhy, podívejte se na návrhy ostatních a hlasujte pro funkce, které chcete",
  "modal.help.viewUpdates.title": "Zobrazit aktualizace",
  "modal.help.viewUpdates.description": "Prohlédněte si naše nejnovější aktualizace a vylepšení",
  "warning.title.paidFeature": "Placená funkce",
  "warning.smsFeatureMissing": "Chcete-li získat přístup k nastavení SMS, musíte svůj program aktualizovat Chcete-li aktualizovat, tak navštivte",
  "warning.link.billingAndPricing": "Plán a fakturace.",
  "warning.newPage.0": "Aktualizováno!",
  "warning.newPage.1": "Pokud jste použili starou verzi Plandok, tak vaše heslo už nefunguje, prosím",
  "warning.newPage.2": "obnovte heslo",
  "warning.newPage.3": "a užijte si nový systém. Pro více informací nás kontaktujte",
  "warning.newPage.4": "nebo",
  "title.blockTime.select": "Vyberte čas",
  "calendar.allResources": "Všechny zdroje",
  "appointment.edit.title": "Upravit schůzku",
  "appointment.create.title": "Schůzka byla úspěšně vytvořena.",
  "appointment.update.title": "Schůzka byla úspěšně aktualizována.",
  "select.notify.title": "{0} nefunguje ve vybraném intervalu, ale stále si můžete rezervovat schůzky.",
  "booking.notify.title": "Vybraný čas je rezervován, ale můžete si vytvořit schůzku.",
  "textArea.characters.left": "Zbývá znaků",
  "textArea.characters.exceeded": "limit překročen o",
  "validation.characters.exceeded": "Počet znaků byl překročen",
  "staff.menu.members": "Zaměstnanci/Personál",
  "staff.menu.dates": "Non-pracovní dny",
  "staff.menu.working": "Pracovní doba",
  "staff.menu.permissions": "Uživatelská oprávnění",
  "staff.menu.members.description": "Přidávejte nebo odebírejte členy tými a spravujte jejich úroveň uživatelského přístupu",
  "staff.menu.dates.description": "Naplánujte nadcházející uzavření obchodů nebo státní svátky",
  "staff.menu.working.description": "Spravujte dostupnost kalendáře, změnou pracovního plánu personálu.",
  "staff.menu.permissions.description": "Nastavte přístupová oprávnění pro citlivé funkce a informace",
  "message.successUpdateLocation": "Hotovo! Profil polohy byl úspěšně aktualizován.",
  "ob.title": "Online rezervace",
  "ob.overview.tab": "Přehled",
  "ob.massMessages.tab": "Hromadné zprávy",
  "ob.campaigns.tab": "Kampaně",
  "ob.profile.tab": "Online profil",
  "ob.links.tab": "Odkazy",
  "ob.settings.tab": "Nastavení",
  "ob.payments.tab": "Platby",
  "ob.status.online": "Online",
  "ob.status.offline": "Offline",
  "ob.status.title": "Postavení",
  "ob.profile.step": "{0} ze 4",
  "ob.profile.step.title": "Profil a umístění",
  "ob.profile.step.description": "Představte svou pobočku a představte služby, které poskytujete svým zákazníkům. Část „About“ je jednou z prvních sekcí, na které se vaši zákazníci budou dívat, a pokud tyto informace nestačí, nezapomeňte zanechat telefonní číslo, kde vás zákazníci mohou kontaktovat pro více informací! Zadejte adresu, kde poskytujete své služby, aby vaši klienti mohli být informováni a zobrazit adresu.",
  "ob.overview.placheholder.title": "Nechte své klienty rezervovat si schůzky online!",
  "ob.overview.placheholder.description": "Vytvořte si profil online rezervací a nechte své klienty rezervovat si schůzky online. Jednoduše vložte odkaz na váš web nebo sociální síť a je to.",
  "ob.overview.start.now": "Začněte hned",
  "ob.links.direct.title": "Přímý odkaz",
  "ob.links.facebook.title": "Odkaz na Facebook",
  "ob.links.instagram.title": "Odkaz na Instagram",
  "ob.links.direct.description": "URL Odkaz na váš profil online rezervací, můžete jej použít na svých webových stránkách nebo na účtech sociálních médií.  Pokud si nejste jisti, jak odkaz použít, kontaktujte nás.",
  "ob.links.facebook.description": "Sdílejte tento odkaz na Facebooku, abyste přilákali více zákazníků a usnadnili jim najít vaši firmu a zarezervovat si schůzku.",
  "ob.links.instagram.description": "Usnadněte si to a propojte svou online rezervaci přímo!\nMůžete jej použít na svém webu a kdekoli jinde, kde se vám zamane.\nPokud si nejste jisti, jak odkaz použít, nemějte obavy, my vám poradíme!",
  "ob.analytics.tab.description": "Přidejte Google Analytics a Facebook Pixel na stránku s online rezervacemi a analyzujte návštěvnost a chování uživatelů.",
  "ob.analytics.google.title": "Google Analytics",
  "ob.analytics.facebook.title": "Facebook Pixel",
  "ob.analytics.google.description": "Pomocí služby Google Analytics můžete sledovat a analyzovat návštěvnost webových stránek a chování uživatelů a získat tak přehled o tom, jak své webové stránky vylepšit.",
  "ob.analytics.active": "Aktivní",
  "ob.analytics.inactive": "Neaktivní",
  "ob.analytics.setUp.btn": "Nastavení",
  "ob.analytics.google.description.1": "Google Analytics je výkonný nástroj, který vám pomůže sledovat návštěvnost webových stránek a chování uživatelů. Pomocí nástroje Google Analytics můžete sledovat počet návštěvníků vašeho webu, odkud přicházejí, jaké stránky navštěvují a jak dlouho na webu zůstávají.",
  "ob.analytics.google.description.2": "Google Analytics navíc umožňuje nastavit cíle a sledovat konverzace, díky čemuž lépe pochopíte, jak dobře si vaše webové stránky vedou a jak je můžete zlepšit.",
  "ob.analytics.google.trackingId": "ID pro sledování",
  "ob.analytics.google.tooltip": "Chcete-li najít své ID sledování, musíte se nejprve přihlásit ke svému účtu Google Analytics, vybrat webovou stránku nebo aplikaci, kterou chcete sledovat, a poté přejít na kartu \"Správce\". Tam klikněte na \"Kód sledování\". Vaše ID sledování se zobrazí ve formátu \"UA-XXXX-Y\".",
  "ob.analytics.google.account": "Nemáte ještě účet Google Analytics?",
  "ob.analytics.click": "Klikněte na",
  "ob.analytics.here": "zde",
  "ob.analytics.learn": "a dozvíte se, jak si ho vytvořit.",
  "ob.analytics.facebook.description.1": "Facebook Pixel je nástroj, který umožňuje sledovat chování uživatelů na vašich webových stránkách, včetně konverzí, zobrazení stránek a dalších událostí.",
  "ob.analytics.facebook.description.2": "Facebook Pixel vám může poskytnout cenné informace o vašem publiku, jako jsou demografické údaje, zájmy, typ zařízení, zobrazení stránek a provedené akce, které se shromažďují, což vám může pomoci lépe pochopit a optimalizovat uživatelskou zkušenost na vašich stránkách.",
  "ob.analytics.facebook.pixelId": "ID pixelu",
  "ob.analytics.facebook.tooltip": "Chcete-li zjistit své Facebook Pixel ID, postupujte podle následujících kroků: Přejděte na svůj účet Facebook Ads Manager. Klikněte na tlačítko \"Menu\" v levém horním rohu obrazovky. V části \"Events Manager\" (Správce událostí) vyberte položku \"Pixels\" (Pixely). Zde byste měli vidět seznam všech pixelů přidružených k vašemu účtu. Klikněte na název pixelu, pro který potřebujete ID. Vaše ID pixelu se zobrazí v levém horním rohu obrazovky vedle názvu pixelu.",
  "ob.analytics.facebook.account": "Nemáte ještě firemní stránku na Facebooku?",
  "ob.analytics.google.account.mobile.1": "Nemáte ještě službu Google Analytics",
  "ob.analytics.google.account.mobile.2": "účet Google? Klikněte na",
  "ob.analytics.google.account.mobile.3": "a dozvíte se, jak",
  "ob.analytics.google.account.mobile.4": "vytvořit.",
  "ob.analytics.facebook.account.mobile.1": "Nemáte ještě účet na Facebooku",
  "ob.analytics.facebook.account.mobile.2": "firemní stránku? Klikněte na",
  "ob.analytics.facebook.account.mobile.3": "a dozvíte se, jak si",
  "ob.analytics.facebook.account.mobile.4": "ji vytvořit.",
  "ob.analytics.successfullySaved.message": "Úspěšně uloženo",
  "ob.settings.booking.title": "Dostupnost online rezervace",
  "ob.settings.booking.description": "Nastavte čas, kdy lze naplánovat nejstarší a nejnovější schůzky. Spravujte časové intervaly schůzek a poskytujte zákazníkům důležité informace spolu s možností vybrat si zaměstnance pro jejich služby.",
  "ob.settings.cancellation.title": "Zrušení a změna termínu",
  "ob.settings.cancellation.description": "Nastavte časový rámec, kdy mohou klienti zrušit nebo přeplánovat schůzku.",
  "ob.settings.locationsUrl.title": "Adresa URL umístění",
  "ob.settings.locationsUrl.description": "Nakonfigurujte a sdílejte jednu adresu URL pro všechna místa s možností rezervace online. To je užitečné pouze pro firmy s více pobočkami.",
  "ob.settings.businessName.explanation": "Vaše URL: book.plandok.com/business-name/Location2556",
  "ob.settings.notifications.title": "E-maily s upozorněním",
  "ob.settings.notifications.description": "Určete příjemce e -mailů s oznámením o nových schůzkách nebo změnách na ně.",
  "ob.settings.required.title": "Povinná a volitelná pole",
  "ob.settings.required.description": "Vyberte, která pole bude klient povinen uvést při provádění online rezervací.",
  "ob.settings.requiredField.title": "Požadovaný",
  "ob.settings.optionalField.title": "Volitelný",
  "ob.settings.requiredField.notification.title": "Vyskytl se problém s nastavením",
  "ob.settings.requiredField.notification.description": "Omlouváme se, ale kromě \"poznámek\" musí být jedno pole povinné.",
  "ob.settings.nameField.title": "Jméno",
  "ob.settings.lastNameField.title": "Příjmení",
  "ob.settings.phoneField.title": "Telefonní číslo",
  "ob.settings.emailField.title": "E-mail",
  "ob.settings.notes.label": "Zástupný text pro poznámky",
  "ob.settings.notes.placeholder": "Např. zadejte své poznámky",
  "ob.bookingLimit.title": "Límite de reservas en línea",
  "ob.bookingLimit.description": "Especifique el número máximo de reservas de servicios que desea establecer.",
  "ob.bookingLimit.label": "Limitar las reservas de servicios",
  "ob.bookingLimit.unlimited.title": "Ilimitado",
  "ob.bookingLimit.1service.title": "máx. 1 Servicio",
  "ob.bookingLimit.2services.title": "máx. 2 Servicios",
  "ob.bookingLimit.3services.title": "máx. 3 Servicios",
  "ob.menu.edit.profile": "Upravit profil",
  "ob.menu.turnOff.profile": "Vypnout profil",
  "ob.profile.status": "Stav",
  "ob.overview.tab.description": "Rozvíjejte své podnikání tím, že svým zákazníkům umožníte rezervovat si schůzky online.",
  "ob.profile.tab.description": "Spravujte online profily pro vaše místa. Upravte obrázky, popisy, adresy a další.",
  "ob.links.tab.description": "Zde najdete odkaz na online rezervace, který můžete sdílet se svými klienty.",
  "ob.settings.tab.description": "Zde upravte nastavení online rezervace, aby vaši klienti mohli rezervovat pouze tehdy, když se vám to bude hodit.",
  "ob.payments.tab.description": "Vyberte, které platební metody budou moci vaši zákazníci používat při placení za služby.",
  "ob.payments.page.description": "Zde můžete vybrat, které platební metody budou moci vaši zákazníci používat při placení za služby.",
  "ob.payOnSite.title": "Platit na místě",
  "ob.payOnSite.description": "Vaši klienti budou moci platit na místě v hotovosti.",
  "ob.payWithCard.title": "Platit kartou",
  "ob.payWithCard.description": "Vaši klienti budou moci platit platební kartou prostřednictvím aplikace Stripe.",
  "ob.payWithCard.info.1": "Používáte bezplatný tarif Plandok, takže budete muset zaplatit.",
  "ob.payWithCard.info.2": "Provizní poplatek Plandok a provizní poplatek Stripe při použití tohoto způsobu platby.",
  "ob.payWithCard.info.3": " mít",
  "ob.payWithCard.info.4": "Plandok provizní poplatky.",
  "ob.payWithCard.info.5": "Prémiový plán není osvobozen od placení provizních poplatků Stripe. ",
  "ob.payWithCard.info.6": "Ceny služby Stripe si můžete prohlédnout zde",
  "ob.stripeConnected.info": "(Připojení ke službě Stripe)",
  "ob.requiresSetup.info": "(Vyžaduje nastavení účtu Stripe)",
  "ob.connectToStripe.btn": "Připojení ke službě Stripe",
  "ob.connectToStripe.successMessage.title": "Úspěšné připojení ke službě Stripe",
  "ob.waitingAuthorization.title": "Čekání na autorizaci ve službě Stripe",
  "ob.stripeDashboard.title": "Všechny záznamy o platbách a vrácení peněz jsou k dispozici v aplikaci ",
  "ob.stripeDashboard.url": "Přístrojovém panelu Stripe",
  "ob.yourStripeAccount.title": "Váš účet Stripe",
  "ob.visitStripe.option": "Navštivte službu Stripe",
  "ob.changeAccount.option": "Změna účtu Stripe",
  "input.earliestAppointment.label": "Nejbližší termín",
  "input.earliestAppointment.placeholder": "Těsně před schůzkou",
  "input.latestAppointment.label": "Poslední schůzka",
  "input.latestAppointment.placeholder": "Ne více než 6 měsíců předem",
  "input.timeSlots.label": "Intervaly časových úseků",
  "input.timeSlots.placeholder": "15 minut",
  "checkbox.allowCustomers": "Umožněte zákazníkům vybrat si zaměstnance pro schůzku",
  "input.importantInfo.label": "Důležité informace",
  "input.importantInfo.placeholder": "Informace, které se zákazníkovi zobrazí při potvrzování jeho schůzky",
  "input.changeAppointment.label": "Je možné provést změny schůzek",
  "input.changeAppointment.placeholder": "Kdykoli",
  "checkbox.sendToBooked": "Odešlete objednanému zaměstnanci",
  "checkbox.sendSpecificEmail": "Odeslat na konkrétní e-mailovou adresu",
  "input.specificEmail.label": "Konkrétní e-mailová adresa",
  "input.specificEmail.placeholder": "Zadejte e-mailovou adresu",
  "input.locationsUrl.label": "Adresa URL umístění",
  "input.locationsUrl.placeholder": "Např. Johns-Barber-Shop",
  "ob.btn.save": "Uložit",
  "ob.btn.cancel": "Zrušit",
  "ob.btn.showLink": "Zobrazit odkaz",
  "ob.btn.active": "Aktivní",
  "ob.btn.inactive": "Neaktivní",
  "ob.linkModal.title": "Přímý odkaz na rezervace",
  "ob.linkModal.description": "Zde je odkaz, kde jsou vaše online rezervační služby přímo dostupné!",
  "ob.linkModal.afterDescription": "Tento odkaz nasměruje uživatele přímo na vaše služby. Jediným kliknutím se zákazníci mohou dostat na vaše firemní profily a rezervovat si schůzky.\nTento odkaz se snadno používá a je přátelský ke všem zařízením, ať už jde o počítač, telefon nebo tablet.",
  "ob.fbLinkModal.title": "Facebook Page Zarezervujte nyní",
  "ob.fbLinkModal.description": "Nastavte integrované tlačítko Rezervovat pro svou firemní stránku na Facebooku, abyste mohli bez námahy přijímat nové rezervace přímo z Facebooku.\n",
  "ob.fbLinkModal.step1": "Přihlaste se na svou firemní stránku na Facebooku",
  "ob.fbLinkModal.step2": "Klikněte na \"Přidat tlačítko\" umístěné vedle titulní fotografie",
  "ob.fbLinkModal.step3": "Vyberte možnost „Zaregistrovat se“ a zadejte níže uvedený rezervační odkaz",
  "ob.linkModalCopySuccess.title": "Odkaz zkopírován!",
  "ob.btn.copyLink": "Kopírovat odkaz",
  "title.appointmentSlot.select": "Vyberte čas",
  "btn.back": "Zadní",
  "btn.continue": "Pokračovat",
  "ob.hours.open": "OTEVŘENO",
  "ob.hours.close": "ZAVŘENO",
  "ob.hours.step.title": "Pracovní doba",
  "ob.hours.step.description": "Dejte svým zákazníkům vědět, kdy se vaše firma otevírá a zavírá.",
  "ob.staffHours.step.title": "Pracovní doba zaměstnanců",
  "ob.staffHours.step.description": "Důležitý krok, přidejte pracovní dobu, aby systém věděl, kdy si klienti mohou rezervovat online.",
  "ob.location.step.title": "Informace o poloze",
  "ob.photo.step.title": "Fotografie",
  "ob.photo.step.description": "Dejte svým zákazníkům vědět, jak vaše firma vypadá.",
  "input.file.dnd.photos": "Přetáhněte fotografie",
  "btn.publish": "Zapnout",
  "text.next": "Další",
  "input.phone.number.label": "Telefonní číslo",
  "input.state.label": "Stát",
  "input.state.placeholder": "Zadejte stát (obec)",
  "input.addressDetails.label": "Podrobnosti adresy",
  "input.addressDetails.placeholder": "Zadejte podrobnosti o adrese, jako je kód dveří, další vchody, informace o parkování atd.",
  "placeholder.no.photos": "Žádné fotky",
  "btn.options": "Možnosti",
  "btn.list.profile": "Umožnit",
  "btn.preview": "Náhled",
  "btn.next": "Další",
  "market.ob.pleaseWait.title": "Počkejte prosím ...",
  "market.ob.location.step.title": "Vyberte umístění",
  "market.ob.services.step.title": "Vyberte služby",
  "market.ob.employee.step.title": "Vyberte datum a personál",
  "market.ob.timeSlot.step.title": "Výběr data a času",
  "market.ob.personalInfo.step.title": "Platba",
  "market.ob.appointmentInfo.step.title": "Informace o jmenování",
  "market.ob.informationAboutYou.title": "Informace o vás",
  "market.ob.paymentMethod.title": "Způsob platby",
  "market.ob.securePayment.title": "Zabezpečená platba",
  "market.ob.payWithCard.title": "Platba kartou",
  "market.ob.payOnSite.title": "Platba na místě",
  "market.ob.payOnSite.firstDescription": "Po zákroku je třeba zaplatit v salonu.",
  "market.ob.payOnSite.secondDescription": "Malá žádost od nás: Podniky jsou často malé nezávislé podniky, takže pokud se nemůžete dostat na plánovanou schůzku, přeplánovat nebo zrušit.",
  "market.ob.validThru.title": "Platnost do (měsíc/rok)",
  "market.ob.information.title": "Informace",
  "market.ob.additionalInformation.title": "Další informace",
  "market.ob.reschedulingPolicy.title": "Pravidla pro zrušení/změnu termínu",
  "market.ob.reschedulingPolicy.description": "Změny schůzek je možné provádět {0} před schůzkou.",
  "market.ob.fieldsAreRequired.title": "* Pole jsou povinná",
  "market.ob.step.title": "Krok {0} ze 3",
  "market.ob.confirm.step.title": "Prohlédnout a potvrdit",
  "market.ob.noServices.placeholder": "Zatím nejsou vybrány žádné služby",
  "market.ob.input.firstName.label": "{0} Jméno",
  "market.ob.input.firstName.placeholder": "e.g. John",
  "market.ob.input.lastName.label": "{0} Příjmení",
  "market.ob.input.lastName.placeholder": "e.g. Srna",
  "market.ob.input.phone.label": "{0} Číslo mobilního telefonu",
  "market.ob.input.phone.placeholder": "670 70 822",
  "market.ob.input.email.label": "{0} E-mailová adresa",
  "market.ob.input.email.placeholder": "john.srna@gmail.com",
  "market.ob.input.description.label": "Poznámky",
  "market.ob.staff.assignedSpecialist.label": "se zaměstnanci",
  "market.ob.staff.noPreference.label": "Žádná preference",
  "market.ob.timeSlots.fullyBooked.title": "Omlouváme se, ale pro tento den nemáme žádné volné časy.",
  "market.ob.timeSlots.choseAnotherDay.title": "Vyberte si prosím jiný den.",
  "market.ob.timeSlots.closedSlot.title": "Nejbližší dostupný čas je ",
  "market.ob.timeSlots.selectClosest.title": "Vyberte nejbližší datum",
  "market.ob.timeSlots.note.title": "Důležité informace",
  "market.ob.service.duration.title": "min",
  "market.location.workingHours.title": "Pracovní doba",
  "market.location.monday.title": "Pondělí",
  "market.location.tuesday.title": "Úterý",
  "market.location.wednesday.title": "Středa",
  "market.location.thursday.title": "Čtvrtek",
  "market.location.friday.title": "Pátek",
  "market.location.saturday.title": "Sobota",
  "market.location.sunday.title": "Neděle",
  "market.location.meetOurTeam.title": "Seznamte se s naším týmem",
  "market.location.title": "Spojte se s námi",
  "market.location.services.title": "Služby",
  "market.location.about.title": "O",
  "market.location.aboutUs.title": "O nás",
  "market.confirmation.appointmentCanceled.title": "Tato schůzka byla zrušena",
  "market.confirmation.appointmentConfirmed.title": "Jmenování potvrzeno",
  "market.confirmation.appointmentConfirmed.description": "Vaše schůzka je potvrzena, zde jsou podrobnosti:",
  "market.confirmation.appointmentDate.title": "Datum schůzky",
  "market.confirmation.scheduledAppointment.title": "Zrušení schůzky není možné online.",
  "market.confirmation.scheduledAppointment.description": "Pokud se nemůžete dostavit na naplánovanou schůzku, přesuňte ji na jiný termín nebo ji zrušte.",
  "market.modal.cancelAppointment.title": "Opravdu chcete tuto schůzku zrušit ?",
  "market.modal.cancelledAppointment.title": "Schůzka byla zrušena",
  "market.language.changer.title": "Jazyk (Language)",
  "market.btn.bookNow": "Rezervovat",
  "market.servicesAvailable.title": "služby",
  "market.poweredByPlandok.title": "Plandok",
  "market.poweredByPlandok.description": "Chcete podobné webové stránky ? Navštivte",
  "market.btn.readMore": "Přečtěte si více",
  "market.btn.showLess": "Zobrazit méně",
  "market.btn.reviews": "Recenze",
  "market.btn.viewMore": "Zobrazit více",
  "market.btn.cancelAppointment": "Zrušit schůzku",
  "market.btn.cancel": "Ano, zrušit",
  "market.wh.closed.title": "ZAVŘENO",
  "market.ob.success.message": "Hotovo! Rezervace byla úspěšně vytvořena",
  "market.ob.cancelAppointment.success.message": "Schůzka úspěšně zrušena",
  "market.ob.cancelAppointment.error.message": "Zrušení schůzky se nezdařilo",
  "market.ob.cancelAppointment.confirm.title": "Opravdu chcete zrušit schůzku?",
  "market.location.getDirections.title": "Získejte trasu",
  "market.notFound.title": "Stránka nebyla nalezena",
  "market.notFound.btn.goBack": "Vrátit se zpět",
  "market.ob.quantitativeLimit.title": "Kvantitativní limit",
  "market.ob.quantitativeLimit.description": "Omlouváme se, majitel stanovil omezení počtu rezervačních služeb, které umožňuje výběr pouze {0} služb{0}",
  "market.ob.quantitativeLimit.three": "tři",
  "market.ob.quantitativeLimit.two": "dvě",
  "market.ob.quantitativeLimit.one": "jedna",
  "market.ob.quantitativeLimit.serviceSingular": "a",
  "market.ob.quantitativeLimit.servicesPlural": "y",
  "validation.characters.minLength": "Tato hodnota je příliš krátká. Měl by mít 3 nebo více znaků.",
  "not.found.btn.label": "Zadní",
  "not.found.subTitle.label": "Omlouváme se, stránka, kterou jste navštívili, neexistuje.",
  "analytics.title": "Analytika",
  "analytics.dashboard.tab": "Dashboard",
  "analytics.reports.tab": "Zprávy",
  "analytics.premium.title": "Pojistné",
  "analytics.totalAppointments.title": "Celkový počet schůzek",
  "analytics.cancellationReasons.title": "Hlavní důvody zrušení",
  "analytics.occupancy.title": "Obsazenost",
  "analytics.topServices.title": "Špičkové služby",
  "analytics.clients.title": "klienti",
  "analytics.completed.title": "Dokončeno",
  "analytics.notCompleted.title": "Nedokončeno",
  "analytics.canceled.title": "Zrušeno",
  "analytics.noShowAppointments.title": "Žádné show schůzky",
  "analytics.reasonName.title": "Název důvodu",
  "analytics.reasonCount.title": "Počítat",
  "analytics.lastPeriod.title": "poslední období",
  "analytics.workingHours.title": "Pracovní doba",
  "analytics.bookedHours.title": "Rezervované hodiny",
  "analytics.blockedHours.title": "Blokované hodiny",
  "analytics.unbookedHours.title": "Nerezervované hodiny",
  "analytics.service.title": "Servis",
  "analytics.thisMonth.title": "Toto Období",
  "analytics.lastMonth.title": "Poslední období",
  "analytics.totalClients.title": "Celkový počet klientů",
  "analytics.averageVisits.title": "Průměrná návštěvnost na klienta ",
  "analytics.newClients.title": "Noví klienti",
  "analytics.returningClients.title": "Vracející se klienti",
  "analytics.returnRate.title": "Míra návratnosti klienta",
  "analytics.clientsByGender.title": "Klienti podle pohlaví",
  "analytics.clientsBySource.title": "Klienti podle zdroje reference",
  "analytics.clientsByAge.title": "Klienti podle věku",
  "analytics.timeRange.custom": "Zvyk",
  "analytics.timeRange.today": "Dnes",
  "analytics.timeRange.yesterday": "Včera",
  "analytics.timeRange.last1day": "Poslední 1 den",
  "analytics.timeRange.last7days": "Posledních 7 dní",
  "analytics.timeRange.last30days": "Posledních 30 dní",
  "analytics.timeRange.last90days": "Posledních 90 dní",
  "analytics.timeRange.last3months": "Poslední 3 měsíce",
  "analytics.timeRange.lastMonth": "Minulý měsíc",
  "analytics.timeRange.last6Months": "Posledních 6 měsíců",
  "analytics.timeRange.lastYear": "Minulý rok",
  "analytics.timeRange.last2year": "Poslední 2 roky",
  "analytics.timeRange.thisWeek": "Tento týden",
  "analytics.timeRange.thisMonth": "Tento měsíc",
  "analytics.timeRange.thisYear": "Tento rok",
  "analytics.timeRange.tomorrow": "Zítra",
  "analytics.timeRange.next7days": "Dalších 7 dní",
  "analytics.timeRange.next30days": "Dalších 30 dní",
  "analytics.menu.dashboard.description": "Prozkoumejte vaše obchodní data v grafech a statistikách",
  "analytics.menu.reports.description": "Prozkoumejte vaše obchodní data ve zprávách",
  "analytics.timeRange.allTime": "Pořád",
  "analytics.timeRange.label": "Časový rozsah",
  "analytics.services.lastPeriod.label": "Poslední období",
  "analytics.totalAppointments.appointment.label": "Schůzky",
  "analytics.totalAppointments.onlineBook.label": "On-line rezervace",
  "analytics.gender.male": "mužský",
  "analytics.gender.female": "ženský",
  "analytics.gender.unknown": "Neznámý",
  "analytics.noData": "Ve vybraném období nejsou žádná data",
  "analytics.premium.info.title": "Upgrade na prémiovou verzi pro zobrazení analýz po dobu delší než 7 dnů.",
  "analytics.premium.upgradeButton.title": "AKTIVOVAT",
  "analytics.premium.skipButton.title": "Přeskočit",
  "reports.appointments.title": "Schůzky",
  "reports.appointments.description": "Prohlédněte si předpokládané příjmy z nadcházejících schůzek, sledujte míru zrušení a důvody.",
  "reports.appointmentsList.title": "Seznam schůzek",
  "reports.appointmentsList.description": "Zobrazí všechny schůzky se souvisejícími informacemi.",
  "reports.appointmentsSummary.title": "Přehled schůzek",
  "reports.appointmentsSummary.description": "Zobrazuje schůzky podle personálu a služby.",
  "reports.appointmentsCancellations.title": "Zrušení schůzek",
  "reports.appointmentCancellations.title": "Zrušení schůzek",
  "reports.appointmentsCancellations.description": "Zobrazuje všechny důvody a množství cencellovaných schůzek za vámi zvolené období.",
  "reports.summary.title": "Shrnutí",
  "reports.clients.title": "klienti",
  "reports.clients.description": "Získejte přehled o tom, jak klienti komunikují s vaší firmou a kdo utrácí nejvíce",
  "reports.clientsList.title": "klienti",
  "reports.clientsList.description": "Seznam klientů s jejich údaji",
  "reports.exportCsv.title": "Export CSV",
  "reports.appointmentsByStaff.title": "Schůzky personálem",
  "reports.appointmentsByService.title": "Schůzky podle servisu",
  "reports.clearFilters.button.title": "Vymazat filtry",
  "reports.filterStaff.placeholder": "Všichni zaměstnanci",
  "reports.filterLocations.placeholder": "Všechny lokality",
  "reports.filters.title": "Filtry",
  "reports.locations.mobile.title": "Umístění:",
  "reports.staff.mobile.title": "Personál:",
  "reports.filters.apply.title": "Použít",
  "reports.search.placeholder": "Vyhledávání podle reference nebo klienta",
  "reports.subscription.description": "{0} plán (pouze {0} zaměstnanci)",
  "reports.notifications.description": "{0} zprávy ({0})",
  "serviceGroup.addCategory.description": "Kategorie jsou nezbytné, protože umožňují seskupovat podobné služby.",
  "serviceGroup.addCategory.title": "Přidat kategorii",
  "app.install.title": "Nainstalujte Plandok",
  "app.install.description.title": "Instalace nevyužívá téměř žádné úložiště a poskytuje rychlý způsob návratu k této aplikaci",
  "app.install.button.title": "Instalovat",
  "app.skip.button.title": "Přeskočit",
  "app.actionUndone.button.title": "Zrušení akce",
  "app.appointmentCancelled.button.title": "Jmenování vymazáno",
  "app.undo.button.title": "Zrušit",
  "app.showAllTimes.button.title": "Zobrazit všechny časy",
  "app.install.success.title": "Aplikace byla úspěšně nainstalována!",
  "rp.upgradeToPremiumLink.title": "Upgradujte na Premium",
  "rp.upgradeToPremium.title": "k použití této funkce",
  "rp.repeatAppointment.title": "Opakovat",
  "rp.input.frequency.title": "Frekvence",
  "rp.input.endsAfter.title": "Konec",
  "rp.input.selectDate.title": "Vyberte datum",
  "rp.upgrade.title": "Upgradujte svůj plán",
  "rp.upgrade.description.title": "Prémiová verze Plandoku není aktivována. Upgradujte svůj plán, abyste měli plný přístup ke všem funkcím, které poskytuje Plandok.",
  "rp.upgrade.button.title": "Upgradujte na prémiové",
  "rp.repeating.title": "Opakování",
  "rp.repeats.title": "Opakuje se",
  "rp.repeatsUntil.title": "až",
  "rp.repeatingTimes.title": "({0}krát)",
  "rp.cancel.upcoming.title": "Smazat všechny nadcházející opakované schůzky",
  "rp.update.upcoming.title": "Aktualizace všechny nadcházející opakované schůzky",
  "rp.edit.appointment.title": "Aktualizovat schůzku",
  "rp.edit.appointment.description.title": "Upravujete opakující se schůzku. Vyberte, které schůzky se mají aktualizovat:",
  "rp.edit.thisAppointment.title": "Aktualizujte pouze tuto schůzku",
  "rp.edit.allAppointments.title": "Aktualizujte všechny nadcházející schůzky",
  "rp.edit.saveAppointment.title": "Uložit",
  "modal.cancelSubscriptionError.title": "Chyba zrušení předplatného",
  "modal.cancelSubscriptionError.text.1": "Bezplatný plán je k dispozici pouze s maximálním počtem 5",
  "modal.cancelSubscriptionError.text.2": "zaměstnanců",
  "modal.cancelSubscriptionError.text.3": "a 1",
  "modal.cancelSubscriptionError.text.4": "Chcete-li používat bezplatnou verzi, odstraňte místa / zaměstnance, abyste splnili požadavky.",
  "modal.latestPaymentFailed.title": "Poslední platba se nezdařila",
  "modal.latestPaymentFailed.text.1": "Chcete-li získat přístup ke všem funkcím, zaplaťte nebo pro použití bezplatné verze odstraňte",
  "modal.latestPaymentFailed.text.2": ", abyste splnili požadavky. Bezplatný plán je k dispozici pouze s max. 5 zaměstnanci a 1 lokalitou.",
  "modal.extraCostsForNewMember.title": "Dodatečné náklady pro nového člena",
  "modal.extraCostsForNewMember.description": "Zvýšením počtu zaměstnanců vzniknou další náklady na měsíční předplatné.",
  "modal.premiumUpgradeRequired.title": "Vyžaduje se upgrade na prémii",
  "modal.premiumUpgradeRequired.description": "Pro přidání dalšího {0} musí být váš plán upgradován na Premium",
  "modal.premiumUpgradeRequired.location": "umístění",
  "modal.premiumUpgradeRequired.staffMembers": "zaměstnance",
  "modal.changeAccount.title": "Změna účtu Stripe?",
  "modal.changeAccount.description": "Během změny účtu nebudete moci přijímat platby. Změna zabere asi minutu.",
  "btn.agree": "Souhlasím",
  "btn.decline": "Odmítnout",
  "paymentMethod.paymentMethod.title": "Platební metoda",
  "paymentMethod.currentPaymentMethod.title": "Aktuální způsob platby",
  "paymentMethod.expirationDate": "Datum vypršení platnosti {0}",
  "paymentMethod.otherPaymentMethods.title": "Ostatní platební metody",
  "paymentMethod.addAnotherPaymentMethod.btn": "Přidat další platební metodu",
  "paymentMethod.addPaymentMethod.title": "Přidat platební metodu",
  "paymentMethod.noPaymentMethodAdded.title": "Žádný způsob platby nebyl přidán",
  "paymentMethod.noPaymentMethodAdded.description": "Způsob platby bude přidán při výběru tarifu Premium",
  "paymentMethod.goToPlanPage.btn": "Přejít na stránku plánu",
  "paymentMethod.add.btn": "Přidat",
  "paymentMethod.makeDefault.btn": "Nastavit jako výchozí",
  "paymentMethod.cardHasBeenAdded.message": "Karta byla přidána",
  "paymentMethod.cardHasBeenAssign.message": "Karta byla přiřazena",
  "page.marketing.overview.messages.description": "Odesílejte svým klientům vlastní marketingové zprávy prostřednictvím SMS nebo e-mailu pomocí několika jednoduchých kliknutí",
  "page.marketing.overview.btn.sendMessage": "Odeslat zprávu",
  "page.marketing.overview.campaigns.title": "Automatické odesílání kampaní",
  "page.marketing.overview.campaigns.description": "Vytvářejte personalizované kampaně na základě událostí, jako jsou narozeniny klientů a další. \nOdesílejte napříč více kanály a doručujte kampaně e-mailem a textovými zprávami",
  "page.marketing.overview.btn.newCampaign": "Nová kampaň",
  "page.marketing.massMessages.title": "Nastavení nové zprávy",
  "page.marketing.massMessages.messageLog": "Protokol zpráv",
  "page.marketing.massMessages.sendOn": "Odeslat na",
  "page.marketing.massMessages.sendTo": "Odeslat na",
  "page.marketing.massMessages.channel": "Kanál",
  "page.marketing.campaigns.description": "Odesílání automatických marketingových zpráv klientům prostřednictvím SMS nebo e-mailu pomocí několika jednoduchých kliknutí",
  "page.marketing.campaigns.birthdaySpecial.title": "Narozeninový speciál",
  "page.marketing.campaigns.winBackClients.title": "Získejte zpět klienty",
  "page.marketing.campaigns.welcomeNewClients.title": "Přivítejte nové klienty",
  "page.marketing.campaigns.rewardRegularClients.title": "Odměňte stálé klienty",
  "page.marketing.campaigns.birthdaySpecial.description": "Překvapte klienty v jejich výjimečný den, tato kampaň se automaticky odešle klientům v blízkosti jejich narozenin",
  "page.marketing.campaigns.winBackClients.description": "Zaměřte se na neaktivní klienty, aby se vrátili, pomocí speciální nabídky. Tato kampaň se zasílá klientům, kteří se po určité době nevrátili",
  "page.marketing.campaigns.welcomeNewClients.description": "Proměňte nově příchozí klienty ve stálé zákazníky tím, že povzbudíte klienty, kteří se objednali poprvé, aby se znovu objednali, pomocí speciální slevy. Tato kampaň se automaticky odešle klientům jeden den po jejich úplně prvním prodeji",
  "page.marketing.campaigns.rewardRegularClients.description": "Překvapte nejlepší klienty speciální nabídkou, což je jistý způsob, jak ještě více zaujmout své nejlepší klienty. Tato kampaň se automaticky odesílá klientům na základě nedávné aktivity při sjednávání schůzek",
  "page.marketing.campaigns.btn.active": "Aktivní",
  "page.marketing.campaigns.btn.inactive": "Neaktivní",
  "page.marketing.campaigns.btn.startCampaign": "Spustit kampaň",
  "page.marketing.campaigns.btn.editCampaign": "Upravit kampaň",
  "page.marketing.campaigns.btn.resetCampaign": "Obnovit kampaň",
  "modal.resetCampaign.description": "Opravdu chcete tuto kampaň resetovat ?",
  "modal.resetCampaign.btn.yesReset": "Ano, resetujte",
  "page.marketing.smsCreate.step.1": "Nastavení SMS. Krok 1 ze 4",
  "page.marketing.smsCreate.step.2": "Nastavení SMS. Krok 2 ze 4",
  "page.marketing.smsCreate.step.3": "Nastavení SMS. Krok 3 ze 4",
  "page.marketing.smsCreate.step.4": "Nastavení SMS. Krok 4 ze 4",
  "page.marketing.emailCreate.step.1": "Nastavení e-mailu. Krok 1 ze 4",
  "page.marketing.emailCreate.step.2": "Nastavení e-mailu. Krok 2 ze 4",
  "page.marketing.emailCreate.step.3": "Nastavení e-mailu. Krok 3 ze 4",
  "page.marketing.emailCreate.step.4": "Nastavení e-mailu. Krok 4 ze 4",
  "page.marketing.campaignCreate.step.1": "Nastavení kampaně. Krok 1 ze 3",
  "page.marketing.campaignCreate.step.2": "Nastavení kampaně. Krok 2 ze 3",
  "page.marketing.campaignCreate.step.3": "Nastavení kampaně. Krok 3 ze 3",
  "page.marketing.smsCreate.step.1.title": "ID zprávy a odesílatele",
  "page.marketing.smsCreate.step.1.defaultTitleForSms": "Zadejte ID odesílatele SMS",
  "page.marketing.smsCreate.step.1.defaultMessageForSms": "Zadejte svou zprávu",
  "page.marketing.emailCreate.step.1.defaultTitleForEmail": "Zadejte název zprávy",
  "page.marketing.emailCreate.step.1.defaultMessageForSms": "Zadejte svou zprávu",
  "page.marketing.emailCreate.step.1.title": "Nastavení e-mailu",
  "page.marketing.emailCreate.step.1.messageTitle": "Název zprávy",
  "page.marketing.emailCreate.step.1.replyToEmail": "Odpovědět na e-mail",
  "page.marketing.emailCreate.step.1.clientReplies": "Odpovědi klientů budou zasílány na tento e-mail",
  "page.marketing.emailCreate.step.1.photo.title": "Foto",
  "page.marketing.emailCreate.step.1.enablePhoto": "Povolit fotografii",
  "page.marketing.emailCreate.step.1.changePhoto": "Změnit fotografii",
  "page.marketing.emailCreate.step.1.button.title": "Tlačítko",
  "page.marketing.emailCreate.step.1.enableButton": "Povolit tlačítko",
  "page.marketing.emailCreate.step.1.buttonName": "Název tlačítka",
  "page.marketing.emailCreate.step.1.buttonURL": "Adresa URL tlačítka",
  "page.marketing.emailCreate.step.1.socialMedia.title": "Sociální média",
  "page.marketing.emailCreate.step.1.enableSocialMedia": "Povolit odkazy na sociální média",
  "page.marketing.emailCreate.step.1.facebookPage": "Stránka na Facebooku",
  "page.marketing.emailCreate.step.1.instagramPage": "Stránka Instagram",
  "page.marketing.emailCreate.step.1.yourPage": "Vaše stránka",
  "page.marketing.smsCreate.yourMessage": "Vaše zpráva",
  "page.marketing.btn.sendMeTestMessage": "Pošlete mi testovací zprávu",
  "page.marketing.smsCreate.step.1.freeTests": "Pro dnešek zbývají 3 bezplatné testy",
  "page.marketing.smsCreate.messagePreview": "Náhled zprávy",
  "page.marketing.smsCreate.btn.clientSelection": "Výběr klienta",
  "page.marketing.audience.title": "Publikum",
  "page.marketing.smsCreate.step.2.description": "Vyberte, kteří klienti obdrží vaši zprávu",
  "page.marketing.smsCreate.step.2.allClient.title": "Všem klientům",
  "page.marketing.smsCreate.step.2.allClient.description": "Odeslat zprávu všem klientům",
  "page.marketing.smsCreate.step.2.clientGroups.title": "Skupiny klientů",
  "page.marketing.smsCreate.step.2.clientGroups.description": "Odeslat zprávu vybraným skupinám klientů",
  "page.marketing.smsCreate.step.2.totalClients": "Celkem klientů",
  "page.marketing.smsCreate.step.2.totalPrice": "Celková cena",
  "page.marketing.smsCreate.step.3.description": "Vyberte čas, kdy chcete zprávu odeslat",
  "page.marketing.smsCreate.step.3.btn.selectDateTime": "Vyberte vlastní datum a čas",
  "page.marketing.smsCreate.step.4.title": "Platba za hromadnou zprávu",
  "page.marketing.smsCreate.step.4.description": "Zadejte údaje o své kreditní kartě a zaplaťte",
  "page.marketing.smsCreate.step.4.paymentAmount": "Výše platby:",
  "btn.scheduleSend": "Naplánujte odeslání",
  "btn.previous": "Předchozí",
  "btn.payment": "Platba",
  "btn.payAndFinish": "Zaplaťte a dokončete",
  "btn.backToDashboard": "Zpět na ovládací panel",
  "btn.saveAndClose": "Uložit a zavřít",
  "modal.editClientSelection.title": "Upravit výběr klienta",
  "modal.newClient.description": "Klienti přidaní v poslední:",
  "modal.recentClient.title": "Poslední klient",
  "modal.recentClient.description": "Klienti, kteří navštívili v poslední:",
  "modal.clientByAge.title": "Klient podle věku",
  "modal.clientsByGender.title": "Klienti podle pohlaví",
  "modal.paymentConfirmation.title": "Úspěšná platba !",
  "modal.paymentConfirmation.messageSend": "Vaše zpráva bude odeslána",
  "modal.previewMessage.title": "Náhled zprávy",
  "modal.previewSms.tab": "Náhled SMS",
  "modal.previewEmail.tab": "Náhled e-mailu",
  "modal.editServiceSelection.title": "Upravit výběr služby",
  "modal.editServiceSelection.allServices": "Všechny služby",
  "modal.campaignActivated.title": "Kampaň aktivována !",
  "modal.campaignActivated.description": "Tuto kampaň můžete kdykoli pozastavit nebo upravit",
  "btn.all": "Všechny {0}",
  "btn.included": "Zahrnuto {0}",
  "btn.excluded": "Vyloučeno {0}",
  "checkbox.allClients": "Všichni klienti {0}",
  "scheduleSend.time": "čas",
  "scheduleSend.timeSetTo": "Čas nastaven na:",
  "scheduleSend.setTime": "Nastavit čas",
  "campaignSetup.step.1.header.title": "Nastavení kampaně",
  "campaignSetup.step.2.header.title": "Upravit zprávu",
  "campaignSetup.step.3.header.title": "Přehled",
  "campaignSetup.channel.description": "Vyberte, jakými kanály bude kampaň odeslána",
  "campaignSetup.smsSetup.tab": "Nastavení SMS",
  "campaignSetup.emailSetup.tab": "Nastavení e-mailu",
  "campaignSetup.almostDone.title": "Téměř hotovo !",
  "campaignSetup.almostDone.description": "Vaše chytrá kampaň je připravena ke spuštění, po aktivaci bude průběžně automaticky zasílat zprávy klientům. Tuto kampaň můžete kdykoli snadno upravit nebo pozastavit",
  "campaignSetup.approximatePrice.header": "Přibližná cena",
  "campaignSetup.approximatePrice.description": "Pro nadcházejících 30 dní",
  "campaignSetup.approximatePrice.approx": "Přibližně",
  "campaign.birthdaySpecial.description": "Tato kampaň se automaticky zasílá klientům v době blízké jejich narozeninám",
  "campaign.birthdaySpecial.sendToClient": "Odeslat klientovi:",
  "campaign.birthdaySpecial.selectOption.1": "V den narozenin",
  "campaign.birthdaySpecial.selectOption.2": "Tři dny před narozeninami",
  "campaign.birthdaySpecial.selectOption.3": "Týden před narozeninami",
  "campaign.birthdaySpecial.selectOption.4": "Dva týdny před narozeninami",
  "campaign.winBackClients.description": "Tato kampaň se zasílá klientům, kteří se po určité době nevrátili",
  "campaign.winBackClients.clientsWhoDidntReturn": "Klienti, kteří se nevrátili v poslední době",
  "campaign.winBackClients.appliedTo": "Platí pro",
  "campaign.winBackClients.all": " všechny ",
  "campaign.winBackClients.services": "služby",
  "campaign.winBackClients.editSelection": "Upravit výběr",
  "campaign.welcomeNewClients.description": "Proměňte nováčky ve stálé zákazníky tím, že povzbudíte klienty, kteří si službu objednají poprvé, aby si ji znovu objednali se speciální slevou",
  "campaign.welcomeNewClients.smartCampaignNewClients": "Tato chytrá kampaň se automaticky odešle novým klientům jeden den po jejich vůbec prvním prodeji",
  "campaign.rewardRegularClients.description": "Tato kampaň se automaticky odesílá klientům na základě nedávné aktivity při sjednávání schůzek",
  "campaign.rewardRegularClients.clientsWithAtLeast": "Klienti s minimálně",
  "campaign.rewardRegularClients.appointmentsInThe": "schůzek v",
  "campaign.rewardRegularClients.last": "posledních",
  "campaign.rewardRegularClients.appointments": "schůzek",
  "campaign.rewardRegularClients.inTheLast": "v posledních",
  "campaign.rewardRegularClients.months": "měsíců",
  "subscriptionPaymentFailed.title": "Aktualizujte způsob platby, dokud {0} budou ukončeny všechny funkce prémiové, děkuji.",
  "subscriptionCancelled.title": "Omlouváme se, ale vaše Premium bylo deaktivováno z důvodu nezaplacení. Pokud chcete nadále používat prémiové funkce, aktivujte si prémiový tarif, děkujeme.",
  "notificationPaymentFailed.title": "Vaše platba za oznámení se nezdařila. Dokončete prosím platbu do {0}, jinak budou upozornění deaktivována, děkujeme.",
  "notificationCancelled.title": "Omlouváme se, ale vaše upozornění byla deaktivována z důvodu nezaplacení. Pokud si přejete pokračovat v zasílání upozornění, proveďte platbu, děkujeme.",
  "btn.hideOptions": "Skrýt možnosti",
  "btn.showOptions": "Zobrazit možnosti",
  "notification.googleDisconnected": "Kalendář Google odpojen",
  "notification.googleConnected": "Kalendář Google připojen",
  "notification.appleDisconnected": "Kalendář Apple odpojen",
  "notification.appleConnected": "Kalendář Apple připojen",
  "notification.microsoft365Disconnected": "Kalendář Microsoft365 odpojen",
  "notification.microsoft365Connected": "Kalendář Microsoft365 připojen",
  "notification.linkCopied": "Zkopírovaný odkaz",
  "calendarSync.title": "Synchronizace kalendáře",
  "calendarSync.description": "Jednosměrná synchronizace schůzek Plandok s vaším oblíbeným kalendářem pro snadnou organizaci a nikdy nezmeškáte důležitou událost.",
  "googleCalendar.title": "Kalendář Google",
  "appleCalendar.title": "Kalendář Apple",
  "microsoft365Calendar.title": "Kalendář Microsoft365",
  "linkYourCalendar.title": "Propojte své kalendáře",
  "connect.btn": "Propojení",
  "disconnect.rtn": "Odpojení",
  "connected.label": "Připojeno",
  "modal.workingHours.standartMethod.btn": "Standardní metoda",
  "modal.workingHours.customMethod.btn": "Vlastní metoda",
  "modal.workingHours.workingDates.input": "Pracovní data",
  "modal.workingHours.selectDates.placeholder": "Vybraná data",
  "noInternetConnection.notification.title": "Žádné připojení k internetu",
  "app.update.title": "K dispozici je nová verze",
  "app.update.description.title": "Přidali jsme nové funkce a opravili některé chyby, aby vaše práce s Plandokem byla co nejplynulejší.",
  "app.update.button.title": "Aktualizovat",
  "app.notNow.button.title": "Nyní ne",
  "app.updateAvailable.button.title": "K dispozici nová verze",
  "upgradePlan.success.message": "Předplatné bylo úspěšně aktualizováno",
  "upgradePlan.error.message": "Chyba aktualizace předplatného, pokud je vaše karta zatížena a předplatné není aktualizováno, kontaktujte prosím podporu",
  "sidebar.inventory": "Inventura",
  "products.tab": "Produkty",
  "suppliers.tab": "Dodavatelé",
  "stockOrders.tab": "Skladové objednávky",
  "stockTracking.tab": "Sledování zásob",
  "input.productsSearch.placeholder": "Vyhledávání podle názvu produktu nebo čárového kódu",
  "product.create.btn.title": "Přidat nový produkt",
  "table.products.product": "Produkt",
  "table.products.barcode": "Čárový kód",
  "table.products.brand": "Značka",
  "table.products.sale": "Prodej",
  "table.products.saleOpt": "Výprodejové mož.",
  "saleOptions.tooltip.title": "Výprodejové možnosti",
  "saleOptions.tooltip.description": "Během pokladny můžete povolit/zakázat prodej.",
  "seeMore.title": "viz více",
  "option.products.downloadCSV": "Stáhnout CSV",
  "option.products.downloadExcel": "Stáhnout Excel",
  "option.products.manageBrands": "Správa značek",
  "option.products.manageCategories": "Správa kategorií",
  "option.products.manageStock": "Správa skladových zásob",
  "option.products.importProducts": "Importovat produkty",
  "modal.filter.categories.title": "Kategorie",
  "modal.filter.brands.title": "Značky",
  "modal.filter.stock.title": "Skladové zásoby",
  "modal.filter.allCategories.selectItem": "Všechny kategorie",
  "modal.filter.allBrands.selectItem": "Všechny značky",
  "modal.filter.allSuppliers.selectItem": "Všichni dodavatelé",
  "modal.filter.allProducts.selectItem": "Všechny produkty",
  "input.suppliersSearch.placeholder": "Vyhledávání podle názvu dodavatele",
  "supplier.create.btn.title": "Přidat nového dodavatele",
  "table.suppliers.supplierName": "Název dodavatele",
  "table.suppliers.phoneNumber": "Telefonní číslo",
  "table.suppliers.physicalAddress": "Fyzická adresa",
  "validation.notification.reminderTime": "Nelze odesílat oznámení se stejným časem připomenutí.",
  "validation.paymentType.switches": "Musí být povolena alespoň jedna platební metoda",
  "plan.premium.option.14": "0% provize Plandok za schůzky rezervované prostřednictvím online rezervací pomocí kreditní karty.",
  "title.dragAndExtend.appointment.permission": "Można przeciągać i przedłużać spotkania",
  "button.lastVisit.addAnotherLastvisit": "PŘIDEJTE DALŠÍ POSLEDNÍ NÁVŠTĚVU",
  "notifications.template.badge.validate": "Ověřit",
  "notifications.template.badge.refused": "Odmítnuto",
  "paid.by.card": "Placeno kartou",
  "costs.manual.paid.link": "Nebo můžete za svá upozornění zaplatit ručně svou kartou kliknutím na tento odkaz",
  "modal.remove.account.title": "Odebrat účet Stripe",
  "modal.remove.account.description": "Váš účet bude odstraněn a nebudete moci přijímat online platby. ",
  "ob.remove.account.option": "Odebrat účet Stripe",
  "btn.remove.account": "Odstranit",
  "calendar.tooltip.selected": "Vybraný",
  "calendar.tooltip.no.preference": "Žádná preference",
  "ob.gtag.manager.title": "Správce značek Google",
  "ob.analytics.gtag.description.1": "Správce značek Google vám umožňuje měřit návratnost investic do reklamy a také sledovat stránky a aplikace ve formátu Flash, videa a sociální sítě.",
  "ob.gtag.manager.description.1": "Google Tag Manager je bezplatný systém pro správu značek, který vám umožňuje spravovat a nasazovat marketingové značky (úryvky kódu nebo měřicí pixely) na vašem webu nebo v mobilní aplikaci, aniž byste museli kód přímo upravovat.",
  "ob.gtag.manager.description.2": "GTM v podstatě zjednodušuje proces správy marketingových značek, což usnadňuje sledování výkonu webu, měření marketingových kampaní a optimalizaci uživatelského dojmu.",
  "text.gender.male": "Samec",
  "text.gender.female": "Žena",
  "text.gender.unknown": "Neznámý",
  "ob.stripeConnected.context": "(proužek připojen)",
  "ob.requiresSetup.context": "(Vyžaduje nastavení účtu Stripe)",
  "ob.payWithCard.context.1": "Používáte bezplatný plán Plandok, takže budete muset zaplatit",
  "ob.payWithCard.context.2": "Provize Plandok a provize Stripe pomocí této platební metody",
  "ob.payWithCard.context.3": "mít",
  "ob.payWithCard.context.4": "Provizní poplatky Plandoku.",
  "ob.payWithCard.context.5": "Prémiový plán neosvobozuje od placení provizních poplatků Stripe",
  "ob.payWithCard.context.6": "Podívejte se na ceny Stripe zde"
};

export default labels;
