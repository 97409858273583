import { MailOutlined } from '@ant-design/icons'
import { CustomIcon, getIsMobile, Link, Text } from '@plandok/core'
import { IntlTemplate } from '@plandok/i18n'
import { Spin } from 'antd'
import { RoutePath } from 'constants/routes'
import { useGetEstimatedCosts } from 'hooks/costs'
import React, { useEffect, useState } from 'react'

import * as SC from './styles'

type Notification = {
   title: string
   count: number
   price: number
   details: {
      channel: string
      count: number
      price: number
   }[]
}

type Header = {
   title: string
   count: number
   price: number
}

type CostItem = {
   mb: string
   label: string
   weightLabel: 'initial' | 'normal' | 'medium' | 'light' | 'bold' | 'extraLight' | 'semiBold' | 'extraBold' | undefined
   weightCost: 'initial' | 'normal' | 'medium' | 'light' | 'bold' | 'extraLight' | 'semiBold' | 'extraBold' | undefined
   cost: number | string
}

export default function EstimatedCostsPage() {
   const [notifications, setNotifications] = useState<Notification[]>([])
   const { estimatedCostsData, isLoading } = useGetEstimatedCosts()

   const isMobile = getIsMobile()

   useEffect(() => {
      if (estimatedCostsData?.message?.details && !Array.isArray(estimatedCostsData?.message?.details)) {
         const prepareNotifications = []

         for (const notification in estimatedCostsData.message.details) {
            prepareNotifications.push({
               title: notification.replaceAll('_', ' '),
               ...estimatedCostsData.message.details[notification],
            })
         }

         setNotifications(prepareNotifications)
      }
   }, [estimatedCostsData])

   const Header = ({ title, count, price }: Header) => (
      <SC.HeaderPanelContainer>
         <Text size="base" mb="none" lh="xlarge">
            {title}
         </Text>
         <Text size="base" mb="none" lh="xlarge">
            {count}
         </Text>
         <Text size="base" mb="none" lh="xlarge">
            {`€${price.toFixed(2)}`}
         </Text>
      </SC.HeaderPanelContainer>
   )

   const CostItem = ({ mb, label, weightLabel, weightCost, cost }: CostItem) => (
      <SC.CostItemContainer theme={{ mb }}>
         <Text size="base" mb="none" lh="xlarge" label={label} weight={weightLabel} />
         <Text size="base" mb="none" lh="xlarge" weight={weightCost}>{`€${cost}`}</Text>
      </SC.CostItemContainer>
   )

   return (
      <Spin spinning={isLoading}>
         <SC.EstimatedCostsCard>
            <SC.EstimatedCostsData>
               <div className="costs-data">
                  <Text className="title" size="mlarge" weight="semiBold" label="costs.billingPeriod.title" />
                  {isMobile && (
                     <Text className="cost" size="base" mb="small">
                        €{estimatedCostsData?.total?.toFixed(2) || 0}
                     </Text>
                  )}
                  <Text className="description" lh="xlarge" label="costs.billingPeriod.description" mb="none" />
               </div>
               {!isMobile && (
                  <Text className="cost" size="base" mb="none">
                     €{estimatedCostsData?.total?.toFixed(2) || 0}
                  </Text>
               )}
            </SC.EstimatedCostsData>

            <SC.EstimatedCostsAdditionalData>
               <Text label="costs.billingPeriod.additionalInfo" mb="none" />
               <Text label="costs.manual.paid.link" mb="none" />{' '}
               <Link to={RoutePath.NOTIFICATION_PAYMENT} label="ob.analytics.click" />
            </SC.EstimatedCostsAdditionalData>
         </SC.EstimatedCostsCard>

         <SC.SummaryCard>
            <Text className="title" size="mlarge" weight="semiBold" label="costs.summary.title" />
            <Text className="description" lh="xlarge" label="costs.summary.description" />

            {!!notifications.length && (
               <>
                  <Text
                     className="charges"
                     size="large"
                     weight="semiBold"
                     label="costs.summary.detailedCharges"
                     lh="xxlarge"
                  />
                  <SC.DetailedChargesHeader>
                     <Text size="base" weight="semiBold" label="sidebar.notification" lh="xlarge" mb="none" />
                     <Text size="base" weight="semiBold" label="costs.summary.quantity" lh="xlarge" mb="none" />
                     <Text size="base" weight="semiBold" label="calendar.tooltip.price" lh="xlarge" mb="none" />
                  </SC.DetailedChargesHeader>
                  <SC.DetailedChargesLineContainer>
                     <SC.StyledCollapse
                        bordered={false}
                        expandIcon={({ isActive }) => <CustomIcon type={isActive ? 'arrowUp' : 'arrowDown'} />}>
                        {notifications.map((notification: Notification) => (
                           <SC.StyledPanel
                              header={
                                 <Header
                                    title={notification.title}
                                    count={notification.count}
                                    price={notification.price}
                                 />
                              }
                              key={notification.title}>
                              <SC.PanelWhatsAppContainer>
                                 <SC.NotificationHeader>
                                    <CustomIcon type="whatsApp" />
                                    <Text ml="xxsmall" size="base" mb="none" lh="xlarge" colorType="lightViolet">
                                       WhatsApp
                                    </Text>
                                 </SC.NotificationHeader>
                                 <Text size="base" mb="none" lh="xlarge" colorType="lightViolet" marginLeft="-70px">
                                    {notification.details?.find(({ channel }) => channel === 'whatsapp')?.count || 0}
                                 </Text>
                                 <Text size="base" mb="none" lh="xlarge" colorType="lightViolet">
                                    {`€${
                                       notification.details
                                          ?.find(({ channel }) => channel === 'whatsapp')
                                          ?.price.toFixed(2) || 0
                                    }`}
                                 </Text>
                              </SC.PanelWhatsAppContainer>
                              <SC.PanelSmsContainer>
                                 <SC.NotificationHeader>
                                    <MailOutlined style={{ color: '#61749D' }} />
                                    <Text ml="xxsmall" size="base" mb="none" lh="xlarge" colorType="lightViolet">
                                       SMS
                                    </Text>
                                 </SC.NotificationHeader>
                                 <Text size="base" mb="none" lh="xlarge" colorType="lightViolet" marginLeft="-70px">
                                    {notification.details?.find(({ channel }) => channel === 'sms')?.count || 0}
                                 </Text>
                                 <Text size="base" mb="none" lh="xlarge" colorType="lightViolet">
                                    {`€${notification.details?.find(({ channel }) => channel === 'sms')?.price.toFixed(2) || 0}`}
                                 </Text>
                              </SC.PanelSmsContainer>
                           </SC.StyledPanel>
                        ))}
                     </SC.StyledCollapse>
                  </SC.DetailedChargesLineContainer>
               </>
            )}

            <SC.SubscriptionLineContainer>
               <div className="justify-between">
                  <div className="d-flex">
                     <Text size="base" mb="none" lh="xlarge" label="costs.subscription" weight="semiBold" />
                     <Text mb="none" size="base" lh="xlarge">
                        (
                        <IntlTemplate
                           label="plan.premium.activeUntilDate"
                           params={[estimatedCostsData?.subscription?.endDate?.replaceAll('-', '.') || '']}
                        />
                        )
                     </Text>
                  </div>
                  <Text size="base" mb="none" lh="xlarge" weight="normal">{`€${
                     estimatedCostsData?.subscription?.total?.toFixed(2) || 0
                  }`}</Text>
               </div>
            </SC.SubscriptionLineContainer>

            <SC.SummaryContainer>
               <CostItem
                  mb="16"
                  label="costs.summary.subtotal"
                  weightLabel="normal"
                  weightCost="normal"
                  cost={estimatedCostsData?.subtotal?.toFixed(2) || 0}
               />
               <CostItem
                  mb="16"
                  label="costs.summary.taxes"
                  weightLabel="normal"
                  weightCost="normal"
                  cost={estimatedCostsData?.taxes || 0}
               />
               <CostItem
                  mb="0"
                  label="appointment.total"
                  weightLabel="semiBold"
                  weightCost="semiBold"
                  cost={estimatedCostsData?.total?.toFixed(2) || 0}
               />
            </SC.SummaryContainer>
         </SC.SummaryCard>
      </Spin>
   )
}
