import { Upload } from 'antd'
import styled, { css } from 'styled-components'

import { UploadContainerProps } from '../index'

export const UploadContainer = styled(Upload)<UploadContainerProps>`
   .ant-upload-wrapper {
      display: flex;
      align-items: center;
   }

   .ant-upload-list-item-progress {
      display: none;
   }

   .ant-upload-list {
      display: ${({ hideList }) => (hideList ? 'none' : 'inherit')};

      .ant-upload.ant-upload-select {
         border: none;
         background-color: inherit;
         height: fit-content;
         margin-bottom: 0;
         margin-top: 8px;
      }
   }

   .ant-upload-list-item-uploading-text {
      display: none;
   }

   .ant-upload.ant-upload-select {
      border: none;
      background-color: inherit;
      height: 0;
      margin-bottom: 0;
      margin-top: 8px;
   }

   .ant-upload-list-item-error {
      border: 1px dashed rgba(0, 0, 0, 0) !important;
      height: auto !important;
   }

   .ant-upload-list-item-info::before {
      background-color: inherit;
   }

   .ant-upload-list-item-thumbnail img {
      border-radius: 50%;
   }

   .ant-upload-list-item-thumbnail {
      opacity: 1;
   }

   .ant-upload-list-item {
      width: 100px;
      height: 100px;
      padding: 0;
      border: 0;
   }

   .ant-upload-list-item-info {
      border: 3px solid #fff;
      border-radius: 50%;
      box-shadow: 0 2px 10px rgb(38 60 122 / 20%);
   }

   .ant-upload.ant-upload-select > .ant-upload {
      padding: 0;
   }

   .anticon svg {
      display: none;
   }

   ${({ uploaded }) => css`
      svg[width='120'] {
         z-index: ${uploaded && 10};
         position: ${uploaded ? 'absolute' : 'inherit'};
         top: 0;
         left: -3px;
         width: 105px;
         height: 105px;

         &.active {
            margin-top: 8px;
            margin-left: 8px;
         }
      }
   `}
`

export const PopoverContent = styled.div`
   display: flex;
   flex-direction: column;
`

export const PopoverRow = styled.div`
   display: flex;
   align-items: center;
   padding: 10px 20px;

   &:hover {
      cursor: pointer;
      background: #d4d4d4;

      &:first-child {
         border-radius: 5px 5px 0 0;
      }

      &:last-child {
         border-radius: 0 0 5px 5px;
      }
   }

   .ant-upload.ant-upload-select {
      width: 100% !important;
      height: inherit;

      .ant-upload {
         display: flex;
         align-items: center;
         justify-content: space-between;
      }
   }
`

export const OptionButton = styled.div`
   &:hover {
      cursor: pointer;
   }
`
