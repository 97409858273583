import { Area } from '@ant-design/plots'
import { Text } from '@plandok/core'
import { IntlDate, IntlLabel } from '@plandok/i18n'
import React, { useEffect, useState } from 'react'

import { App_AppointmentsAnalyticsGraphPoint } from '../../../../../../graphql/generatedTypes'
import * as SC from './styles'

type TotalAppointmentsChartProps = {
   chartPoints?: App_AppointmentsAnalyticsGraphPoint[] | null
}

type ChartsData = {
   range: string
   type: string
   value: number
}

const chartColors = ['#6897a7', '#8bc0d6', '#60d7a7', '#dedede', '#fedca9', '#fab36f', '#d96d6f']

const getTranslatedChartTitles = (label: string): string => {
   switch (label) {
      case 'Appointments':
         return 'analytics.totalAppointments.appointment.label'
      case 'Online bookings':
         return 'analytics.totalAppointments.onlineBook.label'
      default:
         return ''
   }
}

export const TotalAppointmentsChart: React.FC<TotalAppointmentsChartProps> = ({ chartPoints }) => {
   const [mappedPoints, setMappedPoints] = useState<ChartsData[]>([])

   useEffect(() => {
      if (!chartPoints) return

      const totalChartPoints: ChartsData[] = chartPoints.flatMap(
         ({ xValue, yAppointmentsCount, yOnlineBookingsCount }) => [
            { range: xValue.slice(5), value: yAppointmentsCount || 0, type: 'Appointments' },
            { range: xValue.slice(5), value: yOnlineBookingsCount || 0, type: 'Online bookings' },
         ],
      )
      console.log(totalChartPoints)
      setMappedPoints(totalChartPoints)
   }, [chartPoints])

   const areaChartConfig = {
      data: mappedPoints,
      xField: 'range',
      yField: 'value',
      seriesField: 'type',
      color: chartColors,
      smooth: true,
      legend: { position: 'top' },
      tooltip: {
         customContent: (data: any) => {
            const findDate = chartPoints?.find(point => point.xValue.slice(5) === data[0]?.data.range)?.xValue
            if (!findDate) return null

            return (
               <SC.Tooltip>
                  <div>
                     <IntlDate date={new Date(findDate)} dateFormat="MMM yyyy dd" />
                  </div>
                  <SC.TooltipDivider />
                  {data.map((item: any) => (
                     <SC.TooltipDataRow key={item.name}>
                        <SC.TooltipCircle color={item.color} />
                        <SC.TooltipValues>{item.value}</SC.TooltipValues>
                        <IntlLabel label={getTranslatedChartTitles(item.name)} />
                     </SC.TooltipDataRow>
                  ))}
               </SC.Tooltip>
            )
         },
      },
   }

   return (
      <SC.TotalAppointmentsChart>
         <Text size="mlarge" weight="bold" colorType="base" mb="xsmall">
            <IntlLabel label="analytics.totalAppointments.title" />
         </Text>
         <SC.AreaChart>
            <Area {...areaChartConfig} />
         </SC.AreaChart>
      </SC.TotalAppointmentsChart>
   )
}
