import { getIsMobile } from '@plandok/core'
import { Placeholder, PlaceholderProps } from 'components'
import React, { ComponentType, ReactNode, useEffect } from 'react'

import DesktopTable from '../components/DesktopTable'
import MobileTable from '../components/MobileTable'
import { useRestStore } from './useRestStore'

export interface TableStoreInitializeProps {
   fetchData: (...args: any[]) => Promise<any>
   magicMinus?: boolean
   actualOffset?: boolean
   mapping: {
      pageKey: string
      limitKey?: string
      dataKey: string
      totalCountKey: string
      searchKey?: string
      maxCountKey?: string
      emptyStateKey?: string
   }
}

interface AppTableProps {
   columns: any[]
   children?: ReactNode
   selectedPage?: number
   page?: number
   refreshData?: any
   placeholder?: PlaceholderProps
   showPlaceholder?: boolean
   viewDetails?: Function
   mobileRowComponent?: ComponentType
   mobileClassName?: string
   className?: string
   apiConfig: TableStoreInitializeProps
   searchStr?: any
   paginationSpacing?: string
   isCustomPagination?: boolean
   loading?: boolean
   loadMore?: (page: number) => void
   mobileColumns?: any
   hidePageSwitcher?: boolean
}

function RestTable(props: AppTableProps) {
   const tableStore = useRestStore(props.apiConfig, props.searchStr)

   useEffect(() => {
      ;(async () => await tableStore.fetchData())()
   }, [props.refreshData])

   useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
         if (tableStore.initialized) {
            tableStore.fetchData()
         }
      }, 500)

      return () => clearTimeout(delayDebounceFn)
   }, [props.searchStr])

   const isMobile = getIsMobile()

   const isHidePageSwitcher =
      typeof props.hidePageSwitcher === 'boolean' ? props.hidePageSwitcher : tableStore.page === 1 && isMobile

   if (props.placeholder && tableStore.showPlaceholder) {
      return (
         <div style={{ marginTop: '100px' }}>
            <Placeholder {...props.placeholder} position="relative" />
         </div>
      )
   }

   return (
      <>
         {props.children}
         {!isMobile || !props.mobileRowComponent ? (
            <DesktopTable
               columns={props.columns}
               viewDetails={props.viewDetails}
               page={tableStore.page}
               selectedPage={props.selectedPage}
               count={tableStore.count}
               totalCount={tableStore.totalCount}
               hidePageSwitcher={isHidePageSwitcher}
               isLoading={props?.loading ? props?.loading : tableStore.isLoading}
               fetchData={tableStore.fetchData}
               className={props.className}
               isCustomPagination={props.isCustomPagination}
               paginationSpacing={props.paginationSpacing}
               data={tableStore.currentData}
               loadMore={props.loadMore}
            />
         ) : (
            <MobileTable
               page={tableStore.page}
               rowComponent={props.mobileRowComponent}
               className={props.mobileClassName}
               viewDetails={props.viewDetails}
               data={tableStore.currentData}
               hasMore={tableStore.hasMore}
               isLoading={props.loading || tableStore.isLoading}
               fetchData={tableStore.fetchData}
               mobileColumns={props.mobileColumns}
            />
         )}
      </>
   )
}

export default RestTable
