import { message } from 'antd'
import get from 'lodash/get'
import { useCallback, useEffect, useMemo, useState } from 'react'

interface RestStoreInitializeProps {
   fetchData: (...args: any[]) => Promise<any>
   magicMinus?: boolean
   actualOffset?: boolean
   mapping: {
      pageKey: string
      limitKey?: string
      dataKey: string
      totalCountKey: string
      searchKey?: string
      maxCountKey?: string
      emptyStateKey?: string
   }
}

interface RestStoreState {
   page: number
   totalCount: number
   maxCount: number
   isLoading: boolean
   initialized: boolean
   error: string
   data: { [key: string]: any[] }
   showPlaceholder: boolean
}

export const useRestStore = (initProps: RestStoreInitializeProps, searchStr?: string) => {
   const [state, setState] = useState<RestStoreState>({
      page: 1,
      totalCount: 0,
      maxCount: 10,
      isLoading: false,
      initialized: false,
      error: '',
      data: {},
      showPlaceholder: false,
   })

   const getCacheKey = useCallback(
      (page: number) => {
         const searchStrPart = searchStr ? `:query${searchStr}` : ''
         return `${page}${searchStrPart}`
      },
      [searchStr],
   )

   const currentPageKey = useMemo(() => getCacheKey(state.page), [getCacheKey, state.page])

   // Memoized computed values
   const currentData = useMemo(() => {
      const pageData = state.data[currentPageKey]
      return pageData ? pageData.map((v, index) => ({ ...v, key: index })) : []
   }, [state.data, currentPageKey])

   const allData = useMemo(() => {
      return Object.values(state.data).reduce<any[]>((acc, arr) => {
         if (!arr) return acc
         return [...acc, ...arr]
      }, [])
   }, [state.data])

   const totalDataCount = useMemo(() => {
      return Object.values(state.data).reduce((acc, arr) => acc + (arr?.length || 0), 0)
   }, [state.data])

   const hasMore = useMemo(() => {
      return state.totalCount > totalDataCount
   }, [state.totalCount, totalDataCount])

   const count = useMemo(() => {
      return state.data[currentPageKey]?.length ?? 0
   }, [state.data, currentPageKey])

   // Rest of the code remains the same...
   const getFilterParams = useCallback(
      (page: number) => {
         const result: any = { [initProps.mapping.pageKey]: page }

         if (initProps.magicMinus) {
            result[initProps.mapping.pageKey] -= 1
         }
         if (initProps.actualOffset) {
            result[initProps.mapping.pageKey] = result[initProps.mapping.pageKey] * state.maxCount
         }
         if (initProps.mapping.searchKey && searchStr) {
            result[initProps.mapping.searchKey] = searchStr
         }
         if (initProps.mapping.limitKey && state.maxCount) {
            result[initProps.mapping.limitKey] = state.maxCount
         }
         if (initProps.mapping.maxCountKey) {
            result[initProps.mapping.maxCountKey] = state.maxCount
         }

         return result
      },
      [initProps.mapping, initProps.magicMinus, initProps.actualOffset, state.maxCount, searchStr],
   )

   const extractData = useCallback((resp: any) => get(resp, initProps.mapping.dataKey), [initProps.mapping.dataKey])

   const extractTotalCount = useCallback(
      (resp: any) => get(resp, initProps.mapping.totalCountKey) ?? 0,
      [initProps.mapping.totalCountKey],
   )

   const fetchData = useCallback(
      async (page: number = 1) => {
         if (state.isLoading) return
         if (state.data[getCacheKey(page)]) {
            setState(prev => ({ ...prev, page }))
            return
         }

         setState(prev => ({ ...prev, isLoading: true }))

         try {
            const resp = await initProps.fetchData(getFilterParams(page))
            const data = extractData(resp)
            const totalCount = extractTotalCount(resp)

            let showPlaceholder = false
            if (initProps.mapping.emptyStateKey) {
               showPlaceholder = !!get(resp, initProps.mapping.emptyStateKey)
            } else {
               showPlaceholder = !totalCount && !data?.length
            }

            setState(prev => ({
               ...prev,
               data: { ...prev.data, [getCacheKey(page)]: data },
               page,
               totalCount,
               showPlaceholder,
               isLoading: false,
               initialized: true,
            }))
         } catch (e) {
            message.error('Failed to load data, please contact support')
            setState(prev => ({ ...prev, isLoading: false }))
         }
      },
      [state.isLoading, state.data, getFilterParams, getCacheKey, extractData, extractTotalCount],
   )

   const pageChange = useCallback(
      async (page: number) => {
         await fetchData(page)
      },
      [fetchData],
   )

   const clearCache = useCallback(() => {
      setState(prev => ({ ...prev, data: {} }))
   }, [])

   const resetFilter = useCallback(() => {
      setState(prev => ({
         ...prev,
         searchStr: '',
         page: 0,
         totalCount: 0,
         maxCount: 10,
      }))
   }, [])

   useEffect(() => {
      return () => {
         setState(prev => ({
            ...prev,
            showPlaceholder: false,
            initialized: false,
            data: {},
            searchStr: '',
            page: 0,
            totalCount: 0,
            maxCount: 10,
         }))
      }
   }, [])

   return {
      ...state,
      fetchData,
      pageChange,
      clearCache,
      resetFilter,
      currentData,
      allData,
      hasMore,
      count,
   }
}
