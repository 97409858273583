import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CustomIcon } from '@plandok/core'
import React from 'react'

import * as SC from '../styles'

const DragHandle = ({ listeners, attributes }: any) => {
   return (
      <td className="drag-cell" {...listeners} {...attributes} style={{ cursor: 'grab' }}>
         <CustomIcon type="smallDragHandle" />
      </td>
   )
}

function TableRow({ value = {}, columns, index, onClick }: any) {
   const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: value.id,
   })

   const style = {
      transform: CSS.Transform.toString(transform),
      transition,
   }

   return (
      <SC.Row
         className={`ant-table-row ant-table-row-level-${index}`}
         data-row-key={value.id}
         ref={setNodeRef}
         style={style}
         onClick={onClick}>
         <DragHandle listeners={listeners} attributes={attributes} />
         {columns.map(({ render: RenderComponent, key, style }: any, index: any) => (
            <td key={key + index} style={style}>
               {RenderComponent ? <RenderComponent value={value} /> : value[key]}
            </td>
         ))}
      </SC.Row>
   )
}

export default TableRow
